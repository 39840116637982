import React from "react";
import FixedMessage from "../../../components/Global/FixedMessage";
import Footer from "../../../components/Global/Footer";
import AvailableCourses from "../../../components/TrainingCenter/AvailableCourses";
import Tracks from "../../../components/TrainingCenter/Tracks";
import Header from "../../../layouts/Header";
import peeps from "./../../../assets/trainingcenter/images/peeps.png"
import capShape1 from "./../../../assets/trainingcenter/svg/caption-shape1.svg"
import capShape2 from "./../../../assets/trainingcenter/svg/caption-shape2.svg"

const TrainingCenter = () => {
    return (
        <>
            {/* Header */}
            <Header
            shadowStyle="pc:shadow-none"
            bgColor="bg-[#5221B5]" />

            {/* fixed message */}
            <FixedMessage />
            
            <div className="flex flex-col mt-[-5px] mobile:w-screen">
                
                {/* caption section */}
                <div className="flex flex-col bg-[#5221B5] py-28 justify-center items-center relative ">

                    {/* text container */}
                    <div className="flex flex-col  text-white text-center gap-7 relative mobile:py-16 mobile:pb-2 mobile:px-8 mx-auto px-12">

                        {/* shape1 */}
                        <img src={capShape2} className="mobile:w-[20px] mobile:top-[3rem] mobile:right-[1rem] absolute z-[200] right-[-9%] top-[-18%]" alt="" />

                        {/* shape2 */}
                        <img src={capShape1} className="mobile:w-[20px] mobile:top-[3rem] mobile:left-[1rem] absolute z-[200] left-[-23%] top-[-25%]" alt="" />

                        <p className="text-6xl mobile:text-4xl leading-[54px] font-semibold">
                            Education is always the key
                        </p>

                        <p className="text-2xl mobile:text-base leading-[36px] font-semibold">
                            We are here to take your tech education to the next level
                        </p>

                        <p className="font-normal text-xl mobile:text-base leading-[30px]">
                            It doesn’t matter if you are a novice, amateur, intermediate or expert. <br/>We have packages for everyone
                        </p>
                    </div>

                    {/* image */}
                    <div className="flex mt-24 justify-center items-center w-[75%] mobile:w-[85%] relative z-[500]">
                        <img src={peeps} className="w-[100%]" alt="" />
                    </div>

                    {/* free container */}
                    <div className="h-[300px] -mb-2 mobile:h-[23vh] w-full bg-white absolute bottom-[0]"></div>
                </div>

                {/* available courses */}
                <AvailableCourses />

                {/* student to employee track */}
                <Tracks />

                {/* footer */}
                <Footer />
            </div>
        </>
    )
}

export default TrainingCenter