import { useEffect } from "react";
import launching from "./../../assets/events/images/event-launching.png";
import capShape2 from "./../../assets/events/svg/caption-shape1.svg";
import capShape1 from "./../../assets/events/svg/caption-shape2.svg";
import launchingMobile from "./../../assets/events/images/launching-mobile.png";
import { getEventsForUsersRequest } from "../../redux/action";
import { useSelector, useDispatch } from "react-redux";
import { Skeleton } from "@mui/material";
import { useHistory } from "react-router-dom";
import EmptyEventSection from "../Dashboard/Home/EmptyEventSection";

const CaptionJumbotron = () => {
  // history object
  const history = useHistory();

  // dispatch object
  const dispatch = useDispatch();

  // event states
  const { events, eventsForUsersLoading } = useSelector((state) => {
    const { events } = state.eventsReducer;
    const { eventsForUsersLoading } = state.loadingIndicator;
    return {
      events,
      eventsForUsersLoading,
    };
  });

  useEffect(() => {
    dispatch(getEventsForUsersRequest());
  }, []);

  const MONTHS = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const DAY = [
    "Sunday",
    "Monday",
    "Tueday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const getData = {
    date: (prop) => {
      return new Date(prop).getDate();
    },
    dayNum: (prop) => {
      return new Date(prop).getDay();
    },
    month: (prop) => {
      return new Date(prop).getMonth();
    },
    year: (prop) => {
      return new Date(prop).getFullYear();
    },
  };

  return (
    <div className="relative">
      <div className="flex flex-col bg-[#FFF8EB] mobile:py-20 mobile:pb-12 py-28 justify-center items-center relative px-8 mobile:px-10">
        {/* text container */}
        <div className="bg-[#FFF8EB] flex flex-col text-center gap-7 relative w-full">
          {/* shape1 */}
          <img
            src={capShape2}
            className="mobile:w-[20px] mobile:top-[-1rem] mobile:right-[1rem] absolute z-[200] right-[30%] top-[-38%]"
            alt=""
          />

          {/* shape2 */}
          <img
            src={capShape1}
            className="mobile:w-[20px] mobile:top-[-1rem] mobile:left-[1rem] absolute z-[200] left-[23%] top-[-35%]"
            alt=""
          />

          <p className="text-6xl mobile:text-4xl leading-[54px] font-semibold text-black">
            Events
          </p>

          <p className="text-2xl mobile:text-base leading-[36px] font-semibold mobile:px-6 px-6">
            Stay updated on our latest programmes and events
          </p>
        </div>

        {eventsForUsersLoading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              zIndex: "1200",
              width: "80%",
              height: "50vh",
              borderRadius: "20px",
              marginTop: "6rem",
            }}
          />
        ) : events.length !== 0 ? (
          <>
            <div
              key={events[events.length - 1]._id}
              className="flex mobile:flex-col mt-24 mobile:mt-12 justify-center items-center h-[464px] mobile:h-fit w-[75%] mobile:w-full [box-shadow:0px_8px_32px_#d6d6d6] mobile:[box-shadow:0px_2px_8px_#d6d6d6] relative z-[1200] rounded-[20px] bg-white mobile:py-6 mobile:px-6"
            >
              {/* images */}
              <div className="w-full pc:w-[50%] relative mobile:h-[50%] h-full">
                <img
                  src={events[events.length - 1].imgurl}
                  className="object-cover mobile:w-full rounded-l-[20px] h-full pc:w-full"
                  alt=""
                />

                {/* date stamp */}
                <div className="flex bg-[#F8F5FF] rounded-[8px] text-[#1D2433] absolute mobile:top-[10px] mobile:left-[10px] md:!top-[50px] md:!left-[50px] top-[25px] left-[25px] gap-6 py-4 px-5 flex-col-reverse items-center justify-center z-[2]">
                  <span className="font-normal text-xl leading-3">
                    {MONTHS[getData.month(events[events.length - 1].date)]}
                  </span>
                  <span className="font-semibold text-4xl leading-3">
                    {`${getData.date(events[events.length - 1].date)}`}
                  </span>
                </div>

                {/* dark transparent bg */}
                <span className="absolute h-full w-full top-0 left-0 pc:rounded-[10px_0_0_10px] bg-black opacity-40 z-[1] rounded-[10px] ">
                  {""}
                </span>
              </div>

              <div className="w-[50%] mobile:w-full flex flex-col justify-center items-center m-auto">
                {/* texts container */}
                <div className="flex flex-col mobile:w-full w-[60%] md:w-[80%] gap-3 py-12 mobile:py-8 mobile:pb-5 md:text-center">
                  {/* time and place */}
                  <p className="flex flex-col mobile:text-xs text-sm font-semibold text-[#6c707a]">
                    <span>{`${
                      DAY[getData.dayNum(events[events.length - 1].date)]
                    } ${events[events.length - 1].time}`}</span>
                    <span>{events[events.length - 1].location}</span>
                  </p>

                  {/* title */}
                  <p className="text-4xl lg:text-xl mobile:text-2xl xl:text-2xls font-semibold">
                    {events[events.length - 1].name}
                  </p>

                  {/* description */}
                  <p className="font-normal text-base text-[#6c707a]">
                    {events[events.length - 1].description
                      .substring(0, 80)
                      .concat("...")}
                  </p>

                  {/* read more link */}
                  <p
                    onClick={() => history.push(`/old/dashboard/events/${events[events.length - 1]._id}`)}
                    className="font-semibold mobile:text-xs text-base text-[#8B54F7] xl:text-sm"
                  >
                    LEARN MORE
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="relative z-[250] bg-[#D9D9D9] w-full flex flex-col items-center rounded-[10px] py-28 mt-20 p-12 container mx-auto">
            <EmptyEventSection />
          </div>
        )}
      </div>

      {/* free container */}
      <div className="h-[300px] bg-white w-full absolute bottom-[0] z-[160]"></div>
    </div>
  );
};

export default CaptionJumbotron;
