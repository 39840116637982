import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getRTDate } from '../../../../../helpers/getRTDate'
import useEventStatus from '../../../../../hooks/useEventStatus'
import { getSingleEventRequest } from '../../../../../redux/action'
import AboutEvent from './AboutEvent'
import EventInfo from './EventInfo'
import Jumbotron from './Jumbotron'

const SingleEventBody = () => {

    // DATA INITIALIZATION
    const [hasEnded] = useEventStatus()
    const location = useLocation();
    const eventId = location.pathname.split("/").pop();
    const dispatch = useDispatch();
    const FULL_MONTHS = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ]


    // STATES
    const {
        singleEvents,
        singleEventLoading,
      } = useSelector((state) => {
        const { singleEvents } = state.eventsReducer;
        const { singleEventLoading } = state.loadingIndicator;
        return {
          singleEvents,
          singleEventLoading,
        };
    });


    // SIDE EFFECTS
    useEffect(() => {
        dispatch(getSingleEventRequest(eventId));
    }, []);

    return (
        <div className='flex flex-col w-full container mx-auto gap-8'>

            {/* jumbotron */}
            <Jumbotron
            image={singleEvents?.imgurl}
            loading={singleEventLoading}
            notEmpty={singleEvents && Object.keys(singleEvents).length!==0} />

            {/* about event and event info container */}
            <div className='flex mobile:flex-col w-full container mx-auto gap-10'>

                {/* about event */}
                <AboutEvent
                description={singleEvents?.description}
                loading={singleEventLoading}
                notEmpty={singleEvents && Object.keys(singleEvents).length!==0}
                hasEnded={hasEnded} />

                {/* event info */}
                <EventInfo
                time={singleEvents?.time}
                guests={singleEvents?.speakers}
                location={singleEvents?.location}
                date={`${getRTDate.date(singleEvents?.date)} ${FULL_MONTHS[getRTDate.month(singleEvents?.date)]}, ${getRTDate.year(singleEvents?.date)}`}
                loading={singleEventLoading}
                notEmpty={singleEvents && Object.keys(singleEvents).length!==0}
                hasEnded={hasEnded} />

            </div>
            
        </div>
    )
}

export default SingleEventBody