import React from 'react'
import { ReactComponent as ImageIcon } from "./../../../assets/dashboard/Settings/svg/image-icon.svg"

const NoFile = () => {
    return (
        <div className='p-6 flex flex-col gap-4 justify-center items-center'>
            
            <ImageIcon />

            <p className='font-normal text-base text-center flex flex-col gap-1'>
                <span>
                    <span className='text-[#8B54F7]'>
                        Upload a file
                    </span> or drag and drop
                </span>
            

                <span className='text-[#6B7280] font-normal text-sm text-center'>
                    PNG, JPG, , GIF upto 5MB
                </span>
            </p>
        </div>
    )
}

export default NoFile