import React from 'react'
import DynamicCheckIcon from '../Global/DynamicCheckIcon'

const MemberShipInfo = ({packageInfo}) => {
    return (
        <div className="flex flex-col bg-white rounded-[10px] [box-shadow:0_2px_8px_rgb(0,0,0,0.12)] p-6 gap-2">

            <p className="text-[#3f444d] mobile:text-lg md:!text-xl text-xl font-semibold">
                Intermediate Level Membership
            </p>

            <p className="text-[#1d2433] opacity-[0.65] text-sm font-normal flex items-center gap-2">
                <span className="font-semibold">
                    Duration:
                </span>
                <span>
                    {packageInfo?packageInfo.duration:" -"} months
                </span>
            </p>

            <p className="text-[#1d2433] font-semibold text-base opacity-[0.65]">
                Benefits
            </p>

            <div className="flex mobile:flex-col mobile:gap-4 justify-between mt-3">
                <div className="w-full mobile:flex mobile:flex-col mobile:gap-2 grid grid-cols-2 pc:justify-between gap-[6px]">
                    <p className="font-normal text-sm text-[#1d2433] opacity-[0.65] flex items-center gap-3">
                        <DynamicCheckIcon 
                        isAvailable={packageInfo.member_swag} />
                        Member Swag
                    </p>

                    <p className="font-normal text-sm text-[#1d2433] opacity-[0.65] flex items-center gap-3">
                        <DynamicCheckIcon 
                        isAvailable={packageInfo.reserved_office_space} />
                        Reserved Office Space
                    </p>

                    <p className="font-normal text-sm text-[#1d2433] opacity-[0.65] flex items-center gap-3">
                        <DynamicCheckIcon 
                        isAvailable={packageInfo.investment_pitch} />
                        Investment Pitch
                    </p>

                    <p className="font-normal text-sm text-[#1d2433] opacity-[0.65] flex items-center gap-3">
                        <DynamicCheckIcon 
                        isAvailable={packageInfo.business_consultancy} />
                        Business Consultacy
                    </p>

                    <p className="font-normal text-sm text-[#1d2433] opacity-[0.65] flex items-center gap-3">
                        <DynamicCheckIcon 
                        isAvailable={packageInfo.life_insurance} />
                        Life Insurance
                    </p>

                    <p className="font-normal text-sm text-[#1d2433] opacity-[0.65] flex items-center gap-3">
                        <DynamicCheckIcon 
                        isAvailable={packageInfo.news_letter} />
                        Newsletter
                    </p>

                    <p className="font-normal text-sm text-[#1d2433] opacity-[0.65] flex items-center gap-3">
                        <DynamicCheckIcon 
                        isAvailable={packageInfo.discounted_services} />
                        Discounted Services
                    </p>

                    <p className="font-normal text-sm text-[#1d2433] opacity-[0.65] flex items-center gap-3">
                        <DynamicCheckIcon 
                        isAvailable={packageInfo.investment_database} />
                        Investment Database
                    </p>

                    <p className="font-normal text-sm text-[#1d2433] opacity-[0.65] flex items-center gap-3">
                        <DynamicCheckIcon 
                        isAvailable={packageInfo.foreign_tech_consultancy} />
                        Foreign Tech Consultancy
                    </p>
                </div>
            </div>
        </div>
    )
}

export default MemberShipInfo