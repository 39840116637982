import React from "react";
import { Switch, Route } from "react-router-dom";
import BootcampForm from ".";
import ThankyouPage from "./ThankyouPage";

const BootcampFormRouter = ({ path }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={BootcampForm} />

        <Route
          exact
          path={`${path}/success`}
          component={ThankyouPage}
        />
      </Switch>
    </>
  );
};

export default BootcampFormRouter;
