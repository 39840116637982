import React from 'react'
import { Link } from 'react-router-dom';
import Accordion from './Accordion';
import RegistrationSteps from './Student/RegistrationSteps';
import MembersStep from "./Member/MembersStep"
import InvestorStep from './investor/InvestorStep';

const RegistrationForm = () => {

    // OBJECT INITIALIZATION
    // accordion list
    const accordionData = [
        {
            number: 1,
            text: "Student"
        },
        {
            number: 2,
            text: "Member"
        },
        {
            number: 3,
            text: "Investor"
        },
    ]
    

    return (
        <>

            {/* container */}
            <div className='flex flex-col justify-center items-center w-full gap-5'>

                {/* form caption */}
                <div className=" text-center flex flex-col gap-6 mobile:gap-3 mobile:mb-5 mb-2">

                    <p className="font-semibold mobile:text-2xl text-4xl text-[#1D2433] leading-none">
                        Create your account
                    </p>
                    <p className="text-base font-normal text-[#667085] leading-none">
                        Get started on your amazing journey into tech
                    </p>
                </div>

                {/* accordions */}
                <div className='flex flex-col w-full mobile:px-2'>

                    {/* student accordion */}
                    <Accordion
                    number={1}
                    headerText={"Student"}>

                        {/* multi-steps */}
                        <RegistrationSteps />
                    </Accordion>

                    {/* member accordion */}
                    <Accordion
                    number={2}
                    headerText={"Member"}>

                        {/* multi-steps */}
                        <MembersStep />
                    </Accordion>

                    {/* investor accordion */}
                    <Accordion
                    number={3}
                    headerText={"Investor"}>

                        {/* multi-steps */}
                        <InvestorStep />
                    </Accordion>
                </div>

                
                {/* sign up/register */}
                <p className="text-[#667085] text-sm font-normal flex gap-[5px] mt-2">
                    <span>Already have an account?</span>

                    <Link to={"/login"} className="font-semibold text-sm text-[#0B8CBA]">
                        Log in
                    </Link>
                </p>
            </div>
        </>
    )
}

export default RegistrationForm