import React from 'react'
import { Link } from 'react-router-dom'
import mainLogo from "./../../assets/home/svg/logo-main.svg"
import MobileNavList from '../../components/Global/MobileNavList'
import { useState } from 'react'
import NavBtn from '../../components/Global/NavBtn'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'

const AuthHeader = () => {

    // mobile navState
    const [isNavOpen, setNavState] = useState(false)

    // toggle navbar mobile
    const closeNav = () => {
        setNavState(!isNavOpen)
    }

    return (

        // header container
        <div className={`bg-white pt-10 mobile:fixed mobile:z-[500] mobile:left-0 mobile:top-0 mobile:pt-0 mobile:flex mobile:justify-center mobile:flex-col mobile:items-center mobile:w-screen`}>

            {/* header component */}
            <header className='pc:[box-shadow:0px_2px_8px_#e0e0e0] mobile:border-b-2 border-[#F2F4F7] bg-white h-[124px] mobile:h-auto w-[90%] mobile:w-full mx-auto justify-center mobile:justify-between items-center flex px-12 mobile:px-7 rounded-[10px] mobile:rounded-none mobile:py-7 mobile relative z-[1000]'>

                {/* logo */}
                <Link to={"/old"}>
                    <img src={mainLogo} alt="main logo icon" />
                </Link>

                {/* mobile nav button */}
                {/* the display value is none for desktop view */}
                <NavBtn onClick={()=>setNavState(!isNavOpen)}>
                    {
                        isNavOpen? <FontAwesomeIcon size="xl" icon={faXmark} /> : <FontAwesomeIcon size='xl' icon={faBars} />
                    }
                </NavBtn>
                
            </header>

            {/* mobile nav items */}
            {/* the display value is none for desktop view */}
            <MobileNavList 
            closeNav={closeNav} 
            isOpen={isNavOpen} />
        </div>
    )
}

export default AuthHeader