import React, { useEffect } from 'react'
import jumbotronImage from "../../../../../assets/redesign-dashboard/bootcamps/images/jumbotron-image2.png"
import SummaryCard from '../../TheClub/SummaryCard'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearputBootcampPaymentSuccess, getSpecificBootcampRequest, putBootcampPaymentRequest } from '../../../../../redux/action'
import { Skeleton } from '@mui/material'
import { toast } from 'react-toastify'
import MyModal from '../../../../../layouts/MyModal'
import SavedAccountView from '../../Settings/SavedAccountView'

const MakePaymentBody = () => {

    // STATES
    const [successfullPayment, setSuccessfullPayment] = React.useState(false)
    const {
        specificBootcamp,
        user,
        specificBootcampLoading,
        bootcampsLoading,
        putBootcampPaymentLoading,
        putBootcampPaymentSuccess,
        putBootcampPaymentError
      } = useSelector((state) => {

        const {
            success: { 
                putBootcampPayment: putBootcampPaymentSuccess 
            },
            errors: { 
                putBootcampPayment: putBootcampPaymentError 
            },
        } = state.ajaxStatuses;

        const { user } = state.userData;
    
        const { specificBootcamp } = state.bootcampReducer;
        const { 
            specificBootcampLoading, 
            bootcampsLoading,
            putBootcampPaymentLoading
        } = state.loadingIndicator;
    
        return {
            specificBootcamp,
            user,
            bootcampsLoading,
            specificBootcampLoading,
            putBootcampPaymentLoading,
            putBootcampPaymentSuccess,
            putBootcampPaymentError
        };
    });


    // DATA INITIALIZATION
    const location = useLocation();
    const history = useHistory()
    const bootcampId = location.pathname.split("/").pop();
    const dispatch = useDispatch();
    const paystackProps = {
        email: user.email,
        amount: Number(specificBootcamp?.new_price) * 100,
        metadata: {
            name: user.firstname + " " + user.lastname,
            phone: user.phone,
        },
        publicKey: `${process.env.REACT_APP_LIVE_PAYSTACK_KEY}`,
        text: "Make Payment",
        onSuccess: (e) => {
            console.log("payment was succefull", e.reference);

            let formData = {
                email: user.email,
                club_id: bootcampId,
                reference: e.reference,
            };
            
            dispatch(
                putBootcampPaymentRequest({
                    formData,
                })
            );
        },
        onClose: () => {},
    };


    // HANDLERS
    const toggleSuccessPaymentModal = () => {
        setSuccessfullPayment(!successfullPayment)
    }


    // SIDE EFFECTS
    useEffect(() => {
        dispatch(getSpecificBootcampRequest(bootcampId));
    }, [bootcampId]);

    // checking for success or errors
    useEffect(() => {
        if (putBootcampPaymentSuccess) {
            toggleSuccessPaymentModal()
            setTimeout(() => {
                setSuccessfullPayment(false)
                history.push("/dashboard/bootcamps")
            }, 3000);

            // clear success message and fetch data
            dispatch(clearputBootcampPaymentSuccess());
            dispatch(getSpecificBootcampRequest(bootcampId));
        }
    }, [putBootcampPaymentSuccess]);

    useEffect(() => {
        if (putBootcampPaymentError) {
            toast.error(putBootcampPaymentError, {
                duration: 3000,
            });
        }
    }, [putBootcampPaymentError]);

    return (
        <div className='flex mobile:flex-col w-full h-full gap-10'>

            {
                specificBootcampLoading?
                <Skeleton
                variant="rectangular"
                sx={{
                width: "100%",
                height: "70vh",
                borderRadius: "10px",
                }} />:
                (specificBootcamp && specificBootcamp)?
                <>
            
                    {/* package*/}
                    <div className='h-full w-[70%] mobile:w-full flex flex-col gap-10'>

                        <div className='gap-10 flex mobile:flex-col p-5 rounded-[10px] bg-white border border-[#E1E6EF]'>

                            {/* texts section */}
                            <section className='flex flex-col gap-4 items-start w-[60%] mobile:w-full'>

                                {/* label */}
                                <span className='flex items-center justify-center text-[#3F444D] font-semibold text-[11px] rounded-[8px] bg-[#E1E6EF] h-[32px] px-3'>
                                    {specificBootcamp.pathway}
                                </span>

                                {/* texts */}
                                <div className='flex flex-col gap-2 items-start'>
                                    <p className='text-gray-800 font-semibold text-[22px] mt-0'>
                                        {specificBootcamp.name}
                                    </p>

                                    <p className='font-normal text-[22px] text-gray-800 mt-0'>
                                        {specificBootcamp.duration} Months Course
                                    </p>
                                </div>

                                {/* description for pc view*/}
                                <p className='mt-0 text-gray-800 text-base text-normal mobile:hidden'>
                                    {specificBootcamp.description}
                                </p>

                            </section>

                            {/* image section */}
                            <div className='h-fit w-[40%] mobile:w-full'>
                                <img src={jumbotronImage} className='object-cover h-full w-full rounded-[10px]' alt="" />
                            </div>

                            {/* description for mobile view */}
                            <p className='mt-0 text-gray-800 text-base text-normal hidden mobile:block'>
                                {specificBootcamp.description}
                            </p>
                        </div>
                    </div>
                    
                    {/* summary */}
                    <div className='h-full w-[30%] mobile:w-full flex flex-col'>

                        <SummaryCard
                        hasSubscriptionText={"Already a Student!"}
                        hasSubscription={user.user_type.includes("student")}
                        paystackProps={paystackProps}
                        paymentLoading={putBootcampPaymentLoading}
                        originalPrice={specificBootcamp.old_price.toLocaleString("en-US")}
                        promoPrice={specificBootcamp.new_price.toLocaleString("en-US")} />
                    </div>
                </>:
                <div className='flex justify-center items-center w-full h-[70vh] text-2xl rounded-[10px]'>Nothing Here</div>
            }

            {/* successful message modal */}
            <MyModal
            disableBtn={true}
            modalIsOpen={successfullPayment}
            toggleModal={toggleSuccessPaymentModal}>

                <SavedAccountView
                closeModal={toggleSuccessPaymentModal} />
            </MyModal>
        </div>
    )
}

export default MakePaymentBody