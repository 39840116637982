import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const MyLink2 = ({to, children}) => {

    // OBJECT INITIALIZATION
    const location = useLocation()
    return (
        <>
            <NavLink 
            isActive={()=>location.pathname.includes(to)}
            exact 
            to={to} 
            className="text-[#667085] mobile:text-[#101828] font-semibold text-xs flex justify-center items-center transition-all hover:no-underline hover:text-[#0B8CBA] hover:bg-[#F9F5FF] rounded-[8px] mobile:rounded-none h-[40px] px-5 outline-none mobile:justify-start"
            activeClassName='pc:[outline:1px_solid_#E1E6EF] mobile:text-[#0B8CBA] text-[#055774] mobile:bg-[#F8F5FF]'>
                {children}
            </NavLink>
        </>
    )
}

export default MyLink2