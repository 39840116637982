import React from 'react'
import { IoIosArrowBack } from 'react-icons/io'

const BackBtn = ({disabled, onClick}) => {
    return (
        <button
        disabled={disabled}
        onClick={onClick}
        className='text-white inline-flex items-center mobile:justify-center gap-2 h-[40px] bg-[#0B8CBA] px-[18px] rounded-[8px] hover:opacity-80 [box-shadow:0px_1px_2px_rgba(16,24,40,0.05)] border border-[#0B8CBA] disabled:opacity-30'>
            <IoIosArrowBack
            className='text-xl' />

            <span
            className='text-base font-semibold'>
                Back
            </span>
        </button>
    )
}

export default BackBtn