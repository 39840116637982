import React from 'react'
import Header2 from '../../../../../layouts/Redesign/Header2'

const EventDetailsHeader = () => {

    return (
        <div className='flex items-center justify-start bg-[#FFF8EB] h-[42vh] 2xl:h-[50vh] mobile:h-[38vh] relative flex-col top-0'>

            {/* header */}
            <Header2 />

            {/* jumbotron */}
            <div className='w-full flex flex-col items-center top-[20%] mobile:top-[38%] relative z-[200] gap-4 text-center container mx-auto mobile:px-4'>

                <p className='font-semibold text-[45px] mobile:text-4xl text-[#1D2433] mobile:mt-[1rem]'>
                    First Enugu Tech Networking Event
                </p>
            </div>
        </div>
    )
}

export default EventDetailsHeader