import React from 'react'
import { Switch } from 'react-router-dom'
import BootcampsRouter from './Bootcamps/router'
import EventsRouter from './Events/router'
import InvestingRouter from './Investing/router'
import SettingsRouter from './Settings/router'
import SpaceBookingRouter from './SpaceBooking/router'
import TheClubRouter from './TheClub/router'

const RedesignedDboardRouter = ({match: {path}}) => {
    
    return (
        <>
            <Switch>                

                {/* router for the club page */}
                <TheClubRouter
                path={`${path}/club`} />

                {/* router for bootcamps page */}
                <BootcampsRouter
                path={`${path}/bootcamps`} />

                {/* investing router */}
                <InvestingRouter
                path={`${path}/investing`} />

                {/* router for space booking page */}
                <SpaceBookingRouter
                path={`${path}/space-booking`} />

                {/* router for events page */}
                <EventsRouter
                path={`${path}/events`} />

                {/* router for the settings page */}
                <SettingsRouter
                path={`${path}/settings`} />

            </ Switch>
        </>
    )
}

export default RedesignedDboardRouter