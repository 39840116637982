import React from "react";
import BreakTextsMobile from "../Global/BrTextsMobile";
import capShape2 from "./../../assets/contactus/svg/caption-shape1.svg";
import capShape1 from "./../../assets/contactus/svg/caption-shape2.svg";
import mail from "./../../assets/contactus/svg/mail.svg";
import phone from "./../../assets/contactus/svg/phone.svg";
import MessageForm from "./MessageForm";

const GetInTouch = () => {
  return (
    <div className="flex flex-col bg-[#F8F9FC] py-28 justify-center items-center relative md:px-8 mobile:px-4 px-8 ">
      {/* text container */}
      <div className="flex flex-col text-center gap-7 relative w-full mobile:px-4">
        {/* shape1 */}
        <img
          src={capShape2}
          className="mobile:w-[20px] mobile:top-[-1rem] mobile:right-[1rem] absolute z-[200] right-[30%] top-[-18%]"
          alt=""
        />

        {/* shape2 */}
        <img
          src={capShape1}
          className="mobile:w-[20px] mobile:top-[-1rem] mobile:left-[1rem] absolute z-[200] left-[23%] top-[-15%]"
          alt=""
        />

        <p className="text-6xl mobile:text-4xl leading-[54px] font-semibold text-[#000]">
          Get in touch!
        </p>

        <p className="text-2xl mobile:text-base leading-[36px] font-semibold text-[#1D2433] opacity-80">
          Stay updated on our latest programmes and events
        </p>

        {/* email, address and phone */}
        <div className="container mx-auto grid grid-cols-3 lg:grid-cols-2  mobile:flex mobile:flex-col mobile:pxn-8 justify-center items-center gap-8 mt-8 mobile:w-full ">

          {/* email */}
          <div className="flex items-center justify-start gap-4 p-4 [box-shadow:0px_2px_8px_#e0e0e0] rounded-[8px] bg-white mobile:w-full h-full">
            <div className="h-[56px] mobile:h-[50px] mobile:min-w-[50px] bg-[#E1E6EF] w-[56px] rounded-[12px] flex justify-center items-center">
              <img src={mail} className="mobile:min-w-[24px]" alt="" />
            </div>

            <p className="flex flex-col items-start text-[#1D2433] opacity-80 text-base font-semibold">
              <span className="font-normal">Email:</span>
              <span>info@aimovement.tech</span>
            </p>
          </div>

          {/* address */}
          <div className="flex items-center justify-start gap-4 p-4 [box-shadow:0px_2px_8px_#e0e0e0] rounded-[8px] bg-white mobile:w-full h-full">
            <div className="h-[56px] mobile:h-[50px] mobile:w-[50px] bg-[#E1E6EF] rounded-[12px] flex justify-center items-center w-[56px]">
              <img src={mail} className="mobile:min-w-[24px]" alt="" />
            </div>

            <p className="flex flex-col items-start text-[#1D2433] opacity-80 text-base font-semibold w-[80%]">
              <span className="font-normal">Address:</span>
              <span className="text-left">No. 1 Snap Drive, Olive Gate Hotel Junction, Independence Layout, Enugu Nigeria</span>
            </p>
          </div>

          {/* phone */}
          <div className="flex items-center justify-start gap-4 p-4 [box-shadow:0px_2px_8px_#e0e0e0] rounded-[8px] bg-white mobile:w-full h-full">
            <div className="h-[56px] bg-[#E1E6EF] w-[56px] rounded-[12px] flex justify-center items-center">
              <img src={phone} alt="" />
            </div>

            <div className="flex flex-col  text-[#1D2433] opacity-80 text-base font-semibold items-start">
              <span className="font-normal">Phone:</span>
              <div className="flex mobile:flex-col gap-2 mobile:gap-0">
                <span>0703 0470 857, </span><span>0817 0988 894</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Leave us a message */}
      <MessageForm />

      {/* free container */}
      <div className="h-[600px] w-full bg-white absolute bottom-[0]"></div>
    </div>
  );
};

export default GetInTouch;
