import { GET_SINGLE_SPACE_FAIL, GET_SINGLE_SPACE_LOADING, GET_SINGLE_SPACE_REQUEST, GET_SINGLE_SPACE_SUCCESS, GET_SPACES_FAIL, GET_SPACES_LOADING, GET_SPACES_REQUEST, GET_SPACES_SUCCESS, GET_USER_SPACES_FAIL, GET_USER_SPACES_LOADING, GET_USER_SPACES_REQUEST, GET_USER_SPACES_SUCCESS, SPACE_PAYMENT_FAIL, SPACE_PAYMENT_LOADING, SPACE_PAYMENT_REQUEST, SPACE_PAYMENT_SUCCESS, SPACE_PAYMENT_SUCCESS_CLEAR, SPACE_REGISTRATION_FAIL, SPACE_REGISTRATION_LOADING, SPACE_REGISTRATION_REQUEST, SPACE_REGISTRATION_SUCCESS, SPACE_REGISTRATION_SUCCESS_CLEAR } from "../types";

// get spaces
export const getSpacesRequest = (prop) => {
    return {
        type: GET_SPACES_REQUEST,
        payload: prop
    }
}

export const getSpacesSuccess = (prop) => {
    return {
        type: GET_SPACES_SUCCESS,
        payload: prop,
    };
};
  
export const getSpacesFailure = (errors) => {
    return {
        type: GET_SPACES_FAIL,
        payload: { errors },
    };
};

export const getSpacesLoading = (loading) => {
    return {
        type: GET_SPACES_LOADING,
        payload: loading,
    };
};

// get single space
export const getSingleSpaceRequest = (prop) => {
    return {
        type: GET_SINGLE_SPACE_REQUEST,
        payload: prop
    }
}

export const getSingleSpaceSuccess = (prop) => {
    return {
        type: GET_SINGLE_SPACE_SUCCESS,
        payload: prop,
    };
};
  
export const getSingleSpaceFailure = (errors) => {
    return {
        type: GET_SINGLE_SPACE_FAIL,
        payload: { errors },
    };
};

export const getSingleSpaceLoading = (loading) => {
    return {
        type: GET_SINGLE_SPACE_LOADING,
        payload: loading,
    };
};

// get user space
export const getUserSpacesRequest = (prop) => {
    return {
        type: GET_USER_SPACES_REQUEST,
        payload: prop
    }
}

export const getUserSpacesSuccess = (prop) => {
    return {
        type: GET_USER_SPACES_SUCCESS,
        payload: prop,
    };
};
  
export const getUserSpacesFailure = (errors) => {
    return {
        type: GET_USER_SPACES_FAIL,
        payload: { errors },
    };
};

export const getUserSpacesLoading = (loading) => {
    return {
        type: GET_USER_SPACES_LOADING,
        payload: loading,
    };
};

// SPACE REGISTRATION
export const spaceRegistrationRequest = (prop) => {
    return {
        type: SPACE_REGISTRATION_REQUEST,
        payload: prop
    }
}

export const spaceRegistrationSuccess = (prop) => {
    return {
        type: SPACE_REGISTRATION_SUCCESS,
        payload: prop,
    };
};
  
export const spaceRegistrationFailure = (errors) => {
    return {
        type: SPACE_REGISTRATION_FAIL,
        payload: { errors },
    };
};

export const spaceRegistrationLoading = (loading) => {
    return {
        type: SPACE_REGISTRATION_LOADING,
        payload: loading,
    };
};

export const clearSpaceRegistrationSuccess = (loading) => {
    return {
        type: SPACE_REGISTRATION_SUCCESS_CLEAR,
    };
};

// SPACE PAYMENT
export const spacePaymentRequest = (prop) => {
    return {
        type: SPACE_PAYMENT_REQUEST,
        payload: prop
    }
}

export const spacePaymentSuccess = (prop) => {
    return {
        type: SPACE_PAYMENT_SUCCESS,
        payload: prop,
    };
};
  
export const spacePaymentFailure = (errors) => {
    return {
        type: SPACE_PAYMENT_FAIL,
        payload: { errors },
    };
};

export const spacePaymentLoading = (loading) => {
    return {
        type: SPACE_PAYMENT_LOADING,
        payload: loading,
    };
};

export const clearSpacePaymentSuccess = (loading) => {
    return {
        type: SPACE_PAYMENT_SUCCESS_CLEAR,
    };
};
