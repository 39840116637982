import React, { useEffect, useState } from 'react'
import Accordion from '../../Webpage/TrainingCenter/Accordion'

const CourseOutline = ({topics}) => {

    // STATES
    const [topicData, setTopicData] = useState([])


    // SIDE EFFECTS
    useEffect(()=>{
        setTopicData(topics)

        return ()=> {
            setTopicData([])
        }
    }, [topics])

    return (
        <div className='bg-white flex flex-col gap-4 py-6 px-5 rounded-[10px] border border-[#E1E6EF]'>
            
            {/* caption */}
            <p className='text-[#3F444D] text-base font-medium'>
                Course outline
            </p>

            {/* <Accordion /> */}
            {
                !(topicData && Array.isArray(topicData))?
                <></>:
                <>
                    {
                        topicData?.map(({sub_topics, content, _id}, index)=>(
                            <Accordion
                            courseItems={content.split("%")}
                            couseTitle={sub_topics}
                            key={_id}
                            weekNumber={index+1} />
                        ))
                    }
                </>
            }

        </div>
    )
}

export default CourseOutline