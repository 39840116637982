import { useFormik } from 'formik'
import { AnimatePresence } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from "yup"
import StepBtn from '../Student/StepBtn'
import InvestorBenefits from './InvestorBenefits'
import InvestorForm from './InvestorForm'

const InvestorStep = () => {

    // STATES
    const [disableStepBtn, setDisableStepBtn] = useState({
        back: true,
        next: false
    })
    const [pages, setPages] = useState(1)
    const [x, setX] = useState(0)
    const [referenceId, setReferenceId] = useState("")
    const {
        getUserLoading,
        getUserError,
        clubRegisterSuccess,
        clubRegisterError,
        clubRegisterLoading,
        user,
        singleUser,
    } = useSelector((state) => {

        const {
        success: { 
            clubRegister: clubRegisterSuccess 
        },
        errors: { 
            getUser: getUserError, 
            clubRegister: clubRegisterError },
        } = state.ajaxStatuses;
        const { 
            clubRegisterLoading, getUserLoading 
        } = state.loadingIndicator;
        const { user, singleUser } = state.userData;
    
        return {
            getUserLoading,
            getUserError,
            clubRegisterSuccess,
            clubRegisterError,
            clubRegisterLoading,
            user,
            singleUser,
        };
    });


    // OBJECT INITIALIZATION - COMPONENT DATA
    const history = useHistory()
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: {
            club_id: "",
            reference: "",
            firstname: "",
            lastname: "",
            mobile: "",
            email: "",
            password: "",
            password_confirmation: "",
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required("First name is required").min(3).max(15),
            lastname: Yup.string().required("First name is required").min(3).max(15),
            mobile: Yup.string().required("Mobile number is required").min(10).max(14),
            email: Yup
            .string()
            .email("Email is not valid")
            .required("Email is required"),
            password: Yup.string().required("Password is required"),
            password_confirmation: Yup.string().required("Password is required"),
        }),
        onSubmit: (formData) => {
            formData.occupation = "clubMember"
            formData.reference = referenceId
            console.log(formData)
            // let refId = query.get("refId");

            // dispatch(clubRegisterRequest({ formData}));
        },
    });
    const componentList = [
        <InvestorBenefits
        pages={pages}
        setPages={setPages}
        x={x}
        disableBtn={setDisableStepBtn}
        setX={setX} />,

        <InvestorForm
        pages={pages}
        setPages={setPages}
        x={x}
        setX={setX}
        formik={formik}
        disableBtn={setDisableStepBtn}
        registerLoading={clubRegisterLoading}
        setReferenceId={setReferenceId} />
    ]


    // HANDLERS
    const onNextBtnClick = () => {
        setPages(pages+1)
        setX(1000)
    }
    const onBackBtnClick = () => {
        setPages(pages-1)
        setX(-1000)
    }


    // SIDE EFFECTS
    // check for errors
    useEffect(() => {
        if (clubRegisterError) {
            toast.error(clubRegisterError, {
                duration: 3000,
            });
        }
    }, [clubRegisterError]);

    // success message
    useEffect(() => {
        if (clubRegisterSuccess) {
            toast.success(clubRegisterSuccess, {
                duration: 3000,
            });
            history.replace("/dashboard/investing")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubRegisterSuccess]);

    return (
        <div className="flex flex-col items-start gap-3 w-full h-full">
            <AnimatePresence>
                {componentList[pages-1]}
            </AnimatePresence>

            {/* step button */}
            <StepBtn
            disableBack={disableStepBtn.back}
            backClick={onBackBtnClick}
            onClick={onNextBtnClick}
            disabled={disableStepBtn.next} />
        </div>
    )
}

export default InvestorStep