import React, { useEffect } from 'react'
import { FiArrowRight } from "react-icons/fi"
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getEventsForUsersRequest } from '../../../../redux/action'

const EventToggle = () => {


    // OBJECT INITIALIZATION
    const history = useHistory()
    const dispatch = useDispatch()

    
    // STATES
    const {
        events,
        eventsForUsersLoading
    } = useSelector((state)=>{
        const {events} = state.eventsReducer
        const {eventsForUsersLoading} = state.loadingIndicator
        return {
            events,
            eventsForUsersLoading
        }
    })


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getEventsForUsersRequest())
    }, [])

    return (
        <div className='flex items-center bg-[#EDFAFE] p-[4px_10px_4px_4px] rounded-[10px] h-[32px] gap-3 w-fit'>

            <div className='flex items-center justify-center bg-white rounded-[10px] p-[2px_10px] font-medium text-sm mobile:text-xs text-[#055774]'>
                New Event
            </div>

            {
                eventsForUsersLoading?
                <p className='mt-0 cursor-pointer flex items-center text-[#055774] font-medium text-sm mobile:text-xs gap-2'>
                    Loading...
                </p>:
                events && events.length!==0?
                <div 
                onClick={()=>history.push("/events")}
                className='cursor-pointer flex items-center text-[#055774] font-medium text-sm mobile:text-xs gap-2'>
                    <span>{events?.slice(-1)[0].name.slice(0,15).concat("...")}</span>
                    <FiArrowRight
                    color='#0B8CBA'
                    size={20} />
                </div>:
                <p className='mt-0 cursor-pointer flex items-center text-[#055774] font-medium text-sm mobile:text-xs gap-2'>
                    No new event
                </p>                
            }
        </div>
    )
}

export default EventToggle