import React from 'react'
import { AiFillCalendar } from 'react-icons/ai'
import { GrUserExpert } from 'react-icons/gr'
import { HiOutlineOfficeBuilding, HiUserCircle } from 'react-icons/hi'
import { TbCertificate } from 'react-icons/tb'
import BtnPrimary from '../../../../Global/Redesign/BtnPrimary'
import jumbotronImage from "../../../../../assets/redesign-dashboard/bootcamps/images/jumbotron-image2.png"
import instructorImage from "../../../../../assets/redesign-dashboard/bootcamps/images/avatar.png"
import { useHistory } from 'react-router-dom'

const GetStartedCard = ({price, path, duration}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()

    return (
        <div
        id='getStarted' 
        className='flex flex-col border border-[#E1E6EF] bg-white rounded-[10px] gap-5 p-5 w-full'>
            
            {/* image and price info */}
            <div className='gap-4 flex flex-col w-full'>

                <img src={jumbotronImage} className='w-full rounded-[10px] object-cover mobile:hidden' alt="" />

                <div className='flex justify-center items-center gap-1 w-full'>

                    <span className='font-bold text-base text-[#0B8CBA]'>
                        NGN {price}
                    </span>

                    <span className='text-white text-[10px] font-semibold bg-[#E02D3C] h-[22px] px-3 rounded-[8px] flex items-center justify-center'>
                        Promo price
                    </span>
                </div>
            </div>

            {/* button */}
            <BtnPrimary
            onClick={()=>history.push(`/dashboard/bootcamps/checkout/${path}`)}
            title={"Get started now"} />

            {/* what the bootcamp includes */}
            <div className='flex flex-col items-start gap-4'>

                <p className='mt-0 text-[#3F444D] font-medium text-base'>
                    This course includes
                </p>

                <div className='flex flex-col gap-3'>
                    <p className="font-normal text-base text-[#3f444d] flex items-center justify-start gap-3 mt-0">
                        <AiFillCalendar size={24} color={"#000"} />
                        {duration} months intensive training
                    </p>

                    <p className="font-normal text-base text-[#3f444d] flex items-center justify-start gap-3 mt-0">
                        <HiOutlineOfficeBuilding size={24} color={"#000"} />
                        Physical/Online learning
                    </p>

                    <p className="font-normal text-base text-[#3f444d] flex items-center justify-start gap-3 mt-0">
                        <GrUserExpert size={24} color={"#000"} />
                        Access to industry experts
                    </p>

                    <p className="font-normal text-base text-[#3f444d] flex items-center justify-start gap-3 mt-0">
                        <TbCertificate size={24} color={"#000"} />
                        Certificate of completion
                    </p>
                </div>
            </div>

            {/* course Instructor */}
            <div className='border-t border-[#E1E6EF] py-5 gap-4 flex flex-col items-start'>

                {/* instructor caption text */}
                <p className='text-[#3F444D] text-base font-medium mt-0'>
                    Course Instructor
                </p>

                {/* profile */}
                <div className='w-full flex items-center gap-4'>

                    {/* <img src={instructorImage} className='h-[52px] w-[52px] object-cover rounded-[10px]' alt="" /> */}
                    <HiUserCircle className='text-6xl' />

                    <div className='flex flex-col w-[85%]'>
                        <p className='mt-0 font-bold text-base text-[#3F444D]'>
                            Engr Jideobi Nna
                        </p>

                        <p className='mt-0 text-[#3F444D] text-base font-normal'>
                            Senior Front-end Dev
                        </p>
                    </div>
                </div>

                {/* instructor description */}
                <div className='text-[#3F444D] font-normal text-base'>
                    Jideobi Nna is a leading tech enthusiasts, programmer and teacher with our five year of experience working in the tech industry.
                </div>
            </div>
        </div>
    )
}

export default GetStartedCard