import React from "react";
import { ReactComponent as Facebook } from "./../../../assets/global/svg/facebook.svg";
import { ReactComponent as Twitter } from "./../../../assets/global/svg/twitter.svg";
import { ReactComponent as Linkedin } from "./../../../assets/global/svg/linkedin.svg";
import { ReactComponent as Github } from "./../../../assets/global/svg/github.svg";
import { ReactComponent as Behance } from "./../../../assets/global/svg/behance.svg";
import { ReactComponent as Dribble } from "./../../../assets/global/svg/dribble.svg";

const AuthFooter = () => {
  // get and store current year for footer copyright
  const currentYear = new Date().getFullYear();

  return (
    <div className="mobile:hidden">
      {/* social links */}
      <div className="flex justify-between pb-20 border-t pt-10 mx-32">
        {/* copyright */}
        <p className="text-[#98a2b3] text-base font-normal">
          &copy; {`${currentYear}`} AI Movement. All rights reserved.
        </p>

        {/* social icons */}
        <div className="flex gap-5">
          <Twitter />
          <Linkedin />
          <Facebook />
          <Github />
          <Behance />
          <Dribble />
        </div>
      </div>
    </div>
  );
};

export default AuthFooter;
