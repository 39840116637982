import React from "react";
import SettingsBody from "../../../components/Dashboard/Settings/SettingsBody";
import SideNav from "../../../layouts/Dashboard/SideNav";

const DashboardSettings = () => {
  return (
    <div className="flex justify-end mobile:w-screen mobile:flex-col h-full w-full mobile:bg-[#E5E5E5]">
      {/* SideNav */}
      <SideNav />

      {/* Home Body */}
      <SettingsBody />
    </div>
  );
};

export default DashboardSettings;
