import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Investing from '.'
import AboutInvesting from './AboutInvestment'
import ActiveInvestment from './ActiveInvestment'
import Payment from './Payment'
import SingleInvestment from './SingleInvestment'

const InvestingRouter = ({path}) => {
    
    return (
        <Switch>
            
            {/* index page */}
            <Route 
            exact
            path={path}
            component={Investing}/>
            
            {/* about investment page */}
            <Route 
            exact
            path={`${path}/about-investment`}
            component={AboutInvesting}/>
            
            {/* active investment page */}
            <Route 
            exact
            path={`${path}/active-investment`}
            component={ActiveInvestment}/>
            
            {/* single investment page */}
            <Route 
            exact
            path={`${path}/:investingId`}
            component={SingleInvestment}/>
            
            {/* single investment payment page */}
            <Route 
            exact
            path={`${path}/payment/:investingId`}
            component={Payment}/>
        </Switch>
    )
}

export default InvestingRouter