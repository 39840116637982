import { CircularProgress } from "@material-ui/core";
import { useState } from "react";
import {
  getStorage,
  sRef,
  uploadBytesResumable,
  getDownloadURL,
} from "../../../firebase/fireconfig"
import { useDispatch, useSelector } from "react-redux";
import { updateUserRequest } from "../../../redux/action/updateUser.js";

const PickedFile = ({ file, closeModal }) => {
  const [error, setError] = useState(null);
  const [url, setUrl] = useState("");
  const [uploadUrl, setUploadUrl] = useState("");
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  // dispatch object
  const dispatch = useDispatch();

  const { updateUserLoading, token } = useSelector((state) => {
    const { updateUserLoading } = state.loadingIndicator;

    const { token } = state.userData;

    return {
      token,
      updateUserLoading,
    };
  });

  const fileUpload = async () => {
    setLoading(true);

    const storage = getStorage();

    const storageRef = sRef(storage, "image/" + file.name);

    console.log(file.name);

    const uploadTask = uploadBytesResumable(storageRef, file);

    let imgUrl = "";
    let progress = "";
    let error;

    uploadTask.on(
      "state-change",
      (snap) => {
        progress = Math.floor((snap.bytesTransferred / snap.totalBytes) * 100);
        setProgress(progress);
      },
      (err) => {
        error = "Image not uploaded";
        setLoading(false);
      },
      async () => {
        imgUrl = await getDownloadURL(uploadTask.snapshot.ref);

        setUploadUrl(imgUrl);

        if (imgUrl) {
          let formData = {
            avatar: imgUrl,
          };

          dispatch(updateUserRequest({ formData, token }));

          setLoading(false);
        }
      }
    );
  };

  return (
    <div className="flex flex-col items-center gap-3 w-full mt-5">
      {/* selected image preview */}
      <img
        src={file.preview}
        className="[border:2px_dashed_#7F56D9] h-[30vh] w-[30vh] object-cover rounded-[50%]"
        alt="selected photograph"
      />

      {/* upload button */}
      <button
        onClick={() => fileUpload()}
        className="mt-5 h-[44px] self-stretch bg-[#7F56D9] flex justify-center items-center font-semibold text-base text-white px-7 lg:text-xs rounded-[8px] hover:opacity-80"
      >
        {updateUserLoading || loading ? (
          <CircularProgress style={{ color: "white" }} size={20} />
        ) : (
          "Upload"
        )}
      </button>

      {/* cancel button */}
      <button
        onClick={closeModal}
        className="h-[44px] self-stretch bg-[#E02D3C] flex justify-center items-center font-semibold text-base text-white px-7 lg:text-xs rounded-[8px] hover:opacity-80"
      >
        Cancel
      </button>
    </div>
  );
};

export default PickedFile;
