import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import jumbotronImage from "../../../../assets/webpages/club/images/jumbotron-image.png"
import Footer2 from '../../../../layouts/Redesign/Footer2'
import { getClubPackagesRequest } from '../../../../redux/action'
import BookSpace from '../Spaces/BookSpace'
import ClubInfo from './ClubInfo'
import ClubMembership from './ClubMembership'

const ClubBody = () => {

        // STATES
        const [clickedCard, setClickedCard] = useState(null)
        const {
            clubsPackages,
            clubsPackagesLoading
        } = useSelector(state=>{
            const { clubsPackagesLoading } = state.loadingIndicator
            const { clubsPackages } = state.clubPackagesReducer
    
            return {
                clubsPackagesLoading,
                clubsPackages
            }
        })
    
    
        // OBJECT INITIALIZATION
        const dispatch = useDispatch()
    
    
        // SIDE EFFECT
        useEffect(()=>{
            dispatch(getClubPackagesRequest())
        }, [])

        
    return (
        <div className='mobile:w-screen w-full'>

            {/* showcase image */}
            <div className="flex flex-col items-stretch [border:0px_transparent_solid] -mt-1 w-full">
                
                {/* first div */}
                <div className="w-full flex 2xl:h-[232px] h-[200px] md:!h-[140px] mobile:h-[55px] bg-[#1B1F27]">
                </div>

                {/* second div */}
                <div className="w-full flex 2xl:h-[232px] h-[200px] md:!h-[140px] mobile:h-[55px] relative">

                    {/* image */}
                    <img src={jumbotronImage} className="w-[80%] absolute bottom-[0] right-[50%] translate-x-[50%]" alt="" />

                </div>
            </div>

            {/* ClubInfo */}
            <ClubInfo />

            {/* club memberships */}
            <ClubMembership
            clubsPackages={clubsPackages}
            clubsPackagesLoading={clubsPackagesLoading} />

            {/* Book space */}
            <BookSpace />

            {/* Footer */}
            <Footer2 />
        </div>
    )
}

export default ClubBody