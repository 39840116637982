import React from "react";
import BtnSm from "../Global/BtnSm";
import groupPic from "./../../assets/home/svg/group-pic.svg";
import growLady from "./../../assets/home/images/grow-image.png";
import shape1 from "./../../assets/home/svg/shape1.svg";
import shape2 from "./../../assets/home/svg/shape2.svg";
import shape3 from "./../../assets/home/svg/shape3.svg";
import { useHistory } from "react-router-dom";

const Grow = () => {
  // history object
  const history = useHistory();

  return (
    <div className="flex mobile:flex-col-reverse">
      {/* image container */}
      <div className="h-[656px] mobile:h-auto w-full flex justify-start items-end relative">
        {/* image */}
        <img
          src={growLady}
          alt="group photograph"
          className="w-full h-full object-cover"
        />
      </div>

      {/* text container */}
      <div className="flex h-[656px] mobile:py-16 mobile:h-auto px-8 md:px-16 justify-center items-center w-full bg-[#1d2433] relative">
        {/* shape2 */}
        <img
          src={shape2}
          alt=""
          className="absolute left-[-3%] bottom-[20%] mobile:hidden"
        />

        {/* shape3 */}
        <img
          src={shape1}
          alt=""
          className="absolute top-[15%] right-[25%] mobile:hidden"
        />

        {/* inner container */}
        <div className=" flex flex-col justify-center items-start mobile:items-stretch mx-auto gap-5 relative mobile:text-center pc:pr-7 pc:pl-9">
          {/* first text */}
          <p className="font-semibold text-4xl mobile:text-2xl leading-[54px] text-white">
            Grow your career the <br className="mobile:hidden" />
            right way with the right <br className="mobile:hidden" />
            team
          </p>

          {/* second text */}
          <p className="font-medium text-xl mobile:text-base mobile:font-normal leading-[30px] text-white mb-3">
            AI Movement is for those who would like to know more about
            technology trends and are interested in web application, database
            management, system analysis, artificial intelligence, robotics and
            other disruptive technologies.
          </p>

          {/* button */}
          <BtnSm onClick={() => history.push("/old/login")} title={"Get started"} />
        </div>
      </div>
    </div>
  );
};

export default Grow;
