import React from 'react'
import Body from '../../../../components/Redesign/Webpage/Login/Body'
import Footer from '../../../../components/Redesign/Webpage/Login/Footer'
import RegistrationBody from '../../../../components/Redesign/Webpage/Spaces/Register/RegistrationBody'

const Register = () => {
    return (
        <div className='flex flex-col w-full min-h-screen'>

            {/* body */}
            <Body>
                <RegistrationBody />
            </Body>

            {/* footer */}
            <Footer />

        </div>
    )
}

export default Register