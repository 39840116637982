import React from 'react'
import FAQ from '../../../components/ContactUs/FAQ'
import GetInTouch from '../../../components/ContactUs/GetInTouch'
import FixedMessage from "../../../components/Global/FixedMessage";
import Footer from '../../../components/Global/Footer'
import JoinUs from '../../../components/Global/JoinUs'
import Header from '../../../layouts/Header'

const ContactUs = () => {
    return (
        <>
            {/* Header */}
            <Header
            bgColor='bg-[#F8F9FC]' />

            {/* fixed message */}
            <FixedMessage />
            
            <div className='mobile:w-screen mobile:py-16 mobile:pb-6'>
                
                {/* get in touch section */}
                <GetInTouch />

                {/* FAQ */}
                <FAQ />

                {/* Join us today */}
                <JoinUs />

                {/* Footer */}
                <Footer />
            </div>
        </>
    )
}

export default ContactUs