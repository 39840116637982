import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";

import { clientErrorMessage, delay } from "./reusable";
import { clearRegisterBootcampSuccess, registerBootcampFailure, registerBootcampLoading, registerBootcampSuccess } from "../action";
import { REGISTER_BOOTCAMP_REQUEST } from "../types";


// ajaxDBCall
const ajaxDBCalls = {

    // register bootcamp
    registerBootcamp: async ({payload}) => {
      const response = await axios({
        method: "POST",
        url: "form/register",
        data: payload.userData
      });
      return response;
    },
}


// WORKERS
function* registerBootcamp(payload) {
    try {
        yield put(registerBootcampLoading(true));
        const response = yield call(ajaxDBCalls.registerBootcamp, payload);
        yield put(registerBootcampSuccess(response.data));
        yield put(registerBootcampLoading(false));
        yield delay();
        yield put(clearRegisterBootcampSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data.message);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(registerBootcampFailure(errorMessage));
        yield put(registerBootcampLoading(false));
        yield delay();
        yield put(registerBootcampFailure(""));
    }
}

// WATCHERS
function* registerBootcampWatcher() {
    yield takeLatest(REGISTER_BOOTCAMP_REQUEST, registerBootcamp)
}

// register bootcamp saga
export default function* registerBootcampSaga() {
    yield spawn(registerBootcampWatcher);
}