import React from 'react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import PortfolioCard from './PortfolioCard'

const MyPortfolio = () => {

    // HANDLERS
    // scroll left handler
    const scrollLeft = () => {
        document.querySelector("#slider").scrollBy({
            left: -200,
            behavior: "smooth"
        })
    }
    // scroll right handler
    const scrollRight = () => {
        document.querySelector("#slider").scrollBy({
            left: 200,
            behavior: "smooth"
        })
    }

    return (
        <div className='border border-[#E1E6EF] bg-white p-5 gap-4 flex flex-col rounded-[10px] relative'>

            {/* caption */}
            <p className='font-medium text-base text-[#3F444D]'>
                My Portfolio
            </p>

            {/* Buttons */}
            {/* left button */}
            <button
            // disabled={bootcamps?.length===0}
            onClick={scrollLeft}
            className='absolute h-[24px] w-[24px] rounded-[4px] top-[50%] left-[0.7rem] z-[100] bg-[#055774] text-white flex justify-center items-center disabled:opacity-40'>
                <IoIosArrowBack 
                className='text-lg' />
            </button>

            {/* right button */}
            <button
            // disabled={bootcamps?.length===0}
            onClick={scrollRight}
            className='absolute h-[24px] w-[24px] rounded-[4px] top-[50%] right-[0.7rem] z-[100] bg-[#055774] text-white flex justify-center items-center disabled:opacity-40'>
                <IoIosArrowForward 
                className='text-lg' />
            </button>

            {/* list */}
            <div 
            id='slider'
            className='flex overflow-auto disable-scrollbars relative z-[10] snap-x gap-8 px-3 w-full'>

                {/* container */}
                <div className='flex min-w-min gap-5'>
                    <PortfolioCard />
                    <PortfolioCard />
                    <PortfolioCard />
                    <PortfolioCard />
                    <PortfolioCard />
                    <PortfolioCard />
                    <PortfolioCard />
                </div>
            </div>
        </div>
    )
}

export default MyPortfolio