import {
    GET_USER_CLUB_SUBSCRIPTION_SUCCESS
} from "../types"

const initialState = () => ({
    userClubSubscriptions: []
})

const clubSubscriptionReducer = (state = initialState(), {type, payload}) => {
    
    switch(type) {

        case GET_USER_CLUB_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                userClubSubscriptions: payload.clubSubscriptions
            }

            default:
                return state;
    }
}

export default clubSubscriptionReducer;