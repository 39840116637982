import { useHistory } from "react-router-dom"
import avatar1 from "../../../../assets/webpages/home/images/avatar1.png"
import avatar2 from "../../../../assets/webpages/home/images/avatar2.png"
import avatar3 from "../../../../assets/webpages/home/images/avatar3.png"
import q_shape from "../../../../assets/webpages/home/images/q-shape1.png"
import BtnPrimary from "../../../Global/Redesign/BtnPrimary"

const Questions = () => {

    // DATA INITIALIZATION
    const history = useHistory()

    return (
        <div className="flex flex-col pb-24 pt-28 relative w-full container mx-auto gap-8 items-center mobile:px-4">

            {/* shape */}
            <img src={q_shape} className="absolute top-[33%] right-[30%] mobile:w-[17px] mobile:right-[2rem]" alt="" />
            
            {/* image collection */}
            <div className="flex items-center justify-center relative w-fit gap-3 h-fit">

                <img src={avatar2} className="rounded-[50%] h-[48px] w-[48px] object-cover" alt="" />

                <img src={avatar1} className="rounded-[50%] h-[56px] w-[56px] object-cover absolute right-[50%] translate-x-[50%]" alt="" />
                
                <img src={avatar3} className="rounded-[50%] h-[48px] w-[48px] object-cover" alt="" />
            </div>

            {/* texts */}
            <div className="flex flex-col gap-1 text-center">
                <p className="text-[#101828] text-xl font-semibold">
                    Still have questions?
                </p>

                <p className="text-[#667085] font-normal text-base">
                    Can’t find the answer you’re looking for? Please send us a message today and we will be in touch.
                </p>
            </div>

            {/* button */}
            <BtnPrimary
            onClick={()=>history.push("/contactus")}
            title={"Get in touch"} />

        </div>
    )
}

export default Questions