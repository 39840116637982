import CourseCard from "./CourseCard";
import shape from "../../../../assets/webpages/training/images/s-shape1.png"
import { Skeleton } from "@mui/material";
import EmptyData from "../Registration/EmptyData";

const SpecialTrack = ({
    bootcamps,
    bootcampsLoading,
    toggleLearnMoreModal,
}) => {

    return (
        <div className='py-40 flex flex-col mobile:px-5 mobile:py-16 px-8 mx-auto relative gap-12 container text-white'>

            {/* shapes */}
            <img
            src={shape}
            className="absolute top-[10%] right-[10%] mobile:w-[20px] mobile:top-[3rem] mobile:right-[4rem]"
            alt="" />

            {/* text section */}
            <div className="flex flex-col gap-3 text-center items-center w-full">
                <p className="mt-0 font-bold text-sm mobile:text-xs leading-none">
                    OUR VALUES
                </p>

                <p className="mt-0 font-semibold text-[32px] mobile:text-2xl leading-none">
                    The core values behind our work
                </p>

                <p className="mt-0 font-normal text-base mobile:text-base leading-none">
                    Tech is the future, and the future is now. So, how do we move from the present to the future where we ought to be?
                </p>
            </div>

            {
                bootcampsLoading?
                <Skeleton
                variant="rectangular"
                sx={{
                    width: "100%",
                    height: "50vh",
                    borderRadius: "10px",
                }} />:
                bootcamps && bootcamps.length !== 0?
                <div className='grid grid-cols-3 lg:grid-cols-2 mobile:grid-cols-1 gap-6 md:!w-[50%] container 2xl:w-[80%] mx-auto'>
                        
                    {
                        bootcamps.map((bootcamp, index)=>(

                            bootcamp.course_type==="pathway"&&
                            <CourseCard
                            key={bootcamp._id}
                            onClick={()=>toggleLearnMoreModal(bootcamp)}
                            name={bootcamp.name}
                            pathway={bootcamp.pathway}
                            duration={bootcamp.duration}
                            topics={bootcamp.course_id.topics}
                            courseImage={bootcamp.imgurl}
                            oldPrice={bootcamp.old_price}
                            newPrice={bootcamp.new_price} />
                        ))
                    }
                </div>
                :<EmptyData />
            }
        </div>
    )
}

export default SpecialTrack;