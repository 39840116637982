import {
  createTheme,
  FormControlLabel,
  Radio,
  RadioGroup,
  ThemeProvider,
} from "@material-ui/core";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { FormError } from "../Errors";
import BtnPrimary from "../Global/Redesign/BtnPrimary";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { critiqueEventRequest } from "../../redux/action";

const Form = () => {
  // OBJECT INITIALIZATION
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = createTheme({
    typography: {
      fontFamily: "poppins, sans-serif",
    },
    palette: {
      primary: {
        main: "#0B8CBA",
      },
    },
  });

  // STATES
  const [field, setField] = useState("");
  // redux state
  const {
    registerCritiqueEventLoading,
    registerCritiqueEventError,
    registerCritiqueEventSuccess,
  } = useSelector((state) => {
    const { registerCritiqueEventLoading } = state.loadingIndicator;
    const {
      success: { registerCritiqueEvent: registerCritiqueEventSuccess },
      errors: { registerCritiqueEvent: registerCritiqueEventError },
    } = state.ajaxStatuses;
    return {
      registerCritiqueEventLoading,
      registerCritiqueEventError,
      registerCritiqueEventSuccess,
    };
  });

  // SIDE EFFECTS
  // listen for error or success messages
  useEffect(() => {
    if (registerCritiqueEventError) {
      toast.error(registerCritiqueEventError, {
        duration: 3000,
      });
    }
  }, [registerCritiqueEventError]);

  useEffect(() => {
    if (registerCritiqueEventSuccess) {
      history.replace(`/form/critique&lunch2023/success`);
    }
  }, [registerCritiqueEventSuccess]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        profession: "",
      }}
      onSubmit={(values) => {
        const finalObj = { ...values };
        finalObj.profession = field;

        console.log(finalObj);

        dispatch(
          critiqueEventRequest({
            userData: finalObj,
          })
        );
      }}
      validationSchema={Yup.object({
        firstname: Yup.string().required(),
        lastname: Yup.string().required(),
        email: Yup.string().email().required(),
        mobile: Yup.number().required(),
      })}
    >
      {({
        values,
        dirty,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        field,
      }) => {
        return (
          <div className="rounded-[8px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] flex flex-col items-center w-[70%] 2xl:w-[60%] px-24 py-28 mobile:w-full mobile:shadow-none mobile:px-4 md:!px-7 mobile:py-6 gap-8">
            {/*  text */}
            <div className="flex flex-col items-center gap-1 text-center px-3 pc:w-[95%]">
              <p className="text-3xl mobile:text-2xl font-semibold text-[#1D2433]">
                Register for the Critique & Lunch
              </p>

              <p className="font-normal text-base text-[#1D2433] pc:w-[80%]">
                Fill the form below to make reservation for AI Movement critique
                and lunch event.
              </p>
            </div>

            {/* form */}
            <form
              className="w-full pc:w-[70%] flex flex-col gap-8 items-stretch"
              onSubmit={(e) => e.preventDefault()}
            >
              {/* first name */}
              <label className="flex flex-col gap-2">
                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    First name
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="firstname"
                  value={values.firstname}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Enter your firstname"
                />
                {touched.firstname && errors.firstname && (
                  <FormError message={errors.firstname} />
                )}
              </label>

              {/* last name */}
              <label className="flex flex-col gap-2">
                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    Last name
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="lastname"
                  value={values.lastname}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Enter your lastname"
                />
                {touched.lastname && errors.lastname && (
                  <FormError message={errors.lastname} />
                )}
              </label>

              {/* email address */}
              <label className="flex flex-col gap-2">
                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    Email address
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Enter your email"
                />
                {touched.email && errors.email && (
                  <FormError message={errors.email} />
                )}
              </label>

              {/* phone number */}
              <label className="flex flex-col gap-2">
                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    Phone number
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="mobile"
                  value={values.mobile}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Enter your phone number"
                />
                {touched.mobile && errors.mobile && (
                  <FormError message={errors.mobile} />
                )}
              </label>

              {/* What you're good at  */}
              <label className="flex flex-col gap-4">
                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    Tech field that best describes you?
                  </span>
                </div>

                {/* input field */}
                <ThemeProvider theme={theme}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={field}
                    onChange={(e) => setField(e.target.value)}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      className="!font-[poppins]"
                      value="Programmer"
                      control={<Radio color="primary" />}
                      label="Programmer"
                    />

                    <FormControlLabel
                      value="Designer"
                      control={<Radio color="primary" />}
                      label="Designer"
                    />

                    <FormControlLabel
                      value="Content Writer/Tech Writer"
                      control={<Radio color="primary" />}
                      label="Content Writer/Tech Writer"
                    />

                    <FormControlLabel
                      value="Founder"
                      control={<Radio color="primary" />}
                      label="Founder"
                    />

                    <FormControlLabel
                      value="Business Development"
                      control={<Radio color="primary" />}
                      label="Business Development"
                    />

                    <FormControlLabel
                      value="Tech Enthusiast"
                      control={<Radio color="primary" />}
                      label="Tech Enthusiast"
                    />
                  </RadioGroup>
                </ThemeProvider>
              </label>

              <BtnPrimary
                onClick={handleSubmit}
                type={"Submit"}
                disabled={registerCritiqueEventLoading}
                loading={registerCritiqueEventLoading}
                title={"Submit"}
              />
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default Form;
