import React from 'react'

const Wrapper = ({children}) => {
    return (
        <div className='flex justify-end mobile:w-screen flex-col h-full w-full'>
            {children}
        </div>
    )
}

export default Wrapper