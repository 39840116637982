import React from 'react'
import Body from '../../../../components/Redesign/Webpage/Spaces/AvailableSpaces/Body'
import Header from '../../../../components/Redesign/Webpage/Spaces/AvailableSpaces/Header'
import Wrapper from '../../../../layouts/Redesign/Wrapper'

const AvailableSpaces = () => {
    return (
        <Wrapper>
            
            {/* header */}
            <Header />

            {/* body */}
            <Body />

        </Wrapper>
    )
}

export default AvailableSpaces