import React from 'react'
import { Switch, Route } from 'react-router-dom'
import GenericEventsForm from '.'
import ThankYouPage from './ThankYouPage'

const GenericEventsRouter = ({ path }) => {
    return (
        <>
            <Switch>

                <Route 
                exact 
                path={`${path}`} 
                component={GenericEventsForm} />

                <Route
                exact
                path={`${path}/success`}
                component={ThankYouPage} />

            </Switch>
        </>
    )
}


export default GenericEventsRouter