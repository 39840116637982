import React from "react";
import { useHistory } from "react-router-dom";

const Event = ({ image, date, venue, title, desc, id }) => {
  // history object
  const history = useHistory();

  return (
    <div className="flex flex-col [box-shadow:0px_2px_8px_#e0e0e0] bg-white rounded-[10px] p-4 gap-4 hover:scale-105 transition-transform w-full mobile:w-full mx-auto">
      {/*image */}
      {/*image */}
      <div className="w-full relative flex flex-col">
        <img
          src={image}
          className="object-cover rounded-[10px] h-[184px] w-full"
          alt=""
        />
      </div>

      {/* texts */}
      <div className="flex flex-col items-start justify-center py-4 gap-3 break-words w-[296px]">
        <p className="flex flex-col text-xs font-normal text-[#6c707a]">
          <span>{date}</span>
          <span>{venue}</span>
        </p>

        <p className="text-xl xl:text-sm leading-[24px] font-semibold">
          {title}
        </p>

        <p className="font-normal text-sm text-[#6c707a]">{desc}</p>

        <p
          onClick={() => history.push(`/old/dashboard/events/${id}`)}
          className="font-semibold text-base text-[#0B8CBA] xl:text-sm"
        >
          LEARN MORE
        </p>
      </div>
    </div>
  );
};

export default Event;
