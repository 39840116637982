import React from 'react'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'
import SettingsBody from '../../../../components/Redesign/Dashboard/Settings/SettingsBody'

const Settings = () => {
    return (
        <PageWrapper
        title={"Hello Nonso"}
        subTitle={"Happy to see you back!"}>

            {/* body section */}
            <SettingsBody />

        </PageWrapper>
    )
}

export default Settings