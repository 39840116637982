import React from 'react'
import EventDetailsBody from '../../../../components/Redesign/Webpage/Event/EventDetails/EventDetailsBody'
import EventDetailsHeader from '../../../../components/Redesign/Webpage/Event/EventDetails/EventDetailsHeader'
import Wrapper from '../../../../layouts/Redesign/Wrapper'

const EventDetails = () => {
    return (
        <Wrapper>

            {/* header */}
            <EventDetailsHeader />

            {/* body */}
            <EventDetailsBody />

        </Wrapper>
    )
}

export default EventDetails