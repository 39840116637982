import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Settings from '.'

const SettingsRouter = ({path}) => {
    
    return (
        <Switch>
            
            {/* index page */}
            <Route 
            exact
            path={path}
            component={Settings}/>

        </Switch>
    )
}

export default SettingsRouter