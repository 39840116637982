import { Skeleton } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import image from "../../../../../assets/redesign-dashboard/the-club/images/jumbotron-imagee.png"
import { getClubPackagesRequest } from '../../../../../redux/action'
import AvailbleMemberPack from './AvailbleMemberPack'
import HowItWorks from './HowItWorks'

const AvailablePackagesBody = () => {

    // STATES
    const { clubsPackages, clubsPackagesLoading } = useSelector((state) => {

        const { clubsPackages } = state.clubPackagesReducer;
        const { clubsPackagesLoading } = state.loadingIndicator;

        return {
            clubsPackages,
            clubsPackagesLoading,
        };
    });


    // OBJECT INITIALIZATION
    const dispatch = useDispatch()


    // SIDE EFFECTS
    useEffect(() => {
        dispatch(getClubPackagesRequest());
    }, []);

    return (
        <div className='flex mobile:flex-col w-full h-full gap-10'>
            
            {/* section filled with image and text details */}
            <div className='h-full w-[70%] mobile:w-full flex flex-col gap-10'>

                {/* image */}
                <div className='w-full'>

                    <img src={image} className='w-full object-cover h-full rounded-[10px] [box-shadow:0px_8px_32px_rgba(0,0,0,0.16)]' alt="" />

                </div>

                {/* texts */}
                <div className='flex flex-col gap-1 w-full text-base text-gray-800 font-normal'>
                    <p>
                        The club is a community of Family offices, private investors, venture capital companies, institutional investors and technology experts.
                    </p>

                    <p>
                        It is for those who would like to know more about technology trends and are interested in investments in cryptocurrency, blockchain, artificial intelligence, autonomous driving, robotics and other disruptive technologies.
                    </p>

                    <p>
                        The club combines networking, education and investment opportunities in tech sector under one umbrella and provide access to the competitive VC funds with attractive returns.
                    </p>
                </div>

                {/* available membership packages */}
                <AvailbleMemberPack
                loading={clubsPackagesLoading}
                isEmpty={!(clubsPackages && clubsPackages.length!==0)}
                clubPackages={clubsPackages} />
            </div>

            {/* how it works section */}
            <div className='h-full w-[30%] mobile:w-full flex flex-col'>
                <HowItWorks
                infoList={[
                    {
                        id: 1,
                        message: "Select your preferred package"
                    },
                    {
                        id: 2,
                        message: "Make Payment to validate your order."
                    },
                    {
                        id: 3,
                        message: "Enjoy the unlimited benefits"
                    },
                ]} />
            </div>
        </div>
    )
}

export default AvailablePackagesBody