import React from "react";
import { useHistory } from "react-router-dom";
import BtnPrimary from "../../../components/Global/Redesign/BtnPrimary";
import MyLink2 from "../../../components/Global/Redesign/MyLink2";

const DesktopNavList2 = () => {

  // navigation hook
  const history = useHistory();

  return (
    <>
      <div className="w-full mobile:hidden flex items-center justify-end">
        <ul className="flex justify-start items-center gap-4 lg:gap-2 mr-10 2xl:mr-16 mb-0 w-fit">
          
          {/* Training center link */}
          <MyLink2 to={"/training"}>
            Training center
          </MyLink2>

          {/* The club link */}
          <MyLink2 to={"/club"}>
            The Club
          </MyLink2>
          
          {/* space booking */}
          <MyLink2 to={"/spaces"}>
            Space Booking
          </MyLink2>

          {/* Management team link */}
          <MyLink2 to={"/management"}>
            Team
          </MyLink2>

            {/**BootCamps */}
            <MyLink2 to={"/bootcamp"}>
           Bootcamps
          </MyLink2>

          {/* Events link */}
          <MyLink2 to={"/events"}>
            Events
          </MyLink2>

          {/* Contact Us */}
          <MyLink2 to={"/investing"}>
            Investing
          </MyLink2>
        

      
        </ul>

        {/* get started button */}
        <div className="flex my-auto items-center">
          <BtnPrimary
            title={"Get started"}
            onClick={() => history.push("/registration")}
          />
        </div>
      </div>
    </>
  );
};

export default DesktopNavList2;
