import { useEffect, useState } from 'react'
import { daysDiff } from '../../../../../helpers/daysDiff'
import { isEmpty } from '../../../../../helpers/isEmtpy'
import { useDispatch, useSelector } from 'react-redux'
import { spacePaymentRequest } from '../../../../../redux/action'
import PayStackBtn from '../../../../Global/PayStackBtn'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import BtnPrimary from '../../../../Global/Redesign/BtnPrimary'

const SpaceDetail = ({
    startDate,
    endDate,
    selectedSpaceInfo,
    userDetails
}) => {

    // STATES
    const [paystackSuccess, setPaystackSuccess] = useState(false)
    const {
        spacePaymentSuccess,
        spacePaymentError,
        spacePaymentLoading
    } = useSelector((state) => {

        const {
            success: { 
                spacePayment: spacePaymentSuccess 
            },
            errors: { 
                spacePayment: spacePaymentError 
            },
        } = state.ajaxStatuses;
        const { 
            spacePaymentLoading
        } = state.loadingIndicator;
    
        return {
            spacePaymentSuccess,
            spacePaymentError,
            spacePaymentLoading
        };
    });


    // DATA INITIALIZATION
    const history = useHistory()
    const dispatch = useDispatch()
    const componentProps = {
        email: userDetails?.email,
        amount: Number((daysDiff(endDate, startDate)*selectedSpaceInfo?.price)),
        metadata: {
            name: userDetails?.name,
            phone: userDetails?.phone
        },
        publicKey: `${process.env.REACT_APP_TEST_PAYSTACK_KEY}`,
        text: "Proceed to payment",
        onSuccess: (e) => {
            setPaystackSuccess(true)
            dispatch(spacePaymentRequest({
                data: {
                    email: userDetails?.email,
                    reference: e.reference,
                    start_date: startDate,
                    end_date: endDate
                },
                spaceId: selectedSpaceInfo.spaceId
            }))
        },
        onClose: () => {},
    };


    // SIDE EFFECT
    useEffect(() => {
        if (spacePaymentError) {
            toast.error(spacePaymentError, {
                duration: 3000,
            });
        }
    }, [spacePaymentError]);
    
      // success message
      useEffect(() => {
        if (spacePaymentSuccess) {
            toast.success(spacePaymentSuccess, {
                duration: 3000,
            });

            // to login
            history.push("/dashboard/space-booking/about-spaces")
        }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spacePaymentSuccess]);

    return (
        <div className='flex flex-col gap-5 p-5 bg-[#F0F5FF] w-full'>

            {/* caption */}
            <p className="text-2xl font-semibold text-black w-full text-center">
                Search
            </p>

            {/* card for the space */}
            <div className='flex flex-col rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] p-3 gap-4 w-[70%] mobile:w-[87%] mx-auto'>
            
                {/* image */}
                <div className='w-full h-full'>
                    <img src={selectedSpaceInfo.image} className='w-full h-full object-cover rounded-[10px]' alt="" />
                </div>

                {/* texts */}
                <div className='w-full h-full flex flex-col gap-4'>

                    {/* text */}
                    <div className='flex flex-col gap-2 items-center'>

                        <p className='text-base font-medium text-[#0B8CBA]'>
                            {selectedSpaceInfo.name}
                        </p>

                        <p className='text-gray-600 text-base font-bold'>
                            NGN {selectedSpaceInfo.price.toLocaleString("en-US")} /day
                        </p>
                    </div>
                </div>
            </div>

            {/* space meta info */}
            <div className='flex flex-col gap-4 my-2 w-full items-start'>

                <div className='flex items-center gap-4 mobile:gap-2'>
                    <span className='text-[#1D2433] text-base font-medium'>
                        Duration:
                    </span>

                    <span className='font-normal text-base text-gray-800'>
                        {`${daysDiff(endDate, startDate)} day(s)`}
                    </span>
                </div>

                <div className='flex items-center gap-4 mobile:gap-2'>
                    <span className='text-[#1D2433] text-base font-medium'>
                        Total Cost:
                    </span>

                    <span className='font-normal text-base text-gray-800'>
                        NGN{(daysDiff(endDate, startDate)*selectedSpaceInfo.price).toLocaleString('en-US')}
                    </span>
                </div>

            </div>

            {/* button */}
            <div className='w-full flex flex-col items-stretch'>
                {
                    paystackSuccess?
                    <BtnPrimary
                    title={"Proceed to payment"}
                    loading={spacePaymentLoading} />:
                    <PayStackBtn
                    disabled={isEmpty(selectedSpaceInfo) || isEmpty(userDetails)}
                    componentProps={componentProps} />
                }
            </div> 
        </div>
    )
}

export default SpaceDetail