import { 
    GET_CLUBS_PACKAGES_REQUEST,
    GET_CLUBS_PACKAGES_SUCCESS,
    GET_CLUBS_PACKAGES_FAIL,
    GET_CLUBS_PACKAGES_LOADING,
    GET_SPECIFIC_PACKAGE_REQUEST,
    GET_SPECIFIC_PACKAGE_SUCCESS,
    GET_SPECIFIC_PACKAGE_FAIL,
    GET_SPECIFIC_PACKAGE_LOADING
} from "../types";

// get all clubs packages
export const getClubPackagesRequest = () => {
    return {
        type: GET_CLUBS_PACKAGES_REQUEST,
    }
}

export const getClubPackagesSuccess = (prop) => {
    return {
        type: GET_CLUBS_PACKAGES_SUCCESS,
        payload: prop
    }
}

export const getClubPackagesFailure = (prop) => {
    return {
        type: GET_CLUBS_PACKAGES_FAIL,
        payload: prop
    }
}

export const getClubPackagesLoading = (prop) => {
    return {
        type: GET_CLUBS_PACKAGES_LOADING,
        payload: prop
    }
}

// get specific club package
export const getSpecificPackageRequest = (prop) => {
    return {
        type: GET_SPECIFIC_PACKAGE_REQUEST,
        payload: prop
    }
}

export const getSpecificPackageSuccess = (prop) => {
    return {
        type: GET_SPECIFIC_PACKAGE_SUCCESS,
        payload: prop
    }
}

export const getSpecificPackageFailure = (prop) => {
    return {
        type: GET_SPECIFIC_PACKAGE_FAIL,
        payload: prop
    }
}

export const getSpecificPackageLoading = (prop) => {
    return {
        type: GET_SPECIFIC_PACKAGE_LOADING,
        payload: prop
    }
}