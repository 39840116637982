import React from 'react'
import { Switch, Route } from "react-router-dom";
import ManagementTeam from './AboutUs';
import Club from './Club';
import ContactUs from './ContactUs';
import Event from './Event';
import HomepageEventRouter from './Event/router';
import Home from './Home';
import Investing from './Investing';
import Login from './Login';
import Registration from './Registration';
import Spaces from './Spaces';
import HomepageSpaceRouter from './Spaces/router';
import TrainingCenter from './TrainingCenter';
import BootCamp from './BootCamp';
import BootCampSuccess from './BootCampSuccess';

const WebpageRouter = ({path}) => {
    return (
        <>
            <Switch>

                <Route 
                exact 
                path={path}
                component={Home} />

                <Route
                exact
                path={`${path}login`}
                component={Login} />

                <Route
                exact
                path={`${path}registration`}
                component={Registration} />

                <HomepageSpaceRouter
                path={`${path}spaces`} />

                <Route
                exact
                path={`${path}management`}
                component={ManagementTeam} />

                <Route
                exact
                path={`${path}club`}
                component={Club} />
                
                 <Route
                exact
                path={`${path}bootcamp`}
                component={BootCamp} />

                <Route
                exact
                path={`${path}bootcampsuccess`}
                component={BootCampSuccess} />

                <Route
                exact
                path={`${path}training`}
                component={TrainingCenter} />

                <Route
                exact
                path={`${path}investing`}
                component={Investing} />

               

                <Route
                exact
                path={`${path}contactus`}
                component={ContactUs} />


                
                <HomepageEventRouter
                path={`${path}events`} />

            </ Switch>
        </>
    )
}

export default WebpageRouter