import React from 'react'
import Jumbotron from './Jumbotron'
import PaymentCard from './PaymentCard'

const InvestmentPaymentBody = () => {
    return (
        <div className='grid grid-cols-[2fr_1fr] mobile:grid-cols-1 gap-10 w-full'>
            <Jumbotron />
            <PaymentCard />            
        </div>
    )
}

export default InvestmentPaymentBody