import React from 'react'
import SingleSpaceBody from '../../../../components/Redesign/Dashboard/SpaceBooking/SingleSpace/SingleSpaceBody'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'

const SingleSpace = () => {
    return (
        <PageWrapper
        enableBackBtn={true}
        title={"Comfortable Workspaces"}
        subTitle={"Just for You"}>

            {/* body section */}
            <SingleSpaceBody />

        </PageWrapper>
    )
}

export default SingleSpace