import {
  createTheme,
  FormControlLabel,
  Radio,
  RadioGroup,
  ThemeProvider,
} from "@material-ui/core";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect } from "react";
import { FormError } from "../Errors";
import BtnPrimary from "../Global/Redesign/BtnPrimary";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { genericEventsRequest } from "../../redux/action";

const Form = () => {
  // OBJECT INITIALIZATION
  const dispatch = useDispatch();
  const history = useHistory();

  // STATES
  // const [field, setField] = useState("");
  const {
    registerGenericEventLoading,
    registerGenericEventError,
    registerGenericEventSuccess,
  } = useSelector((state) => {
    const { registerGenericEventLoading } = state.loadingIndicator;
    const {
      success: { registerGenericEvent: registerGenericEventSuccess },
      errors: { registerGenericEvent: registerGenericEventError },
    } = state.ajaxStatuses;
    return {
      registerGenericEventLoading,
      registerGenericEventError,
      registerGenericEventSuccess,
    };
  });

  // SIDE EFFECTS
  // listen for error or success messages
  useEffect(() => {
    if (registerGenericEventError) {
      toast.error(registerGenericEventError, {
        duration: 3000,
      });
    }
  }, [registerGenericEventError]);

  useEffect(() => {
    if (registerGenericEventSuccess) {
      history.replace(`/form/refreshevent2023/success`);
    }
  }, [registerGenericEventSuccess]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
      }}
      onSubmit={(values) => {
        const userData = { ...values };

        console.log(userData);

        dispatch(
          genericEventsRequest({
            userData,
          })
        );
      }}
      validationSchema={Yup.object({
        firstname: Yup.string().required(),
        lastname: Yup.string().required(),
        email: Yup.string().email().required(),
        mobile: Yup.number().required(),
      })}
    >
      {({
        values,
        dirty,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        field,
      }) => {
        return (
          <div className="rounded-[8px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] flex flex-col items-center w-[70%] 2xl:w-[60%] px-24 py-28 mobile:w-full mobile:shadow-none mobile:px-4 md:!px-7 mobile:py-6 gap-8">
            {/*  text */}
            <div className="flex flex-col items-center gap-1 text-center px-3 pc:w-[95%]">
              <p className="text-3xl mobile:text-2xl font-semibold text-[#1D2433]">
                Register for Refresh
              </p>

              <p className="font-normal text-base text-[#1D2433] pc:w-[80%]">
                Please enter your correct details as we will use it to
                communicate with you
              </p>
            </div>

            {/* form */}
            <form
              className="w-full pc:w-[70%] flex flex-col gap-8 items-stretch"
              onSubmit={(e) => e.preventDefault()}
            >
              {/* first name */}
              <label className="flex flex-col gap-2">
                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    First name
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="firstname"
                  value={values.firstname}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Enter your firstname"
                />
                {touched.firstname && errors.firstname && (
                  <FormError message={errors.firstname} />
                )}
              </label>

              {/* last name */}
              <label className="flex flex-col gap-2">
                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    Last name
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="lastname"
                  value={values.lastname}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Enter your lastname"
                />
                {touched.lastname && errors.lastname && (
                  <FormError message={errors.lastname} />
                )}
              </label>

              {/* email address */}
              <label className="flex flex-col gap-2">
                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    Email address
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Enter your email"
                />
                {touched.email && errors.email && (
                  <FormError message={errors.email} />
                )}
              </label>

              {/* phone number */}
              <label className="flex flex-col gap-2">
                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    Phone number
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="mobile"
                  value={values.mobile}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Enter your phone number"
                />
                {touched.mobile && errors.mobile && (
                  <FormError message={errors.mobile} />
                )}
              </label>

              {/* Submit button */}
              <BtnPrimary
                onClick={handleSubmit}
                type={"Submit"}
                disabled={registerGenericEventLoading}
                loading={registerGenericEventLoading}
                title={"Submit"}
              />
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default Form;
