import React from "react";
import { Switch, Route } from "react-router-dom";
import Events from ".";
import EventDetails from "./EventDetails";

const EventRouter = ({path}) => {

    return (
        <Switch>

            <Route 
            exact 
            path={path}
            component={Events} />

            <Route 
            exact 
            path={`${path}/:eventId`} 
            component={EventDetails} />
        </ Switch>
    )
}

export default EventRouter