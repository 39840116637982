import { Skeleton } from '@mui/material'
import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { useHistory, useLocation } from 'react-router-dom'
import image from "../../../../assets/redesign-dashboard/events/images/jumbotron-image.png"
import { getRTDate, MONTHS } from '../../../../helpers/getRTDate'

const LatestEvents = ({loading, events, isEmpty}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    return (
        <>
        {            
            loading?
            <Skeleton
            variant="rectangular"
            sx={{
            width: "100%",
            height: "30vh",
            borderRadius: "10px",
            marginTop: "16px"
            }} />:
            !isEmpty?
            <>
                {
                    events.map((event)=>(
                    <div 
                    key={event._id}
                    className='flex flex-col gap-3'>
                        
                        {/* caption */}
                        <p className='font-medium text-base text-[#055774]'>
                            Latest Event
                        </p>

                        {/* event card */}
                        <div 
                        className="flex mobile:flex-col border border-[#E1E6EF] bg-white p-4 gap-16 mobile:gap-12 rounded-[10px]">

                            <div className="w-[40%] mobile:w-full relative">

                                {/* image */}
                                <img
                                src={event.imgurl}
                                className="object-cover object-top rounded-[10px] h-[200px] w-full"
                                alt="" />
                            </div>

                            {/* description */}
                            <div className="w-[60%] mobile:w-full flex flex-col gap-3 items-start justify-center leading-3">

                                <div className='flex flex-col gap-1'>
                                    <p className="font-semibold text-2xl text-[#1D2433]">
                                        {event.name}
                                    </p>

                                    <p className="font-normal text-base text-gray-800">
                                        {`${getRTDate.date(event.date)} ${MONTHS[getRTDate.month(event.date)]}, ${getRTDate.year(event.date)}`}
                                    </p>
                                </div>

                                <p className="text-base font-normal text-gray-800">
                                    {event.description.substring(0, 90).concat("...")}
                                </p>

                                <p 
                                onClick={()=>history.push({
                                    pathname:`/dashboard/events/${event._id}?status=UPCOMING`,
                                })}
                                className="text-[#0B8CBA] font-semibold text-sm mt-0 inline-flex items-center gap-2 cursor-pointer hover:opacity-80">
                                    Learn more
                                    <IoIosArrowForward
                                    className="text-xl text-[#0B8CBA]" />
                                </p>
                            </div>
                        </div>
                    </div>
                    ))
                }
            </>:
            <div className='flex justify-center items-center w-full h-[25vh] text-2xl rounded-[10px]'>Nothing Here</div>
        }
        </>
    )
}

export default LatestEvents