import React from 'react'
import BtnPrimary from '../../../components/Global/Redesign/BtnPrimary'

const SubscribeForm2 = () => {
    return (
        <form className='inline-flex mobile:flex-col mobile:w-full gap-4 pc:items-center'>

            {/* email input */}
            <input 
            type="email" 
            className='border border-[#d0d5dd] font-base font-normal h-[44px] pc:w-[280px] rounded-[8px] px-3 outline-[#d0d5dd]' 
            placeholder='Enter your email' />

            {/* submit button */}
            <BtnPrimary
            title={"Subscribe"} />
        </form>
    )
}

export default SubscribeForm2