import React from 'react'
import { IoIosArrowBack } from "react-icons/io"
import { useHistory } from 'react-router-dom'

const BackBtn = () => {

    const history = useHistory()

    return (
        <div
        onClick={()=>history.goBack()}
        className="hover:opacity-40 cursor-pointer font-normal text-base text-[#1D2433] flex items-center justify-start gap-4">
            <IoIosArrowBack color="#8B54F7" size={24} />
            Back
        </div>
    )
}

export default BackBtn