import React from 'react'
import image1 from '../../../../../assets/webpages/event/images/photo-speak1.png'
import image2 from '../../../../../assets/webpages/event/images/photo-speak2.png'
import image3 from '../../../../../assets/webpages/event/images/photo-speak3.png'
import image4 from '../../../../../assets/webpages/event/images/photo-speak4.png'

const EventGallery = () => {
    return (
        <div className='flex flex-col gap-5 p-5 mobile:p-0 items-start w-full mobile:w-full'>

            {/* caption */}
            <p className='text-base font-medium text-[#055774]'>
                Photo Speaks
            </p>

            {/* photo collection */}
            <div className='grid grid-cols-[auto_auto] mobile:grid-cols-1 mobile:gap-y-[23px] gap-x-[23px] gap-y-[32px]'>

                <img src={image1} className='h-[288px] rounded-[10px] w-full object-cover' alt="" />
                <img src={image2} className='h-[288px] rounded-[10px] w-full object-cover' alt="" />
                <img src={image3} className='h-[288px] rounded-[10px] w-full object-cover' alt="" />
                <img src={image4} className='h-[288px] rounded-[10px] w-full object-cover' alt="" />

            </div>

            {/* button */}
            <button className='h-[40px] hover:bg-[rgba(0,0,0,.12)] rounded-[8px] border border-gray-600 bg-transparent px-3 text-base font-semibold text-gray-800'>
                View all photos
            </button>
        </div>
    )
}

export default EventGallery