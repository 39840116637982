import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

//css
import "react-toastify/dist/ReactToastify.css";

import Pages from "./pages";

//hooks
import ScrollToTheTop from "./hooks/ScrollToTheTop";

// Redux
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <ToastContainer
            position="top-center"
            theme="light"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <ScrollToTheTop />
          <Pages />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
