import {useEffect} from 'react'
import backend from "./../../../assets/trainingcenter/images/backend.png"
import {ReactComponent as Check} from "./../../../assets/trainingcenter/svg/course-check.svg"
import BtnSm from './../../Global/BtnSm'
import LearnMoreBtn from './LearnMoreBtn'
import { useHistory, useLocation } from 'react-router-dom'

const BootcampCard = ({data, bootcampNumbers}) => {

    const history = useHistory()
    const location = useLocation()  

    return (
        <div className='flex flex-col [box-shadow:0px_2px_8px_#e0e0e0] rounded-[10px] mobile:p-4 p-[10px] gap-4 bg-white w-fit mobile:w-full md:!w-[70%] pc:w-[320px]'>
            
            {/* images section */}
            <div className='flex flex-col items-center justify-center gap-4'>

                {/* backend image */}
                <img src={backend} className="object-cover h-[100px] w-full rounded-[10px] mb-1" alt="" />

                {/* descriptive label */}
                <div className='text-[#3F444D] text-base font-semibold flex justify-between gap-4 w-full'>
                    <div className='flex flex-col leading-3 mt-1'>
                        <p className='leading-[24px]'>
                            {
                                data?.name
                            }
                        </p>
                        <p className='font-normal text-base text-[#3F444D]'>
                            {`${data.description.split(" ")[0].replace("Mobile","")}`}
                        </p>
                        <p className='font-normal text-xs text-[#3F444D]'>
                            {data.duration} Months Course
                        </p>
                    </div>

                    {/* button */}
                    <button className='h-[32px] bg-[#E1E6EF] px-3 rounded-[8px] font-semibold text-[11px] cursor-default'>
                        {data.pathway}
                    </button>
                </div>
            </div>

            {/* package info */}
            <div className='flex justify-between px-3'>

               {/* technology */}
               {/* <div className='flex justify-center w-full items-center my-2 gap-2'>
                    <Check />
                    <span className='font-semibold text-xs text-[#667085]'>
                        NODE. JS with (Mongo DB)
                    </span>
                </div> */}

                
                    {/* Technology */}
                    <div className="grid justify-items-center grid-cols-[auto_auto] justify-between items-center gap-3 mb-1">
                        {
                            data.course_id.topics.map(({sub_topics}, index)=>(

                                <p className='flex justify-start w-full items-center gap-2 m-auto' key={index}>
                                    <Check />
                                    <span className='font-semibold text-xs text-[#667085]'>
                                        {sub_topics.replace("CONTENT","")}
                                    </span>
                                </p> 
                            ))
                        }     
                    </div>           
            </div>

            {/* amount */}
            <div className='flex flex-col justify-center items-center gap-1'>
                <span className='font-semibold text-[#6c707a] text-sm line-through'>
                    NGN {data?.old_price.toLocaleString('en-US')}
                </span>

                <div className='flex justify-center items-center gap-3'>
                    <p className='text-[#6c717a] font-semibold text-xl leading-[24px] text-center'>
                        NGN {data?.new_price.toLocaleString('en-US')}
                    </p>

                    {/* label */}
                    <span className='bg-[#E02D3C] h-[22px] inline-flex justify-center items-center font-semibold text-[10px] leading-[14px] px-3 rounded-[8px] text-white'>
                        Promo price
                    </span>
                </div>
            </div>

            {/* button */}
            <div className='flex items-stretch flex-col gap-4'>
                <BtnSm 
                onClick={()=>history.push(`${location.pathname}/${data._id}`)}
                title="Get started now" />

                <LearnMoreBtn
                onClick={()=>history.push(`${location.pathname}/${data._id}`)} />
            </div>
        </div>
    )
}

export default BootcampCard