import SideNav from "../../../layouts/Dashboard/SideNav";
import ProfileAvatar from "../../../components/Dashboard/Home/ProfileAvatar";
import Summary from "../../../components/Dashboard/Global/Summary";
import MemberShipInfo from "../../../components/Dashboard/TheClub/MemberShipInfo";
import { Link } from "react-router-dom";
import BackBtn from "../../../components/Dashboard/Global/BackBtn";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  clearClubSubscriptionPaymentSuccess,
  getSpecificPackageRequest,
  putClubSubscriptionPaymentRequest,
} from "../../../redux/action";
import { CircularProgress } from "@mui/material";
import MyModal from "../../../layouts/MyModal";
import { useEffect, useState } from "react";
import SuccessfulPackageRegistration from "../../../components/Dashboard/TheClub/SuccessfulPackageRegistration";
import { getUserClubSubsciptionRequest } from "../../../redux/action";
import { toast } from "react-toastify";

const ClubGetStarted = () => {
  // modal state for saved account info
  const [successfulSubscription, setSuccessfulSubscription] = useState(false);

  // toggle saved account info
  const toggleSuccessSubscriptionModal = () => {
    setSuccessfulSubscription(!successfulSubscription);
  };

  // location object
  const location = useLocation();
  const packageId = location.pathname.split("/").pop();

  // dispatch object
  const dispatch = useDispatch();

  // specific package state
  const {
    specificPackage,
    specificPackageLoading,
    putClubSubscriptionPaymentLoading,
    putClubSubscriptionPaymentSuccess,
    putClubSubscriptionPaymentError,
    user,
    userClubSubscriptions,
    token,
  } = useSelector((state) => {
    const {
      success: {
        putClubSubscriptionPayment: putClubSubscriptionPaymentSuccess,
      },
      errors: { putClubSubscriptionPayment: putClubSubscriptionPaymentError },
    } = state.ajaxStatuses;

    const { putClubSubscriptionPaymentLoading } = state.loadingIndicator;

    const { user, token } = state.userData;

    const { specificPackage } = state.clubPackagesReducer;
    const { specificPackageLoading } = state.loadingIndicator;
    const { userClubSubscriptions } = state.clubSubscriptionReducer;

    return {
      specificPackage,
      specificPackageLoading,
      putClubSubscriptionPaymentLoading,
      putClubSubscriptionPaymentSuccess,
      putClubSubscriptionPaymentError,
      user,
      userClubSubscriptions,
      token,
    };
  });

  useEffect(() => {
    dispatch(getSpecificPackageRequest(packageId));
    dispatch(getUserClubSubsciptionRequest(token));
  }, []);

  // checking for success or errors
  useEffect(() => {
    if (putClubSubscriptionPaymentSuccess) {
      toggleSuccessSubscriptionModal();
      setTimeout(() => {
        setSuccessfulSubscription(false);
      }, 3000);

      dispatch(clearClubSubscriptionPaymentSuccess());

      dispatch(getSpecificPackageRequest(packageId));
      dispatch(getUserClubSubsciptionRequest(token));
    }
  }, [putClubSubscriptionPaymentSuccess]);

  useEffect(() => {
    if (putClubSubscriptionPaymentError) {
      toast.error(putClubSubscriptionPaymentError, {
        duration: 3000,
      });
    }
  }, [putClubSubscriptionPaymentError]);

  return (
    <div className="flex justify-end mobile:w-screen mobile:flex-col mobile:justify-start min-h-screen w-full mobile:bg-[#E5E5E5]">
      {/* SideNav */}
      <SideNav />

      {/* Home Body */}
      <section className="w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12 mobile:pt-8">
        {/* header */}
        <header className="container mx-auto mobile:px-4 flex justify-between items-center">
          <div className="mobile:flex mobile:flex-col-reverse gap-3 flex flex-col mobile:gap-6">
            <p className="text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]">
              The Club
            </p>

            <BackBtn />
          </div>

          {/* profile dp */}
          <div className="mobile:hidden">
            <ProfileAvatar />
          </div>
        </header>

        {/* middle section */}
        <section className="container mx-auto flex w-full items-start justify-between mt-12 mobile:gap-12 gap-9 mobile:px-4 mobile:flex-col-reverse">
          {/* club membership info */}
          <main className="w-[70%] lg:w-[60%] mobile:w-full">
            {/* membership information */}
            {specificPackageLoading ? (
              <div
                style={{
                  width: "100%",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
                }}
                className="bg-white rounded-[10px] p-6 flex gap-5 flex-col h-[320px] mobile:h-[300px] justify-center items-center "
              >
                {" "}
                <CircularProgress size={20} style={{ color: "#7F56D9" }} />{" "}
              </div>
            ) : (
              <MemberShipInfo packageInfo={specificPackage} />
            )}
          </main>

          {/* summary section*/}
          <aside className="w-[30%] lg:w-[40%] mobile:w-full">
            {/* Summary */}
            {specificPackageLoading ? (
              <div
                style={{
                  width: "100%",
                  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
                }}
                className="bg-white rounded-[10px] p-6 flex gap-5 flex-col h-[320px] mobile:h-[200px] justify-center items-center "
              >
                {" "}
                <CircularProgress size={20} style={{ color: "#7F56D9" }} />{" "}
              </div>
            ) : (
              <Summary
                hasSubscription={!(userClubSubscriptions?.length === 0)}
                user={user}
                specificPackage={specificPackage}
                clubPackageId={specificPackage._id}
                putClubSubscriptionLoading={putClubSubscriptionPaymentLoading}
                originalPrice={specificPackage.amount.toLocaleString("en-US")}
                promoPrice={specificPackage.amount.toLocaleString("en-US")}
              />
            )}
          </aside>
        </section>

        <div className="hidden mobile:flex justify-center items-center mt-5">
          <Link
            to={"/dashboard/courses"}
            className="hidden mobile:inline hover:text-[#8B54F7] hover:no-underline font-semibold text-sm text-[#8B54F7]"
          >
            Click here to sign up for Bootcamp instead
          </Link>
        </div>
      </section>

      {/* successful message modal */}
      <MyModal
        disableBtn={true}
        modalIsOpen={successfulSubscription}
        toggleModal={toggleSuccessSubscriptionModal}
      >
        <SuccessfulPackageRegistration />
      </MyModal>
    </div>
  );
};

export default ClubGetStarted;
