import { CircularProgress } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ProgressCircle from 'rsuite/esm/Progress/ProgressCircle'
import {ReactComponent as FlashIcon} from "./../../../assets/dashboard/Settings/svg/flash-icon.svg"

const DeleteAccountView = ({closeModal, onDelete, loading}) => {

    // HANDLERS
    // handle deletion cancel
    const onCancelDelete = () => {
        closeModal()
    }

    return (
        <div className="p-5 mobile:p-4 bg-white flex flex-col gap-4">
            
            {/* icon container */}
            <div>
                <FlashIcon />
            </div>

            {/* text container */}
            <div>
                <p className='text-base font-semibold text-[#111827]'>
                    Are you sure you want to delete?
                </p>

                <p className='font-normal text-sm text-[#6B7280]'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.
                </p>
            </div>

            {/* buttons container */}
            <div className='flex mobile:flex-col mobile:justify-center mobile:items-stretch justify-end items-center gap-3 mt-5 mobile:mt-2'>

                <button 
                onClick={onCancelDelete}
                className='border border-[#D1D5DB] px-4 py-2 text-[#374151] font-medium text-base rounded-[6px] hover:bg-[#f5f5f5] bg-white inline-flex items-center justify-center'>
                    Don’t delete
                </button>

                <button
                disabled={loading}
                onClick={onDelete} 
                className={`border border-[#E02D3C] px-4 py-2 font-medium text-base rounded-[6px] hover:opacity-70 bg-[#E02D3C] text-white inline-flex items-center justify-center ${loading?"w-[40%]":""}`}>
                    {
                        loading?
                        <div className="text-center">
                            <CircularProgress 
                            color={"inherit"} 
                            size="20px" />
                        </div>:
                        "Delete my account"
                    }
                </button>
            </div>
        </div>
    )
}

export default DeleteAccountView