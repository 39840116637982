import React from 'react'

const SelectPackageBtn = ({onClick}) => {
    return (
        <button 
        onClick={onClick}
        className='rounded-[8px] w-fit px-4 py-2 text-sm font-semibold hover:bg-[#f5f5f5] border border-[D0D5DD] text-[#344054]'>
            Select Package
        </button>
    )
}

export default SelectPackageBtn