import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import jumbotronImage from "../../../../../assets/redesign-dashboard/bootcamps/images/jumbotron-image2.png"
import { getBootcampForUsersRequest, getSpecificBootcampRequest } from '../../../../../redux/action'
import BtnPrimary from '../../../../Global/Redesign/BtnPrimary'
import AvailableTracks from '../AvailableCourse/AvailableTracks'
import CourseOutline from '../CourseOutline'
import FixedNoticeBoard from '../FixedNoticeBoard'
import WhatYouLearn from '../WhatYouLearn'
import FixedGetStarted from './FixedGetStarted'
import GetStartedCard from './GetStartedCard'

const SingleCourseBody = () => {

    // STATES
    const [hasScrolled, setHasScrolled] = useState(false)
    const {
        specificBootcamp,
        bootcamps,
        specificBootcampLoading,
        bootcampsLoading
      } = useSelector((state) => {
    
        const { 
            specificBootcamp, 
            bootcamps 
        } = state.bootcampReducer;
        const { 
            specificBootcampLoading, 
            bootcampsLoading 
        } = state.loadingIndicator;
    
        return {
            specificBootcamp,
            bootcamps,
            bootcampsLoading,
            specificBootcampLoading,
        };
    });


    // DATA INITIALIZATION
    const location = useLocation();
    const history = useHistory()
    const bootcampId = location.pathname.split("/").pop();
    const dispatch = useDispatch();


    // SIDE EFFECTS
    useEffect(() => {
        dispatch(getSpecificBootcampRequest(bootcampId));
        dispatch(getBootcampForUsersRequest())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bootcampId]);

    return (
        <div className='flex flex-col w-full h-full gap-10'>
            {
                specificBootcampLoading?
                <Skeleton
                variant="rectangular"
                sx={{
                width: "100%",
                height: "70vh",
                borderRadius: "10px",
                }} />:
                (specificBootcamp && specificBootcamp)?
                <>

                    {/* course jumbtron info */}
                    <div className='gap-10 flex mobile:flex-col p-5 rounded-[10px] bg-white border border-[#E1E6EF]'>

                        {/* texts section */}
                        <section className='flex flex-col gap-4 items-start w-[70%] mobile:w-full'>

                            {/* label */}
                            <span className='flex items-center justify-center text-[#3F444D] font-semibold text-[11px] rounded-[8px] bg-[#E1E6EF] h-[32px] px-3'>
                                {specificBootcamp.pathway}
                            </span>

                            {/* texts */}
                            <div className='flex flex-col gap-2 items-start'>
                                <p className='text-gray-800 font-semibold text-[22px] mt-0'>
                                    {specificBootcamp.name}
                                </p>

                                <p className='font-normal text-[22px] text-gray-800 mt-0'>
                                    {specificBootcamp.duration} Months Course
                                </p>
                            </div>

                            {/* description for pc view */}
                            <p className='mt-0 text-gray-800 text-base text-normal mobile:hidden'>
                                {specificBootcamp.description}
                            </p>

                        </section>

                        {/* image section */}
                        <div className='w-[30%] mobile:w-full'>
                            <img src={jumbotronImage} className='object-cover w-full h-full rounded-[10px]' alt="" />
                        </div>

                        {/* description for mobile view */}
                        <p className='mt-0 text-gray-800 text-base text-normal hidden mobile:block'>
                            {specificBootcamp.description}
                        </p>
                    </div>
                    
                    {/* package*/}
                    <div className={`flex ${hasScrolled?"mobile:mb-20":""} mobile:flex-col-reverse w-full h-full gap-10`}>
                        <div className='h-full w-[70%] mobile:w-full flex flex-col gap-10'>

                            <WhatYouLearn
                            subpackageList={specificBootcamp.course_id.expected_outcome}
                            disableRouting={true} />

                            {/* course outline */}
                            <CourseOutline
                            topics={specificBootcamp.course_id.topics} />

                            {/* other courses */}
                            <AvailableTracks
                            captionText={"View other courses"}
                            loading={bootcampsLoading}
                            isEmpty={!(bootcamps && bootcamps.length!==0)}
                            regularTrack={bootcamps?.filter((bootcamp)=>bootcamp?.course_type==="regular" && bootcamp._id !== bootcampId)}
                            specialTrack={bootcamps?.filter((bootcamp)=>bootcamp?.course_type==="pathway" && bootcamp._id !== bootcampId)} />
                        </div>
                                    
                        {/* summary */}
                        <div className='h-full w-[30%] mobile:w-full flex flex-col'>
                            <GetStartedCard
                            path={specificBootcamp._id}
                            price={specificBootcamp.new_price.toLocaleString("en-US")}
                            duration={specificBootcamp.duration} />
                        </div>
                    </div>

                    {/* Fixed notice Board */}
                    <FixedNoticeBoard
                    hasScrolled={hasScrolled}
                    setHasScrolled={setHasScrolled}
                    id={"getStarted"}>
                        <FixedGetStarted
                        price={specificBootcamp.new_price.toLocaleString("en-US")}
                        path={specificBootcamp._id} />
                    </FixedNoticeBoard>
                </>:
                <div className='flex justify-center items-center w-full h-[70vh] text-2xl rounded-[10px]'>Nothing Here</div>
            }
        </div>
    )
}

export default SingleCourseBody