import React from "react";
import { Link } from "react-router-dom";
import NavBtn from "../../components/Global/NavBtn";
import mainLogo from "./../../assets/home/svg/logo-main.svg";
import MobileNavList from "../../components/Global/MobileNavList";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import DesktopNavList from "../../components/Global/DesktopNavList";
import first_flower from "./../../assets/home/images/xmas-gift1.png";
import second_flower from "./../../assets/home/images/xmas-flower.png";
import third_flower from "./../../assets/home/images/xmas-gift2.png";

const Header = ({
  bgColor="bg-[#ffffff]", 
  shadowStyle="pc:[box-shadow:0px_2px_8px_#e0e0e0]"
}) => {
  
  // mobile navState
  const [isNavOpen, setNavState] = useState(false);

  // toggle navbar mobile
  const closeNav = () => {
    setNavState(!isNavOpen);
  };

  return (
    // header container
    <div
      className={`${bgColor} pt-10 mobile:fixed mobile:z-[8000000] mobile:left-0 mobile:top-0 mobile:pt-0 mobile:flex mobile:justify-center mobile:flex-col mobile:items-center mobile:w-screen`}
    >
      {/* header component */}
      <header
        className={`${shadowStyle} mobile:border-b-2 border-[#F2F4F7] bg-white h-[124px] mobile:h-[5.5rem] mobile:py-7 w-[90%] mobile:w-full mx-auto justify-between items-center flex px-12 mobile:px-7 rounded-[10px] mobile:rounded-none relative z-[100000]`}
      >
        {/* logo */}
        <Link to={"/old"}>
          <img
            className="mobile:h-[40px]"
            src={mainLogo}
            alt="main logo icon"
          />
        </Link>

        {/* mobile nav button */}
        {/* the display value is none for desktop view */}
        <NavBtn onClick={() => setNavState(!isNavOpen)}>
          {isNavOpen ? (
            <FontAwesomeIcon size="xl" icon={faXmark} />
          ) : (
            <FontAwesomeIcon size="xl" icon={faBars} />
          )}
        </NavBtn>

        {/* Desktop nav items */}
        <DesktopNavList />
      </header>

      {/* mobile nav items */}
      {/* the display value is none for desktop view */}
      <MobileNavList 
      closeNav={closeNav} 
      isOpen={isNavOpen} />
    </div>
  );
};

export default Header;
