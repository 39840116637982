import {
    GET_BOOTCAMP_FOR_USERS_SUCCESS,
    GET_USERS_BOOTCAMP_SUCCESS,
    GET_SPECIFIC_BOOTCAMP_SUCCESS
} from "../types"

const initialState = () => ({
    bootcamp: [],
    bootcamps: [],
    specificBootcamp: []
})

const bootcampReducer = (state = initialState(), { type, payload }) => {
    switch (type) {
        case GET_BOOTCAMP_FOR_USERS_SUCCESS:
            return {
                ...state,
                bootcamps: payload.bootcamp
            }

        case GET_USERS_BOOTCAMP_SUCCESS:
            return {
                ...state,
                bootcamp: payload.bootcamps
            }

        case GET_SPECIFIC_BOOTCAMP_SUCCESS:
            return {
                ...state,
                specificBootcamp: payload.bootcamp
            }

        default:
            return state;
    }
}

export default bootcampReducer