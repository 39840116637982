import React from 'react'
import AboutSpaceBody from '../../../../components/Redesign/Dashboard/SpaceBooking/AboutSpace/Body'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'

const AboutSpace = () => {
    return (
        <PageWrapper
        enableBackBtn={true}
        title={"Comfortable Workspaces"}
        subTitle={"Just for You"}>

            {/* body section */}
            <AboutSpaceBody />

        </PageWrapper>
    )
}

export default AboutSpace