import { useEffect, useState } from 'react'
import Accordion from './Accordion'

const CourseOutline = ({topics}) => {

    useEffect(()=>{
        setTopicData(topics)

        return ()=> {
            setTopicData([])
        }
    }, [topics])

    const [topicData, setTopicData] = useState([])
    

    return (
        <div className='flex flex-col gap-5 justify-between bg-white mt-8'>
                <p className='text-[#3f444d] text-xl font-semibold'>
                    Course outline
                </p>

                {/* <Accordion /> */}
                {
                    !(topicData && Array.isArray(topicData))?
                    <></>:
                    <>
                        {
                            topicData?.map(({sub_topics, content, _id}, index)=>(
                                <Accordion
                                courseItems={content.split("%")}
                                couseTitle={sub_topics}
                                key={_id}
                                weekNumber={index+1} />
                            ))
                        }
                    </>
                }
        </div>
    )
}

export default CourseOutline