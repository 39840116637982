import React from "react";
import FixedMessage from "../../../components/Global/FixedMessage";
import Footer from "../../../components/Global/Footer";
import CoreValues from "../../../components/Management/CoreValues";
import JoinUs from "../../../components/Management/JoinUs";
import Mission from "../../../components/Management/Mission";
import OurTeam from "../../../components/Management/OurTeam";
import Header from "../../../layouts/Header";
import ctShape1 from "./../../../assets/management/svg/ct-shape1.svg";
import ctShape2 from "./../../../assets/management/svg/ct-shape2.svg";
import ctShape3 from "./../../../assets/management/svg/ct-shape3.svg";

const ManagementTeam = () => {
  return (
    <>
      {/* Header */}
      <Header
      bgColor="bg-[#F8F5FF]" />

      {/* fixed message */}
      <FixedMessage />

      <div className="flex flex-col mobile:w-screen">
        {/* caption */}
        <div className="flex flex-col justify-center items-center pt-32 pb-20 gap-7 bg-[#F8F5FF] relative mobile:px-6 md:px-10 px-10 mobile:text-center mobile:gap-5 mobile:mt-6">
          {/* patterns */}
          <img
            src={ctShape3}
            className="mobile:w-[20px] absolute top-[6em] left-[15%]"
            alt=""
          />
          <img
            src={ctShape1}
            className="mobile:w-[20px] absolute top-[15rem] mobile:right-[10%] right-[20%]"
            alt=""
          />

          {/* caption title */}
          <p className="font-semibold mobile:text-4xl text-[60px] leading-[54px] text-black">
            So you want to hear our story...
          </p>
          <p className="font-semibold mobile:text-base text-2xl leading-[36px]">
            Well we enjoy telling it too!!
          </p>
          <p className="font-normal mobile:text-base text-xl leading-[30px] text-center text-[#667085] mobile:mt-5 mobile:px-6">
            AI Movement is a community of private investors, institutional
            investors, <br className="mobile:hidden" />
            and technology experts. We seek to identify, understand, and resolve{" "}
            <br className="mobile:hidden" />
            core humanitarian issues through advanced technology.
          </p>

          {/* third pattern */}
          <img
            src={ctShape2}
            className="mobile:w-[55px] mobile:h-[28px] relative top-[1rem]"
            alt=""
          />
        </div>

        {/* story and mission */}
        <Mission />

        {/* core values */}
        <CoreValues />

        {/* out team */}
        <OurTeam />

        {/* join us */}
        <JoinUs />

        {/* footer */}
        <Footer />
      </div>
    </>
  );
};

export default ManagementTeam;
