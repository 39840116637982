import { Link } from "react-router-dom";
import SubscribeForm2 from "./SubscribeForm2";
import { AiFillInstagram } from "react-icons/ai";
import { ReactComponent as Twitter } from "../../../assets/webpages/home/svg/twitter.svg";
import logo from "../../../assets/webpages/home/svg/logo-main.svg";

const Footer2 = () => {
  return (
    <div>
      {/* subscribe */}
      <div className="bg-[#f9fafb] flex justify-between items-start py-12 mobile:gap-10 mobile:pt-12 pc:px-32 lg:px-20 pc:gap-8 mobile:px-5 mobile:flex-col px-16 md:px-16">
        {/* texts */}
        <div className="flex flex-col gap-1">
          <p className="font-semibold text-2xl leading-[36px] text-[#101828]">
            Subscribe to our Newsletter
          </p>

          <p className="font-normal text-xl mobile:text-base leading-[30px] text-[#667085]">
            Stay up to date with the latest news, announcements, and articles.
          </p>
        </div>

        {/* subscription form */}
        <SubscribeForm2 />
      </div>

      {/* links */}
      <div className="pt-20 pb-20 px-32 relative mobile:px-5 mobile:py-12">
        <div className="grid grid-cols-3 justify-center items-start mobile:flex mobile:flex-col mobile:gap-12 gap-[10rem] mx-auto">
          <div className="flex flex-col gap-3">
            <p className=" mobile:hidden flex justify-start items-center font-semibold text-base">
              <img src={logo} alt="" />
            </p>

            {/* mobile footer logo */}
            <p>
              <img src={logo} className="pc:hidden w-[147px]" alt="" />
            </p>

            <p className="font-normal text-base text-[#667085]">
              Let’s Connect and Educate
            </p>
          </div>

          <ul className="flex flex-col justify-start font-semibold text-base text-[#6941c6] gap-3">
            {/* caption */}
            <li className="font-semibold text-sm text-black">Company</li>

            {/* links */}
            <li>
              <Link
                className="text-[#055774] font-semibold text-base transition-all hover:text-[#055774]"
                to={"/management"}
              >
                Management team
              </Link>
            </li>

            <li>
              <Link
                className="text-[#055774] font-semibold text-base transition-all hover:text-[#055774]"
                to={"/club"}
              >
                The Club
              </Link>
            </li>

            <li>
              <Link
                className="text-[#055774] font-semibold text-base transition-all hover:text-[#055774]"
                to={"/training"}
              >
                Training center
              </Link>
            </li>
          </ul>

          <ul className="flex flex-col justify-start font-semibold text-base text-[#6941c6] gap-3">
            {/* caption */}
            <li className="font-semibold text-sm text-black">Resources</li>

            {/* links */}
            <li>
              <Link
                className="text-[#055774] font-semibold text-base transition-all hover:text-[#055774]"
                to={"/events"}
              >
                Events
              </Link>
            </li>

            <li>
              <Link
                className="text-[#055774] font-semibold text-base transition-all hover:text-[#055774]"
                to={"/contactus"}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* social links */}
      <div className="flex mobile:flex-col-reverse justify-between mobile:items-center mobile:gap-7 mobile:pb-12 pb-20 border-t pt-10 mx-32 mobile:pt-7 mobile:mx-5">
        {/* copyright */}
        <p className="text-[#98a2b3] mobile:text-sm text-base font-normal">
          &copy; {`${new Date().getFullYear()}`} AI Movement. All rights
          reserved.
        </p>

        {/* social icons */}
        <div className="flex gap-5 mobile:justify-evenly mobile:gap-6 mobile:px-10">
          <a
            rel="noreferrer"
            href="https://twitter.com/AIMovementNG"
            target="_blank"
          >
            <Twitter />
          </a>

          <a
            rel="noreferrer"
            className="text-[#98A2B3] text-2xl hover:text-[#98A2B3]"
            href="https://instagram.com/aimovement_ng"
            target="_blank"
          >
            <AiFillInstagram />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer2;
