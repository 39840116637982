import React from 'react'
import SingleCourseBody from '../../../../components/Redesign/Dashboard/Bootcamps/SingleCourse/SingleCourseBody'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'

const SingleCourse = () => {
    return (
        <PageWrapper
        enableBackBtn={true}
        title={"The Training Center"}
        subTitle={"Education is always the key"}>

            {/* body section */}
            <SingleCourseBody />

        </PageWrapper>
    )
}

export default SingleCourse