import BtnPrimary from "../../../Global/Redesign/BtnPrimary";
import coffeeImg from "../../../../assets/webpages/spaces/images/coffee-image.png"
import cofShape1 from "../../../../assets/webpages/spaces/images/cof-shape1.png"

const Coffee = ({toggleModal}) => {
    return (
        <div className="flex flex-col items-center gap-2 text-center container mx-auto w-full py-32 mobile:px-2">

            <p className="leading-none font-semibold text-base text-[#055774]">
                LAST BUT NOT LEAST
            </p>

            <p className="leading-none text-2xl font-semibold text-black mb-4 w-fit relative">
                A hot cup of coffee

                <img src={cofShape1} className="hidden mobile:inline absolute w-[20px] top-[-.6rem] right-[-.89rem]" alt="" />
            </p>

            <img src={coffeeImg} className="w-186px" alt="" />

            <p className="text-gray-600 font-normal text-base mb-6 w-[50%] mobile:w-full">
                We love it when you’re productive and what better way to keep you motivated and energized than a hot sizzling cup of coffee. Don’t worry it’s totally FREE
            </p>

            <BtnPrimary
            onClick={toggleModal}
            title={"Book a space now"} />

        </div>
    )
}

export default Coffee;