// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  getStorage,
  ref as sRef,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCUvpCLAdOWePMFQtPNrehMEYJIzFBa1vA",
  authDomain: "aimovementapp.firebaseapp.com",
  projectId: "aimovementapp",
  storageBucket: "aimovementapp.appspot.com",
  messagingSenderId: "1078175052573",
  appId: "1:1078175052573:web:b13e076c253e0ed36927f4",
  measurementId: "G-T0K4EZQSQ3",
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

getStorage(firebase);
// const projectFirestore = getFirestore(firebase);

export { getStorage, sRef, uploadBytesResumable, getDownloadURL };
