import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

const WithEventStatus = ({children}) => {


    // STATES
    const [hasEnded, setHasEnded] = useState(false)
    

    // OBJECT INITIALIZATION
    let {eventId} = useParams();
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const childrenWithProps = React.Children.map(children, child=>{
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { hasEnded })
        }
        return child
    })

    // populate the ongoing state
    useEffect(()=>{
        if(params.get("status")==="UPCOMING"){
            setHasEnded(false)
        } else if(params.get("status")==="PAST") {
            setHasEnded(true)
        } else {
            setHasEnded(true)
        }
    }, [])


    return (
        <>
            {childrenWithProps}            
        </>
    )
}

export default WithEventStatus