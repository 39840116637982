import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { Link, useHistory } from 'react-router-dom'
import ArrowBtn from '../Bootcamps/ArrowBtn'

const PackFeatures = ({disableRouting}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()
    const subPackages = [
        {
            text: "Member Swag",
            path: "#"
        },
        {
            text: "Newsletter",
            path: "#"
        },
        {
            text: "Networking Event",
            path: "/dashboard/events"
        },
        {
            text: "Reserved Office Space",
            path: "/dashboard/space-booking"
        },
        {
            text: "Foreign Tech Consultancy",
            path: "#"
        },
        {
            text: "Discounted Services",
            path: "#"
        },
        {
            text: "Investment Pitch",
            path: "/dashboard/investing"
        },
        {
            text: "Investment Database",
            path: "/dashboard/club/investors/database"
        },
        {
            text: "Life Insurance",
            path: "#"
        },
        {
            text: "Business Consultacy",
            path: "#"
        },
    ]


    // HANDLERS
    const handleClick = () => {
        history.push("/dashboard/club/availble-packages")
    }

    return (
        <div className='flex flex-col gap-4 w-full'>
            
            {/* caption heading */}
            <section className='flex justify-between items-center w-full'>
                <p className='font-medium text-sm text-[#3F444D]'>
                    Advanced Package Features
                </p>

                {/* arrow botton for pc view */}
                {
                    !disableRouting &&
                    <ArrowBtn
                    text='View other membership packages'
                    onClick={handleClick}
                    className="mobile:hidden" />
                }
            </section>

            {/* subscription card */}
            <section className='w-full p-5 grid grid-cols-4 mobile:grid-cols-2 gap-4 rounded-[10px] border border-[#E1E6EF] bg-white'>

                {
                    subPackages.map((pack, index)=>(
                        <Link
                        key={index}
                        to={pack.path}
                        className="hover:no-underline active:no-underline cursor-pointer rounded-[8px] h-[48px] border border-[#E1E6EF] flex justify-between px-3 text-gray-800 hover:text-gray-800 items-center gap-2 hover:bg-gray-100">
                            {pack.text}
                            <IoIosArrowForward
                            className='text-2xl text-gray-600' />
                        </Link>
                    ))
                }
            </section>

            {/* arrow botton for mobile view */}
            {
                !disableRouting &&
                <ArrowBtn
                text='View other membership packages'
                onClick={handleClick}
                className="hidden mobile:inline-flex" />
            }
        </div>
    )
}

export default PackFeatures