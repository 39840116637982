import React from 'react'
import Footer2 from '../../../../layouts/Redesign/Footer2'
import BookSpace from '../Spaces/BookSpace'
import HowItWorks from './HowItWorks'
import Questions from './Questions'
import Showcase from './Showcase'
import StartInvest from './StartInvest'

const InvestingBody = () => {
    return (
        <div className='mobile:w-screen w-full'>

            {/* showcase component */}
            {/* <Showcase /> */}

            {/* Invest */}
            <StartInvest />

            {/* How it works */}
            <HowItWorks />

            {/* FAQ */}
            <Questions />

            {/* book spaces */}
            <BookSpace />

            {/* Footer */}
            <Footer2 />
            
        </div>
    )
}

export default InvestingBody