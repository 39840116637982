import React from 'react'
import BtnPrimary from '../../../../Global/Redesign/BtnPrimary'

const SpaceCard = ({
    toggleRegModal, 
    toggleSpaceModal, 
    image, 
    spaceName, 
    price,
    hasReg,
    setSelectedSpaceInfo,
    spaceId,
    disabled
}) => {

    return (
        <div className='flex flex-col rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] p-3 gap-4'>
            
            {/* image */}
            <div className='w-full h-full'>
                <img src={image} className='w-full h-full object-cover rounded-[10px]' alt="" />
            </div>

            {/* texts */}
            <div className='w-full h-full flex flex-col gap-4'>

                {/* text */}
                <div className='flex flex-col gap-2 items-center'>

                    <p className='text-base font-medium text-[#0B8CBA]'>
                        {spaceName}
                    </p>

                    <p className='text-gray-600 text-base font-bold'>
                        NGN {price.toLocaleString('en-US')} /day
                    </p>
                </div>

                {/* button */}
                <div className='w-full flex flex-col items-stretch'>

                    <BtnPrimary
                    disabled={disabled}
                    onClick={()=>{
                        setSelectedSpaceInfo({
                            name: spaceName,
                            price: price,
                            image,
                            spaceId
                        })
                        if(hasReg) {
                            toggleSpaceModal()
                        } else {
                            toggleRegModal()
                        }
                    }}
                    title={"Reserve this space"} />
                </div>
            </div>
        </div>
    )
}

export default SpaceCard