import {useEffect} from 'react'
import workspaceImage from "../../../../assets/trainingcenter/images/workspace.png"
import GreenCheck from '../../../Global/GreenCheck';
import { AiFillCalendar } from "react-icons/ai";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { TbCertificate } from "react-icons/tb";
import { GrUserExpert } from "react-icons/gr";
import BtnPrimary from '../../../Global/Redesign/BtnPrimary';
import CourseOutline from './CourseOutline';
import { useHistory } from 'react-router-dom';

const LearnMorePopup = ({moreInfo, closeModal}) => {

    useEffect(()=>{
        console.log(moreInfo)
        document.querySelector("body").style.overflow = "hidden"

        return () => {
            document.querySelector("body").style.overflow = "auto"
        }
    }, [])

    // history object
    const history = useHistory()

    return (
        <div className='bg-white p-10 mobile:p-5 flex flex-col gap-10 w-full'>
            
            {/* container */}
            <div className='flex flex-col w-full gap-4 mobile:gap-5'>

                {/* label */}
                <div className='flex justify-start'>
                    <span className='font-normal text-xs text-[#3F444D] h-[32px] flex items-center justify-center bg-[#F8F5FF] rounded-[20px] px-4 w-fit'>
                        {moreInfo?.pathway}
                    </span>
                </div>

                {/* bootcamp info */}
                <div className='flex justify-between mobile:flex-col-reverse mobile:gap-7'>

                    {/* description */}
                    <div className='w-[50%] mobile:w-full'>
                        <p className='text-4xl mobile:text-2xl lg:text-2xl font-semibold text-gray-800'>
                            {moreInfo?.name}
                        </p>

                        <p className='font-semibold mobile:text-xl text-2xl font-gray-800'>
                            {moreInfo?.description
                            ?.split(" ")[0]
                            .replace("Mobile", "")}
                        </p>

                        <p className='font-normal text-base text-[#1D2433]'>
                            {moreInfo?.description}
                        </p>
                    </div>
                    
                    {/* image */}
                    <div className='w-[45%] mobile:w-full'>
                        <img src={workspaceImage} className={"w-full object-cover"} alt="" />
                    </div>
                </div>
            </div>

            {/* container */}
            <div className='grid grid-cols-2 justify-between gap-7 mobile:flex mobile:flex-col-reverse mobile:gap-9'>

                {/* what you'll learn */}
                <div className='flex flex-col gap-2'>

                    <p className='font-semibold text-xl text-[#3F444D]'>
                        What you’ll learn
                    </p>

                    <p className='font-semibold text-base text[#1D2433]'>
                        Current course enrolled in
                    </p>

                    {/* items */}
                    <div className='flex flex-col gap-4 mt-1'>

                        {moreInfo?.course_id?.expected_outcome?.map(
                        ({ content }, index) => (
                        <div className='flex items-start gap-2'>
                            <GreenCheck />
                            <p className='font-normal text-base text-gray-800'>
                            {content}
                            </p>
                        </div>
                    )
                  ) || "-"}
                    </div>
                </div>

                {/* Course benefits, sort of */}
                <div className='flex flex-col w-[80%] ml-auto mobile:w-full lg:w-full mobile:ml-0'>

                    <div className='flex flex-col gap-2'>

                        {/* caption */}
                        <p className="text-[#3f444d] text-base font-semibold mt-1 mb-3">
                            This course includes
                        </p>

                        {/* benefit details */}
                        <div className='flex flex-col gap-2'>
                            <p className="font-normal text-base text-[#3f444d] flex items-center justify-start gap-3">
                                <AiFillCalendar size={24} color={"#000"} />
                                {moreInfo.duration || "-"} months intensive training
                            </p>

                            <p className="font-normal text-base text-[#3f444d] flex items-center justify-start gap-3">
                                <HiOutlineOfficeBuilding size={24} color={"#000"} />
                                Physical/Online learning
                            </p>

                            <p className="font-normal text-base text-[#3f444d] flex items-center justify-start gap-3">
                                <GrUserExpert size={24} color={"#000"} />
                                Access to industry experts
                            </p>

                            <p className="font-normal text-base text-[#3f444d] flex items-center justify-start gap-3">
                                <TbCertificate size={24} color={"#000"} />
                                Certificate of completion
                            </p>
                        </div>
                    </div>

                    <div className='flex flex-col gap-2'>
                        {/* amount and label*/}
                        <div className='flex justify-center items-center gap-3 w-full mt-5'>
                            <p className='font-semibold text-xl text-gray-600'>
                                NGN {moreInfo?.new_price?.toLocaleString("en-US") || "-"}
                            </p>

                            {/* label */}
                            <span className="bg-[#E02D3C] h-[22px] inline-flex justify-center items-center font-semibold text-[10px] leading-[14px] px-3 rounded-[8px] text-white">
                                Promo price
                            </span>
                        </div>

                        {/* get started button */}
                        <div className="flex flex-col items-stretch mt-1">
                            <BtnPrimary 
                            onClick={()=>history.push(`/login`)}
                            title={"Get started now"} />
                        </div>
                    </div>
                </div>
            </div>

            {/* accordion */}
            <CourseOutline
            topics={moreInfo.course_id?.topics || "-"} />

            {/* close modal button */}
            <div className='flex-col items-stretch hidden mobile:flex'>
                <BtnPrimary 
                title={"Close"} 
                onClick={closeModal} />
            </div>
        </div>
    )
}

export default LearnMorePopup