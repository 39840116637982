import React from 'react'
import Tabs,{Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import AccountForm from './AccountForm';
import EditProfileForm from './EditProfileForm';

const AccountCard = () => {

    // special track tab
    const specialTrack = <span className='text-base font-normal'>
        Edit Profile
    </span>

    // regular track tab
    const regularTrack = <span className='font-normal text-base'>
        Account
    </span>

    return (
        <div className='bg-[#F8F9FC] mobile:bg-transparent mobile:p-0 rounded-[10px] p-5'>

            <Tabs 
            activeTab="1" 
            className={`mt-3`} 
            ulClassName="!border-[#ccc]" 
            activityClassName="!text-[#8B54F7] bg-green-700" 
            onClick={(event, tab) => console.log(event, tab)} >
                
                <Tab 
                title={regularTrack} 
                className="mb-2 mr-7 !px-2">
                    <div className="mt-4">
                        <EditProfileForm />
                    </div>
                </Tab>
            
                <Tab 
                title={specialTrack} 
                className="mr-3 mb-2 !px-2">
                    <div className="mt-4">
                        <AccountForm />
                    </div>
                </Tab>
            </Tabs> 
        </div>
    )
}

export default AccountCard