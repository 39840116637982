import React from "react";
import { Switch } from "react-router-dom";
import BootcampFormRouter from "./BootcampForm/router";
import CritiqueFormRouter from "./CritiqueAndLunch/router";
import DigMarketingFormRouter from "./DigMarketingForm/router";
import GenericEventsRouter from "./GenericEventsFrom/router";
import DataAnaylysisFormRouter from "./DataAnalysisForm/router";

const FormsRouter = ({ path }) => {
  return (
    <>
      <Switch>
        <DigMarketingFormRouter
          path={`${path}/digital-marketing-bootcamp2023`}
        />

        <BootcampFormRouter path={`${path}/journey-into-tech2023`} />

        <CritiqueFormRouter path={`${path}/critique&lunch2023`} />

        <DataAnaylysisFormRouter path={`${path}/dataanalysis2023`} />

        <GenericEventsRouter path={`${path}/refreshevent2023`} />
      </Switch>
    </>
  );
};

export default FormsRouter;
