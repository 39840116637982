import React from 'react'
import Modal from "react-modal"
import { GrClose } from "react-icons/gr"
import "./modal.css"

const MyModal = ({modalIsOpen, toggleModal, children, disableBtn}) => {
    return (
        <div className='div-modal'>
            <Modal
            isOpen={modalIsOpen}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
            className="mymodal"
            overlayClassName="modal-overlay"
            closeTimeoutMS={500}
            ariaHideApp={false}>

                {/* modal content */}
                <div className="model-item-cont">

                    {
                        !disableBtn && 

                        // modal close button
                        <span 
                        className="modal-close-btn" 
                        onClick={toggleModal}>
                            <GrClose />
                        </span>
                    }

                    {/* children */}
                    {children}
                </div>
            </Modal>
        </div>
    )
}

export default MyModal