import React, { useEffect } from "react";
import SideNav from "../../../layouts/Dashboard/SideNav";
import ProfileAvatar from "../../../components/Dashboard/Home/ProfileAvatar";
import jumboImage from "./../../../assets/dashboard/courses/images/jumbo-image.png";
import CheckCircle from "../../../components/Dashboard/Courses/CheckCircle";
import PathwayCard from "../../../components/Dashboard/Courses/PathwayCard";
import CourseOutline from "../../../components/Dashboard/Courses/CourseOutline";
import BackBtn from "../../../components/Dashboard/Global/BackBtn";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  clearputBootcampPaymentSuccess,
  getSpecificBootcampRequest,
} from "../../../redux/action";
import { Skeleton } from "@mui/material";
import { toast } from "react-toastify";
import MyModal from "../../../layouts/MyModal";
import SuccessfulPaymentView from "../../../components/Dashboard/Courses/SuccessfulPaymentView";

const CourseDetails = () => {

  // modal state for saved account info
  const [successfullPayment, setSuccessfullPayment] = React.useState(false)

  // toggle saved account info
  const toggleSuccessPaymentModal = () => {
    setSuccessfullPayment(!successfullPayment)
  }

  // location object
  const location = useLocation();
  const bootcampId = location.pathname.split("/").pop();

  // dispatch object
  const dispatch = useDispatch();

  // bootcamp state
  const {
    specificBootcamp,
    specificBootcampLoading,
    user,
    putBootcampPaymentLoading,
    putBootcampPaymentSuccess,
    putBootcampPaymentError,
  } = useSelector((state) => {
    const {
      success: { putBootcampPayment: putBootcampPaymentSuccess },
      errors: { putBootcampPayment: putBootcampPaymentError },
    } = state.ajaxStatuses;

    const { putBootcampPaymentLoading } = state.loadingIndicator;

    const { user } = state.userData;
    const { specificBootcamp } = state.bootcampReducer;
    const { specificBootcampLoading } = state.loadingIndicator;

    return {
      specificBootcamp,
      specificBootcampLoading,
      user,
      putBootcampPaymentLoading,
      putBootcampPaymentSuccess,
      putBootcampPaymentError,
    };
  });

  useEffect(() => {
    dispatch(getSpecificBootcampRequest(bootcampId));
  }, []);

  // checking for success or errors
  useEffect(() => {
    if (putBootcampPaymentSuccess) {
      toggleSuccessPaymentModal()
      setTimeout(() => {
        setSuccessfullPayment(false)
      }, 3000);

      dispatch(clearputBootcampPaymentSuccess());

      dispatch(getSpecificBootcampRequest(bootcampId));
    }
  }, [putBootcampPaymentSuccess]);

  useEffect(() => {
    if (putBootcampPaymentError) {
      toast.error(putBootcampPaymentError, {
        duration: 3000,
      });
    }
  }, [putBootcampPaymentError]);

  return (
    <div className="flex justify-end mobile:w-screen mobile:flex-col h-full w-full mobile:bg-[#E5E5E5]">
      {/* SideNav */}
      <SideNav />

      {/* Home Body */}
      <section className="w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12 mobile:pt-8">
        {/* header */}
        <header className="container mx-auto mobile:px-4 flex justify-between items-center">
          <div className="mobile:flex mobile:flex-col-reverse gap-3 flex flex-col mobile:gap-6">
            <p className="text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]">
              Courses
            </p>

            <BackBtn />
          </div>

          {/* profile dp */}
          <div className="mobile:hidden">
            <ProfileAvatar />
          </div>
        </header>

        {/* middle section */}
        <section className="container mx-auto flex w-full justify-between mt-12 gap-9 mobile:px-6">
          {/* pathway info */}
          <div className="flex flex-col gap-4 bg-white rounded-[10px] [box-shadow:0_2px_8px_rgb(0,0,0,0.12)] py-8 px-12 mobile:px-6 w-full">
            {/* text section */}
            <div className="flex flex-col items-start">
              {/* pathway label */}
              {specificBootcampLoading ? (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: "8%",
                    height: "2vh",
                    borderRadius: "5px",
                  }}
                />
              ) : (
                <span className="rounded-[20px] bg-[#F8F5FF] flex justify-center items-center text-[#3F444D] p-2 px-3 font-normal text-xs w-fit">
                  {specificBootcamp?.pathway}
                </span>
              )}
            </div>

            {/* image, title and description section */}
            <div className="flex mobile:flex-col-reverse justify-between item mobile:mb-2">

              {/* course name and description */}
              <div className="text-[#1D2433] flex flex-col w-[50%] mobile:w-full gap-2 mobile:gap-0">
                <p className="text-[36px] leading-none mobile:text-center font-semibold opacity-80 my-2">
                  {/* Title */}
                  {specificBootcampLoading ? (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: "40%",
                        height: "2vh",
                        borderRadius: "5px",
                      }}
                    />
                  ) : (
                    specificBootcamp?.name || "-"
                  )}
                </p>

                <p className="font-semibold opacity-[0.65] text-2xl">
                  {specificBootcampLoading ? (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: "10%",
                        height: "2vh",
                        borderRadius: "5px",
                      }}
                    />
                  ) : !(Object.keys(specificBootcamp).length===0) ?
                  (
                    specificBootcamp?.description
                      .split(" ")[0]
                      .replace("Mobile", "")
                  ):"Nothing here"}
                </p>

                <p className="font-normal text-base text-justify opacity-[0.65]">
                  {specificBootcampLoading ? (
                    <Skeleton
                      variant="rectangular"
                      sx={{
                        width: "100%",
                        height: "17vh",
                        borderRadius: "5px",
                      }}
                    />
                  ) : (
                    specificBootcamp?.description || "-"
                  )}
                </p>
              </div>

              {/* image container */}
              <div className="flex flex-col justify-center items-center w-[40%] mobile:w-full">
                <img src={jumboImage} className="mobile:w-[291px] w-full object-cover" alt="" />
              </div>
            </div>

            {/* payment proceeding */}
            
            <div className="mobile:block hidden mobile:w-full">
              
              {/* <PathwayCard /> */}
              {specificBootcampLoading ? (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: "100%",
                    height: "35vh",
                    borderRadius: "10px",
                  }}
                />
              ) : (
                <PathwayCard
                  isMobile={true}
                  user={user}
                  specificBootcamp={specificBootcamp}
                  putBootcampPaymentLoading={putBootcampPaymentLoading}
                />
              )}
            </div>
          </div>
        </section>

        {/* bottom section */}
        <section className="flex mobile:flex-col container mx-auto mobile:px-6 mobile:gap-6 w-full gap-x-5 mt-8">
          {/* main side */}
          <div className="flex flex-col w-[70%] lg:w-[58%] mobile:w-full">
            {/* what you will learn */}
            <div className="flex flex-col gap-2 justify-between bg-white rounded-[10px] [box-shadow:0_2px_8px_rgb(0,0,0,0.12)] p-6">
              <p className="text-[#3F444D] font-semibold text-xl">
                What you’ll learn
              </p>

              <p className="text-[#1D2433] opacity-[0.65] text-sm font-semibold mb-1 mt-2">
                Current course enrolled in
              </p>

              {specificBootcampLoading ? (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: "100%",
                    height: "10vh",
                    borderRadius: "10px",
                  }}
                />
              ) : (
                <div className="pt-1 grid mobile:grid-cols-1 grid-cols-[auto_auto] items-start justify-items-start gap-4">
                  {specificBootcamp?.course_id?.expected_outcome?.map(
                    ({ content }, index) => (
                      <p
                        key={index}
                        className="flex items-center gap-3 text-[#1D2433] opacity-[0.65] font-normal text-sm m-0"
                      >
                        <CheckCircle />
                        {content}
                      </p>
                    )
                  ) || "-"}
                </div>
              )}
            </div>

            {/* Course Outline Accordion */}
            {/* <CourseOutline /> */}
            {specificBootcampLoading ? (
              <div className="mt-8">
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: "100%",
                    height: "35vh",
                    borderRadius: "10px",
                  }}
                />
              </div>
            ) : (
              <CourseOutline topics={specificBootcamp?.course_id?.topics || "-"} />
            )}
          </div>

          {/* aside */}
          <aside className="w-[30%] lg:w-[42%] mobile:hidden">
            {/* <PathwayCard /> */}
            {specificBootcampLoading ? (
              <Skeleton
                variant="rectangular"
                sx={{
                  width: "100%",
                  height: "35vh",
                  borderRadius: "10px",
                }}
              />
            ) : (
              <PathwayCard
                user={user}
                specificBootcamp={specificBootcamp}
                putBootcampPaymentLoading={putBootcampPaymentLoading}
              />
            )}
          </aside>
        </section>

        {/* modal for successfull payment */}
        <MyModal
        disableBtn={true}
        modalIsOpen={successfullPayment}
        toggleModal={toggleSuccessPaymentModal}>
            <SuccessfulPaymentView />
        </MyModal>
      </section>
    </div>
  );
};

export default CourseDetails;
