import React from "react";
import ycap from "./../../assets/home/images/ycap.png";
import teams from "./../../assets/home/images/teams.png";
import shape1 from "./../../assets/management/images/value-shape1.png";

const CoreValues = () => {
  return (
    <div className="bg-[#1D2433] flex flex-col justify-center py-36 mobile:px-5 mobile:py-16 mx-auto">
      {/* text container */}
      <div className="flex flex-col text-white justify-center items-center text-center relative">
        {/* pattern */}
        <img
          src={shape1}
          className="mobile:w-[20px] absolute top-[-5%] left-[20%]"
          alt=""
        />

        {/* texts */}
        <p className="font-semibold text-base text-[#F8F5FF] mobile:text-xs">
          OUR VALUES
        </p>

        <p className="mb-4 mt-2 font-semibold mobile:text-2xl text-4xl leading-[54px] mobile:mt-4">
          The core values behind our work
        </p>

        <p className="font-normal text-base mobile:text-[#F0F5FF]">
          Tech is the future, and the future is now. So, how do we move from the
          present to the future where we ought to be?
        </p>
      </div>

      {/* values container */}
      <div className="grid grid-cols-2 mobile:grid-cols-1  justify-center items-center w-full mt-12 px-36 mobile:px-1 gap-7 gap-y-12">
        {/* first box */}
        <div className="bg-white rounded-[10px] flex gap-8 items-start px-8 py-7 mobile:px-5 mobile:gap-6">
          <img src={ycap} className="mobile:w-[36px]" alt="yellow cap" />
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-xl leading-[24px] text-[#3f444d]">
              Innovation
            </p>
            <p className="font-normal text-base text-[#6c717a]">
              Our products and services always come with our trademark of
              innovation and creativity such that we bang on the very borders of
              the human imagination.
            </p>
          </div>
        </div>

        <div className="bg-white rounded-[10px] flex gap-8 items-start px-8 py-7 mobile:px-5 mobile:gap-6">
          <img src={teams} className="mobile:w-[36px]" alt="yellow cap" />
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-xl leading-[24px] text-[#3f444d]">
              Community
            </p>
            <p className="font-normal text-base text-[#6c717a]">
              Community is at the forefront of all our policies and activities
              as we believe in connecting and networking together to build a
              thriving ecosystem.
            </p>
          </div>
        </div>

        <div className="bg-white rounded-[10px] flex gap-8 items-start px-8 py-7 mobile:px-5 mobile:gap-6">
          <img src={ycap} className="mobile:w-[36px]" alt="yellow cap" />
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-xl leading-[24px] text-[#3f444d]">
              Growth
            </p>
            <p className="font-normal text-base text-[#6c717a]">
              We create a community where everyone can grow regardless of their
              current level or achievement with so many supportive factors;
              project pitch, investment, encouragement and so many more.
            </p>
          </div>
        </div>

        <div className="bg-white rounded-[10px] flex gap-8 items-start px-8 py-7 mobile:px-5 mobile:gap-6">
          <img src={teams} className="mobile:w-[36px]" alt="yellow cap" />
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-xl leading-[24px] text-[#3f444d]">
              Commitment
            </p>
            <p className="font-normal text-base text-[#6c717a]">
              We are committed to seeing the success of our community’s goals
              and ambitions. We believe that “where the heart is willing, it
              will find a thousand ways”. We ensure to fulfill any promise made
              to our customers and students.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoreValues;
