import React from 'react'
import { RiArrowRightSLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'

const ViewInvestment = () => {
    
    // history object
    const history = useHistory()

    return (
        <div
        onClick={()=>history.push("/old/dashboard/venture-funding")}
        className='[box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] flex justify-between items-center gap-4 py-4 px-5 rounded-[10px] bg-white w-full hover:bg-gray-100 cursor-pointer'>
            <span className='text-gray-600 text-base font-normal'>
                View all available investments
            </span>
            <RiArrowRightSLine color="#101828" size={20} />
        </div>
    )
}

export default ViewInvestment