import InvestingBody from "../../../../components/Redesign/Webpage/Investing/InvestingBody"
import InvestingHeader from "../../../../components/Redesign/Webpage/Investing/InvestingHeader"
import Wrapper from "../../../../layouts/Redesign/Wrapper"


const Investing = () => {

    return (
        <Wrapper>
            
            {/* investing header */}
            <InvestingHeader />

            {/* Investing body */}
            <InvestingBody />

        </Wrapper>
    )
}

export default Investing