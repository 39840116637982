import React from 'react'
import image from "../../../../assets/webpages/event/images/card-image.png"
import { useHistory, useLocation } from 'react-router-dom'
import { IoIosArrowForward } from 'react-icons/io'

const EventCard = ({eventImage, date, venue, title, desc, path, status}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    params.set("status", status)

    return (
        <div className='flex flex-col [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] bg-white rounded-[10px] p-3 gap-4 h-full w-full mobile:w-full'>
            
            {/*image */}
            <div className='w-full relative flex flex-col'>
                <img src={eventImage} className="object-cover rounded-[10px] h-[184px] w-full" alt="" />
            </div>

            {/* texts */}
            <div className='flex flex-col items-start justify-center py-4 gap-3 break-words w-full mobile:w-full h-full'>

                <p className='flex flex-col text-xs font-normal text-gray-600'>
                    <span>
                        {date}
                    </span>
                    <span>
                        {venue}
                    </span>
                </p>

                    <p className="text-base font-semibold text-[#1D2433]">
                    {title}
                </p>

                <p className="font-normal text-sm text-gray-800">
                    {desc}
                </p>
                
                <p 
                onClick={()=>history.push({
                    pathname:`/dashboard/events/${path}`,
                    search: params.toString()
                })}
                className="cursor-pointer font-semibold text-sm text-[#0B8CBA] mt-auto flex items-center justify-center gap-1">
                    LEARN MORE
                    <IoIosArrowForward
                    className='text-xl text-[#0B8CBA]' />
                </p>
            </div>
        </div>
    )
}

export default EventCard