import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import Bootcamps from '.'
import AvailableCourses from './AvailableCourses'
import MakePayment from './MakePayment'
import SingleCourse from './SingleCourse'
import Certificate from './Certificate'

const BootcampsRouter = ({path}) => {
    
    return (
        <Switch>
            
            {/* index page */}
            <Route 
            exact
            path={path}
            component={Bootcamps}/>

            {/*page to certificate */}
            <Route
            exact
             path={`${path}/certificate`}
            component={Certificate} />

            {/* available courses page */}
            <Route 
            exact
            path={`${path}/availble-courses`}
            component={AvailableCourses}/>
            
            {/* single course page */}
            <Route 
            exact
            path={`${path}/:courseId`}
            component={SingleCourse}/>

            {/* page to make payment */}
            <Route 
            exact
            path={`${path}/checkout/:courseId`}
            component={MakePayment}/>

       
            

        </Switch>
    )
}

export default BootcampsRouter