import React from "react";
import peeps from "./../../../assets/club/images/more-than-tech.png";
import shape1 from "./../../../assets/club/images/caption-shape.png";
import JoinUs from "../../../components/TheClub/JoinUs";
import AboutUs from "../../../components/TheClub/About.js";
import Footer from "../../../components/Global/Footer";
import Offers from "../../../components/TheClub/Offers";
import Header from "../../../layouts/Header";
import FixedMessage from "../../../components/Global/FixedMessage";

const Club = () => {
  return (
    <>
      {/* header */}
      <Header
      shadowStyle="pc:shadow-none"
      bgColor="bg-[#1B1F27]" />

      {/* fixed message */}
      <FixedMessage />

      <div className="flex flex-col mt-[-5px] mobile:w-screen">
        {/* caption section */}
        <div className="flex flex-col bg-[#1B1F27] py-28 justify-center items-center relative">
          {/* text container */}
          <div className="flex flex-col  text-white text-center gap-7 relative mobile:py-16 mobile:pb-2 mobile:px-8 mx-auto px-12">
            {/* shape */}
            <img
              src={shape1}
              className="mobile:w-[20px] absolute z-[200] right-[-7%] top-[-17%] mobile:right-[1.8rem] mobile:top-[3rem]"
              alt=""
            />

            <p className="text-6xl mobile:text-4xl leading-[54px] font-semibold">
              More than a tech community
            </p>

            <p className="text-2xl mobile:text-base leading-[36px] font-semibold">
              We are here to take your tech career to the next level
            </p>

            <p className="font-normal text-xl mobile:text-base leading-[30px]">
              The club is a community of private investors, venture <br />
              capital companies, institutional investors and technology experts.
            </p>
          </div>

          {/* image */}
          <div className="flex mt-24 justify-center items-center mobile:w-[85%] w-[75%] relative z-[500]">
            <img src={peeps} className="w-[100%]" alt="" />
          </div>

          {/* free container */}
          <div className="h-[300px] -mb-2 mobile:h-[200px] md:!h-[250px] w-full bg-white absolute bottom-[0]"></div>
        </div>

        {/* about us */}
        <AboutUs />

        {/* offers */}
        <Offers />

        {/* join us today */}
        <JoinUs />

        {/* footer */}
        <Footer />
      </div>
    </>
  );
};

export default Club;
