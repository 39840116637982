import React from 'react'
import { Link } from "react-router-dom"

const SingleEvent = ({image, title, date, to}) => {
    return (
        <div className='text-[#1B1F27] border-t border-[#E1E6EF] flex items-center justify-start w-full py-3 gap-4 px-4'>
            <img src={image} className='h-[105px] w-[117px] rounded-[10px] object-cover' alt="" />
            <div className='flex flex-col gap-1'>
                <span className='font-semibold text-sm text-[#1D2433]'>
                    {title}
                </span>
                <span className='font-normal text-xs text-[#1D2433] opacity-[0.65]'>
                    {date}
                </span>

                <span>
                    <Link 
                    to={`/old/dashboard/events/${to}`}
                    className='text-[#6941C6] text-sm font-semibold mt-2 hover:text-[#6941C6] hover:no-underline'>
                        Learn more
                    </Link>
                </span>
            </div>
        </div>
    )
}

export default SingleEvent