import React from 'react'
import { NavLink, useLocation } from "react-router-dom"

const ActiveLink2 = ({children, to}) => {


    // OBJECT INITIALIZATION
    const location = useLocation()
    
    return (
        <>
            <NavLink 
            isActive={()=>location.pathname.includes(to)}
            exact
            to={to} 
            className="text-[#f8f8f8] font-normal text-sm flex justify-start items-center transition-all hover:text-[#055774] hover:bg-[#f9f5ff75] rounded-[6px] h-[40px] gap-3 px-4 active:no-underline hover:no-underline"
            activeClassName='bg-white !text-[#055774] font-bold'>
                {children}
            </NavLink>
        </>
    )
}

export default ActiveLink2