import React from 'react'
import PaymentBtn from './PaymentBtn'

const SummaryCard = ({
    originalPrice, 
    hasSubscription, 
    paymentLoading, 
    promoPrice, 
    paystackProps,
    hasSubscriptionText
}) => {
    return (
        <div className='w-full bg-white border border-[#E1E6EF] gap-4 p-5 flex flex-col rounded-[10px]'>

            <p className='mt-0 text-[#1D2433] font-semibold text-base'>
                Summary
            </p>

            <div className='flex items-center gap-4 border-b border-[#E1E6EF] py-5'>
                <p className='mt-0 font-normal text-base text-gray-600'>
                    Original price:
                </p>

                <p className='mt-0 text-gray-600 font-normal text-base line-through'>
                    N {originalPrice.toLocaleString("en-US")}
                </p>
            </div>

            <div className='flex items-center gap-4 border-b border-[#E1E6EF] pb-5'>
                <p className='mt-0 font-bold text-base text-[#0B8CBA]'>
                    Promo price:
                </p>

                <p className='mt-0 text-gray-600 text-base font-bold'>
                    NGN {promoPrice.toLocaleString("en-US")}
                </p>
            </div>

            <p className='mt-0 w-full font-normal text-base text-gray-600'>
                By completing payment you agree to our terms of service
            </p>

            <div className='flex flex-col items-stretch w-full'>
                <PaymentBtn
                hasSubscription={hasSubscription}
                paymentLoading={paymentLoading}
                paystackProps={paystackProps}
                hasSubscriptionText={hasSubscriptionText} />
            </div>
        </div>
    )
}

export default SummaryCard