import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import { clientErrorMessage, delay } from "./reusable";
import {
  clearGenericEventsSuccess,
  genericEventsFailure,
  genericEventsLoading,
  genericEventsSuccess,
} from "../action/genericEvents";
import { REGISTER_GENERIC_EVENT_REQUEST } from "../types";

// ajaxDBCall
const ajaxDBCalls = {
  // register generic events
  registerGenericEvent: async ({ payload }) => {
    const response = await axios({
      method: "POST",
      url: "/genericbootcamp/register",
      data: payload.userData,
    });
    return response;
  },
};

// WORKERS
function* registerGenericEventsWorker(payload) {
  try {
    yield put(genericEventsLoading(true));
    const response = yield call(ajaxDBCalls.registerGenericEvent, payload);
    yield put(genericEventsSuccess(response.data));
    yield put(genericEventsLoading(false));
    yield delay();
    yield put(clearGenericEventsSuccess());
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("here's what's wrong", err.response.data.message);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(genericEventsFailure(errorMessage));
    yield put(genericEventsLoading(false));
    yield delay();
    yield put(genericEventsFailure(""));
  }
}

// WATCHERS
function* registerGenericEventsWatcher() {
  yield takeLatest(REGISTER_GENERIC_EVENT_REQUEST, registerGenericEventsWorker);
}

// register generic events saga
export default function* registerGenericEventsSaga() {
  yield spawn(registerGenericEventsWatcher);
}
