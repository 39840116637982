import React from 'react'
import { useHistory } from 'react-router-dom'
import BtnPrimary from '../../../../Global/Redesign/BtnPrimary'

const FixedGetStarted = ({price, path}) => {

    // DATA INITIALIZATION
    const history = useHistory()

    return (
        <div className='gap-4 flex flex-col w-full bg-white p-[20px_12px_12px] border-t border-[#E1E6EF]'>

            <div className='flex justify-center items-center gap-1 w-full'>

                <span className='font-bold text-base text-[#0B8CBA]'>
                    NGN {price}
                </span>

                <span className='text-white text-[10px] font-semibold bg-[#E02D3C] h-[22px] px-3 rounded-[8px] flex items-center justify-center'>
                    Promo price
                </span>
            </div>

            {/* button */}
            <BtnPrimary
            onClick={()=>history.push(`/dashboard/bootcamps/checkout/${path}`)}
            title={"Get started now"} />
        </div>
    )
}

export default FixedGetStarted