import { 
    CLEAR_PUT_USER_TO_EVENT_SUCCESS,
    GET_EVENTS_FOR_USERS_FAIL,
    GET_EVENTS_FOR_USERS_LOADING,
    GET_EVENTS_FOR_USERS_REQUEST,
    GET_EVENTS_FOR_USERS_SUCCESS,
    GET_SINGLE_EVENT_FAIL,
    GET_SINGLE_EVENT_LOADING,
    GET_SINGLE_EVENT_REQUEST,
    GET_SINGLE_EVENT_SUCCESS,
    GET_USER_EVENTS_FAIL,
    GET_USER_EVENTS_LOADING,
    GET_USER_EVENTS_REQUEST,
    GET_USER_EVENTS_SUCCESS,
    PUT_USER_TO_EVENT_FAIL,
    PUT_USER_TO_EVENT_LOADING,
    PUT_USER_TO_EVENT_REQUEST,
    PUT_USER_TO_EVENT_SUCCESS
} from "../types";

// GET events for users
export const getEventsForUsersFailure = (errors) => {
    return {
        type: GET_EVENTS_FOR_USERS_FAIL,
        payload: { errors }
    }
}

export const getEventsForUsersLoading = (loading) => {
    return {
        type: GET_EVENTS_FOR_USERS_LOADING,
        payload: loading
    }
}

export const getEventsForUsersRequest = () => {
    return {
        type: GET_EVENTS_FOR_USERS_REQUEST
    }
}

export const getEventsForUsersSuccess = (prop) => {
    return {
        type: GET_EVENTS_FOR_USERS_SUCCESS,
        payload: prop
    }
}

// GET single event
export const getSingleEventFailure = (errors) => {
    return {
        type: GET_SINGLE_EVENT_FAIL,
        payload: { errors }
    }
}

export const getSingleEventLoading = (loading) => {
    return {
        type: GET_SINGLE_EVENT_LOADING,
        payload: loading
    }
}

export const getSingleEventRequest = (prop) => {
    return {
        type: GET_SINGLE_EVENT_REQUEST,
        payload: prop
    }
}

export const getSingleEventSuccess = (prop) => {
    return {
        type: GET_SINGLE_EVENT_SUCCESS,
        payload: prop
    }
}

// GET user events
export const getUserEventsFailure = (errors) => {
    return {
        type: GET_USER_EVENTS_FAIL,
        payload: { errors }
    }
}

export const getUserEventsLoading = (loading) => {
    return {
        type: GET_USER_EVENTS_LOADING,
        payload: loading
    }
}

export const getUserEventsRequest = (prop) => {
    return {
        type: GET_USER_EVENTS_REQUEST,
        payload: prop
    }
}

export const getUserEventsSuccess = (prop) => {
    return {
        type: GET_USER_EVENTS_SUCCESS,
        payload: prop
    }
}

// PUT user to event
export const putUserToEventRequest = (prop) => {
    return {
        type: PUT_USER_TO_EVENT_REQUEST,
        payload: prop
    }
}

export const putUserToEventFailure = (errors) => {
    return {
        type: PUT_USER_TO_EVENT_FAIL,
        payload: { errors }
    }
}

export const putUserToEventSuccess = (prop) => {
    return {
        type: PUT_USER_TO_EVENT_SUCCESS,
        payload: prop
    }
}

export const putUserToEventLoading = (loading) => {
    return {
        type: PUT_USER_TO_EVENT_LOADING,
        payload: loading
    }
}

export const clearPutUserToEventSuccess = () => {
    return {
        type: CLEAR_PUT_USER_TO_EVENT_SUCCESS
    }
}