import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import BtnPrimary from "../Global/Redesign/BtnPrimary";

const SuccessMessage = () => {

    // OBJECT INITIALIZATION
    const history = useHistory()

    return (
        <div className="flex justify-center items-center rounded-[8px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] w-[70%] 2xl:w-[60%] px-24 py-28 mobile:px-5 mobile:py-16 mobile:w-full mobile:mx-4">

            {/* container */}
            <div className="flex flex-col gap-6 pc:p-8 items-center text-center">

                {/* icon */}
                <BsCheckCircleFill className="text-5xl text-[#0B8CBA]" />

                {/* texts */}
                <div className="text-[#1D2433] text-xl font-normal flex flex-col gap-3">

                    <p className="font-semibold text-[#0B8CBA] text-2xl">
                        Thanks for Signing up
                    </p>

                    <p className="text-[#1D2433] font-normal text-xl mobile:text-base">
                        Response successfully submitted.
                    </p>

                    <p className="text-[#1D2433] font-normal text-xl mobile:text-base">
                        Please click on the link below to join our community Whatsapp group
                        for updates concerning upcoming events and bootcamps.
                    </p>

                    {/* button */}
                    <div className="flex w-[70%] flex-col items-stretch mt-3 mx-auto">
                        <BtnPrimary
                        onClick={() => {
                            window.location.href = "https://chat.whatsapp.com/Ja97D1NY2JzK0xcvPaEWFB"
                        }}
                        title="Join"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuccessMessage