import React, { useState } from 'react'
import { HiArrowDown, HiAcademicCap } from "react-icons/hi"
import BtnPrimary from '../../../Global/Redesign/BtnPrimary'
import image1 from "../../../../assets/webpages/home/images/accordion-image1.png"
import { useHistory } from 'react-router-dom'

const Accordion = ({
    number, 
    headerText,
    children
}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()


    // STATES
    const [isOpen, setIsOpen] = useState(false)
    

    // HANDLERS
    const openAccordion = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className={`flex flex-col gap-1 mobile:w-full w-full overflow-hidden [transition:0.4s_linear] [transition-property:max-height] will-change-[contents] ${isOpen?"max-h-[1300px] mobile:max-h-[1424px]":"max-h-[105px] mobile:max-h-[100px]"} border-b border-[#E1E6EF]`}>
            
            {/* heading */}
            <div 
            onClick={openAccordion}
            className='py-[32px] flex items-center justify-between w-full cursor-pointer mt-4 mb-2 pc:mt-6 gap-2'>

                {/* number */}
                <p className='font-semibold mobile:font-bold mobile:text-2xl text-4xl text-[#1D2433]'>
                    0{number}
                </p>

                {/* title */}
                <p className='font-normal text-2xl mobile:text-xl text-[#1D2433] mt-0'>
                    {`Register as a ${headerText}`}
                </p>

                {/* arrow */}
                <HiArrowDown
                className={`text-3xl mobile:text-xl text-[#1D2433] [transition:transform_.4s] ${isOpen?"rotate-[180deg]":"rotate-[360deg]"}`} />
            </div>

            {/* body */}
            <div className='flex items-center justify-center bg-white rounded-[10px] w-full h-[100%] gap-8 pb-4 px-[60px] mobile:py-5 mobile:px-1 overflow-hidden'>

                {children}

            </div>
        </div>
    )
}

export default Accordion