import { Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import MyModal from '../../../../../layouts/MyModal'
import { clearClubSubscriptionPaymentSuccess, getSpecificPackageRequest, getUserClubSubsciptionRequest, putClubSubscriptionPaymentRequest } from '../../../../../redux/action'
import DynamicClubTag from '../../../../Global/DyamicClubTag'
import BtnPrimary from '../../../../Global/Redesign/BtnPrimary'
import SavedAccountView from '../../Settings/SavedAccountView'
import SummaryCard from '../SummaryCard'

const SingleclubPackBody = () => {

    // STATES
    const [successfulSubscription, setSuccessfulSubscription] = useState(false);
    const {
        specificPackage,
        specificPackageLoading,
        putClubSubscriptionPaymentLoading,
        putClubSubscriptionPaymentSuccess,
        putClubSubscriptionPaymentError,
        user,
        userClubSubscriptions,
        token,
    } = useSelector((state) => {

        const {
            success: {
                putClubSubscriptionPayment: putClubSubscriptionPaymentSuccess,
            },
            errors: { 
                putClubSubscriptionPayment: putClubSubscriptionPaymentError 
            },
        } = state.ajaxStatuses;
    
        const { user, token } = state.userData;

        const { specificPackage } = state.clubPackagesReducer;
        const { 
            specificPackageLoading,
            putClubSubscriptionPaymentLoading 
        } = state.loadingIndicator;
        const { userClubSubscriptions } = state.clubSubscriptionReducer;
    
        return {
            specificPackage,
            specificPackageLoading,
            putClubSubscriptionPaymentLoading,
            putClubSubscriptionPaymentSuccess,
            putClubSubscriptionPaymentError,
            user,
            userClubSubscriptions,
            token,
        };
    });


    // DATA INITIALIZATION
    const location = useLocation();
    const history = useHistory();
    const packageId = location.pathname.split("/").pop();
    const dispatch = useDispatch();
    const paystackProps = {
        email: user.email,
        amount: Number(specificPackage.amount) * 100,
        metadata: {
            name: user.firstname + " " + user.lastname,
            phone: user.phone,
        },
        // publicKey: "pk_live_5243093d6fa3b996ac6359e99c989ff9814973f2",
        publicKey: `${process.env.REACT_APP_LIVE_PAYSTACK_KEY}`,
        text: "Make Payment",
        onSuccess: (e) => {
            console.log("payment was succefull", e.reference);

            let formData = {
                email: user.email,
                club_id: packageId,
                reference: e.reference,
            };

            dispatch(
                putClubSubscriptionPaymentRequest({
                    formData,
                })
            );
        },
        onClose: () => {},
    };


    // HANDLERS
    const toggleSuccessSubscriptionModal = () => {
        setSuccessfulSubscription(!successfulSubscription);
    };


    // SIDE EFFECTS
    useEffect(() => {
        dispatch(getSpecificPackageRequest(packageId));
        dispatch(getUserClubSubsciptionRequest(token));
    }, []);

    // checking for success or errors
    useEffect(() => {
        if (putClubSubscriptionPaymentSuccess) {
            toggleSuccessSubscriptionModal();
            setTimeout(() => {
                setSuccessfulSubscription(false);
                history.push("/dashboard/club")
            }, 3000);

            // clear club sub payment success
            dispatch(clearClubSubscriptionPaymentSuccess());

            // get data
            dispatch(getSpecificPackageRequest(packageId));
            dispatch(getUserClubSubsciptionRequest(token));
        }
    }, [putClubSubscriptionPaymentSuccess]);

    //  checking for errors
    useEffect(() => {
        if (putClubSubscriptionPaymentError) {
            toast.error(putClubSubscriptionPaymentError, {
                duration: 3000,
            });
        }
    }, [putClubSubscriptionPaymentError]);

    return (
        <div className='flex mobile:flex-col w-full h-full gap-10'>

            {
                specificPackageLoading?
                <Skeleton
                variant="rectangular"
                sx={{
                width: "100%",
                height: "70vh",
                borderRadius: "10px",
                }} />:
                (specificPackage && specificPackage)?
                <>
            
                    {/* package*/}
                    <div className='h-full w-[70%] mobile:w-full flex flex-col gap-10'>

                        <section className='flex flex-col p-5 gap-5 bg-white rounded-[10px] w-full'>

                            {/* caption heading */}
                            <div className='flex mobile:flex-col mobile:items-start mobile:justify-start items-center justify-between w-full gap-2'>
                                <p className='text-base font-semibold text-[#1D2433] mt-0'>
                                    {specificPackage.name} Package
                                </p>

                                <p className='text-[#0B8CBA] font-semibold text-base mt-0'>
                                    NGN {specificPackage.amount.toLocaleString("en-US")} /yr
                                </p>
                            </div>

                            {/* duration */}
                            <div className='flex items-center gap-4'>
                                <p className='mt-0 font-semibold text-sm text-gray-600'>
                                    Duration:
                                </p>

                                <p className='mt-0 text-gray-600 text-sm font-normal'>
                                    {specificPackage.duration} months
                                </p>
                            </div>

                            {/* contents in grid lock, if you know what i mean */}
                            <div className='w-full grid grid-cols-4 mobile:grid-cols-2 gap-5'>

                                <DynamicClubTag
                                status={specificPackage.networking_event}
                                title={"Networking Event"} />

                                <DynamicClubTag
                                status={specificPackage.member_swag}
                                title={"Member Swag"} />

                                <DynamicClubTag
                                status={specificPackage.news_letter}
                                title={"Newsletter"} />

                                <DynamicClubTag
                                status={specificPackage.reserved_office_space}
                                title={"Reserved Office Space"} />

                                <DynamicClubTag
                                status={specificPackage.discounted_services}
                                title={"Discounted Services"} />

                                <DynamicClubTag
                                status={specificPackage.investment_pitch}
                                title={"Investment Pitch"} />

                                <DynamicClubTag
                                status={specificPackage.investment_database}
                                title={"Investment Database"} />

                                <DynamicClubTag
                                status={specificPackage.business_consultancy}
                                title={"Business Consultancy"} />

                                <DynamicClubTag
                                status={specificPackage.foreign_tech_consultancy}
                                title={"Foreign Tech Consultancy"} />

                                <DynamicClubTag
                                status={specificPackage.life_insurance}
                                title={"Life Insurance "} />
                            </div>
                        </section>
                    </div>
                    
                    {/* summary */}
                    <div className='h-full w-[30%] mobile:w-full flex flex-col'>

                        <SummaryCard
                        hasSubscriptionText={"Already has a subscription!"}
                        hasSubscription={userClubSubscriptions?.length !== 0}
                        paystackProps={paystackProps}
                        paymentLoading={putClubSubscriptionPaymentLoading}
                        originalPrice={specificPackage.amount.toLocaleString("en-US")}
                        promoPrice={specificPackage.amount.toLocaleString("en-US")} />
                    </div>
                </>:
                <div className='flex justify-center items-center w-full h-[70vh] text-2xl rounded-[10px]'>Nothing Here</div>
            }

            {/* successful message modal */}
            <MyModal
            disableBtn={true}
            modalIsOpen={successfulSubscription}
            toggleModal={toggleSuccessSubscriptionModal}>

                <SavedAccountView
                closeModal={toggleSuccessSubscriptionModal} />
            </MyModal>
        </div>
    )
}

export default SingleclubPackBody