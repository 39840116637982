import React, { useState } from "react";
import ClubOption from "./ClubOption";
import SelectPackageBtn from "./SelectPackageBtn";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getClubPackagesRequest } from "../../../redux/action";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";

const MembershipOptions = () => {
  // collection of offers/options
  const options = [
    "Member Swag",
    "Newsletter",
    "Reserved Office Space",
    "Discounted Services",
    "Investment Pitch",
    "Investment Database",
    "Business Consultancy",
    "Foreign Tech Consultancy",
    "Life Insurance",
  ];

  // packages keys
  const keys = [
    "member_swag",
    "news_letter",
    "reserved_office_space",
    "discounted_services",
    "investment_pitch",
    "investment_database",
    "business_consultancy",
    "foreign_tech_consultancy",
    "life_insurance",
  ];

  // dispatch object
  const dispatch = useDispatch();

  // club packages states
  const { clubsPackages, clubsPackagesLoading } = useSelector((state) => {
    const { clubsPackages } = state.clubPackagesReducer;
    const { clubsPackagesLoading } = state.loadingIndicator;
    return {
      clubsPackages,
      clubsPackagesLoading,
    };
  });

  useEffect(() => {
    dispatch(getClubPackagesRequest());
  }, []);

  const history = useHistory();

  // select package handler
  const handleSelectBtn = (path) => {
    history.push(path);
  };

  return (
    <div className="flex flex-col gap-4 mobile:bg-white mobile:pt-9 mobile:px-5">
      {/* caption */}
      <p className="font-semibold container mx-auto text-xl text-[#3F444D]">
        Club membership options
      </p>

      {/* table */}
      {clubsPackagesLoading ? (
        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            minHeight: "50vh",
            borderRadius: "10px",
          }}
        />
      ) : !(clubsPackages.length === 0) ? (
        <div className="w-full container mx-auto mobile:rounded-none rounded-[10px] py-9 px-6 mobile:px-0 mobile:py-6 bg-white">
          {/* title */}
          <div className="grid grid-cols-[40%_20%_20%_20%] mobile:grid-cols-[33%_33%_33%] mobile:gap-2 text-[#3F444D] font-semibold text-base mobile:text-sm border-b pb-4 border-[#E1E6EF] mobile:border-[#8B54F7]">
            <p className="mobile:hidden">Features</p>

            <div
              onClick={() =>
                handleSelectBtn(
                  `/old/dashboard/theclub/${
                    clubsPackages.find((object) => object.name === "Basic")._id
                  }`
                )
              }
              className="flex flex-col"
            >
              <span>Basic</span>
              <span className="text-[#8B54F7] mobile:text-xs">
                NGN{" "}
                {clubsPackages
                  .find((object) => object.name === "Basic")
                  .amount.toLocaleString("en-US")}
              </span>
            </div>

            <div
              onClick={() =>
                handleSelectBtn(
                  `/old/dashboard/theclub/${
                    clubsPackages.find(
                      (object) => object.name === "Intermediate"
                    )._id
                  }`
                )
              }
              className="flex flex-col"
            >
              <span>Intermediate</span>
              <span className="text-[#8B54F7] mobile:text-xs">
                NGN{" "}
                {clubsPackages
                  .find((object) => object.name === "Intermediate")
                  .amount.toLocaleString("en-US")}
              </span>
            </div>

            <div
              onClick={() =>
                handleSelectBtn(
                  `/old/dashboard/theclub/${
                    clubsPackages.find((object) => object.name === "Advanced")
                      ._id
                  }`
                )
              }
              className="flex flex-col"
            >
              <span>Advanced</span>
              <span className="text-[#8B54F7] mobile:text-xs">
                NGN{" "}
                {clubsPackages
                  .find((object) => object.name === "Advanced")
                  .amount.toLocaleString("en-US")}
              </span>
            </div>
          </div>

          {/* subtitle */}
          <div className="grid grid-cols-[40%_20%_20%_20%] mobile:grid-cols-[33%_33%_33%] mobile:items-end text-[#667085] font-normal text-sm  border-b pb-4 pt-7 border-[#E1E6EF]">
            {/* basic package duration desktop */}
            <p className="font-semibold mobile:hidden">Duration</p>

            <span className="mobile:hidden">
              {clubsPackages.find((object) => object.name === "Basic").duration}{" "}
              months
            </span>

            {/* basic package duration mobile */}
            <div className="hidden mobile:flex mobile:flex-col mobile:gap-4">
              <span className="font-semibold">Duration</span>
              <span>
                {
                  clubsPackages.find((object) => object.name === "Basic")
                    .duration
                }{" "}
                months
              </span>
            </div>

            {/* intermediate package duration all screens */}
            <span>
              {!clubsPackagesLoading
                ? clubsPackages.find((object) => object.name === "Intermediate")
                    .duration
                : " -"}{" "}
              months
            </span>

            {/* advanced package duration all screens */}
            <span>
              {
                clubsPackages.find((object) => object.name === "Advanced")
                  .duration
              }{" "}
              months
            </span>
          </div>

          {/* contents */}
          {options.map((item, index) => (
            <ClubOption
              key={index}
              title={item}
              basic={
                clubsPackages.find((object) => object.name === "Basic")[
                  keys[index]
                ]
              }
              intermediate={
                clubsPackages.find((object) => object.name === "Intermediate")[
                  keys[index]
                ]
              }
              advanced={
                clubsPackages.find((object) => object.name === "Advanced")[
                  keys[index]
                ]
              }
            />
          ))}

          {/* select package buttons */}
          <div className="mobile:hidden grid grid-cols-[40%_20%_20%_20%] pb-4 pt-7">
            {/* empty div */}
            <div className="text-[#667085] text-sm font-semibold">-</div>

            {/* button 1 - Basic */}
            <SelectPackageBtn
              onClick={() =>
                handleSelectBtn(
                  `/old/dashboard/theclub/${
                    clubsPackages.find((object) => object.name === "Basic")._id
                  }`
                )
              }
            />

            {/* button 2 - Intermediate */}
            <SelectPackageBtn
              onClick={() =>
                handleSelectBtn(
                  `/old/dashboard/theclub/${
                    clubsPackages.find(
                      (object) => object.name === "Intermediate"
                    )._id
                  }`
                )
              }
            />

            {/* button 3 - Advanced */}
            <SelectPackageBtn
              onClick={() =>
                handleSelectBtn(
                  `/old/dashboard/theclub/${
                    clubsPackages.find((object) => object.name === "Advanced")
                      ._id
                  }`
                )
              }
            />
          </div>

          {/* mobile button */}
          <button className="mt-12 font-semibold text-base hidden mobile:inline w-full text-white bg-[#8B54F7] rounded-[8px] h-[44px] hover:opacity-80">
            Register now
          </button>
        </div>
      ) : (
        <div className="flex justify-center items-center min-h-[50vh] text-2xl font-semibold">
          No club package Available
        </div>
      )}
    </div>
  );
};

export default MembershipOptions;
