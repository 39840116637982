import React from 'react'
import BackBtn from './BackBtn'
import NextBtn from './NextBtn'

const StepBtn = ({disableBack, backClick, onClick, disabled}) => {
    return (
        <div className='flex items-end gap-4 pt-5 pb-9 justify-between w-full'>
            <BackBtn
            disabled={disableBack}
            onClick={backClick} />


            <NextBtn
            disabled={disabled}
            onClick={onClick} />
        </div>
    )
}

export default StepBtn