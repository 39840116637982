import React from "react";
import { Link } from "react-router-dom";
import NavBtn from "../../../components/Global/NavBtn";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import mainLogo2 from "../../../assets/webpages/home/images/logo2.png"
import MobileNavList2 from "../MobileNavList2";
import DesktopNavList2 from "../DesktopNavList2";

const Header2 = ({
  bgColor="bg-[#ffffff]", 
  shadowStyle="pc:[box-shadow:0px_2px_8px_#e0e0e0]"
}) => {
  
    // STATES
    // mobile navState
    const [isNavOpen, setNavState] = useState(false);


    // HANDLERS
    // toggle navbar mobile
    const closeNav = () => {
        setNavState(!isNavOpen);
    };

    return (

        // header container
        <div
        className={`${bgColor} pc:h-fit pc:w-[87vw] relative mobile:fixed pc:mt-[2rem] mx-auto pc:z-[80000] mobile:z-[8000000] mobile:left-0 mobile:top-0 mobile:pt-0 mobile:flex mobile:justify-center mobile:flex-col mobile:items-center mobile:w-full   pc:white rounded-[10px]`}
        >
            {/* header component */}
            <header
                className={`${shadowStyle} mobile:border-b-2 border-[#F2F4F7] bg-white h-[84px] mobile:h-[5.5rem] mobile:py-7 pc:w-full mobile:w-full mx-auto justify-between items-center flex px-12 mobile:px-7 rounded-[10px] mobile:rounded-none relative z-[100000]`}
            >
                    {/* logo */}
                    <Link to={"/"}>
                        <img
                            className="w-[141px]"
                            src={mainLogo2}
                            alt="main logo icon"
                        />
                    </Link>

                    {/* mobile nav button */}
                    {/* the display value is none for desktop view */}
                    <NavBtn onClick={() => setNavState(!isNavOpen)}>
                        {isNavOpen ? (
                                <FontAwesomeIcon size="xl" icon={faXmark} />
                        ) : (
                                <FontAwesomeIcon size="xl" icon={faBars} />
                        )}
                    </NavBtn>

                    {/* Desktop nav items */}
                    <DesktopNavList2 />
            </header>

            {/* mobile nav items */}
            {/* the display value is none for desktop view */}
            <MobileNavList2
            closeNav={closeNav} 
            isOpen={isNavOpen} />
        </div>
    );
};

export default Header2;
