import React from 'react'

const GuideBox = ({
    number,
    title,
    desc
}) => {
    return (
        <div className='flex flex-col items-center gap-3'>
            
            {/* circle */}
            <span className='w-[64px] h-[64px] bg-[#D4F4FF] flex items-center justify-center text-2xl text-[#055774] font-semibold rounded-[50%]'>
                {number}
            </span>

            {/* title */}
            <p className="text-[#1D2433] font-semibold text-[22px] text-center">
                {title}
            </p>

            {/* description */}
            <p className='font-normal text-base text-[#1D2433] text-center'>
                {desc}
            </p>
        </div>
    )
}

export default GuideBox