import React, { useState } from 'react'
import InvestmentCard from '../InvestmentCard'
import FilterCard from './FilterCard'
import { MdOutlineFilterList } from "react-icons/md"
import MyModal from '../../../../../layouts/MyModal'
import Disclaimer from './Disclaimer'

const AllAvailableStartups = ({disableFilterCard}) => {

    // STATES
    const [spaceModalState, setSpaceModalState] = useState(false)


    // HANDLERS
    const toggleSpaceModal = () => {
        setSpaceModalState(!spaceModalState)
    }

    return (
        <div className='flex mobile:flex-col w-full h-full gap-10'>

            {/* available and vetted startups */}
            <div className={`h-full flex flex-col gap-5 ${disableFilterCard?"w-full mobile:w-full":"w-[70%]"}`}>

                {/* caption */}
                <div className="flex items-center justify-between w-full">
                    <p className='font-medium text-base text-[#1D2433]'>
                        All available and vetted startups
                    </p>

                    <span 
                    onClick={toggleSpaceModal}
                    className='hidden mobile:inline-flex items-center justify-center cursor-pointer h-[48px] w-[48px] border border-[#E1E6EF] rounded-[4px] hover:bg-gray-200'>
                        <MdOutlineFilterList className="text-2xl text-[#5C5F62]" />
                    </span>
                </div>

                {/* cards */}
                <div className='grid mobile:grid-cols-1 grid-cols-3 gap-5'>
                    <InvestmentCard />
                    <InvestmentCard />
                    <InvestmentCard />
                </div>
            </div>

            {/* Filter */}
            <div className={`h-full w-[30%] mobile:w-full flex-col gap-5 ${disableFilterCard?"hidden":"flex"}`}>
                
                <div className="flex items-center justify-center h-fit w-full mobile:hidden">
                    <FilterCard />
                </div>

                {/* disclaimer */}
                <Disclaimer />
            </div>

            {/* Modal */}
            {/* logout modal */}
            <MyModal
            modalIsOpen={spaceModalState}
            toggleModal={toggleSpaceModal}
            disableBtn={false}>
                
                {/* search modal */}
                <FilterCard />

            </MyModal>
        </div>
    )
}

export default AllAvailableStartups