import React from 'react'
import { MdLocationOn } from 'react-icons/md'

const DateLocation = ({date, time, address}) => {
    return (
        <div className='flex pc:flex-col gap-5 mobile:gap-3 mobile:justify-center pc:pb-2 w-full'>

            {/* date */}
            <div className='flex flex-col w-fit mobile:w-[55%] md:!w-fit'>
                <p className='font-medium text-[30px] lg:text-xl mobile:text-xl mb-1 border-b border-[#0B8CBA] w-full'>
                    {date}
                </p>
                <p className='font-medium text-[30px] lg:text-xl mobile:text-xl w-full'>
                    {time}
                </p>
            </div>
            
            {/* location */}
            <div className='flex flex-col items-start gap-1 w-full md:!w-[50%] mobile:w-[45%]'>
                <MdLocationOn
                className="text-lg mobile:text-base text-black relative mt-[-1rem]" />

                <p className='font-medium text-base mobile:font-normal mobile:text-xs text-[#055774]'>
                    {address}
                </p>
            </div>
        </div>
    )
}

export default DateLocation