import React from 'react'

const TeamCard = ({image, name, designation, qualities, border}) => {

    return (
        <div className={`w-full pb-5 flex mobile:flex-col items-start gap-10 ${border?"border-b border-[#E1E6EF]":""}`}>

            {/* image */}
            <div className='flex gap-5 p-3 flex-col rounded-[10px] bg-white [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] w-fit mobile:w-full'>
                <img src={image} className="object-cover min-w-[204px] h-[204px] rounded-[10px] object-top" alt="" />

                <div className='flex flex-col gap-1'>
                    <span className='text-[#3F444D] text-base font-semibold'>
                        {name}
                    </span>

                    <span className='text-gray-600 text-sm font-normal'>
                        {designation}
                    </span>
                </div>
            </div>

            {/* texts */}
            <ul className='flex flex-col gap-1 h-full justify-start list-disc font-normal text-base text-gray-600 ml-2 mobile:ml-[14px]'>
                {
                    qualities.split("%").map((item, index)=>(
                        <li key={index}>
                            {item}
                        </li>
                    ))
                }
            </ul>
            
        </div>
    )
}

export default TeamCard