import React from "react"
import Wrapper from "../../../../layouts/Redesign/Wrapper";
// import Navbar from "../../../../components/BootcampForm/Navbar"
import Header from "../../../../components/BootcampForm/Header"
import Body from "../../../../components/BootcampForm/Body"
import BootcampForm from "../../../../components/BootcampForm/BootcampForm"
import Footer from "../../../../components/BootcampForm/Footer"
const BootCamp = () => {
    return(
        <Wrapper>
         
           {/* <Navbar /> */}
           <Header />
           <Body>
           < BootcampForm/>
           </Body>

           <Footer />
        </Wrapper>
    )
}

export default BootCamp;