import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";

import { clientErrorMessage, delay } from "./reusable";
import { REGISTER_DIGMARK_BOOTCAMP_REQUEST } from "../types";
import { clearDigMarketingBootcampSuccess, digMarketingBootcampFailure, digMarketingBootcampLoading, digMarketingBootcampSuccess } from "../action/digMarketingBootcamp";


// ajaxDBCall
const ajaxDBCalls = {

    // register bootcamp
    registerDigMarkBootcamp: async ({payload}) => {
      const response = await axios({
        method: "POST",
        url: "digitalmarketing/register",
        data: payload.userData
      });
      return response;
    },
}


// WORKERS
function* registerDigMarkBootcamp(payload) {
    try {
        yield put(digMarketingBootcampLoading(true));
        const response = yield call(ajaxDBCalls.registerDigMarkBootcamp, payload);
        yield put(digMarketingBootcampSuccess(response.data));
        yield put(digMarketingBootcampLoading(false));
        yield delay();
        yield put(clearDigMarketingBootcampSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data.message);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(digMarketingBootcampFailure(errorMessage));
        yield put(digMarketingBootcampLoading(false));
        yield delay();
        yield put(digMarketingBootcampFailure(""));
    }
}

// WATCHERS
function* registerDigMarkBootcampWatcher() {
    yield takeLatest(REGISTER_DIGMARK_BOOTCAMP_REQUEST, registerDigMarkBootcamp)
}

// register digital marketing bootcamp saga
export default function* registerDigMarkBootcampSaga() {
    yield spawn(registerDigMarkBootcampWatcher);
}