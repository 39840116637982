import React from 'react'

const QuestionAnswer = ({index, number, question, answer}) => {

    // display the faq number "text-color", relative to the index prop
    // display #1D2433 for even numbers, and #8B54F7 for odd numbers
    const numberColor = (() => {
        return index % 2 === 0? "text-[#1D2433]":"text-[#8B54F7]" 
    })()

    return (
        <div className='flex items-start justify-center gap-12 pt-20 mobile:border-b-2 mobile:border-[#F1F3F9] mobile:mx-6 mobile:gap-8 mobile:pt-6 '>

            <p className={`${numberColor}  text-4xl font-semibold flex items-start justify-center`}>
                {number}
            </p>
            
            <div className='w-[60%] mobile:w-[80%] border-b-2 border-[#F1F3F9] h-full pb-20 mobile:pb-6 mobile:border-none'>
                <div className='flex mobile:flex-col w-full gap-28 mobile:gap-8'>
                    <p className='font-semibold text-base text-[#1D2433]'>
                        {question}
                    </p>

                    <p className='font-normal text-base text-[#1D2433] opacity-[0.65]'>
                        {answer}
                    </p>

                </div>
            </div>
        </div>
    )
}

export default QuestionAnswer