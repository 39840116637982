import React from 'react'
import BtnSm from '../Global/BtnSm'
import pointed from "./../../assets/management/svg/join-shape1.svg"
import { useHistory } from 'react-router-dom'

const JoinUs = () => {

    // history object
    const history = useHistory()

    return (
        <div className='flex flex-col items-center justify-center pt-[12rem] mt-24 mobile:mt-16 relative'>
            
            {/* fist container */}
            <div className='w-full flex justify-center absolute top-[-5%] mobile:bottom-[0px] mobile:px-7'>

                {/* blue box */}
                <div className='flex flex-col rounded-[10px] justify-center items-center mobile:items-stretch bg-[#1D2433] text-white text-center md:w-[80%] w-[65%] mobile:w-full relative py-32 gap-8 px-24 mobile:px-5 mobile:py-10 mobile:h-fit'>

                    <p className='font-semibold mobile:text-2xl text-4xl leading-[54px] mobile:px-8'>
                        Join us today and grow your tech career the right way with the right team
                    </p>

                    {/* pattern */}
                    <img src={pointed} className="mobile:hidden absolute bottom-[34%] right-[35%]" alt="" />

                    <BtnSm 
                    onClick={()=>history.push("/old/login")}
                    title={"Get started"} />
                </div>
            </div>

            {/* second container */}
            <div className="h-[250px] mobile:h-[150px] bg-[#F9FAFB] w-full"></div>
        </div>
    )
}

export default JoinUs