import React, { useState } from 'react'
import RedirectCard from '../TheClub/RedirectCard'
import UpcomingEvents from '../TheClub/UpcomingEvents'
import image1 from "../../../../assets/redesign-dashboard/space-booking/images/courses.png"
import image2 from "../../../../assets/redesign-dashboard/space-booking/images/get-started.png"
import NoSubscription from '../TheClub/NoSubscription'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

const InvestingBody = () => {

    // STATES
    const [isEmpty, setIsEmpty] = useState(true)

    // DATA INITIALIZATION
    const history = useHistory()
    const location = useLocation()

    return (
        <div className='flex mobile:flex-col w-full h-full gap-10'>

            {/* section filled with image and text details */}
            {/* <div className='h-full w-[70%] mobile:w-full flex flex-col gap-10'> */}

                {/* package card info */}
                {/* <NoSubscription
                path={"#"}
                procedureList={[
                    {
                        id: 1,
                        message: "Search for an available space by clicking the button below"
                    },
                    {
                        id: 2,
                        message: "Make payment to reserve space"
                    },
                    {
                        id: 3,
                        message: "Access the full features of your new space."
                    },
                ]}
                onClick={()=>history.push(`${location.pathname}/about-investment`)} /> */}
                
                {/* redirection cards */}
                {/* <div className='grid grid-cols-2 mobile:grid-cols-1 gap-10 w-full'>

                    <RedirectCard
                    title={"Interested in learning a new skill?"}
                    path={"#"}
                    linkText={"View our courses"}
                    image={image1} />

                    <RedirectCard
                    title={"Gain profit by investing in startups with the right vision"}
                    path={"#"}
                    linkText={"Get Started today"}
                    image={image2} />
                </div> */}
            {/* </div> */}

            {/* upcomoing event section */}
            {/* <div className='h-full w-[30%] mobile:w-full flex flex-col'>
                <UpcomingEvents />
            </div> */}

            <Redirect to={`${location.pathname}/about-investment`} />
        </div>
    )
}

export default InvestingBody