import {useEffect} from 'react'
import EventCard from './EventCard'
import eventImage from "./../../../assets/dashboard/events/images/event-image.png"
import { useSelector, useDispatch } from 'react-redux';
import { getEventsForUsersRequest } from '../../../redux/action';
import { Skeleton } from '@mui/material'

const AllUpcomingEvents = () => {

    // dispatch object
    const dispatch = useDispatch()

    // event states
    const {
        events,
        eventsForUsersLoading
    } = useSelector((state)=>{
        const {events} = state.eventsReducer
        const {eventsForUsersLoading} = state.loadingIndicator
        return {
            events,
            eventsForUsersLoading
        }
    })

    useEffect(()=>{
        dispatch(getEventsForUsersRequest())
    }, [])

    useEffect(()=>{
        if(eventsForUsersLoading){
            console.log("Loading...")
        } else if(events){
            console.log("here's our data: ", events)
        }

    }, [events])

    const MONTHS = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
    ]

    const DAY = [
        "Sunday",
        "Monday",
        "Tueday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ]

    const getData = {
        date: (prop)=>{
            return new Date(prop).getDate()
        },
        dayNum: (prop)=>{
            return new Date(prop).getDay()
        },
        month: (prop)=>{
            return new Date(prop).getMonth()
        },        
        year: (prop)=>{
            return new Date(prop).getFullYear()
        }
    }

    return (
        <div className='flex flex-col mobile:items-stretch gap-4 mobile:gap-6'>
            
            {/* caption */}
            <p className='font-semibold text-[#3F444D] text-xl'>
                All upcoming events
            </p>

            <>

                {
                    eventsForUsersLoading?
                    <Skeleton
                    variant="rectangular"
                    sx={{
                    width: "100%",
                    height: "50vh",
                    borderRadius: "10px",
                    }} />:
                    !(events.length===0)?
                    <div className='grid mobile:flex mobile:flex-col grid-cols-[30%_30%_30%] lg:grid-cols-[50%_50%] mobile:justify-center justify-between gap-y-8 gap-x-6'>
                        {
                            events.map((event)=>(
                                <EventCard
                                key={event._id}
                                month={MONTHS[getData.month(event.date)]}
                                day={`${getData.date(event.date)}`}
                                image={event.imgurl}
                                time={`${DAY[getData.dayNum(event.date)]} ${event.time.concat(" pm")}`}
                                venue={event.location}
                                title={event.name}
                                desc={event.description.substring(0, 80).concat("...")}
                                to={event._id} />
                        ))}
                    </div>:
                    <div className='flex justify-center items-center'>Nothing Here</div>
                }
            </>
        </div>
    )
}

export default AllUpcomingEvents