import { CLEAR_REGISTER_CRITIQUE_EVENT_SUCEESS, REGISTER_CRITIQUE_EVENT_FAIL, REGISTER_CRITIQUE_EVENT_LOADING, REGISTER_CRITIQUE_EVENT_REQUEST, REGISTER_CRITIQUE_EVENT_SUCCESS } from "../types";

// register for the critique and lunch event
export const critiqueEventRequest = (prop) => {
    return {
      type: REGISTER_CRITIQUE_EVENT_REQUEST,
      payload: prop,
    };
  };
  
  export const critiqueEventSuccess = (prop) => {
    return {
      type: REGISTER_CRITIQUE_EVENT_SUCCESS,
      payload: prop,
    };
  };
  
  export const critiqueEventFailure = (errorMessage) => {
    return {
      type: REGISTER_CRITIQUE_EVENT_FAIL,
      payload: errorMessage,
    };
  };
  
  export const critiqueEventLoading = (loading) => {
    return {
      type: REGISTER_CRITIQUE_EVENT_LOADING,
      payload: loading,
    };
  };
  
  export const clearCritiqueEventSuccess = () => {
    return {
      type: CLEAR_REGISTER_CRITIQUE_EVENT_SUCEESS
    };
  };