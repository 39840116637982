import React from 'react'
import { Route, Switch } from 'react-router-dom'
import DigMarketingForm from '.'
import ThankyouPage from './ThankYouPage'

const DigMarketingFormRouter = ({ path }) => {
    return (
        <>
            <Switch>

                <Route 
                exact 
                path={`${path}`} 
                component={DigMarketingForm} />

                <Route
                exact
                path={`${path}/success`}
                component={ThankyouPage}
                />

            </Switch>
        </>
    )
}

export default DigMarketingFormRouter