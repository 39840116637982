import React from 'react'
import BtnPrimary from '../../../../Global/Redesign/BtnPrimary'

const ConnectWithSponsors = () => {
    return (
        <div className="bg-white border border-[#E1E6EF] p-5 gap-2 rounded-[10px] items-start flex flex-col">

            <p className='font-medium font-base mt-0 text-[#055774]'>
                Interested in connecting with our sponsors?
            </p>

            <p className='font-normal font-base text-gray-800 mt-0'>
                Kindly get in touch with our representatives to schedule a discussion.
            </p>

            <BtnPrimary
            title={"Contact support"} />
        </div>
    )
}

export default ConnectWithSponsors