import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const UpcomingEventCard = ({image, name, date, path}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    params.set("status", "UPCOMING")

    return (
        <div className="flex items-center justify-start bg-white border-[#E1E6EF] w-full border-b p-5 gap-4">

            {/* image */}
            <div className="w-[97px] h-[87px]">
                <img 
                src={image}
                className="h-full w-full object-cover rounded-[10px]" 
                alt="" />
            </div>

            {/* info section */}
            <div className="flex flex-col gap-1 w-[60%]">

                <p className="mt-0 font-semibold text-sm text-[#1D2433]">
                    {name}
                </p>
                <p className="mt-0 text-gray-800 font-normal text-xs">
                    {date}
                </p>

                {/* path */}
                <p
                onClick={()=>history.push({
                    pathname: `/dashboard/events/${path}`,
                    search: params.toString()
                })} 
                className="mt-auto text-[#0B8CBA] text-sm font-semibold">
                    Learn more
                </p>
            </div>
        </div>
    )
}

export default UpcomingEventCard