import React, { useEffect } from "react";
import DBCard from "../TheClub/DBCard";
import image1 from "../../../../assets/redesign-dashboard/bootcamps/images/packages.png";
import RedirectCard from "../TheClub/RedirectCard";
import UpcomingEvents from "../TheClub/UpcomingEvents";
import WhatYouLearn from "./WhatYouLearn";
import CourseOutline from "./CourseOutline";
import { Redirect, useLocation } from "react-router-dom";
import InstructorCard from "./InstructorCard";
import { getUsersBootcampRequest } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import { getTimeSpent } from "../../../../helpers";
import CertificateCard from "./certificate";

const BootcampsBody = () => {
  // DATA INITIALIZATION
  const location = useLocation();
  const dispatch = useDispatch();
  const topics = [
    {
      _id: "akdjfkjaksdjfsadkfjskdfjsdk",
      sub_topics: "Course Overview",
      content: "Introduction%Basic Coding Structures%Working with the DOM",
    },
    {
      _id: "kdfkjdkfjkalsdjfkldsjkfjdksf",
      sub_topics: "Introducing HTML",
      content: "Introduction%Basic Coding Structures%Working with the DOM",
    },
    {
      _id: "fnvgmdhfkmfihijfekdfjdkjl",
      sub_topics: "Course Introducing Bootstrap",
      content: "Introduction%Basic Coding Structures%Working with the DOM",
    },
    {
      _id: "dfkdjsdfjkdhljglshnkmkekhi",
      sub_topics: "Styling Your Pages with CSS",
      content: "Introduction%Basic Coding Structures%Working with the DOM",
    },
    {
      _id: "ihhyghkwelkdjfkdjfkddfgefd",
      sub_topics: "Modern Javascript",
      content: "Introduction%Basic Coding Structures%Working with the DOM",
    },
  ];

  // STATES
  const { bootcamp, bootcampLoading, token } = useSelector((state) => {
    const { token } = state.userData;

    const { bootcamp } = state.bootcampReducer;
    const { bootcampLoading } = state.loadingIndicator;

    return {
      bootcamp,
      bootcampLoading,
      token,
    };
  });

  // SIDE EFFECTS
  useEffect(() => {
    dispatch(getUsersBootcampRequest(token));
  }, []);
  useEffect(() => {
    if (bootcamp && bootcamp.length !== 0) {
      console.log(bootcamp);
      console.log(bootcamp, "this is mine own")
     console.log(bootcamp[0].name, "course track")
     localStorage.setItem("course-selected", JSON.stringify(bootcamp[0].name))
    }
  }, [bootcamp]);
  
  return (
    <div className="flex mobile:flex-col w-full h-full gap-10">
      {/* section filled with image and text details */}
      <div className="h-full w-[70%] mobile:w-full flex flex-col gap-10">
        {/* package card info */}
        {bootcampLoading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              width: "100%",
              height: "50vh",
              borderRadius: "10px",
            }}
          />
        ) : !(bootcamp && bootcamp.length !== 0) ? (
          <Redirect to={`${location.pathname}/availble-courses`} />
        ) : (
          <>
            {bootcamp.slice(0, 1).map((bootcamp) => (
              <div
                className="w-full h-full flex flex-col gap-10"
                key={bootcamp._id}
              >
                {/* card container */}
                <div className="flex mobile:flex-col mobile:w-full w-[100%] gap-5">
                
                  {/* db card */}
                  <DBCard
                    status={"BOOTCAMP"}
                    title={"Current course enrolled in"}
                    packageName={bootcamp.name.slice(0, 25).concat("...")}
                    durationMonth={bootcamp.duration}
                    durationText={"months course"}
                    progressPercent={Math.floor(
                      (getTimeSpent(bootcamp.start_date) / bootcamp.duration) *
                        100
                    )}
                    monthsRemaining={
                      bootcamp.duration - getTimeSpent(bootcamp.start_date)
                    }
                    isCompleted={
                      getTimeSpent(bootcamp.start_date) >= bootcamp.duration
                    }
                  />
                  <CertificateCard />
                </div>

                {/* instruction details */}
                <InstructorCard />

  

                {/* subscription card info */}
                <WhatYouLearn />

                {/* course outline */}
                <CourseOutline topics={topics} />
              </div>
            ))}
          </>
        )}

        {/* redirection cards */}
        <div className="flex gap-10 w-[50%] mobile:w-full">
          <RedirectCard
            title={"Interested in learning a becoming a member"}
            path={"/dashboard/club"}
            linkText={"View our packages"}
            image={image1}
          />
        </div>
      </div>

      {/* upcomoing event section */}
      <div className="h-full w-[30%] mobile:w-full flex flex-col">
        <UpcomingEvents />
      </div>
    </div>
  );
};

export default BootcampsBody;
