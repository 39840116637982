import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import BtnPrimary from "../Global/Redesign/BtnPrimary";

const SuccessMessage = () => {
  // OBJECT INITIALIZATION
  const history = useHistory();

  return (
    <div className="flex justify-center items-center rounded-[8px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] px-5 mobile:px-3 py-12 pc:py-24 w-[50%] mobile:w-full mobile:mx-4">
      {/* container */}
      <div className="flex flex-col gap-6 p-8 mobile:p-3 items-center text-center">
        {/* icon */}
        <BsCheckCircleFill className="text-5xl text-[#0B8CBA]" />

        {/* texts */}
        <div className="text-[#1D2433] text-xl font-normal flex flex-col gap-3">
          <p className="font-semibold text-[#0B8CBA] text-2xl">
            Thanks for Signing up
          </p>

            <p className="font-normal text-[22px] text-[#1D2433]">
            Response successfully submitted.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;
