import React from 'react'
import BtnPrimary from '../../../Global/Redesign/BtnPrimary'
import avatar1 from "../../../../assets/webpages/home/images/avatar1.png"
import avatar2 from "../../../../assets/webpages/home/images/avatar2.png"
import avatar3 from "../../../../assets/webpages/home/images/avatar3.png"
import { useHistory } from 'react-router-dom'

const SponsorEvent = () => {

    // DATA INITIALIZATION
    const history = useHistory()

    return (
        <div className="flex flex-col pb-24 pt-28 relative w-full container mx-auto gap-8 items-center mobile:px-4">
            
            {/* image collection */}
            <div className="flex items-center justify-center relative w-fit gap-3 h-fit">

                <img src={avatar2} className="rounded-[50%] h-[48px] w-[48px] object-cover" alt="" />

                <img src={avatar1} className="rounded-[50%] h-[56px] w-[56px] object-cover absolute right-[50%] translate-x-[50%]" alt="" />
                
                <img src={avatar3} className="rounded-[50%] h-[48px] w-[48px] object-cover" alt="" />
            </div>

            {/* texts */}
            <div className="flex flex-col gap-1 text-center">
                <p className="text-[#101828] text-2xl font-semibold">
                    Interested in sponsoring one of our events?
                </p>

                <p className="text-[#667085] font-normal text-base">
                    Kindly get in touch with our representatives to schedule a discussion.
                </p>
            </div>

            {/* button */}
            <BtnPrimary
            onClick={()=>history.push("/contactus")}
            title={"Get in touch"} />

        </div>
    )
}

export default SponsorEvent