import React from 'react'
import Header2 from '../../../../layouts/Redesign/Header2'
import shape from "../../../../assets/webpages/club/images/m-shape1.png"

const ClubHeader = () => {
    return (
        <div className='flex items-center justify-start bg-[#1B1F27] h-[70vh] mobile:h-[60vh] relative flex-col top-0'>

            {/* header */}
            <Header2
            shadowStyle='none' />

            {/* SHAPES */}
            {/* shape1 */}
            <img src={shape} className="mobile:w-[20px] absolute top-[30%] right-[20%] mobile:right-[2rem] mobile:top-[7.5rem]" alt="" />

            {/* jumbotron */}
            <div className='w-full flex flex-col items-center top-[20%] mobile:top-[25%] relative z-[200] gap-4 text-center container mx-auto mobile:px-4'>

                <p className='font-semibold text-[45px] mobile:text-4xl text-white'>
                    More than a tech community
                </p>

                <p className='text-white text-2xl font-normal mobile:font-semibold'>
                    We are here to take your tech career to the next level
                </p>

                <p className='text-white text-base font-normal pc:w-[45%] mb-4'>
                    The club is a community of Family offices, private investors, venture capital companies, institutional investors and technology experts.
                </p>
            </div>
        </div>
    )
}

export default ClubHeader