import React, { useState } from 'react'
import Tabs,{Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import "../styles/react-tabs-custom-style.css"
import TrackCard from './TrackCard';
import { Skeleton } from '@mui/material';

const AvailableTracks = ({
    regularTrack, 
    specialTrack, 
    captionText,
    loading,
    isEmpty
}) => {

    // STATES
    const [specialTrackModalState, setSpecialModalState] = useState(false)


    // HANDLERS
    const toggleSpecialTrackModal = () => {
        setSpecialModalState(!specialTrackModalState)
    }


    // OBJECT INITIALIZATION
    const specialTrackTitle = <span className='flex gap-2 items-center text-sm font-semibold'>
        Special track

        <span className='mobile:hidden'>
            <FontAwesomeIcon
            onClick={toggleSpecialTrackModal} 
            color='#E1E6EF' 
            icon={faCircleInfo} 
            size="lg" />
        </span>
    </span>

    // regular track tab
    const regularTrackTitle = <span className='items-center text-sm font-semibold'>
        Regular track
    </span>


    return (
        <div className="flex flex-col gap-4">
            
            {/* caption */}
            <p className='text-[#1D2433] text-base font-medium'>
                {captionText}
            </p>

            <Tabs 
            activeTab="1" 
            ulClassName="!border-[#ccc]" 
            activityClassName="!text-[#0B8CBA]">
                
                <Tab 
                title={regularTrackTitle} 
                className="mb-2 mr-7 !px-2">

                    {            
                        loading?
                        <Skeleton
                        variant="rectangular"
                        sx={{
                        width: "100%",
                        height: "30vh",
                        borderRadius: "10px",
                        marginTop: "16px"
                        }} />:
                        isEmpty?
                        <div className='flex justify-center items-center w-full h-[45vh] text-xl rounded-[10px]'>Nothing Here</div>:
                        <div className="mt-4 grid grid-cols-3 mobile:grid-cols-1 gap-3">
                            
                            {
                                regularTrack.map((course)=>(
                                    <TrackCard
                                    path={course._id}
                                    courseTitle={course.name}
                                    duration={course.duration}
                                    pathway={course.pathway}
                                    key={course._id} />
                                ))
                            }
                        </div>
                    }
                </Tab>
            
                <Tab 
                title={specialTrackTitle} 
                className="mr-3 mb-2 !px-2">

                    {            
                        loading?
                        <Skeleton
                        variant="rectangular"
                        sx={{
                        width: "100%",
                        height: "30vh",
                        borderRadius: "10px",
                        marginTop: "16px"
                        }} />:
                        isEmpty?
                        <div className='flex justify-center items-center w-full h-[45vh] text-xl rounded-[10px]'>Nothing Here</div>:
                        <div className="mt-4 grid grid-cols-3 mobile:grid-cols-1 gap-3">
                            
                            {
                                specialTrack.map((course)=>(
                                    <TrackCard
                                    path={course._id}
                                    courseTitle={course.name}
                                    duration={course.duration}
                                    pathway={course.pathway}
                                    key={course._id} />
                                ))
                            }
                        </div>
                    }
                </Tab>
            </Tabs> 

            {/* Special Track Modal */}
            {/* <MyModal
            disableBtn={true}
            modalIsOpen={specialTrackModalState}
            toggleModal={toggleSpecialTrackModal}>
                <SpecialTrackInfoView />
            </MyModal> */}
        </div>
    )
}

export default AvailableTracks