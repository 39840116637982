import React from 'react'
import MakePaymentBody from '../../../../components/Redesign/Dashboard/Bootcamps/MakePayment/MakePaymentBody'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'

const MakePayment = () => {
    return (
        <PageWrapper
        enableBackBtn={true}
        title={"The Training Center"}
        subTitle={"Education is always the key"}>

            {/* body section */}
            <MakePaymentBody />

        </PageWrapper>
    )
}

export default MakePayment