import React from "react";
import Navbar from "../BootcampForm/Navbar";
import dataanalysisimage from "../../assets/form/data-analysis/dataanalysisimage.svg";
import dataanalysisimagemobile from "../../assets/form/data-analysis/dataanalysisimagemobile.svg";

const Header = () => {
  return (
    <div className="relative">
      {/* images */}
      <img
        src={dataanalysisimage}
        className="w-full h-[70vh] hidden md:block lg:block xl:block absolute top-0 left-0 object-cover"
        alt=""
      />
      <img
        src={dataanalysisimagemobile}
        className="w-full block  md:hidden lg:hidden xl:hidden absolute  top-12 left-0 "
        alt=""
      />

      {/* container */}
      <div className="w-[80%] mx-auto  gap-16 lg:gap-10 relative z-[2] flex flex-col pt-4 mobile:w-full mobile:mt-[0px]">
        <Navbar />
      </div>
    </div>
  );
};

export default Header;
