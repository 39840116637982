import React, { useState } from 'react'
import { HiArrowDown, HiAcademicCap } from "react-icons/hi"
import BtnPrimary from '../../../Global/Redesign/BtnPrimary'
import { useHistory } from 'react-router-dom'

const SingleAccordion = ({
    number, 
    headerText, 
    cardTitle, 
    description, 
    linkText, 
    linkPath, 
    icon, 
    image
}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()


    // STATES
    const [isOpen, setIsOpen] = useState(false)
    

    // HANDLERS
    const openAccordion = () => {
        setIsOpen(!isOpen)
    }


    return (
        <div className={`flex flex-col gap-1 mobile:w-full w-[70%] lg:w-[85%] overflow-hidden [transition:0.4s_linear] [transition-property:max-height,height] will-change-[contents] ${isOpen?"max-h-[1000px] mobile:max-h-[1224px]":"max-h-[105px] mobile:max-h-[100px]"}`}>
            
            {/* heading */}
            <div 
            onClick={openAccordion}
            className='border-b border-white py-[32px] flex items-center justify-between w-full cursor-pointer mb-7'>
                <p className='font-semibold mobile:font-bold mobile:text-2xl text-4xl text-[#F8F8F8]'>
                    0{number}
                </p>

                <p className='font-normal text-2xl mobile:text-[18px] text-white mobile:w-[95%] m-0'>
                    {headerText}
                </p>

                <HiArrowDown
                className={`text-3xl mobile:text-xl text-white [transition:transform_.4s] ${isOpen?"rotate-[180deg]":"rotate-[360deg]"}`} />
            </div>

            {/* body */}
            <div className='border-[#E1E6EF] flex items-center justify-center bg-white rounded-[10px] w-full h-[100%] gap-8 py-[40px] px-[60px] mobile:py-5 mobile:px-4 border overflow-hidden'>

                {/* text description */}
                <div className='flex flex-col h-full justify-center items-start gap-5 w-[65%] mobile:w-full'>

                    {/* icon */}
                    <span className='bg-[#D4F4FF] h-[56px] w-[56px] flex justify-center items-center rounded-[50%]'>

                        <HiAcademicCap className='text-3xl text-black' />
                    </span>

                    {/* texts and texts */}
                    <p className='text-2xl font-semibold text-[#1D2433] text-left'>
                        {cardTitle}
                    </p>

                    {/* images: but for mobile */}
                    <img src={image} className='hidden w-full rounded-[10px] h-[356px] object-cover mobile:inline' alt="" />

                    <p className='font-normal text-xl text-gray-800 text-left'>
                        {description}
                    </p>

                    <BtnPrimary
                    onClick={()=>history.push(linkPath)}
                    title={linkText} />
                </div>

                {/* image */}
                <div className='flex items-center justify-center  w-[406px] h-[473px] mobile:w-full mobile:h-[353px] mobile:hidden'>
                    <img src={image} className='w-full h-full object-cover object-[0%_0%] rounded-[10px]' alt="" />
                </div>

            </div>
        </div>
    )
}

export default SingleAccordion