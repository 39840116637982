import React from "react";
import QuestionAnswer from "./QuestionAnswer";
import capShape2 from "./../../assets/contactus/svg/caption-shape1.svg";

const FAQ = () => {
  return (
    <div className="flex flex-col mobile:py-12 py-24 justify-center items-center">
      <p className="text-4xl mobile:text-2xl leading-[54px] text-[#1D2433] font-semibold text-center relative">
        Frequently Asked Questions
        {/* shape pattern */}
        <img
          src={capShape2}
          className="mobile:w-[20px] mobile:right-[-7%] mobile:top-[-50%] absolute z-[200] right-[-15%] top-[-80%]"
          alt=""
        />
      </p>

      {/* question and answers */}
      <div className="flex flex-col justify-center items-center w-full mt-8 mobile:mt-12">
        <QuestionAnswer
          index={1}
          number={"01"}
          question={
            "Do you think I can fit into the tech space without prior knowledge of tech"
          }
          answer={
            "Of course you can fit in just perfect! You don’t need prior knowledge of tech  at all."
          }
        />

        <QuestionAnswer
          index={2}
          number={"02"}
          question={"Does AImovement offers internship program?"}
          answer={
            "About that, we do have from student to employee package. We teach you for some months and we employ you at the end of your learning"
          }
        />

        <QuestionAnswer
          index={3}
          number={"03"}
          question={"Is AImovement teaching approach beginner friendly?"}
          answer={
            "Our approach to teaching tech carters for beginner to mid-level experience in tech. Our curriculum is pratical base and we encourage our students to build build build."
          }
        />

        <QuestionAnswer
          index={4}
          number={"04"}
          question={"Can i pay for your packages in instalment"}
          answer={
            "Yes!, you can pay in up to three instalment spread acrross your learning period. Terms and conditions apply"
          }
        />
      </div>
    </div>
  );
};

export default FAQ;
