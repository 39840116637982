import React from 'react'
import BtnPrimary from '../../../Global/Redesign/BtnPrimary'
import image from "../../../../assets/webpages/investing/images/start-image.png"
import shapeImage from "../../../../assets/webpages/investing/images/s-shape1.png"
import { useHistory } from 'react-router-dom'

const StartInvest = () => {
    
    // DATA INITIALIZATION
    const history = useHistory()

    return (
        <div className='flex items-center justify-center w-full h-full py-44 mobile:py-16 p-5 mobile:px-4'>
            
            {/* container */}
            <div className="container h-full w-[90%] 2xl:w-[80%] mx-auto flex items-center mobile:flex-col mobile:items-center gap-20">

                {/* text */}
                <div className='flex flex-col gap-3 items-start mobile:items-center mobile:text-center w-full h-full'>
                    <p className='font-bold text-sm text-[#0B8CBA]'>
                        INVEST
                    </p>

                    <p className='text-[28px] font-semibold text-[#1D2433]'>
                        Start investing today
                    </p>

                    <p className='font-normal text-base text-gray-800 mb-4'>
                        AI Movement is focused on discovering, funding, and supporting the world’s most promising startups. Fewer than 2% of the startups we review are made available for investment on our platform.
                    </p>

                    <p className='font-normal text-base text-gray-800 mb-4'>
                        Gain access to, review, and invest in the best startups via our web and mobile-enabled startup investing platform. After investing, keep up to date with news, updates and portfolio analysis features. Easily diversify your portfolio with low minimums.
                    </p>

                    <BtnPrimary 
                    onClick={()=>history.push("/registration")}
                    title={"Join now"}/>
                </div>

                {/* image */}
                <div className='w-full h-full flex items-center justify-end mobile:justify-center relative'>

                    {/* shape */}
                    <img src={shapeImage} className='absolute mobile:hidden top-[8%] left-[1rem]' alt="" />

                    <img src={image} className='w-[80%] mobile:w-full h-full object-cover rounded-[20px]' alt="" />
                </div>
            </div>
        </div>
    )
}

export default StartInvest