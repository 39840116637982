import React from "react";
import student from "./../../assets/home/images/student.png";
import club from "./../../assets/home/images/club.png";
import { ReactComponent as CheckCircle } from "./../../assets/home/svg/package-check-circle.svg";
import { useHistory } from "react-router-dom";
import b_shape1 from "./../../assets/home/images/b-shape1.png";
import b_shape2 from "./../../assets/home/images/b-shape2.png";
import b_shape3 from "./../../assets/home/images/b-shape3.png";
import b_shape4 from "./../../assets/home/images/b-shape4.png";

const Package = () => {
  // history object
  const history = useHistory();

  return (
    <div className="bg-[#fff] flex flex-col pt-40 mobile:px-6 mobile:py-20 px-10 gap-20 mobile:gap-12">
      {/* caption */}
      <div className="text-center">
        <p className="font-semibold text-4xl mobile:text-2xl leading-[54px] text-black mobile:text-[#000] mobile:mb-3">
          Build a sustainable tech career either as a
          <br className="mobile:hidden" />
          <span className="text-[#8B54F7] mobile:text-[#000]"> Student</span> or
          an{" "}
          <span className="text-[#8B54F7] mobile:text-[#000]">
            Exclusive Member
          </span>
        </p>

        <p className="font-normal text-xl mobile:text-base leading-[30px] text-[#6c717a]">
          Either way, YOU ALWAYS WIN 🎉
        </p>
      </div>

      {/* student package description */}
      <div className="flex mx-24 mobile:mx-auto mobile:flex-col md:w-[70%] relative">

        {/* image container */}
        <div className="w-[50%] mobile:w-full bg-[#FFF8EB] flex flex-col justify-center items-start mobile:items-center px-16 py-16 mobile:p-[28px_20px] gap-5 pc:rounded-l-[10px] mobile:rounded-t-[10px] relative">
          <img
            src={b_shape1}
            className="absolute top-[3rem] mobile:hidden right-[4rem]"
            alt=""
          />
          <img
            src={b_shape2}
            className="absolute bottom-[5rem] lg:!right-[2rem] mobile:hidden right-[3.5rem]"
            alt=""
          />

          {/* title */}
          <p className="font-semibold text-2xl leading-[36px] text-black">
            Student Package
          </p>

          {/* description */}
          <p className="font-normal text-base text-[#6c717a]">
            As a student, you will be exposed to multiple viable projects that
            will equip you with the radical skills required for a sustainable
            and rewarding tech career
          </p>

          {/* image */}
          <img className="w-[500px]" src={student} alt="" />

          {/* button */}
          <button
            onClick={() => history.push("/old/training")}
            className="mobile:hidden font-semibold text-base text-white px-5 h-[44px] bg-[#454953] rounded-[8px] hover:opacity-80"
          >
            View all available courses
          </button>

          {/* mobile button */}
          <button
            onClick={() => history.push("/old/login")}
            className="pc:hidden font-semibold text-base text-white px-5 h-[44px] w-full bg-[#454953] rounded-[8px] hover:opacity-80"
          >
            Get Started
          </button>
        </div>

        {/* text container */}
        <div className="w-[50%] mobile:w-full [box-shadow:2px_2px_8px_5px_#eee_inset] bg-white p-12 px-16 mobile:px-4 flex flex-col gap-4 items-start justify-center mobile:rounded-b-[10px] ">
          <div className="text-base mobile:text-sm font-normal text-[#667085] flex justify-start items-center mobile:items-start gap-2 mobile:gap-4 mobile:w-[70%] mobile:mx-auto">
            <CheckCircle className="w-[50px] mobile:min-w-[24px] mobile:max-w-[24px] mobile:-mt-[7px]" />
            <p>
              <span className="pc:font-extrabold pc:mr-1">Affordability: </span>
              learn without paying an arm and a leg
            </p>
          </div>

          <div className="text-base mobile:text-sm font-normal text-[#667085] flex justify-start items-center mobile:items-start gap-2 mobile:gap-4 mobile:w-[70%] mobile:mx-auto">
            <CheckCircle className="w-[50px] mobile:min-w-[24px] mobile:max-w-[24px] mobile:-mt-[7px]" />
            <p>
              <span className="pc:font-extrabold pc:mr-1">Efficiency: </span>
              engage in a targeted curriculum that is based on <br />
              real projects
            </p>
          </div>

          <div className="text-base mobile:text-sm font-normal text-[#667085] flex justify-start items-center mobile:items-start gap-2 mobile:gap-4 mobile:w-[70%] mobile:mx-auto">
            <CheckCircle className="w-[50px] mobile:min-w-[24px] mobile:max-w-[24px] mobile:-mt-[7px]" />
            <p>
              <span className="pc:font-extrabold pc:mr-1">Proficiency: </span>
              adopt a hands on approach that ensures a <br />
              competitive portfolio
            </p>
          </div>

          <div className="text-base mobile:text-sm font-normal text-[#667085] flex justify-start items-center mobile:items-start gap-2 mobile:gap-4 mobile:w-[70%] mobile:mx-auto">
            <CheckCircle className="w-[50px] mobile:min-w-[24px] mobile:max-w-[24px] mobile:-mt-[7px]" />
            <p>
              <span className="pc:font-extrabold pc:mr-1">Marketability: </span>
              obtain skillsets that are in very high demand
            </p>
          </div>

          <div className="text-base mobile:text-sm font-normal text-[#667085] flex justify-start items-center mobile:items-start gap-2 mobile:gap-4 mobile:w-[70%] mobile:mx-auto">
            <CheckCircle className="w-[50px] mobile:min-w-[24px] mobile:max-w-[24px] mobile:-mt-[7px]" />
            <p>
              <span className="pc:font-extrabold pc:mr-1">Opportunity: </span>
              create your pathway to gainful employment <br />
              and a sizable income
            </p>
          </div>

          <div className="text-base mobile:text-sm font-normal text-[#667085] flex justify-start items-center mobile:items-start gap-2 mobile:gap-4 mobile:w-[70%] mobile:mx-auto">
            <CheckCircle className="w-[50px] mobile:min-w-[24px] mobile:max-w-[24px] mobile:-mt-[7px]" />
            <p>
              <span className="pc:font-extrabold pc:mr-1">Employment: </span>you
              are guaranteed employment after <br />
              successfully completing our program.
            </p>
          </div>
        </div>
      </div>

      {/* club membership package description */}
      <div className="flex mx-24 mobile:mx-auto mobile:flex-col md:w-[70%] relative">

        {/* image container */}
        <div className="w-[50%] mobile:w-full bg-[#F8F5FF] flex flex-col justify-start items-start mobile:items-center px-16 py-16 mobile:p-[35px_20px] gap-5 mobile:gap-3 pc:rounded-l-[10px] mobile:rounded-t-[10px] relative">
          <img
            src={b_shape3}
            className="absolute top-[3rem] mobile:hidden right-[4rem]"
            alt=""
          />
          <img
            src={b_shape4}
            className="absolute bottom-[5rem] lg:!right-[2rem] mobile:hidden right-[5rem]"
            alt=""
          />

          {/* title */}
          <p className="font-semibold text-2xl mobile:text-xl mobile:leading-2 leading-[36px] text-black">
            Club Membership Package
          </p>

          {/* description */}
          <p className="font-normal text-base text-[#6c717a]">
            As a member, you will be presented with numerous opportunities to
            foster an exchange of ideas and experience; and as an investor, you
            will be provided accelerated investment.
          </p>

          {/* image */}
          <img className="w-[500px]" src={club} alt="" />

          {/* button */}
          <button
            onClick={() => history.push("/old/club")}
            className="mobile:hidden font-semibold text-base text-white px-5 h-[44px] bg-[#454953] rounded-[8px] hover:opacity-80"
          >
            View all membership options
          </button>

          {/* mobile button */}
          <button
            onClick={() => history.push("/old/login")}
            className="pc:hidden mt-1 font-semibold text-base text-white px-5 h-[44px] w-full bg-[#454953] rounded-[8px] hover:opacity-80"
          >
            Get Started
          </button>
        </div>

        {/* text container */}
        <div className="w-[50%] mobile:w-full [box-shadow:2px_2px_8px_5px_#eee_inset] bg-white p-12 px-16 mobile:px-4 mobile:py-10 mobile:rounded-b-[10px] flex flex-col gap-4 items-start justify-center">
          <div className="text-base mobile:text-sm font-normal text-[#667085] flex justify-start items-start gap-2 mobile:px-1 mobile:mx-auto">
            <CheckCircle className="min-w-[50px] max-w-[50px] mobile:min-w-[24px]" />
            <div className="mt-2">
              <span className="font-extrabold mr-1">Affordability:</span>
              <ul className="ml-8 list-disc">
                <li>
                  Get information about new market tech trends and tech projects
                  from all over Africa.
                </li>
                <li>Get access to exclusive viable new projects</li>
                <li>
                  Get professional guidiance on selected projects through the
                  Consulting team.
                </li>
                <li>
                  Get more confidence in investing in tech by acquiring deeper
                  knowledge about the tech market.
                </li>
              </ul>
            </div>
          </div>

          <div className="text-base mobile:text-sm font-normal text-[#667085] flex justify-start items-start gap-2 mobile:px-1 mobile:mx-auto">
            <CheckCircle className="min-w-[50px] max-w-[50px] mobile:min-w-[24px]" />
            <div className="mt-2">
              <span className="font-extrabold mr-1">Networking:</span>
              <ul className="ml-8 list-disc">
                <li>
                  Be part of the technology community in Nigeria and beyond.
                </li>
                <li>
                  Meet tech experts, tech entrepreneurs, tech investors and
                  movers and shakers in an informal environment and exchange
                  ideas.
                </li>
                <li>
                  Share expertise with club members during numerous networking
                  events.
                </li>
              </ul>
            </div>
          </div>

          <div className="text-base mobile:text-sm font-normal text-[#667085] flex justify-start items-start gap-2 mobile:px-1 mobile:mx-auto">
            <CheckCircle className="min-w-[50px] max-w-[50px] mobile:min-w-[24px]" />
            <div className="mt-2">
              <span className="font-extrabold mr-1">Other Benefits:</span>
              <ul className="ml-8 list-disc">
                <li>
                  Access to tech experts based in the United States of America.
                </li>
                <li>
                  Access to valuable discounted services, including fitness,
                  travel, hospitality and tech services.
                </li>
                <li>Access to corporate work space.</li>
                <li>Access to group life insurance policy.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Package;
