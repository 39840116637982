import { useEffect, useState } from 'react'
import "../../../Global/styles/custom-select-style.css"
import BtnPrimary from '../../../Global/Redesign/BtnPrimary';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSpacesRequest } from '../../../../redux/action/spaces';
import { isEmpty } from '../../../../helpers/isEmtpy';
import { daysDiff } from '../../../../helpers/daysDiff';
import { Field, Formik } from 'formik';
import * as Yup from "yup"
import { FormError } from '../../../Errors';

const SearchWidget = ({toggleDrawer}) => {

    // DATA INITIALIZATION
    const dispatch = useDispatch()
    const history = useHistory()


    // STATES
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const {
        spaces,
        getSpacesLoading,
    } = useSelector((state)=>{
        const { spaces } = state.spacesReducer
        const { getSpacesLoading } = state.loadingIndicator
        return {
            spaces,
            getSpacesLoading,
        }
    })


    // SIDE EFFECTS
    useEffect(()=>{
        if(!isEmpty(spaces) && (!isEmpty(startDate) && !isEmpty(endDate))) {

            // creating params object
            const params = new URLSearchParams({start: startDate, end: endDate})

            // pushing available spaces route with params
            history.push({
                pathname: "/spaces/available",
                search: params.toString()
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spaces])

    return (
        <Formik
        enableReinitialize
        initialValues={{
            endDate: "",
            startDate: "",
        }}
        onSubmit={(values) => {
            setStartDate(values.startDate);
            setEndDate(values.endDate);
            dispatch(getSpacesRequest({
                start_date: values.startDate,
                end_date: values.endDate
            }));
        }}
        validationSchema={
            Yup.object({
                startDate: Yup.date().min(new Date(new Date().toDateString()), "check-in date can't be less than today").max(new Date(new Date().getTime() + (60* 24 * 60 * 60 * 1000)), "check-in date can't be more than 60 days from today").required(),
                endDate: Yup.date().min(new Date(), "checkout date can't be less than today").min(Yup.ref("startDate"), "checkout date can't be less than check-in date").max(new Date(new Date().getTime() + (60* 24 * 60 * 60 * 1000)), "check-in date can't be more than 60 days from today").required()
            })
        }>
            {({
                values,
                dirty,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                field,
                isValid
            }) => {
                return (
                    <div className='flex flex-col gap-5 p-5 bg-[#F0F5FF] w-full'>

                        {/* caption */}
                        <p className="text-2xl font-semibold text-black">
                            Search
                        </p>

                        {/* Date input container */}
                        <label className='flex flex-col gap-3'>

                            {/* label text */}
                            <div className='flex items-center justify-start'>
                                <span className='font-semibold text-sm text-gray-700'>
                                    Check-in date
                                </span>
                            </div>

                            {/* date input field */}
                            <Field
                            type="date"
                            name="startDate"
                            value={values.startDate}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className="w-full border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base bg-white placeholder:text-[#667085]"
                            placeholder="Check-out date" />
                            {
                                touched.startDate && errors.startDate && (
                                <FormError message={errors.startDate} />
                            )}
                        </label>

                        {/* Date input container */}
                        <label className='flex flex-col gap-2'>

                            {/* label text */}
                            <div className='flex items-center justify-start'>
                                <span className='font-semibold text-sm text-gray-700'>
                                    Check-out date
                                </span>
                            </div>

                            {/* date input field */}
                            <input
                            type="date"
                            name="endDate"
                            value={values.endDate}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            className="w-full border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base bg-white placeholder:text-[#667085]"
                            placeholder="Check-out date" />
                            {
                                touched.endDate && errors.endDate && (
                                <FormError message={errors.endDate} />
                            )}

                            <span className={"text-gray-500 text-sm font-normal"}>
                                {
                                    isValid && (!isEmpty(values.startDate) && !isEmpty(values.endDate))?
                                    `You are booking this space for ${daysDiff(values.endDate, values.startDate)} day(s).`:""
                                }
                            </span>
                        </label>

                        {/* button */}
                        <div className='w-full flex flex-col items-stretch'>

                            <BtnPrimary
                            disabled={getSpacesLoading || !isValid}
                            loading={getSpacesLoading}
                            onClick={handleSubmit}
                            title={"View available spaces"} />
                        </div>
                    </div>
                )
            }}
        </Formik>
    )
}

export default SearchWidget