import { useEffect, useState } from "react";
import BtnSm from "../../Global/BtnSm";
import { PaystackButton } from "react-paystack";
import { useDispatch } from "react-redux";
import { putClubSubscriptionPaymentRequest } from "../../../redux/action";

const Summary = ({
  originalPrice,
  promoPrice,
  user,
  putClubSubscriptionLoading,
  clubPackageId,
  specificPackage,
  hasSubscription,
}) => {
  //

  // dispatch object
  const dispatch = useDispatch();

  const componentProps = {
    email: user.email,
    amount: Number(specificPackage.amount) * 100,
    metadata: {
      name: user.firstname + " " + user.lastname,
      phone: user.phone,
    },
    publicKey: "pk_test_8e03a316c7b1c00e5c89fa75179de69cf7100e51",
    text: "Make Payment",
    onSuccess: (e) => {
      console.log("payment was succefull", e.reference);

      let formData = {
        email: user.email,
        club_id: clubPackageId,
        reference: e.reference,
      };

      // dispatch(
      //   putClubSubscriptionPaymentRequest({
      //     formData,
      //   })
      // );
    },
    onClose: () => {},
  };

  return (
    <div className="flex flex-col gap-1 justify-between bg-white rounded-[10px] [box-shadow:0_2px_8px_rgb(0,0,0,0.12)] p-6">
      <p className="text-[#3F444D] font-semibold text-xl">Summary</p>

      <p className="text-sm mt-6 font-normal text-[#1d2433] opacity-[0.65] flex items-center gap-2 border-b border-[#e1e6ef] pb-4 w-fit">
        <span>Original price:</span>
        <span>NGN {originalPrice}</span>
      </p>

      <p className="text-xl font-semibold text-[#1d2433] flex items-center gap-2 border-b border-[#e1e6ef] pb-4 w-fit">
        <span className="text-[#8B54F7]">Promo price:</span>
        <span className="opacity-[0.65]">NGN {promoPrice}</span>
      </p>

      <p className="font-normal text-sm text-[#1d2433] opacity-[0.65]">
        By completing payment you agree to our terms of service
      </p>

      <div className="flex flex-col items-stretch mt-3">
        {hasSubscription ? (
          <BtnSm title={"You have a subscription"} />
        ) : putClubSubscriptionLoading ? (
          <BtnSm loading={putClubSubscriptionLoading} title="Make Payment" />
        ) : (
          <PaystackButton
            className="h-[44px] mobile:h-[48px] bg-[#7F56D9] flex justify-center items-center font-semibold text-base lg:text-xs text-white px-7 rounded-[8px] hover:opacity-80 disabled:opacity-50 disabled:cursor-not-allowed"
            {...componentProps}
          />
        )}
      </div>
    </div>
  );
};

export default Summary;
