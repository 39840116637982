import React from 'react'
import image1 from "../../../../assets/webpages/home/images/grow-image.png"
import shape1 from "../../../../assets/webpages/home/images/g-shape1.png"
import shape2 from "../../../../assets/webpages/home/images/g-shape2.png"
import BtnPrimary from '../../../Global/Redesign/BtnPrimary'
import { useHistory } from 'react-router-dom'

const GrowCareer = () => {

    // OBJECT INITIALIZATION
    const history = useHistory();

    return (
        <div className='flex items-center h-full w-full mobile:flex-col-reverse'>
            
            {/* left; image */}
            <div className='w-[50%] mobile:w-full flex items-center justify-center relative h-[656px] mobile:h-auto z-[1]'>

                <img src={image1} className='w-full h-full object-cover' alt="" />
            </div>

            {/* right: text, descriptions */}
            <div className='w-[50%] mobile:w-full flex items-center justify-center h-[656px] mobile:h-auto bg-[#055774] relative z-[1] '>

                {/* shape 2 */}
                <img src={shape2} className="absolute bottom-[8%] left-[-2.5rem] z-[10] mobile:left-[50%] mobile:translate-x-[-50%] mobile:bottom-[-0.6rem] mobile:w-[34px]" alt="" />

                <div className='flex flex-col items-start justify-center gap-5 text-white w-[60%] mobile:w-full relative mobile:items-stretch mobile:py-[60px] mobile:px-6 mobile:text-center'>

                    {/* shape 1 */}
                    <img src={shape1} className="absolute top-[-1.5rem] right-[-3rem] mobile:w-[28px] mobile:right-[1rem] mobile:top-[2.5rem]" alt="" />

                    <p className='font-semibold text-4xl mobile:text-2xl'>
                        Grow your career the right way with the right team
                    </p>

                    <p className='font-medium text-xl mobile:font-normal mobile:text-base mobile:mb-3'>
                        AI Movement is a community of private investors, institutional investors, and technology experts. We seek to identify, understand, and resolve core humanitarian issues through advanced technology.
                    </p>

                    <BtnPrimary
                    onClick={()=>history.push("/registration")}
                    title={"Get started"} /> 
                </div>
            </div>
        </div>
    )
}

export default GrowCareer