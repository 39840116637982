import React from 'react'
import { useHistory } from 'react-router-dom'
import headImage from "../../../assets/bootcamp-form/svg/bootcamp-info.svg"

const FixedMessage = () => {

    // OBJECT INITIALIZATION
    const history = useHistory()

    return (
        <div 
        onClick={()=>history.push("/form/bootcamp2023")}
        className={`fixed bottom-[5%] right-[5%] mobile:right-[4px] bg-[#F8F5FF] rounded-[10px] [box-shadow:0px_8px_32px_rgba(0,0,0,0.16)] z-[12000] cursor-pointer`}>

            {/* image */}
            <div className='w-[131px] flex justify-center items-center'>
                
                <img src={headImage} loading="lazy" className='object-cover w-full h-full' alt="" />
            </div>

            
            {/* details */}
            <div className='flex flex-col items-center justify-center text-center p-4 text-black'>

                <p className='mt-0 leading-4 text-[8px] font-normal'>
                    From
                </p>

                <p className='mt-0 font-semibold text-[11px] leading-4'>
                    25th - 27th JAN
                </p>

                <p className='mt-0 text-[#E02D3C] text-base font-semibold'>
                    Click to Join 
                </p>
            </div>
        </div>
    )
}

export default FixedMessage