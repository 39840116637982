import { CLEAR_REGISTER_DIGMARK_BOOTCAMP_SUCEESS, REGISTER_DIGMARK_BOOTCAMP_FAIL, REGISTER_DIGMARK_BOOTCAMP_LOADING, REGISTER_DIGMARK_BOOTCAMP_REQUEST, REGISTER_DIGMARK_BOOTCAMP_SUCCESS } from "../types";

// register bootcamp form
export const digMarketingBootcampRequest = (prop) => {
    return {
      type: REGISTER_DIGMARK_BOOTCAMP_REQUEST,
      payload: prop,
    };
  };
  
  export const digMarketingBootcampSuccess = (prop) => {
    return {
      type: REGISTER_DIGMARK_BOOTCAMP_SUCCESS,
      payload: prop,
    };
  };
  
  export const digMarketingBootcampFailure = (errorMessage) => {
    return {
      type: REGISTER_DIGMARK_BOOTCAMP_FAIL,
      payload: errorMessage,
    };
  };
  
  export const digMarketingBootcampLoading = (loading) => {
    return {
      type: REGISTER_DIGMARK_BOOTCAMP_LOADING,
      payload: loading,
    };
  };
  
  export const clearDigMarketingBootcampSuccess = () => {
    return {
      type: CLEAR_REGISTER_DIGMARK_BOOTCAMP_SUCEESS
    };
  };