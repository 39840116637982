import React from "react";
import Footer from "../../../components/BootcampForm/Footer";
import Body from "../../../components/DataAnalysisForm/Body";
import Form from "../../../components/DataAnalysisForm/Form";
import Header from "../../../components/DataAnalysisForm/Header";

const DataAnaylysisForm = () => {
  return (
    <div className="relative min-h-screen">
      {/* Header */}
      <Header />
      {/* Body */}
      <Body>
        <Form />
      </Body>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default DataAnaylysisForm;
