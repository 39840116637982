export const MONTHS = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

export const FULL_MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  export const DAY = [
    "Sunday",
    "Monday",
    "Tueday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  export const getRTDate = {
    date: (prop) => {
      return new Date(prop).getDate();
    },
    dayNum: (prop) => {
      return new Date(prop).getDay();
    },
    month: (prop) => {
      return new Date(prop).getMonth();
    },
    year: (prop) => {
      return new Date(prop).getFullYear();
    },
  };