import { FaUsers } from "react-icons/fa";
import {GiGraduateCap} from "react-icons/gi"
import { Progress } from "rsuite";
import "rsuite/dist/rsuite.min.css";

const DBCard = ({
    status,
    title, 
    packageName, 
    durationText,
    durationMonth, 
    monthsRemaining,
    isCompleted,
    progressPercent
}) => {

    return (
        <div className="flex flex-col border border-[#E1E6EF] p-5 gap-4 rounded-[10px] w-full h-fit bg-white">
            
            {/* caption */}
            <p className="mt-0 font-semibold text-sm text-gray-600">
                {title}
            </p>

            {/* body info */}
            <div className="w-full flex flex-col gap-4">

                {/* icon section */}
                <div className="flex gap-4 items-center">

                    {/* icon */}
                    <span className="flex items-center justify-center h-[40px] w-[40px] bg-[#D4F4FF] rounded-[50%]">
                        {
                            status==="BOOTCAMP"?
                            <GiGraduateCap
                            className="text-2xl text-[#1D2433]" />: status==="CLUB"?
                            <FaUsers
                            className="text-2xl text-[#1D2433]" />:
                            null
                        }
                    </span>

                    {/* texts */}
                    <div className="flex flex-col gap-1">
                        <p className="mt-0 font-medium text-base text-[#1D2433]">
                            {packageName}
                        </p>
                        <p className="mt-0 text-gray-800 font-normal text-sm">
                            {`${durationMonth} ${durationText}`}
                        </p>
                    </div>
                </div>

                {/* progress section */}
                <div className="flex items-center -ml-2">
                    <div className="w-[40%]">
                        <Progress.Line
                        percent={progressPercent}
                        showInfo={false}
                        strokeColor="#0B8CBA"/>
                    </div>
                    
                    {
                        isCompleted?
                        <p className="font-normal text-sm text-gray-800 w-[60%] mb-1">
                            Completed
                        </p>:
                        <p className="font-normal text-sm text-gray-800 w-[60%] mb-1">
                            {monthsRemaining} months remaining
                        </p>
                    }
                </div>

            </div>
        </div>
    )
}

export default DBCard;