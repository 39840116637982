import React from 'react'

const BreakTextsMobile = () => {
    return (
        <>
            <br className="hidden mobile:block" />
        </>
    )
}

export default BreakTextsMobile