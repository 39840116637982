import React from 'react'
import emptyEvent from "./../../../assets/dashboard/global/images/empty-event.png"

const EmptyEventSection = () => {
    return (
        <div className='flex flex-col gap-5 py-6 px-3 justify-center items-center font-normal text-xs text-[rgba(29,36,51,0.65)] bg-white rounded-[10px]'>
            <img src={emptyEvent} alt="" />
            <span>No upcoming events yet. Check back later!</span>
        </div>
    )
}

export default EmptyEventSection