import { Skeleton } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getTimeSpent } from '../../../../helpers';
import { 
    deleteUserFailure, 
    getUserClubSubsciptionRequest, 
    getUsersBootcampRequest, logout 
} from '../../../../redux/action';
import DBCard from '../TheClub/DBCard'
import DBEmptyStateCard from '../TheClub/DBEmptyStateCard';
import ProfileCard from './ProfileCard'

const SettingsBody = () => {

    // DATA INITIALIZATION
    const dispatch = useDispatch()


    // STATES
    const { 
        userClubSubscriptions, 
        userClubSubscriptionsLoading, 
        token,
        bootcamp,
        bootcampLoading,
        deleteUserSuccess,
        deleteUserError,       
    } = useSelector((state) => {
        const { token } = state.userData;

        const { bootcamp } = state.bootcampReducer;
        const { userClubSubscriptions } = state.clubSubscriptionReducer;

        const {
            success: { 
                deleteUser: deleteUserSuccess 
            },
            errors: { 
                deleteUser: deleteUserError 
            },
        } = state.ajaxStatuses;

        const { 
            userClubSubscriptionsLoading,
            bootcampLoading
        } = state.loadingIndicator;

        return {
            userClubSubscriptions,
            userClubSubscriptionsLoading,
            token,
            bootcamp,
            bootcampLoading,
            deleteUserSuccess,
            deleteUserError
        };
    });


    // SIDE EFFECTS
    useEffect(() => {
        dispatch(getUsersBootcampRequest(token));
        dispatch(getUserClubSubsciptionRequest(token));
    }, []); 
    
    // success and erros onDeleteUser
    useEffect(()=>{
        if(deleteUserError) {
            toast.error(deleteUserError, {
                duration: 3000,
            })
        }
    }, [deleteUserError])
    useEffect(()=>{
        if(deleteUserSuccess) {
            console.log(deleteUserSuccess)
            toast.success("Your account has been Deleted!", {
                duration: 3000
            })
            dispatch(logout())
        }
    }, [deleteUserSuccess])

    return (
        <div className="flex mobile:flex-col gap-10 w-full">

            {/* Profile Card */}
            <div className='w-[70%] mobile:w-full flex items-start justify-start'>
                
                <ProfileCard />
                
            </div>

            {/* Bootcamp and Club subscription Cards */}
            <div className='w-[30%] mobile:w-full flex flex-col items-start justify-start gap-5'>

                {/* Club Packages */}
                {
                    bootcampLoading?
                    <Skeleton
                    variant="rectangular"
                    sx={{
                    width: "100%",
                    height: "20vh",
                    borderRadius: "10px",
                    }} />:
                    !(bootcamp && bootcamp.length!==0)?
                    <DBEmptyStateCard
                    packageTypeText={"Current course enrolled in"}
                    emptyMessage={"No course registered for yet"}
                    pathText={"View all avalable courses"}
                    pathUrl={"/dashboard/bootcamps"} />:
                    bootcamp.slice(0,1).map((bcamp)=>(
                        <DBCard
                        key={bcamp._id}
                        status={"BOOTCAMP"}
                        title={"Current course enrolled in"}
                        packageName={bcamp.name.slice(0,25).concat("...")}
                        durationMonth={bcamp.duration}
                        durationText={"months course"}
                        progressPercent={Math.floor((getTimeSpent(bcamp.start_date) / bcamp.duration) * 100)}
                        monthsRemaining={bcamp.duration - getTimeSpent(bcamp.start_date)}
                        isCompleted={getTimeSpent(bcamp.start_date) >= bcamp.duration} /> 
                    ))
                }

                {/* Club Packages */}
                {
                    userClubSubscriptionsLoading?
                    <Skeleton
                    variant="rectangular"
                    sx={{
                    width: "100%",
                    height: "20vh",
                    borderRadius: "10px",
                    }} />:
                    !(userClubSubscriptions && userClubSubscriptions.length!==0)?
                    <DBEmptyStateCard
                    packageTypeText={"Current club package"}
                    emptyMessage={"No registered club package"}
                    pathText={"View all club packages"}
                    pathUrl={"/dashboard/bootcamps"} />:
                    userClubSubscriptions.slice(0,1).map((clubSub)=>(
                        <DBCard
                        key={clubSub._id}
                        status={"CLUB"}
                        title={"Current club package"}
                        packageName={"Advanced"}
                        durationMonth={clubSub.club_id.duration}
                        durationText={"months membership"}
                        progressPercent={Math.floor((getTimeSpent(clubSub.start_date) / clubSub.club_id.duration) * 100)}
                        monthsRemaining={clubSub.club_id.duration - getTimeSpent(clubSub.start_date)}
                        isCompleted={getTimeSpent(clubSub.start_date) >= clubSub.club_id.duration} />
                    ))
                }

            </div>
        </div>
    )
}

export default SettingsBody