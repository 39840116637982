import TrainingBody from "../../../../components/Redesign/Webpage/TrainingCenter/TrainingBody";
import TrainingHead from "../../../../components/Redesign/Webpage/TrainingCenter/TrainingHead";
import Wrapper from "../../../../layouts/Redesign/Wrapper";

const TrainingCenter = () => {
    return (
        <Wrapper>

            {/* training center head */}
            <TrainingHead />

            {/* training center body */}
            <TrainingBody />

        </Wrapper>
    )
}

export default TrainingCenter