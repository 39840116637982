import { useEffect } from "react";
import ProfileAvatar from "../Home/ProfileAvatar";
import AllUpcomingEvents from "./AllUpcomingEvents";
import OnGoingEvents from "./OnGoingEvents";
import { useSelector, useDispatch } from "react-redux";
import { getUserEventsRequest } from "../../../redux/action";

const EventsBody = () => {
  return (
    <section className="w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12">
      {/* header */}
      <header className="container mx-auto mobile:px-4 flex justify-between items-center">
        <div>
          <p className="text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]">
            Events
          </p>
        </div>

        {/* profile dp */}
        <div className="mobile:hidden">
          <ProfileAvatar />
        </div>
      </header>

      {/* ongoing events */}
      <section className="container mx-auto w-full mt-12 mobile:px-4">
        {/* ongoing events */}
        <OnGoingEvents />
      </section>

      {/* All upcoming events */}
      <section className='container mx-auto w-full mt-12 mobile:mt-16 mobile:px-4'>
          <AllUpcomingEvents />
      </section>            
    </section>
  );
};

export default EventsBody;
