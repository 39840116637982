import { Skeleton } from '@mui/material'
import React from 'react'
import { AiFillCalendar, AiOutlineClockCircle } from 'react-icons/ai'
import { HiOutlineOfficeBuilding, HiUserCircle } from 'react-icons/hi'
import BtnPrimary from '../../../../Global/Redesign/BtnPrimary'
import EventGallery from './EventGallery'
import SponsorEvent from './SponsorEvent'

const EventInfo = ({
    hasEnded, 
    time,
    guests,
    location,
    date,
    loading,
    notEmpty
}) => {
    return (
        <>
            {   
                loading?
                <div className="flex items-center justify-center rounded-[20px] h-[50vh] w-[80%] mobile:w-full md:!w-[50%] mx-auto">
                    <Skeleton
                    variant="rectangular"
                    sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "20px",
                    }} />
                </div>:
                notEmpty?
                <div className='flex flex-col gap-10 w-[35%] mobile:w-full'>
                    <div className='flex flex-col gap-5 rounded-[10px] border border-[#E1E6EF] p-5 items-start w-full bg-white h-fit'>

                        {/* first container */}
                        <div className='border-b border-[#E1E6EF] gap-5 flex flex-col items-start pb-10 w-full'>

                            <p className='text-2xl font-semibold text-gray-800'>
                                Event Info
                            </p>

                            <div className='flex flex-col gap-4 items-start w-full'>

                                {/* date */}
                                <div className='flex gap-2 items-center w-full'>
                                    <AiFillCalendar className='text-3xl' />
                                    <span className='w-[85%]'>
                                        {date}
                                    </span>
                                </div>

                                {/* time */}
                                <div className='flex gap-2 items-center w-full'>
                                    <AiOutlineClockCircle className='text-3xl' />
                                    <span className='w-[85%]'>
                                        {time} WAT
                                    </span>
                                </div>

                                {/* location */}
                                <div className='flex gap-2 items-start w-full'>
                                    <HiOutlineOfficeBuilding className='text-3xl' />
                                    <span className='w-[85%]'>
                                        {location}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* second container */}
                        <div className='gap-5 flex flex-col items-start pt-5 w-full'>

                            <p className='text-2xl font-semibold text-gray-800'>
                                Guests
                            </p>

                            <div className='flex flex-col gap-4 items-start w-full'>

                                {
                                    guests.map((guest)=>(
                                        <div 
                                        key={guest._id}
                                        className='flex gap-2 items-start w-full'>

                                            {/* items */}
                                            <HiUserCircle className='text-3xl' />
                                            <p className="flex flex-col mt-0 gap-1 w-[85%]">
                                                <span className='font-medium text-base text-gray-800'>
                                                    {guest.name}
                                                </span>
                                                <span className='text-sm font-normal text-gray-800'>
                                                    {guest.about}
                                                </span>
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        {/* button */}
                        {
                            hasEnded?
                            <span className='bg-white border border-gray-600 rounded-[8px] flex items-center justify-center text-gray-800 text-sm font-bold h-[40px] px-3'>
                                Concluded
                            </span>:
                            <BtnPrimary
                            title={"Register for event now"} />
                        }
                        
                    </div>

                    {/* interested in sponsoring event */}
                    <div className='flex-col items-start w-full hidden mobile:flex'>
                        {
                            !hasEnded && 
                            <SponsorEvent />
                        }

                        {/* Gallery */}
                        {
                            hasEnded && <EventGallery />
                        }
                    </div>
                </div>:
                <div className='flex justify-center items-center w-full h-[60vh] text-xl font-medium rounded-[10px]'>Nothing Here</div>
            }
        </>
    )
}

export default EventInfo