import React from "react";
import AvailableCourses from "./AvailableCourses";
import Courses from "./Courses";
import EventsSection from "./EventsSection";
import MembershipOptions from "./MembershipOptions";
import Packages from "./Packages";
import ProfileAvatar from "./ProfileAvatar";
import { useSelector } from "react-redux";

const HomeBody = () => {
  const { firstname } = useSelector((state) => {
    const {
      user: { firstname, lastname, email },
    } = state.userData;
    return {
      firstname,
      lastname,
      email,
    };
  });

  return (
    <section className="w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12">
      {/* header */}
      <header className="container mx-auto mobile:px-4 flex justify-between items-center">
        <div>
          <p className="text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]">
            Hello {firstname}
          </p>

          <p className="font-normal mobile:leading-4 mobile:text-base text-xl text-[#1D2433] opacity-[0.65] ">
            Happy to see you back!
          </p>
        </div>

        {/* profile dp */}
        <div className="mobile:hidden">
          <ProfileAvatar />
        </div>
      </header>

      {/* middle section */}
      <section className="container mx-auto mobile:px-4 flex w-full justify-between mt-9 gap-9">
        {/* course, package information and available courses */}
        <main className="flex w-[70%] lg:w-[60%] mobile:w-full flex-col gap-16">
          {/* course/package detailed info */}
          <div className="flex w-full lg:flex-col mobile:flex-col gap-6">
            <Courses shadow={true} width={"100%"} />

            <Packages shadow={true} width={"100%"} />
          </div>

          {/* available courses */}
          <div>
            <AvailableCourses />
          </div>
        </main>

        {/* upcoming events */}
        <aside className="mobile:hidden flex w-[30%] lg:w-[40%]">
          <EventsSection />
        </aside>
      </section>

      {/* club membership option section */}
      <section className="flex flex-col justify-center w-full mt-10 mobile:mt-16 gap-9">
        <MembershipOptions />
      </section>

      {/* mobile upcoming events */}
      <section className="container mx-auto mobile:px-4 mobile:mt-20 hidden mobile:block">
        <EventsSection />
      </section>
    </section>
  );
};

export default HomeBody;
