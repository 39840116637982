import React from "react";
import MemberCard from "./MemberCard";
import kachi from "../../../../assets/webpages/aboutus/images/ceo-founder.png";
import dolu from "../../../../assets/webpages/aboutus/images/dolu.png";
import esther from "../../../../assets/webpages/aboutus/images/esther.png";
import remy from "../../../../assets/webpages/aboutus/images/remy.png";
import tasha from "../../../../assets/webpages/aboutus/images/tasha.png";
import francis from "../../../../assets/webpages/aboutus/images/francis.png";
import oscar from "../../../../assets/webpages/aboutus/images/oscar.png";
import ebuka from "../../../../assets/webpages/aboutus/images/ebuka.png";
import kcee from "../../../../assets/webpages/aboutus/images/kcee.png";
import shape1 from "../../../../assets/webpages/aboutus/images/e-shape1.png";

const Team = () => {
  return (
    <div className="py-40 flex flex-col mobile:px-5 mobile:py-16 px-8 mx-auto relative gap-16 container">
      {/* shapes */}
      <img
        src={shape1}
        className="absolute top-[9%] right-[22%] mobile:w-[20px] mobile:top-[4%] mobile:hidden mobile:right-[2rem]"
        alt=""
      />

      {/* text section */}
      <div className="flex flex-col gap-2 text-center items-center w-full">
        <p className="font-bold text-sm mobile:text-xs text-[#8B54F7]">
          OUR TEAM
        </p>

        <p className="font-semibold text-[32px] mobile:text-2xl text-[#1D2433] pc:w-[50%]">
          Every great company has a great team behind it. Meet ours
        </p>
      </div>

      {/* team gallery */}
      <div className="grid mobile:grid-cols-[auto] lg:grid-cols-[auto_auto] grid-cols-[auto_auto_auto] items-center justify-center gap-12">
        <MemberCard
          image={kachi}
          name="Barr. Kachi Aghasili"
          title="CEO & Founder"
        />

        {/* Remy */}
        <MemberCard
          image={remy}
          name="Chuks Remy"
          title="Chief Technical Officer"
        />

        {/* esther */}
        {/* <MemberCard
          image={esther}
          name="Esther Nmesooma Anisi"
          title={"Digital Communications Director"}
        /> */}

        {/* Dolu */}
        <MemberCard
          image={dolu}
          name="Engr. Dolu Okeowo"
          title="Chief Consultant Officer (USA)"
        />

        {/* Remy */}
        <MemberCard
          image={tasha}
          name="Natasha  Ikoku"
          title="Executive Assistant"
        />

        {/* Remy */}
        <MemberCard
          image={francis}
          name="Oluchukwu Nnaji"
          title="Design Lead"
        />

        {/* Remy */}
        <MemberCard
          image={oscar}
          name="Chukwu Chimaije Oscar"
          title="Enrollment Officer"
        />

        {/* Remy */}
        <MemberCard
          image={ebuka}
          name="Ebuka Collins"
          title="Digital Communications Director"
        />

        {/* Remy */}
        <MemberCard
          image={kcee}
          name="Ikejiani kenechukwu Kelvin"
          title="Operations Manager"
        />
      </div>
    </div>
  );
};

export default Team;
