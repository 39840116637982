import React from "react";
import { Route, Switch } from "react-router-dom";
import DataAnaylysisForm from ".";
import ThankyouPage from "./ThanksYouPage";

const DataAnaylysisFormRouter = ({ path }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${path}`} component={DataAnaylysisForm} />

        <Route exact path={`${path}/success`} component={ThankyouPage} />
      </Switch>
    </>
  );
};

export default DataAnaylysisFormRouter;
