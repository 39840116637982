import React, { useEffect, useState } from 'react'
import MyModal from '../../../../../layouts/MyModal'
import SearchWidget from './SearchWidget'
import SpaceCard from './SpaceCard'
import SpaceDetail from './SpaceDetail'
import SpaceInfo from './SpaceInfo'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getSpacesRequest } from '../../../../../redux/action'
import { Skeleton } from '@mui/material'
import { isEmpty } from '../../../../../helpers/isEmtpy'
import RegistrationForm from './RegistrationForm'

const AvailableSpaces = () => {

    // DATA INITIALIZATION
    const location = useLocation()
    const dispatch = useDispatch()
    const param = new URLSearchParams(location.search)
    const start_date = param?.get("start")
    const end_date = param?.get("end")

    
    // STATES
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [selectedSpaceInfo, setSelectedSpaceInfo] = useState({
        name: "",
        price: null,
        image: "",
        spaceId: ""
    })
    const [spaceModalState, setSpaceModalState] = useState(false)
    const [regModalState, setRegModalState] = useState(false)
    const [hasReg, setHasReg] = useState(false)
    const [userDetails, setUserDetails] = useState({
        name: "",
        phone: "",
        email: ""
    })
    const {
        spaces,
        getSpacesLoading,
    } = useSelector((state)=>{
        const { spaces } = state.spacesReducer
        const { getSpacesLoading } = state.loadingIndicator
        return {
            spaces,
            getSpacesLoading,
        }
    })


    // HANDLERS
    const toggleSpaceModal = () => {
        setSpaceModalState(!spaceModalState)
    }
    const toggleRegModal = () => {
        setRegModalState(!regModalState)
    }


    // SIDE EFFECTS
    useEffect(()=>{
        if(!start_date || !end_date) {
            setStartDate(start_date)
            setEndDate(end_date)
            dispatch(getSpacesRequest({
                start_date,
                end_date
            }))
        }
    }, [start_date, end_date])

    return (
        <div className='flex items-center justify-center w-full h-full py-44 mobile:pt-6 mobile:pb-16 p-5 mobile:p-0'>
            
            {/* container */}
            <div className="container h-full w-[90%] mx-auto flex items-start mobile:flex-col mobile:items-center gap-[51px] mobile:w-full">
            
                {/* left */}
                <div className='flex flex-col h-full gap-5 w-[30%] mobile:w-full'>

                    {/* search widget */}
                    <SearchWidget
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    end={end_date}
                    start={start_date} />

                    {/* info on current spaces */}
                    <SpaceInfo />
                </div>

                {/* right */}
                <div className='flex flex-col h-full gap-5 w-[70%] mobile:w-full mobile:px-4'>

                    {/* spaces container */}
                    {
                        getSpacesLoading?
                        <Skeleton
                        variant="rectangular"
                        sx={{
                        width: "100%",
                        height: "70vh",
                        borderRadius: "10px",
                        }} />:
                        !isEmpty(spaces)?
                        <>
                            {/* caption */}
                            <p className='text-[28px] mobile:text-2xl mobile:text-center mobile:w-full font-semibold text-gray-900'>
                                Available Spaces Found
                            </p>

                            <div className='grid grid-cols-3 lg:grid-cols-2 mobile:grid-cols-1 gap-8 w-full'>
                                {
                                    spaces.map((space)=>(
                                        <SpaceCard
                                        disabled={isEmpty(startDate) || isEmpty(endDate)}
                                        spaceId={space._id}
                                        key={space._id}
                                        hasReg={hasReg}
                                        spaceName={space.name}
                                        price={space.new_price}
                                        image={space.imgurl}
                                        toggleSpaceModal={toggleSpaceModal}
                                        toggleRegModal={toggleRegModal}
                                        setSelectedSpaceInfo={setSelectedSpaceInfo} />
                                    ))
                                }
                            </div>
                        </>:
                        <div className='flex justify-center items-center w-full h-[70vh] text-2xl border-2 border-gray-100 rounded-[10px]'>Nothing Here</div>
                    }
                </div>
            </div>

            {/* Modal */}
            {/* logout modal */}
            <MyModal
            modalIsOpen={spaceModalState}
            toggleModal={toggleSpaceModal}
            disableBtn={true}>
                
                {/* modal details modal */}
                <SpaceDetail
                userDetails={userDetails}
                startDate={startDate}
                endDate={endDate}
                selectedSpaceInfo={selectedSpaceInfo} />                

            </MyModal>

            {/* registration modal */}
            <MyModal
            modalIsOpen={regModalState}
            toggleModal={toggleRegModal}
            disableBtn={false}>
                
                {/* modal details modal */}
                <div className='min-h-[80vh] pc:w-[50vw] w-[70vw] overflow-auto flex justify-start flex-col items-center'>
                    <RegistrationForm
                    setUserDetails={setUserDetails}
                    closeModal={toggleRegModal}
                    toggleSpaceModal={toggleSpaceModal}
                    setHasReg={setHasReg} />
                </div>             

            </MyModal>
        </div>
    )
}

export default AvailableSpaces