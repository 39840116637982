import { useState } from 'react'
import { RiArrowDownSFill } from "react-icons/ri"
import {RxDotFilled} from "react-icons/rx"

const Accordion = ({label, couseTitle, courseItems, weekNumber}) => {

    // STATES
    const [isOpen, setIsOpen] = useState(false)


    // HANDLERS
    const toggleAccordion = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className={`flex flex-col gap-5 pb-4 mobile:w-full w-full overflow-hidden [transition:0.4s_linear] [transition-property:max-height] will-change-[contents] ${isOpen?"max-h-[1000px] mobile:max-h-[1224px]":"max-h-[60px] mobile:max-h-[60px]"} border-b-[1.5px] border-[#E1E6EF]`} >

            {/* Accordion Header */}
            <div 
            onClick={toggleAccordion}
            className='flex justify-between items-center cursor-pointer'>
                <div className='flex flex-col'>
                    <p className='text-[#0B8CBA] opacity-[65%] text-sm font-semibold'>
                        Week {weekNumber}
                    </p>
                    <p className='text-xs text-gray-800 font-semibold flex items-center gap-2'>

                        <RxDotFilled
                        className="text-base text-gray-800" />

                        <span>
                            {couseTitle}
                        </span>
                    </p>
                </div>

                <span 
                className='cursor-pointer h-[32px] flex justify-center items-center w-[32px] hover:bg-[#e9e5e5] [transition:color_.4s_ease-in-out] rounded-[50%]'>

                    <RiArrowDownSFill
                    className={`text-[#5c5f62] [transition:transform.4s_ease-in-out] ${isOpen?"rotate-[180deg]":"rotate-[360deg]"}`}
                    size={18} />
                </span>
            </div>

            <div className='pl-12 pt-1 h-[100%] overflow-hidden w-full'>
                <ul className='text-[#1d2433] opacity-[0.65] flex flex-col gap-3 text-sm font-normal h-full w-full list-disc'>
                    {
                        courseItems.map((item, index)=>(
                            <li key={index}>{item}</li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default Accordion