import React from 'react'

const Disclaimer = () => {
    return (
        <div className='border p-5 gap-4 border-[#E1E6EF] flex flex-col rounded-[10px] bg-white items-start w-full'>

            {/* caption */}
            <p className='font-medium text-[#B3261E] text-base'>
                Disclaimer
            </p>

            {/* description */}
            <p className='font-normal text-gray-800 text-sm'>
                All investments, including litigation finance, is highly speculative in nature and involves substantial risk of loss. We encourage our investors to invest very carefully. We also encourage investors to get personal advice from your professional investment advisor and to make independent investigations before acting on information that we publish.
            </p>
        </div>
    )
}

export default Disclaimer