import { Skeleton } from '@mui/material'
import React from 'react'
import shape from "../../../../assets/webpages/training/images/h-shape1.png"
import EmptyData from '../Registration/EmptyData'
import LoadingCircles from '../Registration/LoadingCircle'
import CourseCard from './CourseCard'

const Courses = ({
    bootcamps,
    bootcampsLoading,
    toggleLearnMoreModal,
}) => {
    return (
        <div className='py-40 flex flex-col mobile:px-5 mobile:py-16 px-8 mx-auto relative gap-20 container text-white'>

            {/* shape */}
            <img
            src={shape}
            className="absolute top-[10%] right-[30%] mobile:w-[20px] mobile:top-[3rem] mobile:right-[4rem]"
            alt="" />

            {/* texts */}
            <div className="flex flex-col gap-6 text-center items-center w-full">
                <p className="mt-0 font-bold text-sm mobile:text-xs leading-none text-[#0B8CBA]">
                    OUR COURSES
                </p>

                <p className="mt-0 font-semibold text-[28px] leading-none mobile:text-2xl text-[#1D2433]">
                    Here are the available courses we offer
                </p>

                <p className="mt-0 font-normal text-base text-gray-800">
                    Select the pathway below that you are interested in, click on apply, fill the form and an admissions officer will review your application and reach out to you.
                </p>
            </div>

            {
                bootcampsLoading?
                <Skeleton
                variant="rectangular"
                sx={{
                    width: "100%",
                    height: "50vh",
                    borderRadius: "10px",
                }} />:
                bootcamps && bootcamps.length !== 0?
                    
                    // courses
                    <div className='grid grid-cols-3 lg:grid-cols-2 mobile:grid-cols-1 gap-6 container 2xl:w-[80%] md:!w-[50%] mx-auto'>

                        {
                            bootcamps.map((bootcamp, index)=>(

                                bootcamp.course_type==="regular"&&
                                <CourseCard
                                key={bootcamp._id}
                                courseImage={bootcamp.imgurl}
                                onClick={()=>toggleLearnMoreModal(bootcamp)}
                                name={bootcamp.name}
                                pathway={bootcamp.pathway}
                                duration={bootcamp.duration}
                                topics={bootcamp.course_id.topics}
                                oldPrice={bootcamp.old_price}
                                newPrice={bootcamp.new_price} />
                            ))
                        }
                    </div>:
                <EmptyData />
            }
        </div>
    )
}

export default Courses