import React, { useEffect, useState } from 'react'
import Tabs,{Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import MyModal from '../../../../layouts/MyModal';
import "../../Dashboard/Bootcamps/styles/react-tabs-custom-style.css"
import EditPasswordForm from './EditPasswordForm';
import EditProfileForm from './EditProfileForm';
import ProfileImage from './ProfileImage';
import SavedAccountView from './SavedAccountView';

const ProfileCard = () => {


    // STATES
    const [savedAccountInfo, setSavedAccountInfo] = useState(false);
    const [uploadFileModalState, setUploadFileModalState] = useState(false)
    // redux state
    const {
        updateUserSuccess,
        updateUserError,
      } = useSelector((state) => {
        const {
          success: { updateUser: updateUserSuccess },
          errors: { updateUser: updateUserError },
        } = state.ajaxStatuses;
    
        return {
          updateUserSuccess,
          updateUserError,
        };
    });

    
    // DATA INITIALIZATION
    const editPassword = <span className='flex gap-2 items-center text-sm font-semibold'>
        Password
    </span>
    const editProfile = <span className='items-center text-sm font-semibold'>
        Edit Profile
    </span>


    // HANDLERS
    const toggleSavedAccountInfo = () => {
        setSavedAccountInfo(!savedAccountInfo);
    };
    const toggleUploadFileModal = () => {
        // console.log("just checking")
        setUploadFileModalState(!uploadFileModalState)
    }


    // SIDE EFFECTS    
    // checking for errors
    useEffect(() => {
        if (updateUserError) {
            toast.error(updateUserError, {
                duration: 3000,
            });
        }
    }, [updateUserError]);
    
    //   checking for success
    useEffect(() => {
        if (updateUserSuccess) {
            setUploadFileModalState(false)
            toggleSavedAccountInfo();
            setTimeout(() => {
                setSavedAccountInfo(false);
            }, 2000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateUserSuccess]);

    return (
        <div className='bg-white rounded-[10px] p-[20px_20px_0_20px] w-full flex flex-col gap-10'>

            {/* Profile image and name */}
            <ProfileImage
            toggleUploadFileModal={toggleUploadFileModal}
            uploadFileModalState={uploadFileModalState} />
                    
            {/* Tabs Container */}
            <Tabs 
            activeTab="1" 
            ulClassName="!border-[#ccc]" 
            activityClassName="!text-[#0B8CBA]">
                
                {/* Tab 2 */}
                <Tab 
                title={editProfile} 
                className="mb-2 mr-7 !px-2">
                    <div className="mt-4">
                        
                        {/* Edit Profile Form */}
                        <EditProfileForm />
                    </div>
                </Tab>

                {/* Tab 1 */}
                <Tab 
                title={editPassword} 
                className="mr-3 mb-2 !px-2">
                    <div className="mt-4">
                        
                        {/* account form */}
                        <EditPasswordForm />
                    </div>
                </Tab>
            </Tabs> 

            {/* modal for  saved success message */}
            <MyModal
            disableBtn={true}
            modalIsOpen={savedAccountInfo}
            toggleModal={toggleSavedAccountInfo}>

                <SavedAccountView
                closeModal={toggleSavedAccountInfo} />
            </MyModal>
        </div>
    )
}

export default ProfileCard