import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";

import {
  GET_CLUBS_PACKAGES_REQUEST,
  GET_SPECIFIC_PACKAGE_REQUEST,
} from "../types";

import { clientErrorMessage, delay } from "./reusable";

import {
  getClubPackagesSuccess,
  getClubPackagesFailure,
  getClubPackagesLoading,
  getSpecificPackageSuccess,
  getSpecificPackageFailure,
  getSpecificPackageLoading,
} from "../action";

// ajax axios call
const ajaxDBCalls = {
  getClubPackages: async () => {
    const response = await axios({
      method: "GET",
      url: "club/",
    });

    return response;
  },

  getSpecificPackage: async ({ payload }) => {
    const response = await axios({
      method: "GET",
      url: `club/${payload}`,
    });

    return response;
  },
};

// Workers
function* getClubPackages() {
  try {
    yield put(getClubPackagesLoading(true));

    const response = yield call(ajaxDBCalls.getClubPackages);

    yield put(getClubPackagesSuccess(response.data));
    yield put(getClubPackagesLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("here's what's wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getClubPackagesFailure(errorMessage));
    yield put(getClubPackagesLoading(false));
    yield delay();
    yield put(getClubPackagesFailure(""));
  }
}

function* getSpecificPackage(payload) {
  try {
    yield put(getSpecificPackageLoading(true));

    const response = yield call(ajaxDBCalls.getSpecificPackage, payload);

    yield put(getSpecificPackageSuccess(response.data));
    yield put(getSpecificPackageLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("here's what's wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getSpecificPackageFailure(errorMessage));
    yield put(getSpecificPackageLoading(false));
    yield delay();
    yield put(getSpecificPackageFailure(""));
  }
}

// Watchers
function* getClubPackagesWatcher() {
  yield takeLatest(GET_CLUBS_PACKAGES_REQUEST, getClubPackages);
}
function* getSpecificPackageWatcher() {
  yield takeLatest(GET_SPECIFIC_PACKAGE_REQUEST, getSpecificPackage);
}

// club packages sagas
export default function* clubPackagesSaga() {
  yield spawn(getClubPackagesWatcher);
  yield spawn(getSpecificPackageWatcher);
}
