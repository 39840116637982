import { Field, Formik } from "formik";
import * as Yup from "yup";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormError } from "../Errors";
import BtnPrimary from "../Global/Redesign/BtnPrimary";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerBootcampRequest } from "../../redux/action";
import { digMarketingBootcampRequest } from "../../redux/action/digMarketingBootcamp";

const Form = () => {
  // OBJECT INITIALIZATION
  const dispatch = useDispatch();
  const history = useHistory();

  // STATES
  const [session, setSession] = useState("");
  // redux state
  const {
    registerDigMarkBootcampLoading,
    registerDigMarkBootcampError,
    registerDigMarkBootcampSuccess,
  } = useSelector((state) => {
    const { registerDigMarkBootcampLoading } = state.loadingIndicator;
    const {
      success: { registerDigMarkBootcamp: registerDigMarkBootcampSuccess },
      errors: { registerDigMarkBootcamp: registerDigMarkBootcampError },
    } = state.ajaxStatuses;
    return {
      registerDigMarkBootcampLoading,
      registerDigMarkBootcampError,
      registerDigMarkBootcampSuccess,
    };
  });

  // SIDE EFFECT
  // listen for error or success messages
  useEffect(() => {
    if (registerDigMarkBootcampError) {
      toast.error(registerDigMarkBootcampError, {
        duration: 3000,
      });
    }
  }, [registerDigMarkBootcampError]);

  useEffect(() => {
    if (registerDigMarkBootcampSuccess) {
      history.replace(`/form/digital-marketing-bootcamp2023/success`);
    }
  }, [registerDigMarkBootcampSuccess]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        reason: "",
        session: "",
      }}
      onSubmit={(values) => {
        const finalObj = { ...values };
        finalObj.session = session;

        dispatch(
          digMarketingBootcampRequest({
            userData: finalObj,
          })
        );

        console.log(values);
      }}
      validationSchema={Yup.object({
        firstname: Yup.string().required(),
        lastname: Yup.string().required(),
        email: Yup.string().email().required(),
        mobile: Yup.number().required(),
        reason: Yup.string().required(),
      })}
    >
      {({
        values,
        dirty,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        field,
      }) => {
        return (
          <div className="rounded-[8px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] flex flex-col items-center w-[70%] 2xl:w-[60%] px-24 py-28 mobile:w-full mobile:shadow-none mobile:px-4 md:!px-7 mobile:py-6 gap-8">
            {/*  text */}
            <div className="flex flex-col gap-1 text-center px-3 pc:w-[70%]">
              <p className="text-3xl mobile:text-2xl font-semibold text-[#1D2433]">
                Register for Bootcamp
              </p>

              <p className="font-normal text-base text-[#1D2433]">
                Please enter your correct details as we will use it to
                communicate with you
              </p>
            </div>

            {/* form */}
            <form
              className="w-full pc:w-[70%] flex flex-col gap-8 items-stretch"
              onSubmit={(e) => e.preventDefault()}
            >
              {/* first name */}
              <label className="flex flex-col gap-2">
                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    First name
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="firstname"
                  value={values.firstname}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Enter your firstname"
                />
                {touched.firstname && errors.firstname && (
                  <FormError message={errors.firstname} />
                )}
              </label>

              {/* last name */}
              <label className="flex flex-col gap-2">
                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    Last name
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="lastname"
                  value={values.lastname}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Enter your lastname"
                />
                {touched.lastname && errors.lastname && (
                  <FormError message={errors.lastname} />
                )}
              </label>

              {/* email address */}
              <label className="flex flex-col gap-2">
                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    Email address
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Enter your email"
                />
                {touched.email && errors.email && (
                  <FormError message={errors.email} />
                )}
              </label>

              {/* phone number */}
              <label className="flex flex-col gap-2">
                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    Phone number
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="mobile"
                  value={values.mobile}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Enter your phone number"
                />
                {touched.mobile && errors.mobile && (
                  <FormError message={errors.mobile} />
                )}
              </label>

              {/* Do you have coding knowledge?  */}
              <label className="flex flex-col gap-4">
                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    Sessions
                  </span>
                </div>

                {/* input field */}
                <RadioGroup
                  defaultValue={session}
                  onChange={(e) => setSession(e.target.value)}
                  value={session}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="Morning"
                    control={<Radio />}
                    label="Morning | 11am - 2pm "
                  />

                  <FormControlLabel
                    value="Afternoon"
                    control={<Radio />}
                    label="Afternoon | 3pm - 6pm"
                  />
                </RadioGroup>
              </label>

              {/* reason */}
              <label className="flex flex-col gap-2">
                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    Why do you want to be part of this bootcamp?
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="reason"
                  value={values.reason}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Tell us your reason"
                />
                {touched.reason && errors.reason && (
                  <FormError message={errors.reason} />
                )}
              </label>

              <BtnPrimary
                onClick={handleSubmit}
                type={"Submit"}
                disabled={registerDigMarkBootcampLoading}
                loading={registerDigMarkBootcampLoading}
                title={"Submit"}
              />
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default Form;
