import { useHistory } from "react-router-dom";
import BtnPrimary from "../../../Global/Redesign/BtnPrimary";
import image from "../../../../assets/webpages/training/images/course-image.png"
import GrayCheck from "../../../Global/GrayCheck";
import { FiCheckCircle } from "react-icons/fi";

const CourseCard = ({
    onClick,
    courseImage,
    name,
    pathway,
    duration,
    topics,
    oldPrice,
    newPrice
}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()

    return (
        <div className='flex flex-col [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] rounded-[10px] p-4 gap-4 bg-white w-full h-fit'>
            
            {/* images section */}
            <div className='flex flex-col items-center justify-center gap-4'>

                {/* backend image */}
                <img src={courseImage || image} className="object-cover h-[184px] w-full rounded-[10px] mb-1" alt="" />

                {/* descriptive label */}
                <div className='text-[#3F444D] grid grid-cols-[65%_30%] justify-between gap-4 w-full'>
                    <div className='flex flex-col leading-3 mt-1 gap-1'>
                        <p className='leading-7 font-semibold text-[22px] mt-0'>
                            {
                                name.includes("(")?name.replace("(","\n("):name
                            }
                        </p>
                        <p className='font-normal text-xs text-[#3F444D] mt-0'>
                            {duration} Months Course
                        </p>
                    </div>

                    {/* button */}
                    <button className='h-[32px] bg-[#E1E6EF] px-3 rounded-[8px] font-bold text-[11px] cursor-default w-fit ml-auto'>
                        {pathway}
                    </button>
                </div>
            </div>

            {/* package info */}
            <div className='flex justify-between px-3'>
                
                    {/* Technology */}
                    <div className="container mx-auto grid justify-items-center grid-cols-[auto_auto] justify-between items-center gap-3 mb-1 py-4">
                        {
                            // data.course_id.topics
                            topics.map(({sub_topics}, index)=>(

                                // container
                                <div 
                                className='flex justify-start w-full items-center gap-2 m-auto' key={index}>

                                    {/* check circle */}
                                    <span 
                                    className='min-w-[24px] bg-[#F8F9FC] flex justify-center items-center rounded-[50%] min-h-[24px]'>
                                        <FiCheckCircle 
                                        className="text-sm text-gray-800" />
                                    </span>

                                    {/* text */}
                                    <span 
                                    className='font-semibold text-xs text-[#667085]'>
                                        {sub_topics.replace("CONTENT","")}
                                    </span>
                                </div> 
                            ))
                        }     
                    </div>           
            </div>

            {/* amount */}
            <div className='flex flex-col justify-center items-center gap-1'>
                <span className='font-bold text-gray-400 text-sm line-through'>
                    NGN {oldPrice.toLocaleString('en-US')}
                </span>

                <div className='flex justify-center items-center gap-3'>
                    <p className='text-gray-600 font-semibold text-[22px] text-center'>
                        NGN {newPrice.toLocaleString('en-US')}
                    </p>

                    {/* label */}
                    <span className='bg-[#E02D3C] h-[22px] inline-flex justify-center items-center font-semibold text-[10px] leading-[14px] px-3 rounded-[8px] text-white'>
                        Promo price
                    </span>
                </div>
            </div>

            {/* button */}
            <div className='flex items-stretch flex-col gap-3'>
                <button 
                onClick={onClick}
                className="text-sm font-bold text-[#055774] h-[44px] rounded-[8px] hover:opacity-80 border border-[#0B8CBA]">
                    Learn more
                </button>

                <BtnPrimary
                onClick={()=>history.push(`/registration`)}
                title="Get started now" />
            </div>
        </div>
    )
}

export default CourseCard;