import { useSelector } from "react-redux";
import { BiUserCircle } from "react-icons/bi";

const ProfileDisplayInfo = () => {

    // STATES
    const { user } = useSelector((state) => state.userData);

    return (
        <div className="flex justify-between items-center gap-[10px]">

            {/* container */}
            <div className="w-[40px] h-[40px] rounded-[50%] bg-white relative flex items-center justify-center">

                {
                    user.avatar ? 

                        // display image
                        <img
                        src={user.avatar}
                        className="w-full h-full rounded-[50%] object-top object-cover [border:4px_solid_white] [filter:drop-shadow(0px_2px_8px_rgba(0,0,0,0.12))]"
                        alt="" />:
                        <BiUserCircle size={40} />
                }

                <span className="h-[10px] w-[10px] rounded-[50%] bg-green-600 absolute bottom-[0] right-[0] z-[1000]" />
            </div>

            <div className="flex flex-col">
                <span className="font-semibold text-sm text-[#1D2433]">
                    {`${user.firstname || ""} ${user.lastname || "Loading..."}`}
                </span>

                <span className="font-normal text-sm text-[#1D2433] opacity-[0.6]">
                    {user.email || "Loading..."}
                </span>
            </div>
        </div>
    )
}

export default ProfileDisplayInfo