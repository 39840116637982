import React from 'react'
import { useSelector } from 'react-redux';
import BootcampsBody from '../../../../components/Redesign/Dashboard/Bootcamps/BootcampsBody'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'

const Bootcamps = () => {
            
    // STATES
    const { firstname } = useSelector((state) => {
        const {
            user: { firstname },
        } = state.userData;
        return {
            firstname
        };
    });

    return (
        <PageWrapper
        title={`Hello ${firstname}`}
        subTitle={"Happy to see you back!"}>

            {/* body section */}
            <BootcampsBody />

        </PageWrapper>
    )
}

export default Bootcamps