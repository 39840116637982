import React from 'react'

const PathwayCard = ({
    pathway,
    name,
    duration,
    oldPrice,
    newPrice,
    onClick,
    isSelected
}) => {
    return (
        <div 
        onClick={onClick}
        className={`cursor-pointer flex flex-col items-center min-w-[217px] bg-white rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] gap-5 snap-center [transition:border-color.4s_linear] p-[9px] my-2 border ${isSelected?"border-[#0B8CBA]":"border-white"}`}>
            
            {/* label*/}
            <span className='rounded-[8px] bg-[#E1E6EF] flex justify-center items-center text-[#3F444D] h-[32px] font-semibold text-[10px] px-4'>
                {pathway}
            </span>

            {/* bootcamp info container */}
            <div className='flex flex-col text-wrap text-center w-full'>
                <p className='font-semibold text-sm text-[#3F444D]'>
                    {name}
                </p>
                <p className='font-normal text-xs text-[#3F444D]'>
                    {duration} Months Course
                </p>
            </div>

            {/* pricings container*/}
            <div className='flex flex-col gap-3 mt-auto w-full'>
                <div className='flex flex-col text-center items-center w-full gap-1'>
                    <p className='text-gray-400 font-semibold text-sm line-through'>
                        NGN {oldPrice.toLocaleString("en-US")}
                    </p>

                    <div className='flex justify-center items-center gap-1'>
                        <p className='text-gray-600 font-bold text-base text-center'>
                            NGN {newPrice.toLocaleString("en-US")}
                        </p>

                        {/* label */}
                        <span className='bg-[#E02D3C] h-[22px] inline-flex justify-center items-center font-semibold text-[10px] leading-[14px] px-1.5 rounded-[8px] text-white'>
                            Promo price
                        </span>
                    </div>
                </div>

                {/* select button */}
                <div className='flex flex-col items-stretch w-full'>
                    <span
                    className={`flex items-center justify-center font-semibold text-base w-full rounded-[8px] border [transition:.4s_linear] [transition-property:background_color] border-[#055774] h-[44px] ${isSelected?"bg-[#0B8CBA] text-white":"bg-white text-[#0B8CBA]"}`}
                    role={"button"}>
                        {isSelected?"Selected":"Select"}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default PathwayCard