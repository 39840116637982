import React from 'react'
import Accordion from '../../Global/Accordion'

const CourseOutline = ({topics}) => {

    return (
        <div className='flex flex-col gap-5 justify-between bg-white rounded-[10px] [box-shadow:0_2px_8px_rgb(0,0,0,0.12)] py-7 px-8 mt-8'>
                <p className='text-[#3f444d] text-xl font-semibold'>
                    Course outline
                </p>

                {/* <Accordion /> */}
                {
                    topics?.map(({sub_topics, content, _id})=>(
                        <Accordion
                        courseItems={content.split("%")}
                        couseTitle={sub_topics}
                        key={_id} />
                    ))
                }
        </div>
    )
}

export default CourseOutline