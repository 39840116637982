import React from 'react'
import HowItWorks from '../../TheClub/AvailablePackages/HowItWorks'
import image from "../../../../../assets/redesign-dashboard/investing/images/jumbotron-image.png"
import InvestmentCard from '../InvestmentCard'
import { IoIosArrowForward } from 'react-icons/io'
import { useHistory, useLocation } from 'react-router-dom'
import PackageFeature from './PackageFeature'

const AboutInvestmentBody = () => {

    // DATA INITIALIZATION
    const history = useHistory()

    return (
        <div className='flex mobile:flex-col w-full h-full gap-10'>

            {/* section filled with image and text details */}
            <div className='h-full w-[70%] mobile:w-full flex flex-col gap-10'>

                {/* investment info */}
                <div className='w-full flex flex-col gap-4'>

                    {/* image */}
                    <img src={image} className='w-full object-cover' alt="" />
                    
                    {/* text descriptions */}
                    <div className='flex flex-col w-full'>
                        <p className='text-gray-800 font-normal text-base'>
                            AI Movement is focused on discovering, funding, and supporting the world’s most promising startups. Fewer than 2% of the startups we review are made available for investment on our platform
                        </p>

                        <p className='text-gray-800 font-normal text-base'>
                            Gain access to, review, and invest in the best startups via our web and mobile-enabled startup investing platform. After investing, keep up to date with news, updates and portfolio analysis features. Easily diversify your portfolio with low minimums.
                        </p>
                    </div>
                </div>
                
                {/* advanced package features */}
                <PackageFeature />
                
            </div>

            {/* upcomoing event section */}
            <div className='h-full w-[30%] mobile:w-full flex flex-col'>
                <HowItWorks
                infoList={[
                    {
                        id: 1,
                        message: "Select your preferred project/startup"
                    },
                    {
                        id: 2,
                        message: "Make Payment to back the project/startup."
                    },
                    {
                        id: 3,
                        message: "Enjoy the recurring revenue"
                    },
                ]} />
            </div>
        </div>
    )
}

export default AboutInvestmentBody