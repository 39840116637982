import React from "react";
import { CircularProgress } from "@material-ui/core";

const BtnSm = ({ title, loading, onClick, disabled }) => {
  return (
    <>
      <button
        disabled={disabled}
        className="h-[44px] mobile:h-[48px] bg-[#7F56D9] flex justify-center items-center font-semibold text-base lg:text-xs text-white px-7 rounded-[8px] hover:opacity-80 disabled:opacity-50 disabled:cursor-not-allowed"
        onClick={onClick}
      >
        {loading ? (
          <div className="text-center">
            <CircularProgress color={"inherit"} size="20px" />
          </div>
        ) : title ? (
          title
        ) : (
          "Submit"
        )}
      </button>
    </>
  );
};

export default BtnSm;
