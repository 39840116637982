import React from 'react'
import GreenCheck from '../../../../Global/GreenCheck'

const SpaceInfo = () => {
    return (
        <div className='border border-[#D4F4FF] gap-4 p-5 rounded-[10px] mobile:mx-4'>

            {/* caption */}
            <p className='text-gray-600 text-base font-medium'>
                Kindly note that all spaces listed come with the following primary benefits:
            </p>

            {/* list of information regarding the spaces fetched */}
            <div className='flex items-center gap-2 mt-2'>

                {/* check */}
                <GreenCheck />

                {/* text */}
                <p className='text-gray-500 text-sm font-normal'>
                    Free high speed WiFi
                </p>

            </div>

            <div className='flex items-center gap-2 my-1'>

                {/* check */}
                <GreenCheck />

                {/* text */}
                <p className='text-gray-500 text-sm font-normal'>
                    Access to all in-house facilities
                </p>

            </div>

            <div className='flex items-center gap-2'>

                {/* check */}
                <GreenCheck />

                {/* text */}
                <p className='text-gray-500 text-sm font-normal'>
                    Free cup of coffee
                </p>
                
            </div>
        </div>
    )
}

export default SpaceInfo