import React from 'react'
import AllAvailableStartups from './AllAvailableStartups'
import MyPortfolio from './MyPortfolio'

const ActiveInvestmentBody = () => {
    return (
        <div className='w-full gap-10 flex flex-col'>

            {/* portfolio */}
            <MyPortfolio />

            {/* AllAvailable and vetted startups */}
            <AllAvailableStartups />
            
        </div>
    )
}

export default ActiveInvestmentBody