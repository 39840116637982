import React from 'react'
import DynamicCheckIcon from '../Dashboard/Global/DynamicCheckIcon'
import BtnSm from '../Global/BtnSm'
import { useHistory } from 'react-router-dom'

const ClubOfferCard = ({clubPackage}) => {

    // history object
    const history = useHistory()

    // collection of offers/options
    const options = [
        "Networking Event",
        "Member Swag",
        "Newsletter",
        "Reserved Office Space",
        "Discounted Services",
        "Investment Pitch",
        "Investment Database",
        "Business Consultancy",
        "Foreign Tech Consultancy",
        "Life Insurance",
    ]

    // packages keys
    const keys = [
        "networking_event",
        "member_swag",
        "news_letter",
        "reserved_office_space",
        "discounted_services",
        "investment_pitch",
        "investment_database",
        "business_consultancy",
        "foreign_tech_consultancy",
        "life_insurance",
    ]

    return (
        <div className='flex flex-col [box-shadow:0px_2px_8px_#e0e0e0] rounded-[10px] p-3 gap-4'>
            
            {/* package name */}
            <div className='flex flex-col items-center justify-center gap-4 pb-2 pt-6'>
                <p className='text-[#3F444D] text-2xl font-semibold'>
                    {clubPackage.name} Package
                </p>
            </div>

            {/* package info */}
            <div className='flex justify-between px-3 my-2'>

                {/* Listing */}
                <div className='grid grid-cols-2 text-[#667085] font-semibold text-xs justify-start items-center gap-3'>

                    {
                        options.map((option, index)=>(
                            <p 
                            key={index}
                            className='flex items-center justify-center gap-2 m-0 mr-auto'>
                                <DynamicCheckIcon 
                                isAvailable={clubPackage[keys[index]]} />
                                {option}
                            </p>
                        ))
                    }
                    
                </div>
            </div>

            {/* amount */}
            <p className='text-[#6c717a] font-semibold text-xl leading-[24px] text-center'>
                NGN {clubPackage.amount.toLocaleString('en-US')} <span className='font-normal text-base'>/yr</span>
            </p>

            {/* button */}
            <div className='flex items-stretch flex-col'>
                <BtnSm 
                onClick={()=>history.push("/old/login")}
                title="Become a member" />
            </div>
        </div>
    )
}

export default ClubOfferCard