import React from 'react'
import { Link } from 'react-router-dom'
import BtnPrimary from '../../../../Global/Redesign/BtnPrimary'

const Form = () => {
    return (
        <div
        className={"flex flex-col gap-3 justify-center items-center w-full h-full"}>

            {/* form */}
            <form 
            className="flex flex-col justify-center items-center w-full gap-5">

                {/* FORM CONTROLS */}
                {/* first name */}
                <label className="flex flex-col mobile:w-full w-[90%] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        First name
                    </span>

                    <input
                    type="text"
                    // {...formik.getFieldProps("firstname")}
                    className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                    placeholder="Enter your first name" />

                    {/* {formik.errors.firstname && formik.touched.firstname && (
                        <FormError message={formik.errors.firstname} />
                    )} */}
                </label>

                {/* last name */}
                <label className="flex flex-col mobile:w-full w-[90%] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        Last name
                    </span>

                    <input
                    type="text"
                    // {...formik.getFieldProps("lastname")}
                    className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                    placeholder="Enter your last name" />

                    {/* {formik.errors.lastname && formik.touched.lastname && (
                        <FormError message={formik.errors.lastname} />
                    )} */}
                </label>

                {/* email address */}
                <label className="flex flex-col mobile:w-full w-[90%] gap-2">
                    <span className="text-[#344054] text-sm font-semibold">Email</span>

                    <input
                    type="email"
                    // {...formik.getFieldProps("email")}
                    className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                    placeholder="Enter your email" />

                    {/* {formik.errors.email && formik.touched.email && (
                        <FormError message={formik.errors.email} />
                    )} */}
                </label>

                {/* phone number */}
                <label className="flex flex-col mobile:w-full w-[90%] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        Phone number
                    </span>

                    <input
                        type="text"
                        // {...formik.getFieldProps("mobile")}
                        className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                        placeholder="Enter your phone number"
                    />

                    {/* {formik.errors.mobile && formik.touched.mobile && (
                        <FormError message={formik.errors.mobile} />
                    )} */}
                </label>

                {/* password */}
                <label className="flex flex-col mobile:w-full w-[90%] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        Password
                    </span>

                    <div className="flex flex-col gap-1">

                        <input
                        type="password"
                        // {...formik.getFieldProps("password")}
                        className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                        placeholder="Create a password"
                        />
                        <span className="font-normal text-sm text-[#667085]">
                            Must be at least 8 characters.
                        </span>

                    </div>

                    {/* {formik.errors.password && formik.touched.password && (
                        <FormError message={formik.errors.password} />
                    )} */}
                </label>

                {/* sign up button */}
                <div className="flex flex-col items-stretch mobile:w-full w-[90%] gap-5">

                    {/* sign up */}
                    <BtnPrimary
                        // onClick={formik.handleSubmit}
                        // loading={registerLoading}
                        title={"Sign up"}
                    />

                    {/* google signin button */}
                    {/* <GoogleSignInBtn title={"Sign up with Google"} /> */}
                    
                </div>
            </form>
        </div>
    )
}

export default Form