import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";

import { clientErrorMessage, delay } from "./reusable";

import { 
    GET_EVENTS_FOR_USERS_REQUEST,
    GET_USER_EVENTS_REQUEST,
    GET_SINGLE_EVENT_REQUEST, 
    PUT_USER_TO_EVENT_REQUEST
} from "../types";

import { 
    getEventsForUsersSuccess,
    getEventsForUsersLoading,
    getEventsForUsersFailure,
    getUserEventsSuccess,
    getUserEventsLoading,
    getUserEventsFailure,
    getSingleEventSuccess,
    getSingleEventLoading,
    getSingleEventFailure, 
    putUserToEventLoading,
    putUserToEventSuccess,
    putUserToEventFailure
} from "../action";

// ajaxDBCall
const ajaxDBCalls = {

    // get events for users
    getEventsForUsers: async () => {
        const reponse = await axios({
            method: "GET",
            url: "event/users"
        })
        return reponse
    },

    // get users events
    getUserEvents: async ({payload}) => {
        const response = await axios({
            method: "GET",
            url: "event/userevents",
            headers: {
                "Content-Type": "application/json",
                'Authorization':`Bearer ${payload}`
            }
        })
        return response
    },

    // get single event
    getSingleEvent: async ({payload}) => {
        const response = await axios({
            method: "GET",
            url: `event/singleevent/${payload}`
        })
        return response
    },

    // put user to event
    putUserToEvent: async ({payload: { formData, eventId}}) => {
        console.log(formData, eventId);
        const response = await axios({
        url: `event/user/${eventId}`,
        method: "PUT",
        data: formData,
        });
        return response;
    }
}

// WORKERS
// get events for users
function* getEventsForUsers() {
    try {
        yield put(getEventsForUsersLoading(true))

        const response = yield call(ajaxDBCalls.getEventsForUsers)

        yield put(getEventsForUsersSuccess(response.data));
        yield put(getEventsForUsersLoading(false));
    } catch(err) {
        let errorMessage = ""
        if(err.request) errorMessage = clientErrorMessage;

        if(err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(getEventsForUsersFailure(errorMessage));
        yield put(getEventsForUsersLoading(false))
        yield delay()
        yield put(getEventsForUsersFailure(""))
    }
}

// get user events
function* getUserEvents(payload) {
    try {
        yield put(getUserEventsLoading(true))

        const response = yield call(ajaxDBCalls.getUserEvents, payload)

        yield put(getUserEventsSuccess(response.data));
        yield put(getUserEventsLoading(false));
    } catch(err) {
        let errorMessage = ""
        if(err.request) errorMessage = clientErrorMessage;

        if(err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(getUserEventsFailure(errorMessage));
        yield put(getUserEventsLoading(false))
        yield delay()
        yield put(getUserEventsFailure(""))
    }
}

function* getSingleEvent(payload) {
    try {
        yield put(getSingleEventLoading(true))

        const response = yield call(ajaxDBCalls.getSingleEvent, payload)

        yield put(getSingleEventSuccess(response.data))
        yield put(getSingleEventLoading(false))
    } catch (err) {
        let errorMessage = ""
        if(err.request) errorMessage = clientErrorMessage;

        if(err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(getSingleEventFailure(errorMessage))
        yield put(getSingleEventLoading(false))
        yield delay()
        yield put(getSingleEventFailure(""))
    }
}

function* putUserToEvent(payload) {
    try {
        yield put(putUserToEventLoading(true));

        const response = yield call(ajaxDBCalls.putUserToEvent, payload)

        yield put(putUserToEventSuccess(response.data))
        yield put(putUserToEventLoading(false))
        yield delay()
        yield put(putUserToEventSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;

        if (err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(putUserToEventFailure(errorMessage))
        yield put(putUserToEventLoading(false))
        yield delay();
        yield put(putUserToEventFailure(""))
    }
}

// WATCHERS
function* getEventsForUsersWatcher() {
    yield takeLatest(GET_EVENTS_FOR_USERS_REQUEST, getEventsForUsers)
}

function* getUserEventsWatcher() {
    yield takeLatest(GET_USER_EVENTS_REQUEST, getUserEvents)
}

function* getSingleEventWatcher() {
    yield takeLatest(GET_SINGLE_EVENT_REQUEST, getSingleEvent)
}

function* putUserToEventWatcher() {
    yield takeLatest(PUT_USER_TO_EVENT_REQUEST, putUserToEvent)
}

// events saga
export default function* eventsSaga() {
    yield spawn(getEventsForUsersWatcher);
    yield spawn(getUserEventsWatcher);
    yield spawn(getSingleEventWatcher);
    yield spawn(putUserToEventWatcher);
}