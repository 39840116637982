import React from 'react'
import BtnPrimary from '../../../../Global/Redesign/BtnPrimary'

const PaymentCard = () => {
    return (
        <div className='border border-[#E1E6EF] flex flex-col gap-4  py-6 px-5 rounded-[10px] bg-white'>

            {/* caption text */}
            <p className='font-semibold text-xl text-[#3F444D]'>
                How much do you want to fund with?
            </p>

            <label className='flex flex-col gap-2'>

                {/* label text */}
                <span className='font-semibold text-sm text-[#344054]'>
                    Enter amount here
                </span>

                {/* input field */}
                <div className='flex items-center border border-[#d0d5dd] rounded-[8px] bg-white outline-[#d0d5dd]'>

                    <span className='font-normal text-base text-[#101828] h-[44px] border-r border-[#D0D5DD] px-3 flex items-center justify-center'>
                        NGN
                    </span>

                    <input 
                    type="text" 
                    className=' text-base font-normal outline-none w-full h-[44px] px-3 bg-transparent placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                    placeholder='min of NGN50,000' />
                </div>
            </label>

            <p className='font-semibold text-base text-gray-600'>
                By completing payment you agree to our terms of service
            </p>

            <div className='flex flex-col items-stretch'>
                <BtnPrimary title={"Make Payment"} />
            </div>

        </div>
    )
}

export default PaymentCard