import React from 'react'

const ShowcaseCard = ({image, caption, drop}) => {
    return (
        <div className={`flex flex-col bg-white gap-3 py-4 px-3 [box-shadow:0px_8px_32px_rgba(0,0,0,0.16)] w-[318px] mobile:w-full rounded-[8px] h-fit ${drop?"pc:relative pc:top-[13%]":""}`}>

            {/* images */}
            <div className='w-[294px] h-[400px] mobile:w-full flex justify-center items-center rounded-[10px]'>

                <img src={image} className="rounded-[10px] w-full h-full object-cover" alt="" />

            </div>

            {/* caption */}
            <p className='font-normal text-base text-center'>
                {caption}
            </p>
        </div>
    )
}

export default ShowcaseCard