import { useState } from 'react'
import Jumbotron from '../../../../Dashboard/VentureFunding/ViewDetails/Jumbotron'
import MainSection from '../../../../Dashboard/VentureFunding/ViewDetails/MainSection'
import FixedNoticeBoard from '../../Bootcamps/FixedNoticeBoard'
import AllAvailableStartups from '../ActiveInvestment/AllAvailableStartups'
import Disclaimer from '../ActiveInvestment/Disclaimer'
import FixedBackProjectBtn from './FixedBackProjectBtn'
import PaymentInfoCard from './PaymentInfoCard'

const SingleInvestmentBody = () => {

    // STATES
    const [hasScrolled, setHasScrolled] = useState(false)

    return (
        <div className='w-full mt-12 flex flex-col gap-10'>

            {/* mobile:view */}
            <div className='items-center justify-center w-full hidden mobile:flex'>
                <PaymentInfoCard />
            </div>

            {/* Preview */}
            <Jumbotron />

            <div className='grid grid-cols-[2fr_1fr] mobile:grid-cols-1 gap-10'>

                <MainSection />

                {/* pc:view */}
                <div className='flex items-start justify-center w-full mobile:hidden'>
                    <PaymentInfoCard />
                </div>
            </div>

            {/* View all available investments */}

            <div className={`items-start w-full flex-col gap-5 ${hasScrolled?"mobile:mb-20":""}`}>

                {/* all available startups */}
                <AllAvailableStartups
                disableFilterCard={true} />

                {/* disclaimer */}
                <div className='w-full hidden mobile:flex items-start h-fit'>
                    <Disclaimer />
                </div>
            </div>

            {/* Fixed notice Board */}
            <FixedNoticeBoard
            hasScrolled={hasScrolled}
            setHasScrolled={setHasScrolled}
            id={"paymentInfoCard"}>
                <FixedBackProjectBtn />
            </FixedNoticeBoard>
        </div>
    )
}

export default SingleInvestmentBody