import React from 'react'
import { IoIosArrowBack } from "react-icons/io"
import { useHistory } from 'react-router-dom'

const BackBtn = () => {

    // OBJECT INITIALIZATION
    // history object
    const history = useHistory()

    return (
        <div
        onClick={()=>history.goBack()} 
        className='cursor-pointer hover:opacity-60 inline-flex items-center gap-2 text-[#0B8CBA]'>

            {/* back button */}
            <IoIosArrowBack className="text-2xl" />

            {/* text */}
            <span className='font-semibold text-sm'>
                Back
            </span>
        </div>
    )
}

export default BackBtn