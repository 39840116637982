import React, { useState } from 'react'
import BtnPrimary from '../../../../Global/Redesign/BtnPrimary'
import PayStackBtn from '../../../../Global/PayStackBtn'
import { useDispatch, useSelector } from 'react-redux'
import { spacePaymentRequest } from '../../../../../redux/action'
import { isEmpty } from '../../../../../helpers/isEmtpy'

const SummaryCard = ({
    price,
    spaceId,
    startDate,
    endDate,
    duration,
}) => {

    // STATES
    const [paystackSuccess, setPaystackSuccess] = useState(false)
    const {
        spacePaymentLoading,
        user
    } = useSelector((state) => {
        const { user } = state.userData;
        const { 
            spacePaymentLoading
        } = state.loadingIndicator;
    
        return {
            spacePaymentLoading,
            user
        };
    });


    // DATA INITIALIZATION
    const dispatch = useDispatch()
    const componentProps = {
        email: user.email,
        amount: Number((duration*price)),
        metadata: {
            name: `${user.firstname} ${user.lastname}`,
            phone: user.phone
        },
        publicKey: `${process.env.REACT_APP_TEST_PAYSTACK_KEY}`,
        text: "Make payment",
        onSuccess: (e) => {
            setPaystackSuccess(true)
            dispatch(spacePaymentRequest({
                data: {
                    email: user.email,
                    reference: e.reference,
                    start_date: startDate,
                    end_date: endDate
                },
                spaceId: spaceId
            }))
        },
        onClose: () => {},
    };

    return (
        <div className='w-full bg-white border border-[#E1E6EF] gap-4 p-5 flex flex-col rounded-[10px]'>

            <p className='mt-0 text-[#1D2433] font-semibold text-base'>
                Summary
            </p>

            <div className='flex items-center gap-4 border-b border-[#E1E6EF] pb-5'>
                <p className='mt-0 font-bold text-base text-[#0B8CBA]'>
                    Promo price:
                </p>

                <p className='mt-0 text-gray-600 text-base font-bold'>
                    NGN {price.toLocaleString("en-US")}
                </p>
            </div>

            <p className='mt-0 w-full font-normal text-base text-gray-600'>
                By completing payment you agree to our terms of service
            </p>

            <div className='flex flex-col items-stretch w-full'>
                {
                    paystackSuccess?
                    <BtnPrimary
                    title={"Make payment"}
                    loading={spacePaymentLoading} />:
                    <PayStackBtn
                    disabled={isEmpty(user) || isEmpty(price) || isEmpty(duration)}
                    componentProps={componentProps} />
                }
            </div>
        </div>
    )
}

export default SummaryCard