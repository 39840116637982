import { createTheme, FormControlLabel, Radio, RadioGroup, ThemeProvider } from '@material-ui/core'
import React, { useState } from 'react'

const FilterCard = () => {

    // DATA INITIALIZATION
    const theme = createTheme({
        typography: {
            fontFamily: "poppins, sans-serif",
        },
        palette: {
            primary: {
                main: "#0B8CBA",
            },
        },
    });


    // STATES
    const [popularity, setPopularity] = useState()
    const [category, setCategory] = useState()

    return (
    <div className='bg-white flex flex-col p-5 gap-6 rounded-[8px] w-full'>
            
            {/* caption */}
            <p className='text-[#3F444D] font-bold text-base mt-0'>
                Filter
            </p>

            {/* popularity */}
            <div className='flex flex-col py-5 border-t border-[#E1E6EF] gap-2'>

                {/* caption */}
                <p className='text-[#0B8CBA] font-semibold'>
                    Popularity
                </p>

                {/* input field */}
                <ThemeProvider theme={theme}>

                    <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={popularity}
                    onChange={(e) => setPopularity(e.target.value)}
                    name="radio-buttons-group">
                            
                        <FormControlLabel
                        value="Trending"
                        control={<Radio color="primary" />}
                        label="Trending" />

                        <FormControlLabel
                        value="Newest"
                        control={<Radio color="primary" />}
                        label="Newest" />

                        <FormControlLabel
                        value="Oldest"
                        control={<Radio color="primary" />}
                        label="Oldest" />
                    </RadioGroup>
                </ThemeProvider>
            </div>

            {/* category */}
            {/* <div className='flex flex-col py-5 border-t border-[#E1E6EF] gap-2'>

                <p className='text-[#0B8CBA] font-semibold'>
                    Category
                </p>

                <ThemeProvider theme={theme}>

                    <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    name="radio-buttons-group">

                        <FormControlLabel
                        value="Medical"
                        control={<Radio color="primary" />}
                        label="Medical" />

                        <FormControlLabel
                        value="Environment"
                        control={<Radio color="primary" />}
                        label="Environment" />

                        <FormControlLabel
                        value="Engineering"
                        control={<Radio color="primary" />}
                        label="Engineering" />

                        <FormControlLabel
                        value="Education"
                        control={<Radio color="primary" />}
                        label="Education" />

                        <FormControlLabel
                        value="VC Backed"
                        control={<Radio color="primary" />}
                        label="VC Backed" />
                    </RadioGroup>
                </ThemeProvider>
            </div> */}
        </div>
    )
}

export default FilterCard