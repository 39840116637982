import React from 'react'

const InvestorsCard = ({image, name, designation, description}) => {
    return (
        <div className='flex flex-col border border-[#E1E6EF] p-3 gap-4 bg-white rounded-[10px]'>
            
            {/* image */}
            <div className='w-full h-[142px]'>
                <img src={image} className='object-cover h-full w-full rounded-[10px]' alt="" />
            </div>

            <div className='flex flex-col gap-1'>
                <p className="mt-0 text-[#1D2433] font-medium text-base">
                    {name}
                </p>

                <p className="mt-0 text-[#1D2433] text-sm font-normal">
                    {designation}
                </p>
            </div>

            {/* personal description */}
            <p className='font-normal text-xs text-gray-800'>
                {description}
            </p>
        </div>
    )
}

export default InvestorsCard