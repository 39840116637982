import { Skeleton } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import ImageViewerModal from '../../../../Dashboard/Events/ImageViewerModal'
import ViewEventImage from '../../../../Dashboard/Events/ViewEventImage'
import ToggleViewImage from './ToggleViewImage'

const Jumbotron = ({loading, image, notEmpty}) => {

    // STATES
    const [imageModalState, setImageModalState] = useState(false);


    // HANDLERS
    const toggleImageModal = () => {
        setImageModalState(!imageModalState);
    };


    return (
        <>
            {
                loading?
                <div className="flex items-center justify-center rounded-[20px] h-[287px] mobile:h-[130px] w-full">
                    <Skeleton
                    variant="rectangular"
                    sx={{
                    zIndex: "1200",
                    width: "100%",
                    height: "100%",
                    borderRadius: "20px",
                    }} />
                </div>:
                notEmpty?
                <div className='w-full h-[287px] rounded-[10px] mobile:h-[130px] relative'>

                    <img 
                    src={image}
                    className='w-full h-full object-cover object-[20%_13%] rounded-[10px]' 
                    alt="" />

                    {/* dark transparent bg */}
                    <span className="absolute h-full w-full bg-black opacity-40 z-[1] rounded-[10px] top-0 left-0">
                        {""}
                    </span>

                    <ToggleViewImage
                    handleClick={toggleImageModal} />

                    {/* modal for viewing and downloading image */}
                    <ImageViewerModal
                        modalIsOpen={imageModalState}
                        toggleModal={toggleImageModal}
                        disableBtn={true}
                    >
                        <ViewEventImage
                        closeModal={toggleImageModal} 
                        image={image} />
                    </ImageViewerModal>
                    
                </div>:
                <div className='flex justify-center items-center w-full h-[287px] mobile:h-[130px] text-xl rounded-[10px]'>Nothing Here</div>
            }
        </>
    )
}

export default Jumbotron