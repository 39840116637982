import React from 'react'
import AdvancedPack from './AdvancedPack'
import BasicPackage from './BasicPack'
import IntermidiatePack from './IntermidiatePack'
import shape1 from "./../../assets/club/svg/offer-shape1.svg"
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getClubPackagesRequest } from '../../redux/action'
import { useEffect } from 'react'
import { Skeleton } from '@mui/material'
import ClubOfferCard from './ClubOfferCard'

const Offers = () => {

    // collection of offers/options
    const options = [
        "Member Swag",
        "Newsletter",
        "Reserved Office Space",
        "Discounted Services",
        "Investment Pitch",
        "Investment Database",
        "Business Consultancy",
        "Foreign Tech Consultancy",
        "Life Insurance"
    ]

    // packages keys
    const keys = [
        "member_swag",
        "news_letter",
        "reserved_office_space",
        "discounted_services",
        "investment_pitch",
        "investment_database",
        "business_consultancy",
        "foreign_tech_consultancy",
        "life_insurance",
    ]

    // dispatch object
    const dispatch = useDispatch()

    // club packages states
    const {
        clubsPackages,
        clubsPackagesLoading
    } = useSelector((state)=>{
        const {clubsPackages} = state.clubPackagesReducer
        const {clubsPackagesLoading} = state.loadingIndicator
        return {
            clubsPackages,
            clubsPackagesLoading
        }
    })

    useEffect(()=>{
        dispatch(getClubPackagesRequest())
    }, [])

    return (
        <div className='flex flex-col py-36 gap-12 mobile:py-16 mobile:pb-6'>
            
            {/* text */}
            <div className='flex flex-col items-center justify-center text-center gap-1 relative px-6 mobile:px-4'>

                {/* patter shape */}
                <img src={shape1} className="mobile:w-[20px] mobile:top-[1rem] mobile:right-[2rem] absolute right-[34%] top-[-6%]" alt="" />

                <p className='text-[#8B54F7] mobile:text-xs text-base font-semibold'>
                    Offers
                </p>
                <p className='font-semibold mobile:text-2xl text-4xl leading-[54px] mb-2 text-black'>
                    Club membership options
                </p>
                <p className='font-normal text-base text-[#6c717a] md:px-24'>
                    Select the package below that suits your needs, click on become a member, fill the form, pay the membership fee and upload proof of <br/>payment.
                </p>
            </div>

            {/* packages */}
            <div className="flex justify-center items-start gap-12 px-8 mobile:flex-col mobile:px-6 mobile:items-center">

                {
                    clubsPackagesLoading?
                    <>
                    <Skeleton 
                    variant="rectangular" 
                    sx={{ 
                        width: '70%', 
                        height: "40vh",
                        borderRadius: "10px"
                    }} />
                    <Skeleton 
                    variant="rectangular" 
                    sx={{ 
                        width: '70%', 
                        height: "40vh",
                        borderRadius: "10px"
                    }} />
                    <Skeleton 
                    variant="rectangular" 
                    sx={{ 
                        width: '70%', 
                        height: "40vh",
                        borderRadius: "10px"
                    }} />
                    </>: 
                    !(clubsPackages.length===0)?
                    clubsPackages.map((clubPackage)=>(
                        <ClubOfferCard 
                        clubPackage={clubPackage} 
                        key={clubPackage._id} />
                    )):<div></div>
                }
            </div>
        </div>
    )
}

export default Offers