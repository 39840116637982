import React from 'react'
import Header2 from '../../../../layouts/Redesign/Header2'
import shape1 from "../../../../assets/webpages/training/images/e-shape1.png"
import shape2 from "../../../../assets/webpages/training/images/e-shape2.png"

const TrainingHead = () => {
    return (
        <div className='flex items-center justify-start bg-[#055774] h-[70vh] mobile:h-[60vh] relative flex-col top-0'>

            {/* header */}
            <Header2
            shadowStyle='none' />

            {/* SHAPES */}
            {/* shape1 */}
            <img src={shape1} className="mobile:w-[20px] absolute top-[28%] left-[14%] mobile:left-[2rem] mobile:top-73.5rem]" alt="" />

            {/* shape2 */}
            <img src={shape2} className="mobile:w-[20px] absolute top-[28%] right-[18%] mobile:right-[2rem] mobile:top-[7.5rem]" alt="" />

            {/* jumbotron */}
            <div className='w-full flex flex-col items-center top-[20%] mobile:top-[25%] relative z-[200] gap-4 text-center container mx-auto mobile:px-4'>

                <p className='font-semibold text-[45px] mobile:text-4xl text-white'>
                    Education is always the key
                </p>

                <p className='text-white text-2xl mobile:text-[22px] font-normal'>
                    We are here to take your tech education to the next level
                </p>

                <p className='text-white text-base font-normal pc:w-[45%] mb-4'>
                    It doesn’t matter if you are a novice, amateur, intermediate or expert. We have packages for everyone
                </p>
            </div>
        </div>
    )
}

export default TrainingHead