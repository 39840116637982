import React from 'react'
import { useHistory } from 'react-router-dom'
import DynamicClubTag from '../../../../Global/DyamicClubTag'

const PackageCard = ({
    path, 
    packageName,
    packagePrice,
    business_consultancy,
    discounted_services,
    foreign_tech_consultancy,
    investment_database,
    investment_pitch,
    life_insurance,
    member_swag,
    networking_event, 
    news_letter, 
    reserved_office_space,
    onClick
}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()
    
    return (
        <section className='flex flex-col p-5 gap-5 bg-white rounded-[10px] w-full'>

            {/* caption heading */}
            <div className='flex mobile:flex-col mobile:items-start mobile:justify-start items-center justify-between w-full gap-2'>
                <p className='text-base font-semibold text-[#1D2433] mt-0'>
                    {packageName}
                </p>

                <p className='text-[#0B8CBA] font-semibold text-base mt-0'>
                    NGN {packagePrice.toLocaleString("en-US")} /yr
                </p>
            </div>

            {/* contents in grid lock, if you know what i mean */}
            <div className='w-full grid grid-cols-4 mobile:grid-cols-2 gap-5'>

                <DynamicClubTag
                status={networking_event}
                title={"Networking Event"} />
        
                <DynamicClubTag
                status={member_swag}
                title={"Member Swag"} />
        
                <DynamicClubTag
                status={news_letter}
                title={"Newsletter"} />
        
                <DynamicClubTag
                status={reserved_office_space}
                title={"Reserved Office Space"} />
        
                <DynamicClubTag
                status={discounted_services}
                title={"Discounted Services"} />
        
                <DynamicClubTag
                status={investment_pitch}
                title={"Investment Pitch"} />
        
                <DynamicClubTag
                status={investment_database}
                title={"Investment Database"} />
        
                <DynamicClubTag
                status={business_consultancy}
                title={"Business Consultancy"} />
        
                <DynamicClubTag
                status={foreign_tech_consultancy}
                title={"Foreign Tech Consultancy"} />
        
                <DynamicClubTag
                status={life_insurance}
                title={"Life Insurance "} />
            </div>

            {/* button */}
            <div className='w-full flex justify-start'>
                <button 
                onClick={()=>history.push(`/dashboard/club/${path}`)}
                className='text-sm font-semibold text-[#0B8CBA] h-[36px] border border-[#0B8CBA] px-3 rounded-[8px] hover:bg-[#0b8bba49]'>
                    Get started with this package
                </button>
            </div>
        </section>
    )
}

export default PackageCard