import React from 'react'
import AvailableCourses from './AvailableCourses'
import Courses from '../Home/Courses'
import ProfileAvatar from '../Home/ProfileAvatar'
import { Link } from 'react-router-dom'

const CoursesBody = () => {
    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>

            {/* header */}
            <header className='container mx-auto mobile:px-4 flex justify-between items-center'>
                <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                    Courses
                </p>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* middle section */}
            <section className='container mx-auto flex w-full justify-between mt-12 gap-9 mobile:px-4'>

                {/* course information */}
                <main className='flex w-full flex-col gap-16'>

                    {/* course/package detailed info */}
                    <div className='flex w-full gap-6 mobile:hidden'>
                        <Courses shadow={true} width={"65%"} />
                    </div>

                    {/* mobile course package */}
                    <div className='hidden mobile:flex w-full gap-6'>
                        <Courses shadow={true} width={"100%"} />
                    </div>

                    {/* available courses */}
                    <div className='flex flex-col gap-8'>
                        <AvailableCourses />

                        <Link to={"/old/dashboard/theclub"} className='hover:text-[#8B54F7] hover:no-underline font-semibold mobile:self-center mobile:text-sm text-xl text-[#8B54F7]'>
                            Click here to join the club membership instead
                        </Link>
                    </div>
                </main>
            </section>            
        </section>
    )
}

export default CoursesBody