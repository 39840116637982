import React from 'react'
import { useHistory } from 'react-router-dom'

const TrackCard = ({pathway, duration, courseTitle, path}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()

    return (
        <div className='flex flex-col gap-5 p-5 bg-white rounded-[10px] border border-[#E1E6EF] items-center'>

            {/* info */}
            <div className='flex flex-col items-center gap-6 text-center'>

                {/* label */}
                <span className='flex items-center justify-center text-[#3F444D] font-semibold text-[11px] rounded-[8px] bg-[#E1E6EF] h-[32px] px-3'>
                    {pathway}
                </span>

                {/* texts */}
                <div className='flex flex-col gap-2 items-center text-center'>
                    <p className='text-[#3F444D] font-semibold text-sm mt-0'>
                        {courseTitle}
                    </p>

                    <p className='font-normal text-xs text-gray-800 mt-0'>
                        {duration} Months Course
                    </p>
                </div>

            </div>

            {/* button */}
            <button 
            onClick={()=>history.push(`/dashboard/bootcamps/${path}`)}
            className="border border-[#0B8CBA] hover:opacity-80 font-semibold text-sm text-[#0B8CBA] rounded-[8px] h-[36px] px-3 [filter:drop-shadow(0px_1px_2px_rgba(16,24,40,0.05))]">
                Learn more
            </button>
        </div>
    )
}

export default TrackCard