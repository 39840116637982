import React from 'react'
import { HiOutlineLogout } from 'react-icons/hi'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from '../../../../redux/action'

const LogoutView = ({closeModal}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()
    const dispatch = useDispatch()


    // HANDLERS
    const onLogout = () => {
        closeModal()
        dispatch(logout())
        history.push("/login")
    }
    const onCancelLogout = () => {
        closeModal()
    }

    return (
        <div className="p-5 py-7 mobile:p-4 bg-white flex flex-col gap-4 items-center">
            
            {/* icon container */}
            <div>
                <HiOutlineLogout 
                color='#E02D3C' 
                size={32} />
            </div>

            {/* text container */}
            <div>
                <p className='text-base font-semibold text-[#111827]'>
                    Are you sure you want to Logout?
                </p>
            </div>

            {/* buttons container */}
            <div className='flex w-full mobile:flex-col mobile:justify-center mobile:items-stretch justify-between items-center gap-4 mt-5 mobile:mt-2'>

                <button 
                onClick={onCancelLogout}
                className='border border-[#D1D5DB] px-4 py-2 text-[#374151] font-medium text-base rounded-[6px] hover:bg-[#f5f5f5] bg-white inline-flex items-center justify-center w-full'>
                    No
                </button>

                <button
                onClick={onLogout} 
                className='border border-[#E02D3C] px-4 py-2 font-medium text-base rounded-[6px] hover:opacity-70 bg-[#E02D3C] text-white inline-flex items-center justify-center w-full'>
                    Yes
                </button>
            </div>
        </div>
    )
}

export default LogoutView