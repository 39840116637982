import React from 'react'
import EventsBody from '../../../components/Dashboard/Events/EventsBody'
import SideNav from '../../../layouts/Dashboard/SideNav'

const Events = () => {
    return (
        <div className='flex justify-end mobile:w-screen mobile:flex-col h-full w-full mobile:bg-[#E5E5E5]'>
            
            {/* Side nav */}
            <SideNav />

            {/* Events Body */}
            <EventsBody />

        </div>
    )
}

export default Events