import React from 'react'
import tempImage from "../../../../../assets/redesign-dashboard/investing/images/selected-image.png"

const Jumbotron = () => {
    return (
        <div className='flex mobile:flex-col-reverse mobile:items-start items-center gap-12 mobile:gap-5 bg-white rounded-[10px] border border-[#E1E6EF] p-5 mobile:h-auto h-[236px]'>

            {/* details */}
            <div className='w-full h-full'>
                <div className='flex flex-col justify-between gap-4'>
                    <div className='flex flex-col gap-2 items-start'>

                        <span className='flex justify-center items-center text-gray-600 h-[32px] rounded-[8px] bg-[#E1E6EF] px-3 font-semibold text-xs'>
                            Environment
                        </span>

                        <p className='text-[#1D2433] font-semibold text-[22px] mt-0'>
                            Smart Sort Ai
                        </p>
                    </div>

                    <div className='flex flex-col gap-1 items-start mt-2'>
                        <span className='text-gray-600 font-semibold text-xs'>
                            Tags:
                        </span>

                        <span className='font-normal text-xs text-gray-600'>
                            VC backed, Socialtech, Artificial Intelligence
                        </span>
                    </div>

                    <p className='text-[#1D2433] font-normal text-base'>
                        Recycling done smarter - Patented Artificial technology.
                    </p>
                </div>
            </div>

            {/* image */}
            <div className='w-full h-full mobile:h-auto'>
                <img src={tempImage} className="w-full h-full object-cover rounded-[10px]" alt="" />
            </div>
        </div>
    )
}

export default Jumbotron