import { useState, useEffect } from 'react'
import { motion } from "framer-motion";
import StepBtn from "../Student/StepBtn"
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import SubscriptionCard from './SubscriptionCard';
import { useDispatch, useSelector } from 'react-redux';
import { getClubPackagesRequest } from '../../../../../redux/action';
import EmptyData from '../EmptyData';
import LoadingCircles from '../LoadingCircle';

const MemberCarousel = ({
    pages,
    setPages,
    x,
    setX,
    number,
    onClick,
    isSelected,
    setClubPackage,
    disableBtn
}) => {

    // STATES
    const [clickedCard, setClickedCard] = useState(null)
    const {
        clubsPackages,
        clubsPackagesLoading
    } = useSelector(state=>{
        const { clubsPackagesLoading } = state.loadingIndicator
        const { clubsPackages } = state.clubPackagesReducer

        return {
            clubsPackagesLoading,
            clubsPackages
        }
    })


    // OBJECT INITIALIZATION
    const dataset = ["item1","item2","item3","item4","item5",]
    const dispatch = useDispatch()


    // HANDLERS
    // scroll left handler
    const scrollLeft = () => {
        document.querySelector("#slider").scrollBy({
            left: -250,
            behavior: "smooth"
        })
    }
    // scroll right handler
    const scrollRight = () => {
        document.querySelector("#slider").scrollBy({
            left: 250,
            behavior: "smooth"
        })
    }


    // SIDE EFFECT
    useEffect(()=>{
        dispatch(getClubPackagesRequest())
    }, [])

    // step button
    useEffect(()=> {
        disableBtn({
            back: true,
            next: clickedCard===null
        })
    }, [clickedCard])

    return (
        <motion.div
        initial={{ x: x }}
        transition={{ duration: 1 }}
        animate={{ x: 0 }}
        exit={{ x: x }}
        className="flex flex-col gap-3 items-center justify-center w-full h-full relative">

            {/* caption */}
            <p className='mt-0 text-gray-800 text-sm font-normal'>
                Select a package and click on next
            </p>

            {/* left button */}
            <button
            disabled={clubsPackages?.length===0}
            onClick={scrollLeft}
            className='absolute h-[24px] w-[24px] rounded-[4px] top-[37%] left-[-0.1rem] z-[100] bg-[#055774] text-white flex justify-center items-center'>
                <IoIosArrowBack 
                className='text-lg' />
            </button>

            {/* right button */}
            <button 
            disabled={clubsPackages?.length===0}
            onClick={scrollRight}
            className='absolute h-[24px] w-[24px] rounded-[4px] top-[37%] right-[-0.1rem] z-[100] bg-[#055774] text-white flex justify-center items-center'>
                <IoIosArrowForward 
                className='text-lg' />
            </button>


            {/* Slider component */}
            <div
            id='slider'
            className='flex h-full w-full overflow-auto disable-scrollbars relative z-[10] snap-x gap-8 px-2'>

                {
                    clubsPackagesLoading?
                    <LoadingCircles />:
                    clubsPackages && clubsPackages.length !== 0?
                    <>
                        {
                            clubsPackages.map((clubPackage, index)=>(
                                <SubscriptionCard
                                onClick={()=>{
                                    setClickedCard(clubPackage._id)
                                    setClubPackage(
                                        clubPackage._id,
                                        clubPackage.amount
                                    )
                                }}
                                isSelected={clickedCard===clubPackage._id}
                                key={clubPackage._id}
                                packageName={clubPackage.name}
                                packagePrice={clubPackage.amount}
                                business_consultancy={clubPackage.business_consultancy}
                                discounted_services={clubPackage.discounted_services}
                                foreign_tech_consultancy={clubPackage.foreign_tech_consultancy}
                                investment_database={clubPackage.investment_database}
                                investment_pitch={clubPackage.investment_pitch}
                                life_insurance={clubPackage.life_insurance}
                                member_swag={clubPackage.member_swag}
                                networking_event={clubPackage.networking_event}
                                news_letter={clubPackage.news_letter}
                                reserved_office_space={clubPackage.reserved_office_space} /> 
                            ))
                        }
                    </>:
                    <EmptyData />
                }
            </div>

        </motion.div>
    )
}

export default MemberCarousel