import React from 'react'
import { useHistory } from 'react-router-dom'
import BtnPrimary from '../../../Global/Redesign/BtnPrimary'
import DynamicClubTag from '../../../Global/DyamicClubTag'

const MembershipCard = (
    {
        path, 
        packageName,
        packagePrice,
        business_consultancy,
        discounted_services,
        foreign_tech_consultancy,
        investment_database,
        investment_pitch,
        life_insurance,
        member_swag,
        networking_event, 
        news_letter, 
        reserved_office_space,
        onClick,
        isSelected
    }
) => {

    // OBJECT INITIALIZATION
    const history = useHistory()

    return (
        <div className='flex flex-col items-center [box-shadow:0px_2px_8px_#e0e0e0] rounded-[10px] [padding:40px_12px_20px] gap-4 bg-white'>
            
            {/* package name */}
            <div className='flex flex-col items-center justify-center gap-4 pb-2 pt-6'>
                <p className='text-[#3F444D] text-2xl font-semibold'>
                    {packageName} Package
                </p>
            </div>

            {/* package info */}
            <div className='flex justify-between px-3 my-2'>

                {/* Listing */}
                <div className='grid grid-cols-2 text-[#667085] font-semibold text-xs justify-start items-center gap-3'>

                    <DynamicClubTag
                    status={networking_event}
                    title={"Networking Event"} />
            
                    <DynamicClubTag
                    status={member_swag}
                    title={"Member Swag"} />
            
                    <DynamicClubTag
                    status={news_letter}
                    title={"Newsletter"} />
            
                    <DynamicClubTag
                    status={reserved_office_space}
                    title={"Reserved Office Space"} />
            
                    <DynamicClubTag
                    status={discounted_services}
                    title={"Discounted Services"} />
            
                    <DynamicClubTag
                    status={investment_pitch}
                    title={"Investment Pitch"} />
            
                    <DynamicClubTag
                    status={investment_database}
                    title={"Investment Database"} />
            
                    <DynamicClubTag
                    status={business_consultancy}
                    title={"Business Consultancy"} />
            
                    <DynamicClubTag
                    status={foreign_tech_consultancy}
                    title={"Foreign Tech Consultancy"} />
            
                    <DynamicClubTag
                    status={life_insurance}
                    title={"Life Insurance "} />
                    
                </div>
            </div>

            {/* amount */}
            <p className='text-[#0B8CBA] font-semibold text-xl leading-[24px] text-center'>
                NGN {packagePrice.toLocaleString('en-US')} <span className='font-normal text-base'>/yr</span>
            </p>

            {/* button */}
            <div className='flex items-stretch flex-col w-full'>
                <BtnPrimary 
                onClick={()=>history.push("/login")}
                title="Become a member" />
            </div>
        </div>
    )
}

export default MembershipCard