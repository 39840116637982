import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import image from "../../../../../assets/redesign-dashboard/bootcamps/images/jumbotron-image.png"
import { getBootcampForUsersRequest } from '../../../../../redux/action'
import HowItWorks from '../../TheClub/AvailablePackages/HowItWorks'
import AvailableTracks from './AvailableTracks'

const AvailableCourseBody = () => {

    // STATES
    const {
        bootcamps,
        bootcampsLoading
    } = useSelector((state)=>{
        const { bootcamps } = state.bootcampReducer
        const { bootcampsLoading } = state.loadingIndicator
        return {
            bootcamps,
            bootcampsLoading
        }
    })


    // OBJECT INITIALIZATION
    const dispatch = useDispatch()


    // SIDE EFFECT
    useEffect(()=>{
        dispatch(getBootcampForUsersRequest())
    }, [])

    return (
        <div className='flex mobile:flex-col w-full h-full gap-10'>
            
            {/* section filled with image and text details */}
            <div className='h-full w-[70%] mobile:w-full flex flex-col gap-10'>

                {/* image */}
                <div className='w-full'>

                    <img src={image} className='w-full object-cover h-full rounded-[10px] [box-shadow:0px_8px_32px_rgba(0,0,0,0.16)]' alt="" />

                </div>

                {/* texts */}
                <div className='flex flex-col gap-1 w-full text-base text-gray-800 font-normal'>
                    <p>
                        Discover Your Passion and Change Your Story at Our Programming Training Center.Where every day is an opportunity to learn, grow, and make an impact.
                    </p>

                    <p>
                        With personalized attention and real-world projects, you'll have everything you need to turn your coding aspirations into a fulfilling career.
                    </p>

                    <p>
                        Join a supportive community of like-minded individuals, who are turning their tech skills into meaningful solutions for a better world.
                    </p>

                    <p>
                        Don't just learn, live your dream. Don't just imagine, build. Enroll now and start your journey to a more fulfilling, tech-driven life.
                    </p>
                </div>

                {/* available courses */}
                <AvailableTracks
                captionText={"Available courses"}
                loading={bootcampsLoading}
                isEmpty={!(bootcamps && bootcamps.length!==0)}
                regularTrack={bootcamps?.filter((bootcamp)=>bootcamp?.course_type==="regular")}
                specialTrack={bootcamps?.filter((bootcamp)=>bootcamp?.course_type==="pathway")} />
            </div>

            {/* how it works section */}
            <div className='h-full w-[30%] mobile:w-full flex flex-col'>
                <HowItWorks
                infoList={[
                    {
                        id: 1,
                        message: "Select your preferred course/bootcamp"
                    },
                    {
                        id: 2,
                        message: "Make Payment to validate your order."
                    },
                    {
                        id: 3,
                        message: "Enjoy the unlimited benefits"
                    },
                ]} />
            </div>
        </div>
    )
}

export default AvailableCourseBody