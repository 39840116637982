import React from 'react'
import ClubBody from '../../../components/Dashboard/TheClub/ClubBody'
import SideNav from '../../../layouts/Dashboard/SideNav'

const TheClub = () => {
    return (
        <div className='flex justify-end mobile:w-screen mobile:flex-col h-full w-full mobile:bg-[#E5E5E5]'>
            
            {/* Side nav */}
            <SideNav />

            {/* The Club Body */}
            <ClubBody />

        </div>
    )
}

export default TheClub