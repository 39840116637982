import { useState } from 'react'
import MyModal from '../../../../../layouts/MyModal'
import image from "../../../../../assets/redesign-dashboard/space-booking/images/jumbotron-image.png"
import HowItWorks from '../../TheClub/AvailablePackages/HowItWorks'
import SearchSpaces from '../SearchSpaces'
import Benefits from '../Benefits'

const AboutSpaceBody = () => {

    // DATA INITIALIZATION
    const subPackages = [
        {
            _id: 0,
            content: "Unlimited wifi services",
        },
        {
            _id: 1,
            content: "Maximum security in a serene and peaceful environment",
        },
        {
            _id: 2,
            content: "24/7 uninterrupted power supply",
        },
        {
            _id: 3,
            content: "A spacious lounge with a great view",
        },
        {
            _id: 4,
            content: "Free parking space",
        },
        {
            _id: 5,
            content: "Free coffee"
        },
    ]


    // STATES
    const [spaceModalState, setSpaceModalState] = useState(false)


    // HANDLERS
    const toggleSpaceModal = () => {
        setSpaceModalState(!spaceModalState)
    }

    return (
        <div className='flex w-full mobile:flex-col h-full gap-10'>

            {/* section filled with image and text details */}
            <div className='h-full w-[70%] mobile:w-full flex flex-col gap-10'>

                {/* image */}
                <div className='w-full'>

                    <img src={image} className='w-full object-cover h-full rounded-[10px] [filter:drop-shadow(0px_8px_32px_rgba(0,0,0,0.16))]' alt="" />

                </div>

                {/* texts */}
                <div className='flex flex-col gap-1 w-full text-base text-gray-800 font-normal'>
                    <p>
                        From cost savings to increased collaboration opportunities, our modern co-working spaces will make your work life more enjoyable and productive.
                    </p>
                </div>

                {/* benefits */}
                <Benefits
                onClick={toggleSpaceModal}
                subPackages = {subPackages} />
                
            </div>
            
            {/* how it works section */}
            <div className='h-full w-[30%] mobile:w-full flex flex-col'>
                <HowItWorks
                infoList={[
                    {
                        id: 1,
                        message: "Search for available spaces"
                    },
                    {
                        id: 2,
                        message: "Select a space from the available options"
                    },
                    {
                        id: 3,
                        message: "Make payment to reserve space"
                    },
                    {
                        id: 4,
                        message: "Enjoy the unlimited benefits"
                    },
                ]} />
            </div>

            {/* Modal */}
            {/* logout modal */}
            <MyModal
            modalIsOpen={spaceModalState}
            toggleModal={toggleSpaceModal}
            disableBtn={true}>
                
                {/* search modal */}
                <SearchSpaces />

            </MyModal>
        </div>
    )
}

export default AboutSpaceBody