import React from "react";
import HomeBody from "../../../components/Dashboard/Home/HomeBody";
import SideNav from "../../../layouts/Dashboard/SideNav";

const DashboardHome = () => {
  return (
    <div className="flex justify-end mobile:w-screen mobile:flex-col h-full w-full mobile:bg-[#E5E5E5]">
      {/* SideNav */}
      <SideNav />

      {/* Home Body */}
      <HomeBody />
    </div>
  );
};

export default DashboardHome;
