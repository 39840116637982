import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'
import tempImage from "../../../../assets/redesign-dashboard/investing/images/investment-image.png"

const InvestmentCard = () => {

    return (
        <div className='flex flex-col gap-4 p-3 bg-white rounded-[10px]'>

            {/* image */}
            <div className='w-full h-full flex items-center justify-center'>
                <img src={tempImage} className='rounded-[10px] w-full h-full object-cover' alt="" />
            </div>

            {/* info or details */}
            <div className='flex flex-col items-start'>

                {/* info */}
                <div className='flex flex-col gap-4'>

                    {/* caption and label */}
                    <div className='flex flex-col gap-2 items-start'>

                        {/* caption */}
                        <p className='font-semibold text-base text-[#1D2433]'>
                            Smart Sort Ai
                        </p>

                        {/* label */}
                        <span className='inline-flex items-center justify-center text-[11px] font-semibold text-[#3F444D] bg-[#E1E6EF] h-[32px] px-3 rounded-[10px]'>
                            Environment
                        </span>
                    </div>

                    {/* description */}
                    <p className='text-gray-800 font-normal text-sm'>
                        The world’s first installable energy storage appliance.
                    </p>
                </div>

                {/* links */}
                <Link to={"/dashboard/investing/kljkjadfjsdkfjskfjskfdk"} className='flex gap-2 items-center text-[#0B8CBA] font-semibold text-sm mt-12 hover:opacity-60 hover:text-[#0B8CBA] active:text-[#0B8CBA] hover:no-underline'>
                    Learn more
                    <IoIosArrowForward className='text-xl' />
                </Link>
            </div>
        </div>
    )
}

export default InvestmentCard