import React from 'react'
import { NavLink, useLocation } from "react-router-dom"

const ActiveLink = ({children, to}) => {

    const location = useLocation()
    
    return (
        <>
            <NavLink 
            isActive={()=>location.pathname.includes(to)}
            exact
            to={to} 
            className="text-white font-semibold mobile:font-normal mobile:text-base text-base lg:text-xs xl:text-xs flex justify-start items-center transition-all hover:text-[#5221B5] hover:bg-[#f9f5ff75] rounded-[6px] h-[40px] gap-[8px] mobile:gap-3 px-4 hover:no-underline"
            activeClassName='bg-[#F9F5FF] text-[#5221B5] mobile:font-semibold mobile:text-base'>
                {children}
            </NavLink>
        </>
    )
}

export default ActiveLink