import { useFormik } from 'formik';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from "yup"
import Carousel from "./Carousel";
import CourseTrack from "./CourseTrack";
import Form from "./Form";
import { AnimatePresence } from "framer-motion";
import { studentRegisterRequest } from "../../../../../redux/action";
import StepBtn from './StepBtn';

const RegistrationSteps = () => {

    // STATES
    const [disableStepBtn, setDisableStepBtn] = useState({
      back: true,
      next: false
    })
    const [pages, setPages] = useState(1);
    const [x, setX] = useState(0);
    const [track, setTrack] = useState("")
    const [referenceId, setReferenceId] = useState("")
    const [bootcamp, setBootcamp] = useState({
      id: "",
      price: ""
    })
    const {
        getUserLoading,
        getUserError,
        studentRegisterSuccess,
        studentRegisterError,
        studentRegisterLoading,
        user,
        singleUser,
    } = useSelector((state) => {

        const {
          success: { studentRegister: studentRegisterSuccess },
          errors: { 
            getUser: getUserError, 
            studentRegister: studentRegisterError },
        } = state.ajaxStatuses;
        const { studentRegisterLoading, getUserLoading } = state.loadingIndicator;
        const { user, singleUser } = state.userData;
    
        return {
          getUserLoading,
          getUserError,
          studentRegisterSuccess,
          studentRegisterError,
          studentRegisterLoading,
          user,
          singleUser,
        };
    });


    // HANDLERS
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    function handleBootcampInfo (id, price) {
      setBootcamp({
        id,
        price
      })
    }
    const onNextBtnClick = () => {
      setPages(pages+1)
      setX(1000)
    }
    const onBackBtnClick = () => {
      setPages(pages-1)
      setX(-1000)
    }


    // OBJECT INITIALIZATION - COMPONENT DATA
    const history = useHistory()
    let query = useQuery();
    const dispatch = useDispatch()
    const formik = useFormik({
      initialValues: {
        bootcamp_id: "",
        reference: "",
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
        date_of_birth: "",
        reason: "",
        occupation: "",
        password: "",
        password_confirmation: "",
      },
      validationSchema: Yup.object({
        firstname: Yup.string().required("First name is required").min(3).max(15),
        lastname: Yup.string().required("First name is required").min(3).max(15),
        mobile: Yup.string().required("Mobile number is required").min(10).max(14),
        email: Yup
          .string()
          .email("Email is not valid")
          .required("Email is required"),
        date_of_birth: Yup.date().required(),
        reason: Yup.string().required().min(10).max(200),
        // occupation: Yup.string().required(),
        password: Yup.string().required("Password is required"),
        password_confirmation: Yup.string().required("Password is required"),
      }),
      onSubmit: (formData) => {
        formData.bootcamp_id = bootcamp.id
        formData.occupation = "student"
        formData.reference = referenceId
        dispatch(studentRegisterRequest({ formData}));
      },
    });
    const componentList = [
        <CourseTrack 
        pages={pages} 
        setPages={setPages} 
        x={x} 
        setX={setX}
        track={track}
        disableBtn={setDisableStepBtn}
        setTrack={setTrack} />,

        <Carousel 
        pages={pages} 
        setPages={setPages} 
        x={x} 
        setX={setX}
        track={track}
        disableBtn={setDisableStepBtn}
        setBootcamp={handleBootcampInfo} />,

        <Form
        pages={pages}
        setPages={setPages}
        bootcampId={bootcamp.id}
        x={x}
        setX={setX}
        disableBtn={setDisableStepBtn}
        registerLoading={studentRegisterLoading}
        formik={formik}
        bootcampPrice={bootcamp.price}
        setReferenceId={setReferenceId} />,
    ];


    // SIDE EFFECTS
    // check for errors
    useEffect(() => {
      if (studentRegisterError) {
        toast.error(studentRegisterError, {
          duration: 3000,
        });
      }
    }, [studentRegisterError]);
  
    // success message
    useEffect(() => {
      if (studentRegisterSuccess) {
        toast.success(studentRegisterSuccess, {
          duration: 3000,
        });
        history.replace("/dashboard/bootcamps")
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentRegisterSuccess]);

    return (
        <div className="flex flex-col items-start gap-3 w-full h-full">
            <AnimatePresence>
              {componentList[pages - 1]}
            </AnimatePresence>

            {/* step button */}
            <StepBtn
            disableBack={disableStepBtn.back}
            backClick={onBackBtnClick}
            onClick={onNextBtnClick}
            disabled={disableStepBtn.next} />
        </div>
    );
};

export default RegistrationSteps;
