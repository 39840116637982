import React from 'react'
import BtnPrimary from '../../../Global/Redesign/BtnPrimary'
import { useHistory } from 'react-router-dom'

const AboutCard = ({icon, title, description, linkText, linkPath}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()


    return (
        <div className='border border-[#E1E6EF] py-[32px] px-[20px] h-fit bg-white rounded-[10px] [box-shadow:0px_0px_0px_4px_rgba(255,255,255,0.2)] flex flex-col items-center text-center gap-5'>
            
            {/* icon */}
            <span className='bg-[#D4F4FF] h-[56px] w-[56px] flex justify-center items-center rounded-[50%]'>

                {icon}
            </span>

            {/* texts */}
            <div className='flex flex-col gap-2'>
                <p className='font-semibold mobile:font-medium text-xl text-[#3f444d]'>
                    {title}
                </p>

                <p className="font-normal text-base text-gray-800">
                    {description}
                </p>
            </div>

            {/* button */}
            <div className='flex flex-col items-stretch w-full'>
                <BtnPrimary 
                onClick={()=>history.push(linkPath)}
                title={linkText} />
            </div>

        </div>
    )
}

export default AboutCard