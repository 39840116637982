import React from "react";
import Video from "./Video";
import ycap from "./../../assets/home/images/ycap.png";
import teams from "./../../assets/home/images/teams.png";
import kachiDp from "./../../assets/home/images/kachi-dp.png";
import logoSm from "./../../assets/home/svg/logo-main.svg";
import a_shape1 from "./../../assets/home/svg/a-shape1.svg";
import a_shape2 from "./../../assets/home/svg/a-shape2.svg";

const AboutUs = () => {
  return (
    <div className="bg-[#F8F9FC] pb-[100px] pt-[550px] mobile:pt-[250px] md:!pt-[370px] pc:px-5 flex justify-center flex-col px-4">

      {/* titles */}
      <div className="flex flex-col justify-center items-center mobile:gap-4 container mx-auto">
        <h3 className="font-semibold text-base mobile:text-xs text-[#8B54F7] text-center">
          ABOUT US
        </h3>

        <p className="font-semibold text-4xl mobile:text-2xl leading-[54px] mobile:leading-none text-center text-black">
          More than a tech community
        </p>

        <p className="font-normal text-base text-gray-600 text-center mt-4 mobile:mt-0">
          Tech is the future, and the future is now. So, how do we move from the
          present to the future where we ought to be?
        </p>

        <p className="font-normal mobile:font-semibold text-xl mobile:text-base leading-[30px] text-center pc:mt-2 text-black">
          Here’s how we move -
        </p>
      </div>

      {/* key points */}
      <div className="grid grid-cols-2 mobile:grid-cols-[auto] gap-10 container mx-auto w-full mt-12 items-start mobile:flex-col px-2">

        {/* first box */}
        <div className="bg-white rounded-[10px] h-full w-full flex gap-8 items-start px-8 mobile:px-5 py-7">
          <img src={ycap} className="mobile:w-[36px]" alt="yellow cap" />

          <div className="flex flex-col gap-2">
            <p className="font-semibold mobile:text-sm text-xl leading-[24px] text-[#3f444d]">
              Make tech education accessible
            </p>
            <p className="font-normal mobile:text-sm text-base text-[#6c717a]">
              AI Movement offers both a radical tech training center and a new
              exclusive club for tech professionals and investors.
            </p>
          </div>
        </div>

        {/* second box */}
        <div className="bg-white rounded-[10px] h-full w-full flex gap-8 items-start px-8 mobile:px-5 py-7">
          <img src={teams} className="mobile:w-[36px]" alt="yellow cap" />

          <div className="flex flex-col gap-2">
            <p className="font-semibold mobile:text-sm text-xl leading-[24px] text-[#3f444d]">
              Community building and Networking
            </p>
            <p className="font-normal mobile:text-sm text-base text-[#6c717a]">
              Everyone can learn how to code, everyone can learn how to invest
              in the next big thing,but not everyone has the courage to say
              hello to their next-door neighbor, or ability to spot the next big
              trend, or know how to begin riding this massive tech wave. So
              these are the reasons why you should consider becoming a member or
              student.
            </p>
          </div>
        </div>
      </div>

      {/* video introduction */}
      <Video />

      {/* profile */}
      <div className="flex flex-col justify-center items-center mt-12 mobile:px-2 relative">
        {/* scrables */}
        <img
          src={a_shape1}
          className="pc:w-[40px] absolute top-[0rem] mobile:right-[0] mobile:top-[-.5rem] right-[18%] lg:right-[8%]"
          alt=""
        />
        <img
          src={a_shape2}
          className="pc:w-[40px] absolute bottom-[4rem] left-[20%]"
          alt=""
        />

        <p className="text-[36px] mobile:text-sm leading-[54px] font-medium text-center text-black mobile:text-[#000]">
          <span className="text-[#8b54f7] mobile:text-[#000]">
            AI Movement’s
          </span>{" "}
          aim is simple, to move us from where we
          <br className="mobile:hidden" /> currently are into a new invigorating
          tech future.
        </p>

        {/* profile section */}
        <div className="flex flex-col gap-1 justify-center items-center mt-7">
          <img
            src={kachiDp}
            className="mobile:w-[64px]"
            alt="kachi's profile"
          />
          <p className="text-[#101828] font-semibold text-base mt-3">
            Kachi Aghasili
          </p>
          <p className="flex justify-center items-center font-normal text-base mobile:text-sm text-[#667085] gap-4">
            Founder & CEO
            <img src={logoSm} width="103" alt="logo small" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
