import React from 'react'
import Modal from "react-modal"
import { GrClose } from "react-icons/gr"
import "./index.css"

const ImageViewerModal = ({modalIsOpen, toggleModal, children, disableBtn}) => {
    return (
        <>
            <Modal
            isOpen={modalIsOpen}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
            className="image-modal"
            overlayClassName="myoverlay"
            closeTimeoutMS={500}
            ariaHideApp={false}>

                {/* modal content */}
                <div className="image-model-item-cont">

                    {
                        !disableBtn && 

                        // modal close button
                        <span 
                        className="modal-close-btn" 
                        onClick={toggleModal}>
                            <GrClose />
                        </span>
                    }

                    {/* children */}
                    {children}
                </div>
            </Modal>
        </>
    )
}

export default ImageViewerModal