import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import { clientErrorMessage, delay } from "./reusable";
import { clearSpacePaymentSuccess, clearSpaceRegistrationSuccess, getSingleSpaceFailure, getSingleSpaceLoading, getSingleSpaceSuccess, getSpacesFailure, getSpacesLoading, getSpacesSuccess, getUserSpacesFailure, getUserSpacesLoading, getUserSpacesSuccess, spacePaymentFailure, spacePaymentLoading, spacePaymentSuccess, spaceRegistrationFailure, spaceRegistrationLoading, spaceRegistrationSuccess } from "../action";
import { GET_SINGLE_SPACE_REQUEST, GET_SPACES_REQUEST, GET_USER_SPACES_REQUEST, SPACE_PAYMENT_REQUEST, SPACE_REGISTRATION_REQUEST } from "../types";

const ajaxDBCalls = {
    getSpaces: async ({payload}) => {
        const reponse = await axios.post("space/user", payload)
        return reponse
    },
    
    getSingleSpace: async ({ payload }) => {
        const response = await axios({
          method: "GET",
          url: `space/user/${payload}`,
        });
    
        return response;
    },
    
    getUserSpaces: async ({ payload }) => {
        const response = await axios({
            method: "GET",
            url: `space/userspace`,
            headers: {
                "Content-Type": "application/json",
                'Authorization':`Bearer ${payload}`
            }
        });
    
        return response;
    },

    registerForSpace: async ({payload}) => {
        const reponse = await axios.post("auth/registerforspacebooking", payload)
        return reponse
    },

    payForSpace: async ({payload: {spaceId, data }}) => {
        const reponse = await axios.post(`space/onlinepayment/${spaceId}`, data)
        return reponse
    },
}


// WORKERS
// get clubs
function* getSpaces(payload) {
    try {
        yield put(getSpacesLoading(true));

        const response = yield call(ajaxDBCalls.getSpaces, payload)

        yield put(getSpacesSuccess(response.data))
        yield put(getSpacesLoading(false))
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage; 

        if (err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(getSpacesFailure(errorMessage))
        yield put(getSpacesLoading(false))
        yield delay();
        yield put(getSpacesFailure(""))
    }
}

// get single space
function* getSingleSpace(payload) {
    try {
        yield put(getSingleSpaceLoading(true));
    
        const response = yield call(ajaxDBCalls.getSingleSpace, payload);
    
        yield put(getSingleSpaceSuccess(response.data));
        yield put(getSingleSpaceLoading(false));
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(getSingleSpaceFailure(errorMessage));
        yield put(getSingleSpaceLoading(false));
        yield delay();
        yield put(getSingleSpaceFailure(""));
    }
}

// get user's spaces
function* getUserSpaces(payload) {
    try {
        yield put(getUserSpacesLoading(true));
    
        const response = yield call(ajaxDBCalls.getUserSpaces, payload);
    
        yield put(getUserSpacesSuccess(response.data));
        yield put(getUserSpacesLoading(false));
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(getUserSpacesFailure(errorMessage));
        yield put(getUserSpacesLoading(false));
        yield delay();
        yield put(getUserSpacesFailure(""));
    }
}

// register for space
function* registerForSpace(payload) {
    try {
        yield put(spaceRegistrationLoading(true));

        const response = yield call(ajaxDBCalls.registerForSpace, payload)

        yield put(spaceRegistrationSuccess(response.data))
        yield put(spaceRegistrationLoading(false))
        yield delay();
        yield put(clearSpaceRegistrationSuccess());
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage; 

        if (err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(spaceRegistrationFailure(errorMessage))
        yield put(spaceRegistrationLoading(false))
        yield delay();
        yield put(spaceRegistrationFailure(""))
    }
}

// pay for a space
function* payForSpace(payload) {
    try {
        yield put(spacePaymentLoading(true));

        const response = yield call(ajaxDBCalls.payForSpace, payload)

        yield put(spacePaymentSuccess(response.data))
        yield put(spacePaymentLoading(false))
        yield delay();
        yield put(clearSpacePaymentSuccess());
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage; 

        if (err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(spacePaymentFailure(errorMessage))
        yield put(spacePaymentLoading(false))
        yield delay();
        yield put(spacePaymentFailure(""))
    }
}

// WATCHERS
function* getSpacesWatcher() {
    yield takeLatest(GET_SPACES_REQUEST, getSpaces)
}

function* getSingleSpaceWatcher() {
    yield takeLatest(GET_SINGLE_SPACE_REQUEST, getSingleSpace)
}

function* getUserSpacesWatcher() {
    yield takeLatest(GET_USER_SPACES_REQUEST, getUserSpaces)
}

function* registerForSpaceWatcher() {
    yield takeLatest(SPACE_REGISTRATION_REQUEST, registerForSpace)
}

function* payForSpaceWatcher() {
    yield takeLatest(SPACE_PAYMENT_REQUEST, payForSpace)
}


// spaces saga
export default function* spacesSaga() {
    yield spawn(getSpacesWatcher);
    yield spawn(getSingleSpaceWatcher);
    yield spawn(getUserSpacesWatcher);
    yield spawn(registerForSpaceWatcher);
    yield spawn(payForSpaceWatcher);
}