import React from 'react'
import { useHistory } from 'react-router-dom'
import GreenCheck from '../../../Global/GreenCheck'
import ArrowBtn from '../Bootcamps/ArrowBtn'

const Benefits = ({
    disableRouting,
    onClick, 
    subPackages 
}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()

    return (
        <div className='flex flex-col gap-4 w-full'>
            
            {/* caption heading */}
            <section className='flex justify-between items-center w-full'>
                <p className='font-medium text-sm text-[#3F444D]'>
                    Benefits
                </p>

                {/* arrow botton for pc view */}
                <ArrowBtn
                onClick={onClick}
                className="mobile:hidden"
                text='View other available spaces' />
            </section>

            {/* subscription card */}
            <section className='w-full p-5 grid grid-cols-2 mobile:grid-cols-1 gap-5 rounded-[10px] border border-[#E1E6EF] bg-white'>

                {
                    subPackages.map((subPackage)=>(
                        <div 
                        key={subPackage._id}
                        className='flex gap-2 items-center'>
                            <GreenCheck />
                            <p className='mt-0 font-normal text-xs text-gray-800'>
                                {subPackage.content}
                            </p>
                        </div>
                    ))
                }
            </section>

            {/* arrow botton for mobile view */}
            <ArrowBtn
            onClick={onClick}
            className="hidden mobile:inline-flex"
            text='View other available spaces' />
        </div>
    )
}

export default Benefits