import { IoIosArrowForward } from "react-icons/io"
import { useHistory } from "react-router-dom"
import { ReactComponent as EmptyState } from "../../../../assets/redesign-dashboard/the-club/svg/empty-state.svg"

const DBEmptyStateCard = ({
    packageTypeText,
    emptyMessage,
    pathText,
    pathUrl
}) => {

    //  OBJECT INITIALIZATION
    const history = useHistory()

    return (
        <div className="flex flex-col border border-[#E1E6EF] p-5 gap-4 rounded-[10px] w-full bg-white">
            
            {/* caption */}
            <p className="mt-0 font-semibold text-sm text-gray-600 w-full">
                {packageTypeText}
            </p>

            {/* body info */}
            <div className="w-full flex flex-col gap-4">

                {/* icon section */}
                <div className="flex gap-4 items-end w-full">

                    {/* icon */}
                    <EmptyState
                    className="text-3xl text-[#1D2433]" />

                    {/* texts */}
                    <p className="mt-0 text-gray-800 font-normal text-sm w-full">
                        {emptyMessage}
                    </p>
                </div>
            </div>

            
            {/* path */}
            <p 
            onClick={()=>history.push(pathUrl)}
            className="text-[#0B8CBA] font-semibold text-sm mt-auto w-full flex items-center gap-2 cursor-pointer hover:opacity-80">
                {pathText}
                <IoIosArrowForward
                className="text-2xl text-[#0B8CBA]" />
            </p>
        </div>
    )
}

export default DBEmptyStateCard