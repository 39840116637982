import MyModal from "../../../layouts/MyModal";
import SavedAccountInfo from "./SavedAccountInfo";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { FormError } from "../../Errors";
import { updateUserRequest } from "../../../redux/action";
import EditProfileBtn from "./EditProfileBtn";

const EditProfileForm = () => {
  // modal state for saved account info
  const [savedAccountInfo, setSavedAccountInfo] = useState(false);

  // toggle saved account info
  const toggleSavedAccountInfo = () => {
    setSavedAccountInfo(!savedAccountInfo);
  };

  // dispatch object
  const dispatch = useDispatch();

  // states
  const {
    getUserLoading,
    getUserError,
    updateUserSuccess,
    updateUserError,
    updateUserLoading,
    user,
    token,
    singleUser,
  } = useSelector((state) => {
    const {
      success: { updateUser: updateUserSuccess },
      errors: { getUser: getUserError, updateUser: updateUserError },
    } = state.ajaxStatuses;

    const { updateUserLoading, getUserLoading } = state.loadingIndicator;

    const { user, token, singleUser } = state.userData;

    return {
      getUserLoading,
      getUserError,
      updateUserSuccess,
      updateUserError,
      updateUserLoading,
      user,
      token,
      singleUser,
    };
  });

  // formik object
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      mobile: "",
      date_of_birth: "",
      reason: "",
      occupation: "",
    },
    validationSchema: yup.object({
      firstname: yup.string().min(3).max(15),
      lastname: yup.string().min(3).max(15),
      mobile: yup.string().min(10).max(14),
      date_of_birth: yup.string(),
      reason: yup.string().min(10).max(200),
      occupation: yup.string(),
    }),

    onSubmit: (prop) => {
      let formData = prop;
      console.log(prop);
      dispatch(updateUserRequest({ formData, token }));
    },
  });

  // set formik initial values from redux store
  useEffect(() => {
    if (user) {
      formik.setValues((values) => ({
        ...values,
        firstname: user.firstname,
        lastname: user.lastname,
        mobile: user.mobile,
        date_of_birth: user.date_of_birth,
        reason: user.reason,
        occupation: user.occupation,
      }));
    }
  }, [user]);

  // checking for success or errors
  useEffect(() => {
    if (updateUserError) {
      toast.error(updateUserError, {
        duration: 3000,
      });
    }
  }, [updateUserError]);

  useEffect(() => {
    if (updateUserSuccess) {
      toggleSavedAccountInfo();
      setTimeout(() => {
        setSavedAccountInfo(false);
      }, 2000);
    }
  }, [updateUserSuccess]);

  return (
    <div className="mt-10">
      <form
        className="flex flex-col gap-5"
        onSubmit={(e) => e.preventDefault()}
      >
        {/* form caption */}
        <p className="font-semibold text-xl text-[#3F444D]">
          Personal Information
        </p>

        {/* first name input container */}
        <label className="flex flex-col gap-2">
          {/* label text */}
          <span className="font-semibold text-sm text-[#344054]">
            First name
          </span>

          {/* input field */}
          <input
            type="text"
            {...formik.getFieldProps("firstname")}
            className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
            placeholder="Enter your first name"
          />

          {formik.errors.firstname && formik.touched.firstname && (
            <FormError message={formik.errors.firstname} />
          )}
        </label>

        {/* last name input container */}
        <label className="flex flex-col gap-2">
          {/* label text */}
          <span className="font-semibold text-sm text-[#344054]">
            Last name
          </span>

          {/* input field */}
          <input
            type="text"
            {...formik.getFieldProps("lastname")}
            className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
            placeholder="Enter your last name"
          />

          {formik.errors.lastname && formik.touched.firstname && (
            <FormError message={formik.errors.lastname} />
          )}
        </label>

        {/* email input container */}
        <label className="flex flex-col gap-2">
          {/* label text */}
          <span className="font-semibold text-sm text-[#344054]">Email</span>

          {/* input field */}
          <input
            disabled={true}
            type="email"
            className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal disabled:bg-[#f9f5fc] disabled:cursor-not-allowed placeholder:text-base placeholder:text-[#667085]"
            value={user.email}
            placeholder="Enter your email"
          />
        </label>

        {/* phone number input container */}
        <label className="flex flex-col gap-2">
          {/* label text */}
          <span className="font-semibold text-sm text-[#344054]">
            Phone number
          </span>

          {/* input field */}
          <input
            type="text"
            {...formik.getFieldProps("mobile")}
            className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
            placeholder="Enter your phone number"
          />

          {formik.errors.firstname && formik.touched.firstname && (
            <FormError message={formik.errors.firstname} />
          )}
        </label>

        {/* occupation input container */}
        <label className="flex flex-col gap-2">
          {/* label text */}
          <span className="font-semibold text-sm text-[#344054]">
            Occupation
          </span>

          {/* input field */}
          <input
            type="text"
            {...formik.getFieldProps("occupation")}
            className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
            placeholder="Enter your occupation"
          />

          {formik.errors.firstname && formik.touched.occupation && (
            <FormError message={formik.errors.occupation} />
          )}
        </label>

        {/* About input container */}
        <label className="flex flex-col gap-2">
          {/* label text */}
          <span className="font-semibold text-sm text-[#344054]">Reason</span>

          {/* input field */}
          <textarea
            {...formik.getFieldProps("reason")}
            className="h-[120px] pt-2 [resize:none] border border-[#d0d5dd] text-base font-normal rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
            placeholder="Tell us a why you want to join us"
          />

          {formik.errors.firstname && formik.touched.firstname && (
            <FormError message={formik.errors.firstname} />
          )}
        </label>

        {/* save info button */}
        <EditProfileBtn
          onClick={formik.handleSubmit}
          loading={updateUserLoading}
          title={"Save"}
        />

        {/* modal for  saved success message */}
        <MyModal
          disableBtn={true}
          modalIsOpen={savedAccountInfo}
          toggleModal={toggleSavedAccountInfo}
        >
          <SavedAccountInfo closeModal={toggleSavedAccountInfo} />
        </MyModal>
      </form>
    </div>
  );
};

export default EditProfileForm;
