import React from 'react'

const MemberCard = ({image, name, title}) => {
    return (
        <div className='flex flex-col rounded-[10px] [box-shadow:0px_2px_8px_#e0e0e0] w-fit p-3 gap-5 hover:scale-105 transition-transform'>
            
            {/* image */}
            <div className=''>
                <img src={image} className="w-[296px] object-cover rounded-[10px]" alt="" />
            </div>

            {/* infos */}
            <div className='flex justify-between'>
                <div className='flex flex-col'>
                    <p className='font-semibold text-base text-[#3F444D]'>
                        {name}
                    </p>

                    <p className='text-[#6c707a] font-normal text-sm'>
                        {title}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default MemberCard