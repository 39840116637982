import React from 'react'
import logo from "../../../../assets/bootcamp-form/images/form-logo.png"
import BackBtn from '../../../Global/Redesign/BackBtn'

const Header = () => {
    return (
        <div className='mobile:[box-shadow:0px_8px_32px_rgba(0,0,0,0.16)] bg-white pc:min-h-[84px] mobile:fixed mobile:top-0 mx-auto mobile:left-0 mobile:h-[72px] z-[800] mobile:px-5 md:!px-12 flex w-full'>
            
            {/* container */}
            <div className='flex justify-between items-center w-full'>

                {/* back btn */}
                <BackBtn />

                {/* logo image */}
                <img src={logo} className='w-[141px]' alt="" />
            </div>
            
        </div>
    )
}

export default Header