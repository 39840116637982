import React from 'react'
import { BsFillCheckCircleFill } from "react-icons/bs"
import BtnPrimary from '../../../Global/Redesign/BtnPrimary'

const SavedAccountView = ({closeModal, text="Saved successfully"}) => {
    return (
        <div className="p-5 bg-white flex flex-col justify-center items-stretch gap-4 w-full">
            
            {/* icon container */}
            <div className='flex justify-center items-center text-[#0B8CBA]'>
                <BsFillCheckCircleFill size={64} />
            </div>

            {/* text container */}
            <div className='flex flex-col items-center'>
                <p className='text-2xl font-semibold text-[#1A1C1D]'>
                    Awesome!
                </p>

                <p className='font-medium text-xs text-[#202223]'>
                    {text}
                </p>
            </div>

            {/* back to settings button */}
            <div className='w-[80%] mx-auto flex flex-col items-stretch'>
                <BtnPrimary
                onClick={closeModal}
                title={"Continue"} />
            </div>
        </div>
    )
}

export default SavedAccountView