import {
  REGISTER_LOADING,
  UPDATE_USER_LOADING,
  LOGIN_LOADING,
  GET_BOOTCAMP_FOR_USERS_LOADING,
  GET_USERS_BOOTCAMP_LOADING,
  GET_SPECIFIC_BOOTCAMP_LOADING,
  GET_CLUBS_PACKAGES_LOADING,
  GET_SPECIFIC_PACKAGE_LOADING,
  PUT_BOOTCAMP_PAYMENT_LOADING,
  GET_EVENTS_FOR_USERS_LOADING,
  GET_USER_EVENTS_LOADING,
  GET_SINGLE_EVENT_LOADING,
  GET_USER_CLUB_SUBSCRIPTION_LOADING,
  PUT_USER_TO_EVENT_LOADING,
  PUT_CLUB_SUBSCRIPTION_PAYMENT_LOADING,
  GET_SPACES_LOADING,
  GET_SINGLE_SPACE_LOADING,
  GET_USER_SPACES_LOADING,
  REGISTER_BOOTCAMP_LOADING,
  REGISTER_DIGMARK_BOOTCAMP_LOADING,
  REGISTER_CRITIQUE_EVENT_LOADING,
  STUDENT_REGISTER_LOADING,
  UPDATE_USER_PASSWORD_LOADING,
  CLUB_REGISTER_LOADING,
  REGISTER_GENERIC_EVENT_LOADING,
  DELETE_USER_LOADING,
  BYPASS_REGISTER_LOADING,
  SPACE_REGISTRATION_LOADING,
  SPACE_PAYMENT_LOADING,
} from "../types";

const initialState = {
  // auth
  registerLoading: false,
  updateUserLoading: false,
  updateUserPasswordLoading: false,
  loginLoading: false,
  deleteUserLoading: false,

  // bootcamps
  specificBootcampLoading: true,
  bootcampsLoading: true,
  bootcampLoading: true,
  putBootcampPaymentLoading: false,
  registerBootcampLoading: false,

  // club packages
  clubsPackagesLoading: true,
  specificPackageLoading: true,
  userClubSubscriptionsLoading: true,
  putClubSubscriptionPaymentLoading: false,

  // events
  putUserToEventLoading: false,
  eventsForUsersLoading: true,
  userEventsLoading: true,
  singleEventLoading: true,

  // spaces
  getSpacesLoading: false,
  getSingleSpaceLoading: true,
  getUserSpacesLoading: true,
  spaceRegistrationLoading: false,
  spacePaymentLoading: false,

  // forms
  registerDigMarkBootcampLoading: false,
  registerCritiqueEventLoading: false,
  registerGenericEventLoading: false,

  // REDESIGN
  studentRegisterLoading: false,
  clubRegisterLoading: false,

  // BYPASS
  bypassRegisterLoading: false,
};

const loadingIndicator = (state = initialState, { type, payload }) => {
  switch (type) {

    // register
    case REGISTER_LOADING:
      return {
        ...state,
        registerLoading: payload,
      };

    // login
    case LOGIN_LOADING:
      return {
        ...state,
        loginLoading: payload,
      };

    // update user
    case UPDATE_USER_LOADING:
      return {
        ...state,
        updateUserLoading: payload,
      };

    // update user password
    case UPDATE_USER_PASSWORD_LOADING:
      return {
        ...state,
        updateUserPasswordLoading: payload,
      };

    // delete user
    case DELETE_USER_LOADING:
      return {
        ...state,
        deleteUserLoading: payload,
      };

    // get user's bootcamp
    case GET_USERS_BOOTCAMP_LOADING:
      return {
        ...state,
        bootcampLoading: payload,
      };

    // get bootcamp for all users
    case GET_BOOTCAMP_FOR_USERS_LOADING:
      return {
        ...state,
        bootcampsLoading: payload,
      };

    // post request loading to bootcamp after payment
    case PUT_BOOTCAMP_PAYMENT_LOADING:
      return {
        ...state,
        putBootcampPaymentLoading: payload,
      };

    // get specific bootcamp
    case GET_SPECIFIC_BOOTCAMP_LOADING:
      return {
        ...state,
        specificBootcampLoading: payload,
      };

    // get all club packages
    case GET_CLUBS_PACKAGES_LOADING:
      return {
        ...state,
        clubsPackagesLoading: payload,
      };

    // get specific club package
    case GET_SPECIFIC_PACKAGE_LOADING:
      return {
        ...state,
        specificPackageLoading: payload,
      };

    // get events for users
    case GET_EVENTS_FOR_USERS_LOADING:
      return {
        ...state,
        eventsForUsersLoading: payload,
      }
    
    // get user events
    case GET_USER_EVENTS_LOADING:
      return {
        ...state,
        userEventsLoading: payload,
      }

    // get single event
    case GET_SINGLE_EVENT_LOADING:
      return {
        ...state,
        singleEventLoading: payload,
      }

    // get user club subscription
    case GET_USER_CLUB_SUBSCRIPTION_LOADING:
      return {
        ...state,
        userClubSubscriptionsLoading: payload,
      }
    
    // put user to event loading 
    case PUT_USER_TO_EVENT_LOADING:
      return {
        ...state,
        putUserToEventLoading: payload,
      }
    
    // put club subscription payment loading
    case PUT_CLUB_SUBSCRIPTION_PAYMENT_LOADING:
      return {
        ...state,
        putClubSubscriptionPaymentLoading: payload,
      }
    
    // get spaces
    case GET_SPACES_LOADING:
      return {
        ...state,
        getSpacesLoading: payload,
      }
    
    // get single space
    case GET_SINGLE_SPACE_LOADING:
      return {
        ...state,
        getSingleSpaceLoading: payload,
      }
    
    // get user's spaces
    case GET_USER_SPACES_LOADING:
      return {
        ...state,
        getUserSpacesLoading: payload,
      }
    
    // register bootcamp loading
    case REGISTER_BOOTCAMP_LOADING:
      return {
        ...state,
        registerBootcampLoading: payload,
      }
    
    // register dgital marketing bootcamp loading
    case REGISTER_DIGMARK_BOOTCAMP_LOADING:
      return {
        ...state,
        registerDigMarkBootcampLoading: payload,
      }
    
    // register critique event loading
    case REGISTER_CRITIQUE_EVENT_LOADING:
      return {
        ...state,
        registerCritiqueEventLoading: payload,
      }
    
    // register generic event loading
    case REGISTER_GENERIC_EVENT_LOADING:
      return {
        ...state,
        registerGenericEventLoading: payload,
      }

    // REDESIGN
    // student registration
    case STUDENT_REGISTER_LOADING:
      return {
        ...state,
        studentRegisterLoading: payload
      }

    // BYPASS
    // student registration
    case BYPASS_REGISTER_LOADING:
      return {
        ...state,
        bypassRegisterLoading: payload
      }

    // space registration
    case SPACE_REGISTRATION_LOADING:
      return {
        ...state,
        spaceRegistrationLoading: payload
      }

    // space payment
    case SPACE_PAYMENT_LOADING:
      return {
        ...state,
        spacePaymentLoading: payload
      }

    // club registration
    case CLUB_REGISTER_LOADING:
      return {
        ...state,
        clubRegisterLoading: payload
      }
    default:
      return state;

  }
};

export default loadingIndicator;
