import React from 'react'
import { CircularProgress } from "@material-ui/core";

const EditProfileBtn = ({ title, loading, onClick, disabled }) => {
  return (
    <>
        <button 
        onClick={onClick}
        className='mt-3 h-[44px] bg-[#7F56D9] flex justify-center items-center font-semibold text-base text-white px-7 lg:text-xs rounded-[8px] hover:opacity-80'>
            
            {loading ? (
            <div className="text-center">
                <CircularProgress color={"inherit"} size="20px" />
            </div>
            ) : title ? (
                title
            ) : (
                "Save"
            )}
        </button>
    </>
  )
}

export default EditProfileBtn