import React from 'react'
import NavBtn from '../../../components/Global/NavBtn';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import ProfileDisplayInfo from '../../../components/Global/ProfileDisplayInfo';

const MobileNavigationBar = ({toggleNav}) => {
    return (
        <div className='hidden mobile:block w-full h-[88px]'>
            <div className='hidden mobile:flex justify-between items-center fixed px-6 h-[88px] py-5 top-0 left-0 w-screen bg-white z-[900] [box-shadow:0px_4px_12px_rgba(0,0,0,0.16)]'>
                <ProfileDisplayInfo />
                
                <NavBtn onClick={toggleNav}>
                    <FontAwesomeIcon size="2xl" icon={faBars} />
                </NavBtn>             
            </div>    
        </div>
    )
}

export default MobileNavigationBar