import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Events from '.'
import SingleEvent from './SingleEvent'

const EventsRouter = ({path}) => {
    
    return (
        <Switch>
            
            {/* index page */}
            <Route 
            exact
            path={path}
            component={Events}/>
            
            {/* single event page */}
            <Route 
            exact
            path={`${path}/:eventId`}
            component={SingleEvent}/>

        </Switch>
    )
}

export default EventsRouter