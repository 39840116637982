import { 
    GET_USER_CLUB_SUBSCRIPTION_REQUEST,
    GET_USER_CLUB_SUBSCRIPTION_SUCCESS,
    GET_USER_CLUB_SUBSCRIPTION_LOADING,
    GET_USER_CLUB_SUBSCRIPTION_FAIL, 
    CLEAR_CLUB_SUBSCRIPTION_PAYMENT_SUCCESS,
    PUT_CLUB_SUBSCRIPTION_PAYMENT_LOADING,
    PUT_CLUB_SUBSCRIPTION_PAYMENT_SUCCESS,
    PUT_CLUB_SUBSCRIPTION_PAYMENT_FAIL,
    PUT_CLUB_SUBSCRIPTION_PAYMENT_REQUEST
} from "../types";

// GET user club subscription
export const getUserClubSubsciptionRequest = (prop) => {
    return {
        type: GET_USER_CLUB_SUBSCRIPTION_REQUEST,
        payload: prop
    }
}

export const getUserClubSubsciptionSuccess = (prop) => {
    return {
        type: GET_USER_CLUB_SUBSCRIPTION_SUCCESS,
        payload: prop
    }
}

export const getUserClubSubsciptionLoading = (loading) => {
    return {
        type: GET_USER_CLUB_SUBSCRIPTION_LOADING,
        payload: loading
    }
}

export const getUserClubSubsciptionFailure = (errors) => {
    return {
        type: GET_USER_CLUB_SUBSCRIPTION_FAIL,
        payload: {errors}
    }
}

// PUT club suscription payment
export const putClubSubscriptionPaymentRequest = (prop) => {
    return {
        type: PUT_CLUB_SUBSCRIPTION_PAYMENT_REQUEST,
        payload: prop
    }
}

export const putClubSubscriptionPaymentFailure = (errors) => {
    return {
        type: PUT_CLUB_SUBSCRIPTION_PAYMENT_FAIL,
        payload: { errors }
    }
}

export const putClubSubscriptionPaymentSuccess = (prop) => {
    return {
        type: PUT_CLUB_SUBSCRIPTION_PAYMENT_SUCCESS,
        payload: prop
    }
}

export const putClubSubscriptionPaymentLoading = (loading) => {
    return {
        type: PUT_CLUB_SUBSCRIPTION_PAYMENT_LOADING,
        payload: loading
    }
}

export const clearClubSubscriptionPaymentSuccess = () => {
    return {
        type: CLEAR_CLUB_SUBSCRIPTION_PAYMENT_SUCCESS
    }
}