import React from "react";
import Footer from "../../../components/BootcampForm/Footer";
import Body from "../../../components/DataAnalysisForm/Body";
import Header from "../../../components/DataAnalysisForm/Header";
import SuccessMessage from "../../../components/DataAnalysisForm/SuccessMessage";

const ThankyouPage = () => {
  return (
    <div className="flex flex-col w-full min-h-screen">
      {/* Header */}
      <Header />

      {/* Body */}
      <Body>
        {/* Success message */}
        <SuccessMessage />
      </Body>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ThankyouPage;
