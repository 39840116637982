import React from "react";
import bShape1 from "../../assets/form/digital-marketing/images/b-shape1.png";
import bShape2 from "../../assets/bootcamp-form/images/b-shape2.png";

const Body = ({ children }) => {
  return (
    <div className="w-full bg-white relative mobile:mt-[540px]  mt-[400px]  z-[5] flex items-center justify-center pb-5  mobile:py-4 px-20 mobile:px-2 container mx-auto">
      {/* shapes */}
      <img
        src={bShape2}
        className="absolute top-[25%] right-[2.6rem] z-[90] mobile:hidden"
        alt=""
      />
      <img
        src={bShape1}
        className="absolute bottom-[55%] left-[4rem] z-[90] mobile:hidden"
        alt=""
      />

      {/* Form */}
      {children}
    </div>
  );
};

export default Body;
