import React from 'react'
import BtnPrimary from '../../../Global/Redesign/BtnPrimary'
import ShowcaseCard from './ShowcaseCard'
import image1 from "../../../../assets/webpages/home/images/showcase1.png"
import image2 from "../../../../assets/webpages/home/images/showcase2.png"
import image3 from "../../../../assets/webpages/home/images/showcase3.png"
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

const Showcase = () => {

    // OBJECT INITIALIZATION
    const history = useHistory()

    return (
        <div className='flex flex-col container mobile:px-3 mx-auto gap-28 mobile:gap-16 relative 2xl:top-[-10rem] top-[-7rem] items-center mobile:top-[-14rem]'>
            
            {/* cards collection */}
            <div className='grid grid-cols-[auto] pc:grid-cols-[auto_auto_auto] w-fit gap-9 mobile:px-3'>
                <ShowcaseCard
                image={image1}
                caption={"Connect and exchange ideas with great minds"} />

                <ShowcaseCard
                image={image2}
                drop={true}
                caption={"Sharpen your tech skills"} />

                <ShowcaseCard
                image={image3}
                caption={"Gain access to capital funds and projects with attractive returns"} />
            </div>

            {/* button */}
            <div className='w-full flex items-center justify-center mobile:flex-col mobile:items-stretch mobile:w-full mobile:px-4 container mx-auto'>
                <BtnPrimary
                onClick={()=>history.push("/login")}
                title="Join us today" />
            </div>
        </div>
    )
}

export default Showcase