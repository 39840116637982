import React from 'react'
import ProfileAvatar from '../../../components/Dashboard/Home/ProfileAvatar'
import ProfileDisplayInfo from '../../../components/Global/ProfileDisplayInfo'
import BackBtn from '../../../components/Global/Redesign/BackBtn'
import SideNavigation from './SideNavigation'

const PageWrapper = ({children, enableBackBtn, title, subTitle}) => {
    return (
        <div className='flex justify-end mobile:justify-start mobile:w-screen mobile:flex-col min-h-screen w-full mobile:bg-[#F8F8F8]'>

            {/* Side navigation */}
            <SideNavigation />

            {/* Body section */}
            <section className='w-[80vw] mobile:w-full bg-[#F8F8F8] py-16 px-12 mobile:px-4'>
                

                {/* container */}
                <div className='flex flex-col items-start gap-[32px] container mx-auto w-full h-full'>

                    {/* back button */}
                    {
                        enableBackBtn &&
                        <BackBtn />
                    }

                    {/* Header */}
                    <header  className='flex justify-between items-start w-full'>

                        {/* text container */}
                        <div className='flex flex-col gap-[4px]'>

                            {/* title */}
                            <p className='font-semibold text-2xl text-[#1D2433] mt-0'>
                                {title}
                            </p>

                            {/* sub title */}
                            <p className='font-normal text-base text-[#1D2433] mt-0'>
                                {subTitle}
                            </p>
                        </div>

                        {/* profile dp */}
                        <div className='mobile:hidden'>
                            <ProfileDisplayInfo />
                        </div>

                    </header>

                    {children}

                </div>
            </section>
        </div>
    )
}

export default PageWrapper