import React from 'react'
import { FiCheckCircle } from "react-icons/fi"

const CheckCircle = () => {
    return (
        <>
            <span className="w-[32px] h-[32px] rounded-[50%] bg-[#F8F9FC] flex items-center justify-center">
                <FiCheckCircle color="#08875D" />
            </span>
        </>
    )
}

export default CheckCircle