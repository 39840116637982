import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { useHistory } from 'react-router-dom'

const RedirectCard = ({
    image,
    linkText,
    path,
    title
}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()

    return (
        <div className='w-full relative'>
            
            {/* text section */}
            <div className='bg-[#D4F4FF] rounded-[10px] flex flex-col w-[95%] p-5 gap-4 h-[155px] justify-center'>

                <p className='mt-0 font-normal text-sm text-[#3F444D] w-[45%]'>
                    {title}
                </p>

                <p 
                onClick={()=>history.push(path)}
                className='mt-0 font-bold text-sm text-[#1D2433] cursor-pointer hover:opacity-80 flex items-center gap-2'>
                    {linkText}
                    <IoIosArrowForward
                    className="text-lg text-[#1D2433]" />
                </p>
            </div>

            {/* absolutely fixed image */}
            <img src={image} className='absolute bottom-0 right-0 w-[50%] h-[155px] object-cover' alt="" />
        </div>
    )
}

export default RedirectCard