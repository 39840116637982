import React from 'react'
import EventCard from './EventCard'
import { getRTDate, MONTHS } from "../../../../helpers/getRTDate"
import { Skeleton } from '@mui/material'

const CardContainer = ({
    title,
    events,
    loading
}) => {

    return (
        <div className='flex flex-col gap-5 w-[80%] mobile:w-full 2xl:w-[75%] container mx-auto h-full '>

            {/* caption */}
            <p className='text-[#1D2433] text-[28px] font-semibold'>
                {title}
            </p>

            {/* event card container  */}
            {/* card */}
            {
                loading?

                <Skeleton
                variant="rectangular"
                sx={{
                width: "100%",
                height: "50vh",
                borderRadius: "10px",
                }} />:

                events && events.length!==0?

                <div className="grid mobile:grid-cols-1 md:!grid-cols-2 lg:grid-cols-2 grid-cols-3 items-center justify-center gap-[46px] container mx-auto mobile:gap-10">
                    {
                        events.map(event=>(
                            <EventCard
                            status={event.is_Ended?"PAST":"UPCOMING"}
                            key={event._id}
                            path={event._id}
                            eventImage={event.imgurl}
                            date={`${getRTDate.date(event.date)} ${MONTHS[getRTDate.month(event.date)]}, ${getRTDate.year(event.date)}`}
                            venue={event.location}
                            title={event.name}
                            desc={event.description.substring(0, 80).concat("...")}
                            />
                        ))
                    }
                </div>:
                <div className='flex justify-center items-center w-full h-[45vh] text-2xl border-2 border-gray-100 rounded-[10px]'>Nothing Here</div>
            }
        </div>
    )
}

export default CardContainer