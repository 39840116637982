import React from "react";
import SideNav from "../../../layouts/Dashboard/SideNav";
import ProfileAvatar from "../../../components/Dashboard/Home/ProfileAvatar";
import jumboImage from "./../../../assets/dashboard/courses/images/jumbo-image.png"
import BtnSm from "../../../components/Global/BtnSm";
import BackBtn from "../../../components/Dashboard/Global/BackBtn";

const GetStarted = () => {

    return (
        <div className="flex justify-end mobile:w-screen mobile:flex-col min-h-screen w-full mobile:bg-[#E5E5E5]">

            {/* SideNav */}
            <SideNav />

            {/* Home Body */}
            <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12 mobile:pt-8'>

                {/* header */}
                <header className='container mx-auto mobile:px-4 flex justify-between items-center'>
                    <div className="mobile:flex mobile:flex-col-reverse gap-3 flex flex-col mobile:gap-6">
                        <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                            Courses
                        </p>

                        <BackBtn />
                    </div>
                    

                    {/* profile dp */}
                    <div className='mobile:hidden'>
                        <ProfileAvatar />
                    </div>
                </header>

                {/* middle section */}
                <section className='container mx-auto flex w-full justify-between mt-12 gap-9 mobile:px-4'>

                    {/* pathway info */}
                    <div className="flex justify-between bg-white rounded-[10px] [box-shadow:0_2px_8px_rgb(0,0,0,0.12)] py-6 px-12">

                        {/* text section */}
                        <div className="flex flex-col w-[50%] gap-4">

                            {/* pathway label */}
                            <span className='rounded-[20px] bg-[#F8F5FF] flex justify-center items-center text-[#3F444D] p-2 px-3 font-normal text-xs w-fit'>
                                Pathway 1
                            </span>

                            {/* course name and description */}
                            <div className="text-[#1D2433] flex flex-col gap-1">
                                <p className="text-[36px] leading-none font-semibold opacity-80 my-2">
                                    Web App Development
                                </p>

                                <p className="font-semibold opacity-[0.65] text-2xl">
                                    Front-End
                                </p>

                                <p className="font-normal text-base opacity-[0.65]">
                                    Getting started in web development can be daunting. In this course, you will learn how to use HTML, CSS and JavaScript to start your journey towards web development.
                                </p>
                            </div>
                        </div>

                        {/* image section */}
                        <div>
                            <img src={jumboImage} className="w-[291px] object-cover" alt="" />
                        </div>
                    </div>
                </section>

                {/* main summary */}
                <section className="w-full mt-8 container mx-auto">
                    <div className="flex flex-col w-fit">

                        {/* Summary */}
                        <div className="flex flex-col gap-1 justify-between bg-white rounded-[10px] [box-shadow:0_2px_8px_rgb(0,0,0,0.12)] p-6">
                            <p className="text-[#3F444D] font-semibold text-xl">
                                Summary
                            </p>
                            
                            <p className="text-sm mt-6 font-normal text-[#1d2433] opacity-[0.65] flex items-center gap-2 border-b border-[#e1e6ef] pb-4 w-fit">
                                <span>Original price:</span>
                                <span>NGN 230,000</span>
                            </p>

                            <p className="text-xl font-semibold text-[#1d2433] flex items-center gap-2 border-b border-[#e1e6ef] pb-4 w-fit">
                                <span className="text-[#8B54F7]">
                                    Promo price:
                                </span>
                                <span className="opacity-[0.65]">
                                    NGN 120,000
                                </span>
                            </p>

                            <p className="font-semibold text-sm text-[#1d2433] opacity-[0.65]">
                                By completing payment you agree to our terms of service
                            </p>

                            <div className="flex flex-col items-stretch mt-3">
                                <BtnSm title={"Make Payment"} />
                            </div>
                        </div>
                    </div>
                </section>
                         
            </section>
        </div>
    )
}

export default GetStarted