import React from 'react'
import {ReactComponent as Check} from "./../../../assets/dashboard/home/svg/check.svg"
import {ReactComponent as Uncheck} from "./../../../assets/dashboard/home/svg/uncheck.svg"

const ClubOption = ({title, basic, intermediate, advanced}) => {
    return (
        <>
            <div className='grid mobile:hidden grid-cols-[40%_20%_20%_20%]  text-[#667085] font-semibold text-sm  border-b pb-4 pt-7 border-[#F1F3F9]'>
                <p>
                    {title}
                </p>

                {/* mobile basic */}
                {basic?
                    <Check />:<Uncheck />
                }

                {/* intermediate */}
                {intermediate?
                    <Check />:<Uncheck />
                }

                {/* advanced */}
                {advanced?
                    <Check />:<Uncheck />
                }

            </div>

            {/* mobile */}
            {/* className='hidden mobile:flex mobile:flex-col mobile:gap-4' */}
            <div className='hidden mobile:flex mobile:flex-col  text-[#667085] font-semibold text-sm  border-b pb-4 pt-10 border-[#F1F3F9] gap-4'>
                <p>
                    {title}
                </p>

                <div className='grid grid-cols-[33%_33%_33%]'>

                {/* basic */}
                <div className='mobile:hidden'>
                    {basic?
                        <Check />:<Uncheck />
                    }
                    </div>

                    {/* mobile basic */}
                    {basic?
                        <Check />:<Uncheck />
                    }

                    {/* intermediate */}
                    {intermediate?
                        <Check />:<Uncheck />
                    }

                    {/* advanced */}
                    {advanced?
                        <Check />:<Uncheck />
                    }
                </div>
            </div>
        </>
    )
}

export default ClubOption