import { useEffect } from "react";
import AuthFooter from "../../../components/Global/AuthFooter";
import LoginForm from "../../../components/Login/LoginForm";
import AuthHeader from "../../../layouts/AuthHeader";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const Login = () => {
  // history object
  const history = useHistory();

  // destructuring state out
  const { isLoggedIn, user } = useSelector((state) => {
    const { isLoggedIn } = state.userData;
    const { user } = state.userData;
    return {
      isLoggedIn,
      user,
    };
  });

  useEffect(() => {
    if (!(Object.keys(user).length === 0)) {
      history.push("/old/dashboard/home");
    } else {
      console.log("no user's loggedin");
    }
  }, []);

  return (
    <>
      {/* Header */}
      <AuthHeader />

      <div className="flex flex-col">
        {/* login form */}
        <div className="container mx-auto flex flex-col items-center mobile:py-2 mobile:px-6 md:px-10 p-10 pb-24">
          <LoginForm />
        </div>

        {/* footer */}
        <AuthFooter />
      </div>
    </>
  );
};

export default Login;
