import React from 'react'
import AboutInvestmentBody from '../../../../components/Redesign/Dashboard/Investing/AboutInvestment/Body'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'

const AboutInvesting = () => {
    return (
        <PageWrapper
        title={"Investing"}
        subTitle={"Gain profit by investing in startups with the right vision"}>

            {/* body section */}
            <AboutInvestmentBody />

        </PageWrapper>
    )
}

export default AboutInvesting