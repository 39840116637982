import React, { useEffect, useState } from 'react'
import SearchWidget from './SearchWidget'
import SpaceCard from './SpaceCard'
import SpaceInfo from './SpaceInfo'
import { getSpacesRequest } from '../../../../../redux/action'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Skeleton } from '@mui/material'
import { isEmpty } from '../../../../../helpers/isEmtpy'
import { daysDiff } from '../../../../../helpers/daysDiff'

const AvailableSpacesBook = () => {

    // DATA INITIALIZATION
    const location = useLocation()
    const dispatch = useDispatch()
    const param = new URLSearchParams(location.search)
    const start_date = param?.get("start")
    const end_date = param?.get("end")

    
    // STATES
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [spaceModalState, setSpaceModalState] = useState(false)
    const [regModalState, setRegModalState] = useState(false)
    const {
        spaces,
        getSpacesLoading,
    } = useSelector((state)=>{
        const { spaces } = state.spacesReducer
        const { getSpacesLoading } = state.loadingIndicator
        return {
            spaces,
            getSpacesLoading,
        }
    })


    // HANDLERS
    const toggleSpaceModal = () => {
        setSpaceModalState(!spaceModalState)
    }
    const toggleRegModal = () => {
        setRegModalState(!regModalState)
    }


    // SIDE EFFECTS
    useEffect(()=>{
        if(!start_date || !end_date) {
            setStartDate(start_date)
            setEndDate(end_date)
            dispatch(getSpacesRequest({
                start_date,
                end_date
            }))
        }
    }, [start_date, end_date])

    return (
        <div className='flex mobile:flex-col-reverse w-full h-full gap-10'>

            {/* section filled with image and text details */}
            <div className='h-full w-[70%] mobile:w-full flex flex-col gap-5'>

                 {/* spaces container */}
                 {
                        getSpacesLoading?
                        <Skeleton
                        variant="rectangular"
                        sx={{
                        width: "100%",
                        height: "70vh",
                        borderRadius: "10px",
                        }} />:
                        !isEmpty(spaces)?
                        <>

                            {/* caption */}
                            <p className='text-base font-medium text-[#3F444D]'>
                                Available Spaces Found
                            </p>

                            {/* spaces container */}
                            <div className='grid grid-cols-3 lg:grid-cols-2 mobile:grid-cols-1 gap-6 w-full'>

                                {/* cards */}
                                {
                                    spaces.map((space)=>(
                                        <SpaceCard
                                        startDate={startDate}
                                        endDate={endDate}
                                        duration={daysDiff(endDate, startDate)}
                                        spaceId={space._id}
                                        key={space._id}
                                        spaceName={space.name}
                                        price={space.new_price}
                                        image={space.imgurl} />
                                    ))
                                }
                            </div>
                        </>:
                    <div className='flex justify-center items-center w-full h-[70vh] text-2xl border-2 border-gray-100 rounded-[10px]'>Nothing Here</div>
                }
            </div>


            <div className='h-full w-[30%] mobile:w-full flex flex-col gap-5'>
                
                {/* search widget */}
                <SearchWidget
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                end={end_date}
                start={start_date} />

                {/* info on current spaces */}
                <SpaceInfo />
            </div>
        </div>
    )
}

export default AvailableSpacesBook