import React from 'react'
import Header2 from '../../../../layouts/Redesign/Header2'
import shape1 from "../../../../assets/webpages/training/images/e-shape1.png"
import shape2 from "../../../../assets/webpages/training/images/e-shape2.png"

const InvestingHeader = () => {
    return (
        <div className='flex items-center justify-start bg-[#EDFAFE] h-[70vh] mobile:h-auto relative flex-col top-0'>

            {/* little space */}
            <div className='pc:hidden mobile:h-[5.7rem] w-full bg-[#EDFAFE]'>
                [' ']
            </div>

            {/* header */}
            <Header2 />

            {/* jumbotron */}
            <div className='w-full flex flex-col items-center top-[20%] mobile:top-[18%] relative z-[200] gap-4 text-center container mx-auto mobile:px-4 mobile:py-12'>

                <p className='font-semibold text-[45px] mobile:text-4xl text-[#1D2433]'>
                    We find...We Vet...You Invest
                </p>

                <p className='text-[#1D2433] text-2xl mobile:text-[22px] font-normal'>
                    Gain profit by investing in startups with the right vision
                </p>

                <p className='text-gray-800 text-base font-normal pc:w-[45%] mb-4'>
                    It doesn’t matter if you are a novice, amateur, intermediate or expert. We take the stress out of vetting startups for you by providing pre-vetted startups with low risk and sizeable ROI. 
                </p>
            </div>
        </div>
    )
}

export default InvestingHeader