import React from 'react'
import Navbar from '../BootcampForm/Navbar'
import showcaseImage from "../../assets/form/digital-marketing/images/showcase-image.png"
import showcaseImage1 from "../../assets/form/digital-marketing/images/showcase-image1.png"
import showcaseImage2 from "../../assets/form/digital-marketing/images/showcase-image2.png"

const Header = () => {
    return (
        <div className='flex items-center justify-center gap-5 top-0 relative bg-white w-full overflow-hidden'>

            {/* images */}
            <img src={showcaseImage} className='w-full h-full absolute top-0 left-0 z-[1]' alt="" />
                        
            {/* container */}
            <div className='w-[80%] h-full gap-16 lg:gap-10 relative z-[2] flex flex-col pt-10 mobile:w-full mobile:mt-[100px]'>
                <Navbar />
                
                <div className='flex justify-between mobile:flex-col mobile:items-center mobile:justify-start gap-3 mobile:gap-6'>

                    {/* text */}
                    <div className='text-white flex flex-col gap-3 mb-10 mobile:text-center mobile:px-4'>

                        <p className="capitalize mobile:text-xl text-3xl font-extrabold">
                            Our Love Language is
                        </p>

                        <img src={showcaseImage1} className='h-[171px] w-[509px] mobile:h-[113px]' alt="" />

                        <p className="capitalize text-xl font-extrabold mobile:text-base">
                            AN Introduction to Digital Marketing BOOTCAMP
                        </p>
                    </div>

                    <img src={showcaseImage2} className='w-[594px] bottom-0 right-[10%]' alt="" />
                </div>
            </div>

        </div>
    )
}

export default Header