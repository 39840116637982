import React from 'react'
import BtnPrimary from '../../../Global/Redesign/BtnPrimary'
import GuideBox from './GuideBox'
import { useHistory } from 'react-router-dom'

const HowItWorks = () => {

    // OBJECT INITIALIZATION
    const history = useHistory()

    return (
        <div className='py-44 h-full w-full mobile:py-16 px-5 mobile:px-4 flex justify-center items-center bg-[#F8F8F8]'>
            
            {/* container */}
            <div className='container h-full w-[90%] 2xl:w-[80%] mx-auto flex flex-col gap-16 items-center'>

                {/* text */}
                <p className='font-semibold text-[28px] text-[#1D2433]'>
                    How it works
                </p>

                {/* boxes */}
                <div className='grid grid-cols-3 mobile:grid-cols-1 gap-16'>

                    <GuideBox
                    number={1}
                    title={"Register"}
                    desc={"We just want to get to know you better and hear your beautiful story. So register with your correct details."} />

                    <GuideBox
                    number={2}
                    title={"Browse Investments"}
                    desc={"Review hundreds of investment opportunities, from Startups to Collectibles, all available and accessible on a personalized dashboard just for you."} />

                    <GuideBox
                    number={3}
                    title={"Make an Investment"}
                    desc={"Submit your payment and own a financial stake in a Startup."} />
                </div>

                {/* button */}
                <BtnPrimary
                onClick={()=>history.push("/registration")}
                title="Start investing today" />
            </div>
        </div>
    )
}

export default HowItWorks