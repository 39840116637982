import { Skeleton } from '@mui/material'
import React from 'react'
import { getRTDate, MONTHS } from '../../../../helpers/getRTDate'
import EventCard from './EventCard'

const EventCardContainer = ({eventStatus, events, loading}) => {

    // OBJECT INITIALIZATION
    const dataset = [1,2,3,4,5,6,7,8]

    return (
        <>
        {            
            loading?
            <Skeleton
            variant="rectangular"
            sx={{
            width: "100%",
            height: "50vh",
            borderRadius: "10px",
            marginTop: "16px"
            }} />:
            events && events.length!==0?
            <div className="mt-4 grid md:!grid-cols-2 grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 mobile:grid-cols-1 gap-y-4 gap-x-3">
                
                {
                    events.map((event, index)=>(
                        <EventCard
                        status={event.is_Ended?"PAST":"UPCOMING"}
                        key={event._id}
                        path={event._id}
                        eventImage={event.imgurl}
                        date={`${getRTDate.date(event.date)} ${MONTHS[getRTDate.month(event.date)]}, ${getRTDate.year(event.date)}`}
                        venue={event.location}
                        title={event.name}
                        desc={event.description.substring(0, 80).concat("...")} />
                    ))
                }

            </div>:
            <div className='flex justify-center items-center w-full h-[45vh] text-2xl rounded-[10px]'>Nothing Here</div>
        }
        </>
    )
}

export default EventCardContainer