import React from 'react'
import SingleInvestmentBody from '../../../../components/Redesign/Dashboard/Investing/SingleInvestment/Body'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'

const SingleInvestment = () => {
    return (
        <PageWrapper
        enableBackBtn={true}
        title={"Investing"}
        subTitle={"Gain profit by investing in startups with the right vision"}>

            {/* body section */}
            <SingleInvestmentBody/>

        </PageWrapper>
    )
}

export default SingleInvestment