import React from 'react'
import AuthFooter from '../../../components/Global/AuthFooter'
import SignupForm from '../../../components/SignUp/SignupForm'
import AuthHeader from '../../../layouts/AuthHeader'

const SignUp = () => {
    return (
        <>
            {/* Header */}
            <AuthHeader />

            <div className='flex flex-col'>
                
                {/* login form */}
                <div className='container mx-auto flex flex-col items-center mobile:py-2 mobile:px-6 md:px-10 p-10 pb-24'>
                    <SignupForm />
                </div>

                {/* footer */}
                <AuthFooter />
            </div>
        </>
    )
}

export default SignUp