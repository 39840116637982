import { CLEAR_REGISTER_GENERIC_EVENT_SUCEESS, REGISTER_GENERIC_EVENT_FAIL, REGISTER_GENERIC_EVENT_LOADING, REGISTER_GENERIC_EVENT_REQUEST, REGISTER_GENERIC_EVENT_SUCCESS } from "../types";

// register generic event form
export const genericEventsRequest = (prop) => {
    return {
        type: REGISTER_GENERIC_EVENT_REQUEST,
        payload: prop,
    };
};

export const genericEventsSuccess = (prop) => {
    return {
        type: REGISTER_GENERIC_EVENT_SUCCESS,
        payload: prop,
    };
};

export const genericEventsFailure = (prop) => {
    return {
        type: REGISTER_GENERIC_EVENT_FAIL,
        payload: prop,
    };
};

export const genericEventsLoading = (prop) => {
    return {
        type: REGISTER_GENERIC_EVENT_LOADING,
        payload: prop,
    };
};

export const clearGenericEventsSuccess = () => {
    return {
        type: CLEAR_REGISTER_GENERIC_EVENT_SUCEESS
    };
};