import React, { useCallback, useEffect } from 'react'

const FixedNoticeBoard = ({children, id, hasScrolled, setHasScrolled}) => {

    // HANDLERS
    const handleScrollEvent = useCallback(() => {
        let elementToWatch = document.getElementById(id);
        if (window.scrollY > (elementToWatch?.offsetTop || 940) + (elementToWatch?.offsetHeight || 580)){
            setHasScrolled(true)
        } else {
            setHasScrolled(false)
        }
    }, [])


    // SIDE EFFECTS
    useEffect(() => {
        window.addEventListener("scroll", handleScrollEvent)
        return () => {
            window.removeEventListener("scroll", handleScrollEvent)
        }
    }, [handleScrollEvent]);

    return (
        <div className={`hidden mobile:flex transition-[.4s_linear_transform] ${hasScrolled?"translate-x-0 right-0":"translate-x-[-1000rem]"} items-start justify-start w-full fixed bottom-0 z-[100]`}>
            {children}
        </div>
    )
}

export default FixedNoticeBoard