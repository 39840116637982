import React from 'react'

const LearnMoreBtn = ({ onClick}) => {
    return (
        <>
            <button className='h-[44px] mobile:h-[48px] bg-white flex justify-center border-2 border-[#aaa] items-center font-semibold text-base lg:text-xs text-[#8B54F7] px-7 rounded-[8px] hover:bg-[#f5f5f5]'
            onClick={onClick}>
                Learn more
            </button>
        </>
    )
}

export default LearnMoreBtn