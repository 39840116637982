import Tabs,{Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import "../Home/styles/react-tabs-custom-style.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import MyModal from '../../../layouts/MyModal';
import SpecialTrackInfoView from '../Home/SpecialTrackInfoView';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BootcampCard from './BootcampCard';
import { getBootcampForUsersRequest } from '../../../redux/action';
import { Skeleton } from '@mui/material';

const AvailableCourses = () => {

    // dispatch object
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getBootcampForUsersRequest())
    }, [])

    // bootcamps state
    const { bootcamps,bootcampsLoading  } = useSelector((state)=>{
        const { bootcamps } = state.bootcampReducer
        const { bootcampsLoading } = state.loadingIndicator
        return { bootcamps,bootcampsLoading }
    })

    const [specialTrackModalState, setSpecialModalState] = useState(false)

    // handle modal toggling
    const toggleSpecialTrackModal = () => {
        setSpecialModalState(!specialTrackModalState)
    }

    // special track tab
    const specialTrack = <span className='flex gap-2 items-center text-base font-normal'>
        Special track
        <FontAwesomeIcon 
        onClick={toggleSpecialTrackModal}
        color='#8B54F7' 
        icon={faCircleInfo} 
        size="lg" />
    </span>

    // regular track tab
    const regularTrack = <span className='font-normal text-base'>
        Regular track
    </span>

    return (
        <div className={"flex flex-col"}>
            
            {/* caption */}
            <p className='text-[#3F444D] text-xl font-semibold mb-5'>
                Available courses
            </p>

            <section className='bg-[#fff] mobile:bg-transparent rounded-[10px] p-3 mobile:p-0'>
                <Tabs 
                activeTab="1" 
                className={`mt-3`} 
                ulClassName="!border-[#ccc]" 
                activityClassName="!text-[#8B54F7] bg-green-700" 
                onClick={(event, tab) => console.log(event, tab)} >
                    
                    <Tab 
                    title={regularTrack} 
                    className="mb-2 mr-7 !px-2">
                        <div className="mt-4 bg-[#F8F9FC] mobile:bg-transparent mobile:p-1 p-[13px] rounded-[10px] pb-12">
                            <div className='bg-transparent mobile:flex-col lg:grid lg:grid-cols-[auto_auto] lg:justify-evenly flex justify-between items-start mobile:gap-8 gap-4'>
                                {
                                    bootcampsLoading?
                                    <Skeleton 
                                    variant="rectangular" 
                                    sx={{ 
                                        width: '100%', 
                                        height: "30vh",
                                        borderRadius: "10px"
                                    }} />: 
                                    bootcamps.map((object)=>(
                                        object.course_type==="regular"&&<BootcampCard data={object} key={object._id} />
                                    ))
                                }
                            </div>
                        </div>
                    </Tab>
                
                    <Tab 
                    title={specialTrack} 
                    className="mr-3 mb-2 !px-2">
                        <div className="mt-4 bg-[#fff] mobile:bg-transparent mobile:p-1 p-[13px] rounded-[10px] pb-12">
                            <div className='bg-transparent mobile:flex-col flex justify-center items-start mobile:gap-8 gap-4'>

                                {
                                    bootcampsLoading?
                                    <Skeleton 
                                    variant="rectangular" 
                                    sx={{ 
                                        width: '100%', 
                                        height: "30vh",
                                        borderRadius: "10px"
                                    }} />:
                                    bootcamps.length===0?
                                    <div>empty</div>: 
                                    bootcamps.map((object)=>(
                                        object.course_type==="pathway"&&<BootcampCard 
                                        data={object} 
                                        key={object._id} />
                                    ))
                                }
                            </div>
                        </div>
                    </Tab>
                </Tabs>

                {/* Special Track Modal */}
                <MyModal
                disableBtn={true}
                modalIsOpen={specialTrackModalState}
                toggleModal={toggleSpecialTrackModal}>
                    <SpecialTrackInfoView />
                </MyModal>
            </section>
                            
        </div>
    )
}

export default AvailableCourses