import React from 'react'
import tempImage from "../../../../../assets/redesign-dashboard/investing/images/sort-image.png"
import { Progress } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useHistory } from 'react-router-dom';
import BtnPrimary from '../../../../Global/Redesign/BtnPrimary';

const PaymentInfoCard = () => {

    // OBJECT INITIALIZATION
    const history = useHistory()

    return (
        <div 
        id="paymentInfoCard"
        className='p-5 [box-shadow:0px_4px_12px_rgba(0,0,0,0.16)] flex flex-col gap-8 rounded-[10px] bg-white h-fit w-full'>

            <img src={tempImage} className="w-[192px] self-center" alt="" />

            <div className="flex flex-col">
                <div className="w-[100%] -ml-2">
                    <Progress.Line
                        percent={30}
                        showInfo={false}
                        strokeColor="#0B8CBA"
                    />
                </div>

                <span className="text-gray-600 font-semibold text-xl">
                    NGN 120,000
                </span>
                <span className="text-gray-600 font-medium text-sm">
                    raised out of NGN 3,000,000
                </span>
            </div>

            <div className='flex flex-col gap-1'>
                <span className="text-gray-600 font-semibold text-xl">
                    46
                </span>
                <span className="text-gray-600 font-normal text-sm">
                    Backers
                </span>
            </div>

            <div className='flex flex-col gap-1'>
                <span className="text-gray-600 font-semibold text-xl">
                    3
                </span>
                <span className="text-gray-600 font-normal text-sm">
                    Months to go
                </span>
            </div>

            <div className="flex flex-col items-stretch">
                <BtnPrimary
                onClick={()=>history.push("/dashboard/investing/payment/kljkjadfjsdkfjskfjskfdk")} 
                title={"Back this Project"} />    
            </div>            
        </div>
    )
}

export default PaymentInfoCard