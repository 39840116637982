import React from 'react'

const Video2 = () => {
    return (
        <div className="mt-20 flex flex-col justify-center items-center container mx-auto mobile:px-2 md:px-12">
            <iframe
                className="rounded-[20px] mobile:rounded-[10px] mobile:h-[300px] mobile:w-[100%]"
                width="70%"
                height="515"
                src="https://www.youtube.com/embed/jR6TJc6fnj8"
                title="YouTube video player"
                frameBorder={"0"}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscrope; picture-in-picture"
                allowFullScreen
            ></iframe>
            </div>
    )
}

export default Video2