import React from "react";
import EventJumbotron from "./EventJumbotron";

const Showcase = () => {
  return (
    <div className="flex flex-col items-stretch [border:0px_transparent_solid] -mt-1 w-full">
      {/* first div */}
      <div className="w-full flex mobile:h-[310px] 2xl:h-[200px] h-[200px] bg-[#FFF8EB]"></div>

      {/* second div */}
      <div className="w-full flex mobile:h-[310px] 2xl:h-[200px] h-[200px] relative">
        {/* component */}
        <EventJumbotron />
      </div>
    </div>
  );
};

export default Showcase;
