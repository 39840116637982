import React from 'react'
import aShape1 from "../../../../assets/webpages/home/images/accord-shape1.png"
import SingleAccordion from './SingleAccordion'
import image1 from "../../../../assets/webpages/home/images/accordion-image1.png"
import image2 from "../../../../assets/webpages/home/images/accordion-image2.png"
import image3 from "../../../../assets/webpages/home/images/accordion-image3.png"
import image4 from "../../../../assets/webpages/home/images/accordion-image4.png"

const Accordion = () => {
    return (
        <div className='pb-24 pt-36 flex flex-col relative w-full text-center items-center gap-20 container mx-auto mobile:px-4'>
            
            <p className='font-semibold mobile:text-2xl text-4xl text-white'>
                <span>So, what are you here for?</span>
            </p>
            <img src={aShape1} className="w-[40px] absolute right-[30%] top-[5.2rem] mobile:w-[26px] mobile:right-[1rem]" alt="" />

            {/* accordion */}
            <div className="flex flex-col w-full items-center">

                <SingleAccordion
                number={1}
                image={image1}
                headerText="I want to learn a tech skill"
                cardTitle={"Learn a tech skill"}
                description="We adequately train a host of students by deeply immersing them in projects that provide them with the necessary skills for a successful career in the tech industry."
                linkText={"View all available courses"}
                linkPath="/training" />

                <SingleAccordion
                number={2}
                image={image2}
                headerText="I want to become a member"
                cardTitle={"Become a member of fast growing tech community"}
                description="We give you a thriving environment alien to what is obtainable in the city, that helps you create the solution you want to offer to the world. Then we go beyond that to also provide mentorship and a formidable ecosystem where dreamers and builders like you can connect with other dreamers and builders as well as have access to funding that ensures your dreams become realities."
                linkText={"View Membership offers"}
                linkPath="/club" />

                <SingleAccordion
                number={3}
                image={image3}
                headerText="I want to Invest in Projects"
                cardTitle={"Invest in Projects"}
                description="We give you a thriving environment alien to what is obtainable in the city, that helps you create the solution you want to offer to the world. Then we go beyond that to also provide mentorship and a formidable ecosystem where dreamers and builders like you can connect with other dreamers and builders as well as have access to funding that ensures your dreams become realities."
                linkText={"View available projects"}
                linkPath="/investing" />

                <SingleAccordion
                number={4}
                image={image4}
                headerText="I need a co-working space"
                cardTitle={"Book a co-working space"}
                description="We give you a thriving environment alien to what is obtainable in the city, that helps you create the solution you want to offer to the world. Then we go beyond that to also provide mentorship and a formidable ecosystem where dreamers and builders like you can connect with other dreamers and builders as well as have access to funding that ensures your dreams become realities."
                linkText={"Book a space now"}
                linkPath="/spaces" />

            </div>
        </div>
    )
}

export default Accordion