import { 
  FormControlLabel, 
  Radio, 
  RadioGroup, 
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { FormError } from "../Errors";
import BtnPrimary from "../Global/Redesign/BtnPrimary";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerBootcampRequest } from "../../redux/action";

const BootcamForm = () => {

  // OBJECT INITIALIZATION
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = createTheme({
    typography: {
      fontFamily: "poppins, sans-serif",
    },
    palette: {
      primary: {
        main: "#0B8CBA",
      },
    },
  });


  // STATES
  const [techSkillField, setTechSkillField] = useState("");
  const {
    registerBootcampLoading,
    registerBootcampError,
    registerBootcampSuccess,
  } = useSelector((state) => {
    const { registerBootcampLoading } = state.loadingIndicator;
    const {
      success: { registerBootcamp: registerBootcampSuccess },
      errors: { registerBootcamp: registerBootcampError },
    } = state.ajaxStatuses;
    return {
      registerBootcampLoading,
      registerBootcampError,
      registerBootcampSuccess,
    };
  });


  // SIDE EFFECTS
  useEffect(() => {
    if (registerBootcampError) {
      toast.error(registerBootcampError, {
        duration: 3000,
      });
    }
  }, [registerBootcampError]);
  useEffect(() => {
    if (registerBootcampSuccess) {
      history.replace(`/bootcampsuccess`);
    }
  }, [registerBootcampSuccess]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
        bootcampName: "CC",
      //  tech_skill: "",
        // reason: "",
      }}
      onSubmit={(values) => {
        const userData = { ...values };
        
        // userData.tech_skill = techSkillField;
        console.log(userData)

        dispatch(
          registerBootcampRequest({
            userData,
          })
        );
      }}
      validationSchema={Yup.object({
        firstname: Yup.string().required(),
        lastname: Yup.string().required(),
        email: Yup.string().email().required(),
        mobile: Yup.number().required(),
        // reason: Yup.string().required(),
      })}
    >
      {({
        values,
        dirty,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        field,
      }) => {
        return (
          <div className="rounded-[8px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] flex flex-col items-center w-[70%] px-32 py-24 mobile:w-full mobile:shadow-none mobile:px-4 md:!px-7 mobile:py-6 gap-8">
            
            {/*  text */}
            <div className="flex flex-col gap-1 text-center px-3">
              <p className="font-normal text-base text-[#1D2433]">
                Enter your correct details below and our customer care representative will reach out to you immediately
              </p>
            </div>

            {/* form */}
            <form
            className="w-full flex flex-col gap-8 items-stretch"
            onSubmit={(e) => e.preventDefault()}>

              {/* first name */}
              <label className="flex flex-col gap-2">

                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    First name
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="firstname"
                  value={values.firstname}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Enter your firstname"
                />
                {touched.firstname && errors.firstname && (
                  <FormError message={errors.firstname} />
                )}
              </label>

              {/* last name */}
              <label className="flex flex-col gap-2">

                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    Last name
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="lastname"
                  value={values.lastname}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Enter your lastname"
                />
                {touched.lastname && errors.lastname && (
                  <FormError message={errors.lastname} />
                )}
              </label>

              {/* email address */}
              <label className="flex flex-col gap-2">

                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    Email address
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="email"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Enter your email"
                />
                {touched.email && errors.email && (
                  <FormError message={errors.email} />
                )}
              </label>

              {/* phone number */}
              <label className="flex flex-col gap-2">

                {/* label text */}
                <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    Phone number
                  </span>
                </div>

                {/* input field */}
                <Field
                  type="text"
                  name="mobile"
                  value={values.mobile}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                  placeholder="Enter your phone number"
                />
                {touched.mobile && errors.mobile && (
                  <FormError message={errors.mobile} />
                )}
              </label>
              
              {/* What you're good at 
              <label className="flex flex-col gap-4"> */}

                {/* label text */}
                {/* <div className="flex items-center justify-start">
                  <span className="font-semibold text-sm text-[#344054]">
                    Which tech skill are you interested in?
                  </span>
                </div> */}

                {/* input field */}
                {/* <ThemeProvider theme={theme}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={techSkillField}
                    onChange={(e) => setTechSkillField(e.target.value)}
                    name="radio-buttons-group"
                  > */}
                    {/* <FormControlLabel
                      className="!font-[poppins]"
                      value="Front-End Development"
                      control={<Radio color="primary" />}
                      label="Front-End Development"
                    /> */}

                    {/* <FormControlLabel
                      value="Back-End Development"
                      control={<Radio color="primary" />}
                      label="Back-End Development"
                    />

                    <FormControlLabel
                      value="UI/UX Design"
                      control={<Radio color="primary" />}
                      label="UI/UX Design"
                    /> */}

                    {/* <FormControlLabel
                      value="Founder"
                      control={<Radio color="primary" />}
                      label="Founder"
                    /> */}

                    {/* <FormControlLabel
                      value="Artificial Intelligence"
                      control={<Radio color="primary" />}
                      label="Artificial Intelligence"
                    />

                    <FormControlLabel
                      value="Digital Marketing"
                      control={<Radio color="primary" />}
                      label="Digital Marketing"
                    />

                     <FormControlLabel
                      value="Data Analysis"
                      control={<Radio color="primary" />}
                      label="Data Analysis"
                    /> */}
                  {/* </RadioGroup>
                </ThemeProvider>
              </label> */}

                    {/* Reason*/}
                    {/* <label className="flex flex-col gap-2"> */}

                    {/* label text */}
                    {/* <div className="flex items-center justify-start">
                      <span className="font-semibold text-sm text-[#344054]">
                      Reason
                      </span>
                    </div> */}

                    {/* input field */}
                     {/* <textarea
                           name="reason"
                          value={values.reason}
                           onBlur={handleBlur}
                          onChange={handleChange}
                          className="border border-[#d0d5dd] text-sm font-normal h-[80px] rounded-[8px] px-3 py-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085] "
                          placeholder="Enter your reasons"
                        />
                    {touched.reason && errors.reason && (
                      <FormError message={errors.reason} />
                    )} */}
                    {/* </label> */}

              <BtnPrimary
            
              onClick={handleSubmit}
              type={"Submit"}
              disabled={registerBootcampLoading}
              loading={registerBootcampLoading}
              title={"Submit"} />
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default BootcamForm;
