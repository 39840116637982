import React from 'react'
import Tabs,{Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import CardContainer from './EventCardContainer';

const AllEvents = ({loading, events}) => {


    // OBJECT INITIALIZATION
    const upcomingEventTitle = <span className='flex gap-2 items-center text-sm font-semibold'>
        Upcoming Events
    </span>
    const endedEventTitle = <span className='items-center text-sm font-semibold'>
        Past Events
    </span>

    return (
        <div className="flex flex-col gap-4">

            {/* caption */}
            <p className='text-[#1D2433] text-base font-medium'>
                All Events
            </p>

            <Tabs 
            activeTab="1" 
            ulClassName="!border-[#ccc]" 
            activityClassName="!text-[#0B8CBA]" 
            onClick={(event, tab) => console.log(event, tab)} >
                
                <Tab 
                title={upcomingEventTitle} 
                className="mb-2 mr-7 !px-2">

                    {/* upcoming event container */}
                    <CardContainer
                    loading={loading}
                    events={events?.filter(event=>event?.is_Ended===false)} />

                </Tab>
            
                <Tab 
                title={endedEventTitle} 
                className="mr-3 mb-2 !px-2">

                    {/* past event container */}
                    <CardContainer
                    loading={loading}
                    events={events?.filter(event=>event?.is_Ended===true)} />
                    
                </Tab>
            </Tabs> 
            
        </div>
    )
}

export default AllEvents