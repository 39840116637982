import jumbotronImage from "../../../../assets/webpages/spaces/images/jumbotron-image.png"
import Footer2 from "../../../../layouts/Redesign/Footer2";
import BookSpace from "./BookSpace";
import ChooseCard from "./ChooseCard";
import Coffee from "./Coffee";
import locationImage from "../../../../assets/webpages/spaces/images/location-image.png"
import womanImage from "../../../../assets/webpages/spaces/images/woman-image.png"
import wifiImage from "../../../../assets/webpages/spaces/images/wifi-image.png"
import { useState } from "react";
import MyModal from '../../../../layouts/MyModal'
import SearchWidget from './SearchWidget'

const SpaceBody = () => {
    

    // STATES
    const [spaceModalState, setSpaceModalState] = useState(false)


    // HANDLERS
    const toggleSpaceModal = () => {
        setSpaceModalState(!spaceModalState)
    }

    return (
        <div className='mobile:w-screen w-full'>

            {/* showcase image */}
            <div className="flex flex-col items-stretch [border:0px_transparent_solid] w-full">
                
                {/* first div */}
                <div className="w-full flex 2xl:h-[232px] h-[200px] md:!h-[140px] mobile:h-[55px] bg-[#F0F5FF]">

                </div>

                {/* second div */}
                <div className="w-full flex 2xl:h-[232px] h-[200px] md:!h-[140px] mobile:h-[55px] relative">

                    {/* image */}
                    <img src={jumbotronImage} className="w-[80%] absolute bottom-[0] right-[50%] translate-x-[50%]" alt="" />

                </div>
            </div>

            {/* why choose us */}
            <div className="flex flex-col gap-[64px] mobile:gap-10 pt-[100px] pb-[60px] text-center items-center mobile:px-4">

                {/* caption */}
                <p className="text-[32px] text-[#101828] font-semibold w-[75%] mobile:w-full">
                    Why Choose Us
                </p>

                <div className="flex flex-col gap-[64px] mobile:gap-[94px] text-center items-center">
                    <ChooseCard
                    toggleModal={toggleSpaceModal}
                    subToptic={"LOCATION"}
                    title={"Your workspace deserves the best location"}
                    desc="Located in the beating heart of Enugu state at Independence Layout. With accessible road networks to all major parts of Enugu, you won’t have an issue going to and fro. Besides just location we also have"
                    qualities={[
                        "Serene and quiet environment.",
                        "Clean and well maintained surroundings.",
                        "Quiet and distraction free environment."
                    ]}
                    image={locationImage} />

                    <ChooseCard
                    toggleModal={toggleSpaceModal}
                    subToptic={"AMENITIES"}
                    title={"Your workspace deserves the best Amenities"}
                    desc="They say all work and no play makes Jack a dull worker right. We believe in proper rest after long work hours that’s why we have provided some of these to help you relax and unwind."
                    qualities={[
                        "24/7 uninterrupted power supply.",
                        "Free high speed internet connection",
                        "Free parking spaces",
                        "24/7 security"
                    ]}
                    reverse={true}
                    image={wifiImage} />

                    <ChooseCard
                    toggleModal={toggleSpaceModal}
                    subToptic={"RELAXATION"}
                    title={"Your workspace deserves the best Recreation & Relaxation"}
                    desc="They say all work and no play makes Jack a dull worker right. We believe in proper rest after long work hours that’s why we have provided some of these to help you relax and unwind."
                    qualities={[
                        "A spacious lounge with a great view",
                        "An indoor game room",
                        "A pool table."
                    ]}
                    image={womanImage} />
                </div>
            </div>

            {/* hot cup of coffee */}
            <Coffee
            toggleModal={toggleSpaceModal} />

            {/* Book a space */}
            <BookSpace />

            {/* footer */}
            <Footer2 />

            {/* Modal */}
            {/* logout modal */}
            <MyModal
            modalIsOpen={spaceModalState}
            toggleModal={toggleSpaceModal}
            disableBtn={true}>
                
                {/* search modal */}
                <SearchWidget />

            </MyModal>
        </div>
    )
}

export default SpaceBody;