import React from 'react'
import UpcomingEvents from '../UpcomingEvents'
import ConnectWithSponsors from './ConnectWithSponsors'
import InvestorsCard from './InvestorsCard'
import kachiImage from "../../../../../assets/redesign-dashboard/the-club/images/kachi-image.png"
import cubanasImage from "../../../../../assets/redesign-dashboard/the-club/images/cubanas-image.png"

const InvestorsDatabaseBody = () => {

    // DATA INITIALIZATION
    const investorsData = [
        {
            name: "Barr. Kachi Aghasili",
            designation: "CEO & Founder AI Movement",
            description: "Relentless visionary with a missionary to improve the tech status of eastern Nigeria and turn it into a hub of digital excellence and expertise.",
            image: kachiImage
        },
        {
            name: "Obi Iyiegbu ",
            designation: "Chairman - Cubana Group",
            description: "Obi Cubana (Real Name: Obinna Iyiegbu, born April 12th, 1975) is a Nigerian entrepreneur and businessman best known as the Chief Executive Officer of Cubana Group.",
            image: cubanasImage
        },
        {
            name: "Barr. Kachi Aghasili",
            designation: "CEO & Founder AI Movement",
            description: "Relentless visionary with a missionary to improve the tech status of eastern Nigeria and turn it into a hub of digital excellence and expertise.",
            image: kachiImage
        },
        {
            name: "Obi Iyiegbu ",
            designation: "Chairman - Cubana Group",
            description: "Obi Cubana (Real Name: Obinna Iyiegbu, born April 12th, 1975) is a Nigerian entrepreneur and businessman best known as the Chief Executive Officer of Cubana Group.",
            image: cubanasImage
        },
    ]

    return (
        <div className='flex mobile:flex-col w-full h-full gap-10'>

            {/* section filled with image and text details */}
            <div className='h-full w-[70%] mobile:w-full flex flex-col gap-10'>

                {/* Investors */}
                <div className='grid grid-cols-2 mobile:grid-cols-1 gap-6'>

                    {
                        investorsData.map((investor, index)=>(
                            <InvestorsCard
                            key={index}
                            image={investor.image}
                            name={investor.name}
                            designation={investor.designation}
                            description={investor.description} />
                        ))
                    }

                </div>
                
                {/* connect with sponsors */}
                <ConnectWithSponsors />

            </div>

            {/* upcomoing event section */}
            <div className='h-full w-[30%] mobile:w-full flex flex-col'>
                <UpcomingEvents />
            </div>

        </div>
    )
}

export default InvestorsDatabaseBody