import { spawn } from "redux-saga/effects";
import authSagas from "./auth";
import bootcampSaga from "./bootcamp";
import clubPackagesSaga from "./clubPackages";
import clubSubscriptionSaga from "./clubSubscriptions";
import eventsSaga from "./events";
import spacesSaga from "./spaces";
import registerBootcampSaga from "./registerBootcamp";
import registerDigMarkBootcampSaga from "./registerDigMarkBootcamp";
import registerCritiqueEventSaga from "./critiqueEvent";
import registerGenericEventsSaga from "./genericEvents";

export default function* rootSaga() {
  yield spawn(authSagas);
  yield spawn(bootcampSaga);
  yield spawn(clubPackagesSaga);
  yield spawn(clubSubscriptionSaga);
  yield spawn(eventsSaga);
  yield spawn(spacesSaga);
  yield spawn(registerBootcampSaga);
  yield spawn(registerDigMarkBootcampSaga);
  yield spawn(registerCritiqueEventSaga);
  yield spawn(registerGenericEventsSaga);
}
