import React from "react";
import BtnSm from "../Global/BtnSm";
import { ReactComponent as Facebook } from "./../../assets/contactus/svg/facebook.svg";
import { ReactComponent as Twitter } from "./../../assets/contactus/svg/twitter.svg";
import { ReactComponent as Instagram } from "./../../assets/contactus/svg/instagram.svg";
import { ReactComponent as Linkedin } from "./../../assets/contactus/svg/linkedin.svg";
import l_shape1 from "./../../assets/contactus/images/l-shape1.png";
import { AiOutlineInstagram } from "react-icons/ai";

const MessageForm = () => {
  return (
    <div className="[box-shadow:0px_2px_8px_#e0e0e0] relative z-[500] flex flex-col mt-24 justify-center items-center bg-white w-[40%] mobile:w-full mobile:h-fit mobile:py-12 mobile:px-8 h-[898px] rounded-[8px] gap-6">
      {/* shape */}
      <img
        src={l_shape1}
        className="absolute bottom-[28%] right-[-5rem] mobile:bottom-[-6rem] mobile:right-[50%] mobile:w-[20px]"
        alt=""
      />

      {/* form */}
      <form
        onSubmit={(e) => e.preventDefault()}
        className="flex flex-col justify-center items-center w-full gap-5"
      >
        {/* form caption */}
        <div className="w-[360px] mobile:w-full text-center flex flex-col gap-4 mb-1">
          <p className="font-semibold text-4xl mobile:text-2xl text-[#000]">
            Leave us a message
          </p>
          <p className="text-base font-normal text-[#667085]">
            Welcome back! Please enter your details.
          </p>
        </div>

        {/* FORM CONTROLS */}
        {/* first name */}
        <label className="flex flex-col w-[360px] mobile:w-full gap-2">
          <span className="text-[#344054] text-sm font-semibold">
            Full name
          </span>

          <input
            type="email"
            className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
            placeholder="Enter your full name"
          />
        </label>

        {/* email address */}
        <label className="flex flex-col w-[360px] mobile:w-full gap-2">
          <span className="text-[#344054] text-sm font-semibold">Email</span>

          <input
            type="email"
            className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
            placeholder="Enter your email"
          />
        </label>

        {/* phone number */}
        <label className="flex flex-col w-[360px] mobile:w-full gap-2">
          <span className="text-[#344054] text-sm font-semibold">
            Full name
          </span>

          <input
            type="email"
            className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
            placeholder="Enter your phone number"
          />
        </label>

        {/* message */}
        <label className="flex flex-col w-[360px] mobile:w-full gap-2">
          <span className="text-[#344054] text-sm font-semibold">Message</span>

          <textarea className="border border-[#d0d5dd] font-base font-normal h-[120px] rounded-[8px] p-3 outline-[#d0d5dd] w-full resize-none" />
        </label>

        {/* send message button */}
        <div className="flex flex-col items-stretch mobile:w-full w-[360px]">
          <BtnSm title={"Send messsage"} />
        </div>
      </form>

      {/* socials */}
      <div className="flex flex-col gap-5">
        <p className="text-[#667085] text-sm font-normal">
          Or send us a message on our socials
        </p>

        <div className="flex items-center justify-center gap-6">
          <a href="https://twitter.com/AIMovementNG" target="_blank">
            <Twitter />
          </a>
          <a
            className="text-black text-2xl hover:text-black"
            href="https://instagram.com/aimovement_ng"
            target="_blank"
          >
            <AiOutlineInstagram />
          </a>
          {/* <Facebook />
                    <Instagram /> */}
        </div>
      </div>
    </div>
  );
};

export default MessageForm;
