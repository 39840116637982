import React from "react"
import AboutBody from "../../../../components/Redesign/Webpage/AboutUs/AboutBody";
import AboutHeader from "../../../../components/Redesign/Webpage/AboutUs/AboutHeader";
import Wrapper from "../../../../layouts/Redesign/Wrapper"

const ManagementTeam = () => {
    return (
        <Wrapper>
            
            {/* Aboutus Header */}
            <AboutHeader />

            {/* Aboutus Body */}
            <AboutBody />

        </Wrapper>
    )
}

export default ManagementTeam;