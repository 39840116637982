import React from "react";
import Footer2 from "../../../../layouts/Redesign/Footer2";
import BookSpace from "../Spaces/BookSpace";
import FAQ from "./FAQ";
import MessageForm from "./MessageForm";

const ContactBody = () => {
  return (
    <div className="mobile:w-screen w-full">
      {/* showcase image */}
      <div className="flex flex-col items-stretch [border:0px_transparent_solid] mobile:mb-10 w-full">
        {/* first div */}
        <div className="w-full flex h-[449px] md:!h-[500px] mobile:h-[500px] bg-[#F8F9FC]"></div>

        {/* second div */}
        <div className="w-full flex h-[449px] md:!h-[500px] mobile:h-[500px] relative bg-white">
          {/* Message Form */}
          <MessageForm />
        </div>
      </div>

      {/* Frequently Asked Questions */}
      {/* <FAQ /> */}

      {/* Book space */}
      <BookSpace />

      {/* Footer */}
      <Footer2 />
    </div>
  );
};

export default ContactBody;
