import React, { Suspense, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { PrivateRoute } from "../components/PrivateRoute";
import Club from "./webpages/Club";
import Home from "./webpages/Home";
import ManagementTeam from "./webpages/Management";
import TrainingCenter from "./webpages/TrainingCenter";
import Events from "./webpages/Events";
import ContactUs from "./webpages/ContactUs";
import Login from "./webpages/Login";
import SignUp from "./webpages/SignUp";
import Dashboard from "./Dashboard";
import SpaceBooking from "./webpages/SpaceBooking";
import WebpageRouter from "./Redesign/Webpages/router";
import FormsRouter from "./Forms/router";
import RedesignedDboardRouter from "./Redesign/Dashboard/router";


const Pages = () => {
  return (
    <>

      {/* switching component based on their routes */}
      <Switch>

        <Route exact path="/old" component={Home} />
        <Route exact path="/old/club" component={Club} />
        <Route exact path="/old/management" component={ManagementTeam} />
        <Route exact path="/old/training" component={TrainingCenter} />
        <Route exact path="/old/events" component={Events} />
        <Route exact path="/old/contactus" component={ContactUs} />
  
        {/* <Route exact path="/space-booking" component={SpaceBooking} /> */}


        {/* authentication pages */}
        <Route exact path="/old/signup" component={SignUp} />
        <Route exact path="/old/login" component={Login} />


        {/* Form Router */}
        <FormsRouter
        path={"/form"} />


        {/* Dashboard */}
        <PrivateRoute 
        path="/old/dashboard" 
        component={Dashboard}
        redirectedPath="/old/login" />

        {/* Redesigned Dashboard Route */}
        <PrivateRoute 
        path="/dashboard" 
        component={RedesignedDboardRouter}
        redirectedPath="/login" />


        {/* Redesign route */}
        <WebpageRouter path={"/"} />        

      </Switch>
    </>
  );
};

export default Pages;
