import { CLEAR_REGISTER_BOOTCAMP_SUCEESS, REGISTER_BOOTCAMP_FAIL, REGISTER_BOOTCAMP_LOADING, REGISTER_BOOTCAMP_REQUEST, REGISTER_BOOTCAMP_SUCCESS } from "../types";

// register bootcamp form
export const registerBootcampRequest = (prop) => {
    return {
      type: REGISTER_BOOTCAMP_REQUEST,
      payload: prop,
    };
  };
  
  export const registerBootcampSuccess = (prop) => {
    return {
      type: REGISTER_BOOTCAMP_SUCCESS,
      payload: prop,
    };
  };
  
  export const registerBootcampFailure = (errorMessage) => {
    return {
      type: REGISTER_BOOTCAMP_FAIL,
      payload: errorMessage,
    };
  };
  
  export const registerBootcampLoading = (loading) => {
    return {
      type: REGISTER_BOOTCAMP_LOADING,
      payload: loading,
    };
  };
  
  export const clearRegisterBootcampSuccess = () => {
    return {
      type: CLEAR_REGISTER_BOOTCAMP_SUCEESS
    };
  };