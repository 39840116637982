import React from 'react'
import Header2 from '../../../../layouts/Redesign/Header2'
import shape1 from "../../../../assets/webpages/contactus/images/g-shape1.png"
import shape2 from "../../../../assets/webpages/contactus/images/g-shape2.png"
import DetailsCard from './DetailsCard'
import { MdOutlineMail } from 'react-icons/md'
import { FiPhone } from 'react-icons/fi'

const ContactHeader = () => {
    return (
        <div className='flex items-center justify-start bg-[#F8F9FC] h-[80vh] lg:h-[90vh] mobile:h-auto relative flex-col top-0'>

            {/* little space */}
            <div className='pc:hidden mobile:h-[5.7rem] w-full bg-[#F8F9FC]'>
                [' ']
            </div>

            {/* header */}
            <Header2
            shadowStyle='none' />

            {/* SHAPES */}
            {/* shape1 */}
            <img src={shape1} className="mobile:w-[20px] absolute top-[30%] left-[16%] mobile:left-[2rem] mobile:top-[7.5rem]" alt="" />

            {/* shape2 */}
            <img src={shape2} className="mobile:w-[20px] absolute top-[30%] right-[15%] mobile:right-[11%] mobile:top-[8.3rem]" alt="" />

            {/* jumbotron */}
            <div className='w-full flex flex-col items-center top-[15%] mobile:top-[18%] relative z-[200] gap-4 text-center container mx-auto pc:w-[85%] lg:w-[90%] mobile:px-4 pt-5 mobile:pt-16 mobile:pb-12'>

                <p className='font-semibold text-[45px] mobile:text-4xl text-[#1D2433]'>
                    Get in touch!
                </p>

                <p className='text-[#1D2433] text-2xl font-semibold mobile:font-semibold mt-0'>
                    Stay updated on our latest programmes and events
                </p>

                <div className='grid grid-cols-3 lg:grid-cols-2 mobile:grid-cols-1 gap-6 mobile:gap-4 mt-7 mobile:mt-8 container mx-auto'>

                    {/* Company's Email address */}
                    <DetailsCard
                    icon={
                        <MdOutlineMail
                        className='text-2xl text-[#1D2433]' />
                    }
                    textTitle="Email:"
                    textDescription={"info@aimovement.tech"} />

                    {/* Company's Address */}
                    <DetailsCard
                    icon={
                        <MdOutlineMail
                        className='text-2xl text-[#1D2433]' />
                    }
                    textTitle="Address"
                    textDescription={"No. 1 Snap Drive, Olive Gate Hotel Junction, Independence Layout, Enugu Nigeria"} />

                    {/* Company's Phone number */}
                    <DetailsCard
                    icon={
                        <FiPhone
                        className='text-2xl text-[#1D2433]' />
                    }
                    textTitle="Phone:"
                    textDescription={"0703 0470 857 ,  0817 0988 894"} />

                </div>
            </div>
        </div>
    )
}

export default ContactHeader