import React, { useEffect, useState } from 'react'
import RedirectCard from '../TheClub/RedirectCard'
import UpcomingEvents from '../TheClub/UpcomingEvents'
import image1 from "../../../../assets/redesign-dashboard/space-booking/images/courses.png"
import image3 from "../../../../assets/redesign-dashboard/space-booking/images/packages.png"
import SpaceFaceCard from './SpaceFaceCard'
import Benefits from './Benefits'
import { Redirect, useLocation } from 'react-router-dom'
import MyModal from '../../../../layouts/MyModal'
import SearchSpaces from './SearchSpaces'
import { useDispatch, useSelector } from 'react-redux'
import { getUserSpacesRequest } from '../../../../redux/action'
import { isEmpty } from '../../../../helpers/isEmtpy'
import { Skeleton } from '@mui/material'

const SpaceBookingBody = () => {
    
    // STATES
    const [isEmptyJ, setIsEmpty] = useState(false)
    const [spaceModalState, setSpaceModalState] = useState(false)
    const {
        getUserSpacesLoading ,
        token,
        userSpaces
    } = useSelector(state=>{
        const { getUserSpacesLoading } = state.loadingIndicator
        const { token } = state.userData;
        const { userSpaces } = state.spacesReducer
        return {
            getUserSpacesLoading,
            token,
            userSpaces
        }
    })


    // DATA INITIALIZATION
    const location = useLocation()
    const dispatch = useDispatch()


    // HANDLERS
    const toggleSpaceModal = () => {
        setSpaceModalState(!spaceModalState)
    }


    // SIDE EFFECT
    useEffect(()=>{
        dispatch(getUserSpacesRequest(token))
    }, [])

    return (
        <div className='flex mobile:flex-col w-full h-full gap-10'>

            {/* section filled with image and text details */}
            <div className='h-full w-[70%] mobile:w-full flex flex-col gap-10'>

                {/* package card info */}
                {
                    getUserSpacesLoading?
                    <Skeleton
                        variant="rectangular"
                        sx={{
                        width: "100%",
                        height: "50vh",
                        borderRadius: "10px",
                        }}
                    />:
                    isEmpty(userSpaces)?
                        <Redirect to={`${location.pathname}/about-spaces`} />
                    :
                    userSpaces?.map(userSpace=>(
                        <>
                            <SpaceFaceCard
                            key={userSpace._id}
                            startDate={userSpace.space_id.booked_dates[0].start_date}
                            endDate={userSpace.space_id.booked_dates[0].end_date}
                            duration={userSpace.number_of_days}
                            image={userSpace.space_id.imgurl}
                            price={userSpace.price}
                            spaceName={userSpace.space_id.name} />

                            {/* benefit */}
                            <Benefits
                            subPackages={userSpace.space_id.features}
                            onClick={toggleSpaceModal} />
                        </>
                    ))
                }

                
                {/* redirection cards */}
                <div className='grid grid-cols-2 mobile:grid-cols-1 gap-10 w-full'>

                    <RedirectCard
                    title={"Interested in learning a new skill?"}
                    path={"#"}
                    linkText={"View our courses"}
                    image={image1} />

                    <RedirectCard
                    title={"Interested in learning a becoming a member"}
                    path={"#"}
                    linkText={"View our packages"}
                    image={image3} />

                </div>
            </div>

            {/* upcomoing event section */}
            <div className='h-full w-[30%] mobile:w-full flex flex-col'>
                <UpcomingEvents />
            </div>

            {/* Modal */}
            {/* logout modal */}
            <MyModal
            modalIsOpen={spaceModalState}
            toggleModal={toggleSpaceModal}
            disableBtn={true}>
                
                {/* search modal */}
                <SearchSpaces />

            </MyModal>
        </div>
    )
}

export default SpaceBookingBody