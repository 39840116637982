import { useEffect } from "react";
import frontend from "./../../../assets/trainingcenter/images/frontend.png";
import { AiFillCalendar } from "react-icons/ai";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { TbCertificate } from "react-icons/tb";
import { GrUserExpert } from "react-icons/gr";
import BtnSm from "./../../Global/BtnSm";
import { useHistory, useLocation } from "react-router-dom";
import { PaystackButton } from "react-paystack";
import { useDispatch } from "react-redux";
import { putBootcampPaymentRequest } from "../../../redux/action";

const PathwayCard = ({
  user,
  specificBootcamp,
  putBootcampPaymentLoading,
  isMobile,
}) => {
  const history = useHistory();
  const location = useLocation();

  // dispatch object
  const dispatch = useDispatch();

  const componentProps = {
    email: user.email,
    amount: Number(specificBootcamp.new_price) * 100,
    metadata: {
      name: user.firstname + " " + user.lastname,
      phone: user.phone,
    },
    publicKey: "pk_test_8e03a316c7b1c00e5c89fa75179de69cf7100e51",
    text: "Pay Now",
    onSuccess: (e) => {
      console.log("payment was succefull", e.reference);

      console.log(e);

      let formData = {
        email: user.email,
        reference: e.reference,
      };

      dispatch(
        putBootcampPaymentRequest({
          formData,
          bootcampId: specificBootcamp._id,
        })
      );
    },
    onClose: () => {},
  };

  return (
    <div
      className={`flex flex-col ${
        isMobile ? "shadow-none" : "[box-shadow:0px_4px_12px_rgba(0,0,0,0.16)]"
      } rounded-[10px] mobile:p-4 p-5 pb-7 gap-4 bg-white w-full`}
    >
      {/* images section */}
      <div
        style={{
          display: isMobile ? "none" : "block",
        }}
        className="flex flex-col items-center justify-center gap-4"
      >
        {/* backend image */}
        <img
          src={frontend}
          className="object-cover h-[183.15px] w-full rounded-[10px] mb-1"
          alt=""
        />
      </div>

      {/* amount */}
      <div className="flex flex-col justify-center items-center gap-1">
        <div className="flex justify-center items-center gap-3">
          <p className="text-[#6c717a] font-semibold text-xl leading-[24px] text-center">
            NGN {specificBootcamp?.new_price?.toLocaleString("en-US") || "-"}
          </p>

          {/* label */}
          <span className="bg-[#E02D3C] h-[22px] inline-flex justify-center items-center font-semibold text-[10px] leading-[14px] px-3 rounded-[8px] text-white">
            Promo price
          </span>
        </div>
      </div>

      {/* button */}
      <div className="flex items-stretch flex-col gap-4">
        {/* <BtnSm
          onClick={() => history.push(`${location.pathname}/get-started`)}
          title="Make payment"
        /> */}

        {specificBootcamp?.students?.find((e) => e.user_id === user._id) ? (
          <BtnSm title="Already enrolled" />
        ) : putBootcampPaymentLoading ? (
          <BtnSm loading={putBootcampPaymentLoading} title="Pay Now" />
        ) : (
          <PaystackButton
            className="h-[44px] mobile:h-[48px] bg-[#7F56D9] flex justify-center items-center font-semibold text-base lg:text-xs text-white px-7 rounded-[8px] hover:opacity-80 disabled:opacity-50 disabled:cursor-not-allowed"
            {...componentProps}
          />
        )}
      </div>

      {/* course info */}
      <div>
        <p className="text-[#3f444d] text-base font-semibold mt-1 mb-3">
          This course includes
        </p>

        <p className="font-normal text-base text-[#3f444d] flex items-center justify-start gap-3">
          <AiFillCalendar size={24} color={"#000"} />
          {specificBootcamp?.duration || "-"} months intensive training
        </p>

        <p className="font-normal text-base text-[#3f444d] flex items-center justify-start gap-3">
          <HiOutlineOfficeBuilding size={24} color={"#000"} />
          Physical/Online learning
        </p>

        <p className="font-normal text-base text-[#3f444d] flex items-center justify-start gap-3">
          <GrUserExpert size={24} color={"#000"} />
          Access to industry experts
        </p>

        <p className="font-normal text-base text-[#3f444d] flex items-center justify-start gap-3">
          <TbCertificate size={24} color={"#000"} />
          Certificate of completion
        </p>
      </div>
    </div>
  );
};

export default PathwayCard;
