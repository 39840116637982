import React from 'react'

const EmptyData = ({title = "Nothing here"}) => {
    return (
        <div className="flex w-full items-center justify-center text-xl font-semibold text-gray-800 m-auto h-[30vh]">
            {title}
        </div>
    )
}

export default EmptyData