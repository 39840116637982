import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'
import image from "../../../../../assets/redesign-dashboard/investing/images/sort-image.png"

const PortfolioCard = () => {
    return (
        <div className='border border-[#E1E6EF] bg-white gap-4 p-3 flex flex-col rounded-[10px] w-[225px]'>

            {/* images */}
            <div className='flex items-center justify-center w-full'>
                <img src={image} className='object-cover w-[95%]' alt="" />
            </div>

            {/* details */}
            <div className='flex flex-col gap-4'>

                {/* caption/label */}
                <div className='flex flex-col gap-2 items-start'>

                    {/* caption */}
                    <p className='font-semibold text-base text-[#1D2433]'>
                        Smart Sort Ai
                    </p>

                    {/* label */}
                    <span className='inline-flex items-center justify-center text-[11px] font-semibold text-[#3F444D] bg-[#D4F4FF] h-[32px] px-3 rounded-[10px]'>
                        Environment
                    </span>
                </div>

                {/* links */}
                <Link to={"/dashboard/investing/kljkjadfjsdkfjskfjskfdk"} className='flex gap-2 items-center text-[#0B8CBA] font-semibold text-sm cursor-pointer hover:opacity-60 hover:text-[#0B8CBA] active:text-[#0B8CBA] hover:no-underline'>
                    Learn more
                    <IoIosArrowForward className='text-xl' />
                </Link>
            </div>           
        </div>
    )
}

export default PortfolioCard