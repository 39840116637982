
import {TfiAngleRight} from "react-icons/tfi"

import cert from "../../../../assets/home/images/cert1.png";
import "rsuite/dist/rsuite.min.css";
import { useHistory } from 'react-router-dom'
import ArrowBtn from './ArrowBtn'

const CertificateCard= ({disableRouting}) => {

    const history = useHistory()

      // HANDLERS
      const handleClick = () => {
        history.push("/dashboard/bootcamps/certificate")
    }

    return (
        <div className="flex flex-col border border-[#E1E6EF] p-5 gap-4 rounded-[10px] w-full h-fit bg-white">
            
            {/* title */}
            <p className="mt-0 font-semibold text-sm text-gray-600">
            Ceritificate
            </p>

            {/* body info */}

                {/* image */}
                {/* <div> */}
                    <img src={cert} className="object-cover w-full h-12 ml-[-10px]" alt="cert" 
                    />
                {/* </div> */}

                {/* text*/}

                {
                    !disableRouting &&
                    <ArrowBtn
                    text="Veiw Certificate"
                    onClick={handleClick}
                   className= "mobile:hidden"
                   />
                }
            </div>
        // </div>
    )
}

export default CertificateCard;