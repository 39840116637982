import React from 'react'
import { useSelector } from 'react-redux';
import TheClubBody from '../../../../components/Redesign/Dashboard/TheClub/Body'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'

const TheClub = () => {
        
    // STATES
    const { firstname } = useSelector((state) => {
        const {
            user: { firstname },
        } = state.userData;
        return {
            firstname
        };
    });

    return (
        <PageWrapper
        title={`Hello ${firstname}`}
        subTitle={"Happy to see you back!"}>

            {/* body section */}
            <TheClubBody />

        </PageWrapper>
    )
}

export default TheClub