import { Skeleton } from '@mui/material'
import React from 'react'
import BtnPrimary from '../../../../Global/Redesign/BtnPrimary'
import EventGallery from './EventGallery'
import SponsorEvent from './SponsorEvent'

const AboutEvent = ({hasEnded, loading, notEmpty, description}) => {
    return (
        <>
            {   
                loading?
                <div className="flex items-center justify-center rounded-[20px] h-[50vh] w-[80%] mobile:w-full md:!w-[50%] mx-auto">
                    <Skeleton
                    variant="rectangular"
                    sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "20px",
                    }} />
                </div>:
                notEmpty?
                <div className='flex flex-col gap-10 w-[65%] mobile:w-full'>
                    
                    {/* about event */}
                    <div className='flex flex-col gap-4 rounded-[10px] border border-[#E1E6EF] py-6 px-5 items-start bg-white'>

                        <p className='text-[#1D2433] text-[28px] mobile:text-2xl font-semibold mt-0 leading-none'>
                            About Event
                        </p>

                        <p className='font-normal text-base text-gray-800 mt-0'>
                            {description}
                        </p>

                        {/* button */}
                        {
                            !hasEnded &&
                            <BtnPrimary
                            title={"Register for event now"} />
                        }
                        
                    </div>
                    
                    {/* interested in sponsoring event */}
                    <div className='flex-col items-start w-full flex mobile:hidden'>
                        {
                            !hasEnded && 
                            <SponsorEvent />
                        }

                        {/* Gallery */}
                        {
                            hasEnded && <EventGallery />
                        }
                    </div>
                </div>:
                <div className='flex justify-center items-center w-full h-[60vh] text-xl font-medium rounded-[10px]'>Nothing Here</div>
            }
        </>
    )
}

export default AboutEvent