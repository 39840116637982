import React from 'react'
import { Route, Switch } from 'react-router-dom'
import SpaceBooking from '.'
import AboutSpace from './AboutSpace'
import AvailableSpaces from './AvailableSpaces'
import SingleSpace from './SingleSpace'

const SpaceBookingRouter = ({path}) => {
    
    return (
        <Switch>
            
            {/* index page */}
            <Route 
            exact
            path={path}
            component={SpaceBooking}/>

            {/* about spaces page */}
            <Route
            exact
            path={`${path}/about-spaces`}
            component={AboutSpace} />
            
            {/* Availble spaces page */}
            <Route 
            exact
            path={`${path}/availble-spaces`}
            component={AvailableSpaces}/>
            
            {/* single page page */}
            <Route 
            exact
            path={`${path}/:spaceId`}
            component={SingleSpace}/>

        </Switch>
    )
}

export default SpaceBookingRouter