import React from 'react'
import Answer from './Answer'

const Questions = () => {
    return (
        <div className='flex items-center justify-center w-full h-full py-44 mobile:py-16 p-5 mobile:px-4'>
            
            {/* container */}
            <div className="container h-full w-[90%] 2xl:w-[80%] mx-auto flex items-center flex-col mobile:items-center gap-16 mobile:gap-10">

                <p className='text-[28px] font-semibold text-[#1D2433] mobile:text-center'>
                    Frequently Asked Questions
                </p>

                <div className='grid grid-cols-1 w-full gap-12'>

                    <Answer
                    index={1}
                    question={
                      "Do you think I can fit into the tech space without prior knowledge of tech"
                    }
                    answer={
                      "Of course you can fit in just perfect! You don’t need prior knowledge of tech  at all."
                    } />

                    <Answer
                    index={2}
                    question={
                      "Do you think I can fit into the tech space without prior knowledge of tech"
                    }
                    answer={
                      "Of course you can fit in just perfect! You don’t need prior knowledge of tech  at all."
                    } />

                    <Answer
                    index={3}
                    question={
                      "Do you think I can fit into the tech space without prior knowledge of tech"
                    }
                    answer={
                      "Of course you can fit in just perfect! You don’t need prior knowledge of tech  at all."
                    } />

                    <Answer
                    index={4}
                    question={
                      "Do you think I can fit into the tech space without prior knowledge of tech"
                    }
                    answer={
                      "Of course you can fit in just perfect! You don’t need prior knowledge of tech  at all."
                    } />

                </div>
            </div>
        </div>
    )
}

export default Questions