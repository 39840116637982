import React from "react";
import Navbar from "../BootcampForm/Navbar";
import showcaseImage from "../../assets/form/critique-form/images/jumbotron-image.png";
import leftArc from "../../assets/form/critique-form/images/left-arc.png";
import rightArc from "../../assets/form/critique-form/images/right-arc.png";
import { MdLocationOn } from "react-icons/md";

const Header = () => {
  return (
    <div className="flex items-center justify-center gap-5 top-0 relative bg-[#EDFAFE] w-full overflow-hidden">
      {/* ARCS */}
      {/* left arc */}
      <img
        src={leftArc}
        className="absolute top-0 left-0 mobile:hidden"
        alt=""
      />

      {/* right arc */}
      <img src={rightArc} className="absolute top-0 right-0 z-[1]" alt="" />

      {/* container */}
      <div className="w-[80%] h-full gap-10 lg:gap-10 relative z-[2] flex flex-col pt-10 mobile:w-full mobile:mt-[120px] pb-7 mobile:pb-16">
        <Navbar />

        <div className="flex flex-col items-center gap-9 mobile:px-4">
          {/* image */}
          <div className="mobile:w-full container mx-auto w-[50%]">
            <img
              src={showcaseImage}
              className="w-full h-full object-cover"
              alt=""
            />
          </div>

          {/* purpose */}
          <div className="border-2 border-[#E1E6EF] rounded-[8px] p-5 bg-white">
            <p className="font-normal mobile:text-base text-2xl text-[#1D2433] text-center mt-0">
              We’ll analyze/critique{" "}
              <span className="font-bold mobile:font-normal">CONTYNIO</span>, an
              NFT based community with the aim of expanding the horizon of NFT's
              with fashion.
            </p>
          </div>

          {/* date and location */}
          <div className="flex gap-6 mobile:justify-between container mx-auto">
            {/* date */}
            <div className="flex flex-col w-full">
              <p className="font-medium text-[30px] lg:text-xl mobile:text-[22px] w-fit mb-1 border-b border-[#0B8CBA]">
                28th Apr, 2023
              </p>
              <p className="font-medium text-[30px] lg:text-xl mobile:text-[22px]">
                01:00 PM
              </p>
            </div>

            {/* location */}
            <div className="flex items-start gap-1 w-full">
              <MdLocationOn className="text-6xl text-black relative mt-[-1rem]" />

              <p className="font-medium text-base mobile:font-normal mobile:text-xs text-[#055774]">
                No. 1 Snap Drive, Olive Gate Hotel Junction, Independence
                Layout, Enugu Nigeria
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
