import React from 'react'
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CourseCard from './CourseCard';
import { getBootcampForUsersRequest } from '../../redux/action';
import { Skeleton } from '@mui/material';
import capShape2 from "./../../assets/trainingcenter/svg/caption-shape2.svg"
import s_shape2 from "./../../assets/trainingcenter/images/s-shape2.png"
import LearnMorePopup from './LearnMorePopup';
import TrainingCenterModal from '../../layouts/TrainingCenterModal';

const Tracks = () => {

    // learn more modal state
    const [learnMoreModalState, setLearnMoreModalState] = useState(false)
    const [modalData, setModalData] = useState(null)

    const toggleLearnMoreModal = (data) => {
        setModalData(data)
        setLearnMoreModalState(!learnMoreModalState)
    }

    // dispatch object
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getBootcampForUsersRequest())
    }, [])

    // bootcamps state
    const { bootcamps,bootcampsLoading  } = useSelector((state)=>{
        const { bootcamps } = state.bootcampReducer
        const { bootcampsLoading } = state.loadingIndicator
        return { bootcamps,bootcampsLoading }
    })

    return (
        <div className='bg-[#1D2433] py-64 flex flex-col gap-16 mobile:py-16 mobile:pb-16 relative'>

            {/* shape */}
            <img src={s_shape2} className="mobile:w-[20px] mobile:left-[2rem] absolute z-[200] left-[35%] bottom-[6rem] mobile:bottom-[1.5rem]" alt="" />
            
            {/* text container */}
            <div className='flex flex-col text-white text-center gap-4 px-6 mobile:px-4'>

                {/* texts */}
                <p className='font-semibold mobile:text-xs text-base'>
                    SPECIAL COURSE TRACK
                </p>

                <p className='text-4xl mobile:text-2xl font-semibold mb-2 relative inline self-center'>
                    Student to Employee Track

                    {/* shape pattern */}
                    <img src={capShape2} className="mobile:w-[20px] mobile:right-[-2rem] absolute z-[200] right-[-15%] top-[-117%]" alt="" />
                </p>                 

                <p className="font-normal text-base md:px-20">
                    Select the pathway below that you are interested in, click on apply, fill the form and an admissions officer will review your application <br/>and reach out to you.
                </p>
            </div>

            {/* student to employee track */}
            <div className='flex gap-12 justify-center items-start px-8 mobile:flex-col mobile:px-6 mobile:items-center'>

                {
                    bootcampsLoading?
                    <>
                    <Skeleton 
                    variant="rectangular" 
                    sx={{ 
                        width: '70%', 
                        height: "60vh",
                        borderRadius: "10px"
                    }} />
                    <Skeleton 
                    variant="rectangular" 
                    sx={{ 
                        width: '70%', 
                        height: "60vh",
                        borderRadius: "10px"
                    }} />
                    <Skeleton 
                    variant="rectangular" 
                    sx={{ 
                        width: '70%', 
                        height: "60vh",
                        borderRadius: "10px"
                    }} />
                    </>: 
                    bootcamps.map((object)=>(
                        object.course_type==="pathway"&&
                        <CourseCard 
                        data={object} 
                        key={object._id}
                    onLearnMoreClick={()=>toggleLearnMoreModal(object)} />
                    ))
                }

            </div>

            {/* Learn More Modal */}
            <TrainingCenterModal
            modalIsOpen={learnMoreModalState}
            toggleModal={toggleLearnMoreModal}
            disableBtn={false}
            >
                <LearnMorePopup
                path='/old/login'
                moreInfo={modalData}
                closeModal={toggleLearnMoreModal} />
            </TrainingCenterModal>
        </div>
    )
}

export default Tracks