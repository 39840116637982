import React, { useState, useEffect, useRef } from 'react';
import logo from "../../../../../assets/home/images/logo.svg"
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import Kachi from "../../../../../assets/bootcamp-form/svg/Kachiaghasili.svg"
import Seal from "../../../../../assets/bootcamp-form/svg/seal.svg"

const SingleCert = () => {
    const userData = useSelector(store => store)
    console.log(userData.userData)

    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
      const date = new Date();

      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      const formattedDate = date.toLocaleDateString('en-US', options);

      setCurrentDate(formattedDate);
    }, []);
  
  const StudentCourse = localStorage.getItem("course-selected")
  const formattedSelectedCourse = StudentCourse.replace(/^"(.*)"$/, '$1');
    const componentRef = useRef();
   const handlePrint = useReactToPrint({
    content: () => componentRef.current,
   });

    return (
        <div>
             <style>
        {`
          @media print {
            #certificate {
              width: 100%;
             margin: 0;
            }
            #heading {
                height: 17rem;
                margin-top:0;
            }
            #yellow-line {
            
             margin-bottom: -55.5rem;
            margin-top: -50rem;
            
            
            }
           
            #whitecard {
                height: 31rem;
                padding-top: -20rem
            }
           #lastdiv{
            margin-bottom: 10rem;
           }
          

          }
        `}
      </style>
            <div id="certificate" ref={componentRef} className=" text-black  w-[80%]  mx-auto mt-10 ">
          
                <div id="heading" className="h-[10rem] flex flex-col items-center justify-center px-8 " style={{ background: 'linear-gradient(to right, #0B8CBA 30.07%, #064055 66.77%)' }}>
                <div id="yellow-line" className='border-2 border-b-0 border-yellow-300  mb-[-1.7rem] rounded-lg rounded-b-none   py-5 mt-5 w-full print:w-[100%] '>
                    <div id="logodiv" className="flex flex-row items-center justify-center gap-1 mb-2">
                        <img src={logo} className=" h-8 " alt="cert" />
                        <p className="text-md font-semibold text-white text-[10px]">AI Movement</p>
                    </div>
                    <h3 className='text-[30px] text-[#EBC757] font-mono tracking-[0.2rem] text-center print:text-[45px]'>CERTIFICATE</h3>
                    <h3 className="text-sm text-white tracking-[0.2rem] mt-[-0.5rem] font-light text-center print:text-[20px] print:mt-3">OF COMPLETION</h3>
                </div>
                {/* <div class="h-6 bg-[#064055]"></div> */}
                </div>
                <div class="h-2 bg-[#EBC757]"></div>
                <div id="whitecard" className="flex flex-col justify-center  border  border-[#E1E6EF] px-8 py-9 gap-4  w-full h-fit bg-white ">
                <div id="bottomyellow" className='border-2 border-t-0 border-yellow-300 mt-[-2.3rem] rounded-lg rounded-t-none print:h-[24rem] print:mt-[-1.8rem] print:mb-8 print:w-[100%]'>
                    <p  id="cardtext" className='text-center mt-7 tracking-[0.2rem] text-md mb-5  print:text-lg print:mt-[5rem]'>PROUDLY PRESENTED TO</p>
                    <p className="mx-auto text-sm font-semibold text-center border-b w-2/3  print:w-[40%] print:text-lg">{userData.userData.user.firstname} {userData.userData.user.lastname}</p>
                    <p className='w-[80%] text-center mx-auto mt-3 print:text-lg print:w-[60%]' >For having successfully completed the <span className='font-bold'>{formattedSelectedCourse}  </span>from AI Movement Tech Academy</p>
                    <div id="lastdiv" className='flex flex-row justify-around items-center mt-8 mb-7'>
                        <div className='flex flex-col items-center'>
                            <p  className="text-sm font-semibold border-b text-center px-6 print:text-md">{currentDate}</p>
                            <p className="print:text-md">Manager</p>
                        </div>
                        <div>
                            <img  src={Seal} alt="seal"/>
                        </div>
                        <div className='flex flex-col items-center '>
                            {/* <p className="text-sm font-semibold border-b text-center px-6 print:text-md">Mrs Kachi James</p> */}
                            <img  src={Kachi}  alt='kachi' className=''/>
                            <p className='print:text-md border-t'>C.E.O AI Movement</p>
                        </div>

                    </div>
                    </div>
               

                </div>
            </div>
            <button onClick={handlePrint} className="mt-[3rem] bg-[#0B8CBA] text-sm text-white px-9 py-2 rounded ">Downlaod Certificate</button>
        </div>
    );
};

export default SingleCert;
