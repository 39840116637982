import { Skeleton } from '@mui/material'
import React from 'react'
import image1 from '../../../../../assets/webpages/event/images/photo-speak1.png'
import image2 from '../../../../../assets/webpages/event/images/photo-speak2.png'
import image3 from '../../../../../assets/webpages/event/images/photo-speak3.png'
import image4 from '../../../../../assets/webpages/event/images/photo-speak4.png'

const ImageGallery = ({hasEnded, loading, notEmpty}) => {

    return (
        <div className={`w-full items-center justify-center ${hasEnded?"flex":"hidden"} py-44 mobile:py-16 px-5 mobile:px-4`}>

            {
                
                loading?
                <div className="flex items-center justify-center rounded-[20px] h-[50vh] w-[80%] mobile:w-[90%] md:!w-[50%]">
                    <Skeleton
                    variant="rectangular"
                    sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "20px",
                    }} />
                </div>:
                notEmpty?
                <div className='container mx-auto flex flex-col gap-10 items-start w-[80%] mobile:w-full'>

                    {/* caption */}
                    <p className='text-[28px] font-semibold text-[#1D2433]'>
                        Photo Speaks
                    </p>

                    {/* photo collection */}
                    <div className='grid grid-cols-[auto_auto] mobile:grid-cols-1 mobile:gap-y-[23px] gap-x-[23px] gap-y-[32px]'>

                        <img src={image1} className='h-[288px] rounded-[10px] w-full object-cover' alt="" />
                        <img src={image2} className='h-[288px] rounded-[10px] w-full object-cover' alt="" />
                        <img src={image3} className='h-[288px] rounded-[10px] w-full object-cover' alt="" />
                        <img src={image4} className='h-[288px] rounded-[10px] w-full object-cover' alt="" />

                    </div>

                    {/* button */}
                    <button className='h-[40px] hover:bg-[rgba(0,0,0,.12)] rounded-[8px] border border-gray-600 bg-white px-3 text-base font-semibold text-gray-800'>
                        View all photos
                    </button>

                </div>:
                <div className='flex justify-center items-center w-full h-[60vh] text-2xl font-medium rounded-[10px]'>Nothing Here</div>
            }
        </div>
    )
}

export default ImageGallery