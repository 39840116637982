import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Footer2 from '../../../../../layouts/Redesign/Footer2'
import { getSingleEventRequest } from '../../../../../redux/action'
import BookSpace from './BookSpace'
import ImageGallery from './ImageGallery'
import Info from './Info'
import Showcase from './Showcase'
import SponsorEvent from './SponsorEvent'
import WithEventStatus from './WithEventStatus'
import { getRTDate, MONTHS } from '../../../../../helpers/getRTDate'

const EventDetailsBody = () => {

    // OBJECT INITIALIZATION
    const location = useLocation();
    const eventId = location.pathname.split("/").pop();
    const dispatch = useDispatch();
    const FULL_MONTHS = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ]


    // STATES
    const {
        singleEvents,
        singleEventLoading,
      } = useSelector((state) => {
        const { singleEvents } = state.eventsReducer;
        const { singleEventLoading } = state.loadingIndicator;
        return {
          singleEvents,
          singleEventLoading,
        };
    });


    // SIDE EFFECTS
    useEffect(() => {
        dispatch(getSingleEventRequest(eventId));
    }, []);

  return (
        <div className='mobile:w-screen w-full'>

            {/* event details */}
            <WithEventStatus>

                {/* showcase component */}
                <Showcase
                loading={singleEventLoading}
                image={singleEvents?.imgurl}
                month={MONTHS[getRTDate.month(singleEvents.date)]}
                day={getRTDate.date(singleEvents.date)}
                notEmpty={singleEvents && Object.keys(singleEvents).length!==0} />

                {/* Body details */}
                <Info
                loading={singleEventLoading}
                description={singleEvents?.description}
                notEmpty={singleEvents && Object.keys(singleEvents).length!==0}
                time={singleEvents?.time}
                guests={singleEvents?.speakers}
                location={singleEvents?.location}
                date={`${getRTDate.date(singleEvents?.date)} ${FULL_MONTHS[getRTDate.month(singleEvents?.date)]}, ${getRTDate.year(singleEvents?.date)}`} />

                {/* Sponsoring events: this is hidden for past events */}
                <SponsorEvent />

                {/* Photo gallery: This is hidden for upcoming events */}
                <ImageGallery
                loading={singleEventLoading}
                notEmpty={singleEvents && Object.keys(singleEvents).length!==0} />

                {/* Book a space */}
                <BookSpace />

            </WithEventStatus>

            {/* Footer */}
            <Footer2 />
            
        </div>
    )
}

export default EventDetailsBody