import React from 'react'
import ActiveInvestmentBody from '../../../../components/Redesign/Dashboard/Investing/ActiveInvestment/Body'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'

const ActiveInvestment = () => {
    return (
        <PageWrapper
        enableBackBtn={true}
        title={"Investing"}
        subTitle={"Gain profit by investing in startups with the right vision"}>

            {/* body section */}
            <ActiveInvestmentBody/>

        </PageWrapper>
    )
}

export default ActiveInvestment