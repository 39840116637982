import React from 'react'
import CertificateGeneratorBody from '../../../../components/Redesign/Dashboard/Bootcamps/CertificateGenerator/CertificateGeneratorBody'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'

const Certificate = () => {
    return (
        <PageWrapper
        enableBackBtn={true}
        title={"Certification"}
        subTitle={"Glad you made it this far 🎉"}>

            {/* body section */}
            <CertificateGeneratorBody />

        </PageWrapper>
    )
}

export default Certificate