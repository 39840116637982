import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FormError } from '../../../../Errors'
import BtnPrimary from '../../../../Global/Redesign/BtnPrimary'
import { motion } from "framer-motion";
import StepBtn from './StepBtn';
import PayStackBtn from '../../../../Global/PayStackBtn';
import { studentRegisterRequest } from '../../../../../redux/action';
import { useDispatch } from 'react-redux';
import MyModal from '../../../../../layouts/MyModal';
import PaymentVerificationView from './PaymentVerificationView';

const Form = ({
    formik, 
    registerLoading,
    pages,
    setPages,
    x,
    setX,
    bootcampPrice,
    bootcampId,
    setReferenceId,
    disableBtn
}) => {


    // OBJECT INITIALIZATION
    const dispatch = useDispatch()
    const componentProps = {
        email: formik.getFieldProps("email").value,
        amount: Number(bootcampPrice) * 100,
        metadata: {
            name: formik.getFieldProps("firstname").value + " " + formik.getFieldProps("lastname").value,
            phone: formik.getFieldProps("mobile").value,
        },
        publicKey: `${process.env.REACT_APP_LIVE_PAYSTACK_KEY}`,
        text: "Sign up",
        onSuccess: (e) => {
            console.log("payment was successfull", e.reference);
            setReferenceId(e.reference)
            formik.handleSubmit()
        },
        onClose: () => {},
    };


    // STATES
    const [isFormReady, setIsFormReady] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)


    // HANDLERS
    const toggleModal = () => {
        setIsModalOpen(!isModalOpen)
    }

    
    // SIDE EFFECT
    useEffect(()=>{
        if((Object.keys(formik.errors).length === 0) && (Object.keys(formik.touched).length!==0)) {
            console.log("Your ready to pay now, and register for this bootcamps!")
            setIsFormReady(true)
        } else {
            setIsFormReady(false)
        }
    }, [formik.errors, formik.touched])

    // step button
    useEffect(()=> {
        disableBtn({
            back: false,
            next: true
        })
    }, [])


    return (
        <motion.div
        initial={{ x: x }}
        key={"form"}
        transition={{ duration: 1 }}
        animate={{ x: 0 }}
        exit={{ x: x }}
        className={"flex flex-col gap-3 justify-center items-center w-full h-full"}>
            <form 
            onSubmit={(e)=>e.preventDefault()}
            className="flex flex-col justify-center items-center w-full gap-5">

                {/* FORM CONTROLS */}
                {/* first name */}
                <label className="flex flex-col mobile:w-full w-[360px] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        First name
                    </span>

                    <input
                    type="text"
                    {...formik.getFieldProps("firstname")}
                    className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                    placeholder="Enter your first name" />

                    {formik.errors.firstname && formik.touched.firstname && (
                        <FormError message={formik.errors.firstname} />
                    )}
                </label>

                {/* last name */}
                <label className="flex flex-col mobile:w-full w-[360px] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        Last name
                    </span>

                    <input
                    type="text"
                    {...formik.getFieldProps("lastname")}
                    className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                    placeholder="Enter your last name" />

                    {formik.errors.lastname && formik.touched.lastname && (
                        <FormError message={formik.errors.lastname} />
                    )}
                </label>

                {/* email address */}
                <label className="flex flex-col mobile:w-full w-[360px] gap-2">
                    <span className="text-[#344054] text-sm font-semibold">Email</span>

                    <input
                    type="email"
                    {...formik.getFieldProps("email")}
                    className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                    placeholder="Enter your email" />

                    {formik.errors.email && formik.touched.email && (
                        <FormError message={formik.errors.email} />
                    )}
                </label>

                {/* phone number */}
                <label className="flex flex-col mobile:w-full w-[360px] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        Phone number
                    </span>

                    <input
                        type="text"
                        {...formik.getFieldProps("mobile")}
                        className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                        placeholder="Enter your phone number"
                    />

                    {formik.errors.mobile && formik.touched.mobile && (
                        <FormError message={formik.errors.mobile} />
                    )}
                </label>

                {/* date of birth */}
                <label className="flex flex-col mobile:w-full w-[360px] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        Date of birth
                    </span>

                    <input
                        type={"date"}
                        {...formik.getFieldProps("date_of_birth")}
                        className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                        placeholder="Date of Birth"
                    />

                    {formik.errors.date_of_birth && formik.touched.date_of_birth && (
                        <FormError message={formik.errors.date_of_birth} />
                    )}
                </label>

                {/* reason */}
                <label className="flex flex-col mobile:w-full w-[360px] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        Reason
                    </span>

                    <input
                    type="text"
                    {...formik.getFieldProps("reason")}
                    className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                    placeholder="Why do you want to join us" />

                    {formik.errors.reason && formik.touched.reason && (
                        <FormError message={formik.errors.reason} />
                    )}
                </label>

                {/* password */}
                <label className="flex flex-col mobile:w-full w-[360px] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        Password
                    </span>

                    <div className="flex flex-col gap-1">

                        <input
                        type="password"
                        {...formik.getFieldProps("password")}
                        className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                        placeholder="Create a password"
                        />
                        <span className="font-normal text-sm text-[#667085]">
                            Must be at least 8 characters.
                        </span>

                    </div>

                    {formik.errors.password && formik.touched.password && (
                        <FormError message={formik.errors.password} />
                    )}
                </label>

                {/* confirm password */}
                <label className="flex flex-col mobile:w-full w-[360px] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        Confirm Password
                    </span>

                    <div className="flex flex-col gap-1">

                        <input
                        type="password"
                        {...formik.getFieldProps("password_confirmation")}
                        className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                        placeholder="Confirm your password"
                        />
                        <span className="font-normal text-sm text-[#667085]">
                            Must be at least 8 characters.
                        </span>

                    </div>

                    {formik.errors.password_confirmation && formik.touched.password_confirmation && (
                        <FormError message={formik.errors.password_confirmation} />
                    )}
                </label>

                {/* sign up button */}
                <div className="flex flex-col items-stretch mobile:w-full w-[360px] gap-5">

                    {/* sign up */}
                    {  
                        registerLoading?
                        <BtnPrimary
                        title={"Register now"}
                        loading={true} />:
                        formik.getFieldProps("reference").value?
                        <BtnPrimary
                        title={"Sign up"}
                        loading={registerLoading}
                        onClick={()=>{
                            const formData = formik.values
                            dispatch(studentRegisterRequest({ formData}))
                        }} />:
                        <BtnPrimary
                        disabled={!isFormReady}
                        onClick={toggleModal}
                        title="Sign up" />
                    }

                    {/* google signin button */}
                    {/* <GoogleSignInBtn title={"Sign up with Google"} /> */}
                    
                </div>

                {/* sign up/register */}
                <p className="text-[#667085] text-sm font-normal flex gap-[5px] mt-2">
                    <span>Already have an account?</span>

                    <Link to={"/login"} className="font-semibold text-sm text-[#0B8CBA]">
                        Log in
                    </Link>
                </p>
            </form>

            {/* modal */}
            <MyModal
            modalIsOpen={isModalOpen}
            toggleModal={toggleModal}
            disableBtn={true}>

                {/* modal view */}
                <PaymentVerificationView
                formik={formik}
                bootcampId={bootcampId}
                price={bootcampPrice}
                setReferenceId={setReferenceId}
                config={componentProps}
                closeModal={toggleModal} />
            </MyModal>
        </motion.div>
    )
}

export default Form