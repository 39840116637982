import ContactBody from "../../../../components/Redesign/Webpage/ContactUs/ContactBody";
import ContactHeader from "../../../../components/Redesign/Webpage/ContactUs/ContactHeader";
import Wrapper from "../../../../layouts/Redesign/Wrapper";

const ContactUs = () => {

    return (
        <Wrapper>

            {/* Contact us Header */}
            <ContactHeader />

            {/* Contact us Body */}
            <ContactBody />

        </Wrapper>
    )
}

export default ContactUs