import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import image from "../../../../../assets/redesign-dashboard/bootcamps/images/jumbotron-image.png"
import { getBootcampForUsersRequest } from '../../../../../redux/action'
import SingleCert from './singlecert'
// import HowItWorks from '../../TheClub/AvailablePackages/HowItWorks'
import UpcomingEvents from "../../TheClub/UpcomingEvents";
// import AvailableTracks from './AvailableTracks'

const CertificateGeneratorBody = () => {




    // OBJECT INITIALIZATION
    const dispatch = useDispatch()


    // SIDE EFFECT
    useEffect(()=>{
        dispatch(getBootcampForUsersRequest())
    }, [])

    return (
        <div className='flex mobile:flex-col w-full h-full gap-10  '>
            
            {/* section filled with image and text details */}
            <div className='h-full w-[70%] mobile:w-full flex flex-col gap-10 '>

                {/* image */}
               

                {/* texts */}
                <div className='flex flex-col gap-1 w-full text-base text-gray-800 font-normal '>
                    <p>
                    Your journey in the tech world has been remarkable, and your achievements are commendable. The AI Movement takes immense pride in being part of your memorable voyage. 
                    </p>

                    <p>
                    As you embrace the world of endless opportunities, we're honored to present you with this certificate, acknowledging your dedication and successes. It's a testament to your capabilities and the exciting potential that lies ahead.
                    </p>

                </div>

                <div className='w-full'>
                    <SingleCert />

                {/* <img src={image} className='w-full object-cover h-full rounded-[10px] [box-shadow:0px_8px_32px_rgba(0,0,0,0.16)]' alt="" /> */}

                </div>

             
            </div>

              {/* upcomoing event section */}
              <div className="h-full w-[30%] mobile:w-full flex flex-col ">
                <UpcomingEvents />
            </div>
        </div>
    )
}

export default CertificateGeneratorBody