import React from 'react'
import { Progress } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { daysDiff } from '../../../../helpers/daysDiff';

const SpaceFaceCard = ({
    duration,
    price,
    image,
    startDate,
    endDate,
    spaceName
}) => {

    // DATA INITIALIZATION
    const s_day = new Date(startDate)
    const t_day = new Date()

    const today = t_day > s_day? t_day: s_day
    const end = new Date(endDate)
    
    return (
        <div className='gap-10 flex mobile:flex-col-reverse p-5 rounded-[10px] bg-white border border-[#E1E6EF]'>

            {/* texts section */}
            <section className='flex flex-col gap-4 items-start w-[60%] mobile:w-full'>

                {/* caption text */}
                <p className='mt-0 font-semibold text-sm text-gray-600'>
                    Current space booked
                </p>

                {/* texts */}
                <div className='flex items-center gap-4 w-full'>
                    <p className='mt-0 font-medium text-base text-[#1D2433]'>
                        Space name:
                    </p>

                    <p className='mt-0 text-gray-800 font-normal text-sm'>
                        {spaceName}
                    </p>
                </div>

                {/* texts */}
                <div className='flex items-center gap-4 w-full'>
                    <p className='mt-0 font-medium text-base text-[#1D2433]'>
                        Duration:
                    </p>

                    <p className='mt-0 text-gray-800 font-normal text-sm'>
                        {duration}days
                    </p>
                </div>

                {/* texts */}
                <div className='flex items-center gap-4 w-full mt-aut'>
                    <p className='mt-0 font-medium text-base text-[#1D2433]'>
                        Total Cost:
                    </p>

                    <p className='mt-0 text-gray-800 font-normal text-sm'>
                        NGN{price.toLocaleString('en-US')}
                    </p>
                </div>

                {/* progress section */}
                <div className="flex items-center -ml-2 w-full mt-auto">
                    <div className="w-[45%]">
                        <Progress.Line
                        percent={(+daysDiff(end, today)/+duration) * 100}
                        showInfo={false}
                        strokeColor="#0B8CBA"/>
                    </div>
    
                    <p className="font-normal text-sm text-gray-800 w-[55%] mb-1">
                        {`${t_day>s_day?(daysDiff(end, today)):duration} day(s) remaining`}
                    </p>
                </div>
            </section>

            {/* image section */}
            <div className='h-full w-[40%] mobile:w-full'>
                <img src={image} className='object-cover h-full rounded-[10px] w-full' alt="" />
            </div>
        </div>
    )
}

export default SpaceFaceCard