import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const SpecialTrackInfoView = () => {
    return (
        <div className="p-5 pc:p-9 bg-white flex flex-col justify-center items-center gap-5 pc:gap-7">
            
            {/* icon container */}
            <div className='flex justify-center items-center text-[#8B54F7] h-[48px] w-[48px] rounded-[50%] bg-[#F8F5FF]'>
                <FontAwesomeIcon 
                color='#8B54F7' 
                icon={faCircleInfo} 
                size="lg" />
            </div>

            {/* text container */}
            <p className='text-sm font-normal pc:text-lg text-[#6B7280]'>
                The special track is an intensive program that runs for 12 months after which successful students will be offered positions in the company.
            </p>
        </div>
    )
}

export default SpecialTrackInfoView