import React from 'react'
import { useHistory } from 'react-router-dom'
import BtnSm from '../BtnSm'

const JoinUs = () => {

    // history object
    const history = useHistory()

    return (
        <div className='flex flex-col items-center justify-center pt-[12rem] mt-24 mobile:mt-6 relative'>
            
            {/* fist container */}
            <div className='w-full flex justify-center absolute top-[-5%] mobile:top-[15%] mobile:px-6'>

                {/* texts */}
                <div className='flex flex-col rounded-[10px] justify-center items-center mobile:items-stretch bg-[#1D2433] text-white text-center mobile:w-full md:w-[80%] w-[65%] relative py-32 gap-8 px-24 mobile:p-[54px_26px] mobile:h-fit'>
                    <p className='font-semibold mobile:text-2xl text-4xl leading-[54px] mobile:px-6'>
                        Join us today and grow your tech career the right <br/>way with the right team
                    </p>

                    {/* button */}
                    <BtnSm 
                    onClick={()=>history.push("/old/login")}
                    title={"Get started"} />
                </div>
            </div>

            {/* second container */}
            <div className="h-[250px] mobile:h-[30vh] bg-[#F9FAFB] w-full"></div>
        </div>
    )
}

export default JoinUs