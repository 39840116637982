import React from 'react'

const SubscribeForm = () => {
  return (
    <>
        <form className='inline-flex mobile:flex-col mobile:w-full gap-4'>

            {/* email input */}
            <input 
            type="email" 
            className='border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd]' 
            placeholder='Enter your email' />

            {/* submit button */}
            <button className='h-[44px] bg-[#7F56D9] flex justify-center items-center font-semibold text-base text-white px-7 lg:text-xs rounded-[8px] hover:opacity-80'>
                Subscribe
            </button>
        </form>
    </>
  )
}

export default SubscribeForm