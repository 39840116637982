import { combineReducers } from "redux";
import userData from "./auth";
import { persistReducer } from "redux-persist";
import loadingIndicator from "./loadingIndicator";
import ajaxStatuses from "./ajaxStatuses";
import storage from "redux-persist/lib/storage";
import bootcampReducer from "./bootcamp";
import clubPackagesReducer from "./clubPackages";
import eventsReducer from "./events";
import clubSubscriptionReducer from "./clubSubscriptions";
import spacesReducer from "./spaces";

const authPersistConfig = {
  key: "userData",
  storage,
  whitelist: ["isLoggedIn", "token", "user"],
};

export default combineReducers({
  userData: persistReducer(authPersistConfig, userData),
  loadingIndicator,
  ajaxStatuses,
  bootcampReducer,
  clubPackagesReducer,
  eventsReducer,
  clubSubscriptionReducer,
  spacesReducer
});
