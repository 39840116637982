import React from 'react'
import CoursesBody from '../../../components/Dashboard/Courses/CoursesBody'
import SideNav from '../../../layouts/Dashboard/SideNav'

const DashboardCourses = () => {
    return (
        <div className='flex justify-end mobile:w-screen mobile:flex-col h-full w-full mobile:bg-[#E5E5E5]'>

            {/* Side nav */}
            <SideNav />

            {/* Courses Body */}
            <CoursesBody />
        </div>
    )
}

export default DashboardCourses