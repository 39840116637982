import { useState, useEffect } from 'react'
import StepBtn from './StepBtn'
import TrackCard from './TrackCard';
import { motion } from "framer-motion";

const CourseTrack = ({
    pages,
    setPages,
    x,
    setX,
    track,
    setTrack,
    disableBtn,
}) => {

    // SIDE EFFECTS
    useEffect(()=> {
        disableBtn({
            back: true,
            next: !track
        })
    }, [track])

    return (
        <motion.div
        key={"courseTrack"}
        initial={{ x: x }}
        transition={{ duration: 1 }}
        animate={{ x: 0 }}
        exit={{ x: x }}
        className='flex flex-col w-full items-start gap-3'>

            {/* caption */}
            <p className='mt-0 mb-1 text-gray-800 text-sm font-normal'>
                Select a track and click on next
            </p>

            {/* container */}
            <div className='flex flex-col gap-5'>

                {/* regular track */}
                <TrackCard
                onClick={()=>setTrack("1")}
                title={"Regular course track"}
                desc={"Project based intensive training programme"}
                clicked={track==="1"} />

                {/* special */}
                <TrackCard
                onClick={()=>setTrack("2")}
                title={"Special course track"}
                desc={"Project based intensive training program + Employment offer after training."}
                clicked={track==="2"} />

            </div>
        </motion.div>
    )
}

export default CourseTrack