import {
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    UPDATE_USER_LOADING,
    UPDATE_SUCCESS_CLEAR,
    UPDATE_USER_PASSWORD_REQUEST,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_PASSWORD_FAIL,
    UPDATE_USER_PASSWORD_LOADING,
    CLEAR_UPDATE_USER_PASSWORD_SUCCESS
} from "../types"

// UPDATE USER INFORMATIONS
export const updateUserRequest = (prop) => {
    return {
      type: UPDATE_USER_REQUEST,
      payload: prop,
    };
  };
  
  export const updateUserSuccess = (prop) => {
    return {
      type: UPDATE_USER_SUCCESS,
      payload: prop,
    };
  };
  
  export const updateUserFailure = (errors) => {
    return {
      type: UPDATE_USER_FAIL,
      payload: { errors },
    };
  };
  
  export const updateUserLoading = (loading) => {
    return {
      type: UPDATE_USER_LOADING,
      payload: loading,
    };
  };

  export const clearUpdateSuccess = () => {
    return {
      type: UPDATE_SUCCESS_CLEAR
    };
  };

// UPDATE USER'S PASSWORD
export const updateUserPasswordRequest = (prop) => {
    return {
      type: UPDATE_USER_PASSWORD_REQUEST,
      payload: prop,
    };
  };
  
  export const updateUserPasswordSuccess = (prop) => {
    return {
      type: UPDATE_USER_PASSWORD_SUCCESS,
      payload: prop,
    };
  };
  
  export const updateUserPasswordFailure = (errors) => {
    return {
      type: UPDATE_USER_PASSWORD_FAIL,
      payload: { errors },
    };
  };
  
  export const updateUserPasswordLoading = (loading) => {
    return {
      type: UPDATE_USER_PASSWORD_LOADING,
      payload: loading,
    };
  };

  export const clearUpdatePasswordSuccess = () => {
    return {
      type: CLEAR_UPDATE_USER_PASSWORD_SUCCESS
    };
  };