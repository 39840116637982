import React from 'react'
import shape from "../../../../assets/webpages/club/images/w-shape1.png"

const InfoCard = ({
    reverse,
    subTopic,
    title,
    desc,
    image,
    mobileImage
}) => {
    return (
        <div className={`flex flex-col relative gap-20 mobile:gap-8 text-white ${reverse?"flex-col-reverse mobile:flex-col items-end justify-start":"items-start justify-end"}`}>

            {/* shape */}
            {
                reverse && 
                <img src={shape} className='absolute top-[-12%] right-[25%] mobile:hidden mobile:' alt="" />
            }

            {/* texts */}
            <div className='flex flex-col gap-4'>

                <p className='text-[#0B8CBA] text-sm font-bold mt-0 mobile:text-center'>
                    {subTopic}
                </p>
                
                <p className='font-semibold text-[28px] text-[#1D2433] mt-0 mobile:text-center mobile:text-2xl'>
                    {title}
                </p>

                <p className='font-normal text-base text-gray-800 mt-0 mobile:text-center'>
                    {desc}
                </p>
            </div>
            
            {/* image */}
            <div className={`w-full flex ${reverse?"justify-end":"justify-start"}`}>

                {/* pc image */}
                <img src={image} className='w-[75%] mobile:hidden h-full rounded-[20px]' alt="" />

                {/* mobile image */}
                <img src={mobileImage} className='hidden mobile:inline w-full h-full rounded-[20px]' alt="" />
            </div>
        </div>
    )
}

export default InfoCard