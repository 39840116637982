import React from 'react'
import { ReactComponent as ExpandIcon } from "../../../../../assets/dashboard/events/svg/expand-icon.svg";

const ToggleViewImage = ({handleClick}) => {
    return (
        <button
        onClick={handleClick}
        className="flex flex-col text-[#1d2433] text-base font-normal justify-center items-center p-3 gap-2 absolute right-[15px] top-[15px] hover:bg-[#e3e3e3] transition-colors bg-white rounded-[4px] z-[2]">

            <ExpandIcon className="h-[20px] w-[20px]" />

            <span className="opacity-[0.65] mobile:hidden">
                View full image
            </span>
        </button>
    )
}

export default ToggleViewImage