import { FiCheckCircle } from 'react-icons/fi'

const GreenCheck = () => {
    
    return (
        <span className='min-w-[32px] bg-[#F8F9FC] flex justify-center items-center rounded-[50%] p-1 min-h-[32px]'>
            <FiCheckCircle color='#08875D' size={15} />
        </span>
    )
}

export default GreenCheck;