import React from 'react'
import { useHistory } from 'react-router-dom'
import BtnPrimary from '../../../../Global/Redesign/BtnPrimary'

const FixedBackProjectBtn = ({path}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()

    return (
        <div
        className='flex flex-col w-full bg-white border-t border-[#E1E6EF] p-[20px_12px_12px]'>
            <BtnPrimary
            onClick={()=>history.push("/dashboard/investing/payment/kljkjadfjsdkfjskfjskfdk")} 
            title={"Back this Project"} />
        </div>
    )
}

export default FixedBackProjectBtn