import React, { useState } from 'react'
import Header2 from '../../../../layouts/Redesign/Header2'
import shape1 from "../../../../assets/webpages/spaces/images/c-shape1.png"
import shape2 from "../../../../assets/webpages/spaces/images/c-shape2.png"
import BtnPrimary from '../../../Global/Redesign/BtnPrimary'
import MyModal from '../../../../layouts/MyModal'
import SearchWidget from './SearchWidget'

const SpaceHeader = () => {
        

    // STATES
    const [spaceModalState, setSpaceModalState] = useState(false)


    // HANDLERS
    const toggleSpaceModal = () => {
        setSpaceModalState(!spaceModalState)
    }

    return (
        <div className='flex items-center justify-start bg-[#F0F5FF] h-[75vh] mobile:h-[63vh] relative flex-col top-0'>

            {/* header */}
            <Header2 />

            {/* SHAPES */}
            {/* shape1 */}
            <img src={shape1} className="mobile:w-[20px] absolute top-[30%] left-[20%] mobile:left-[2rem] mobile:top-[7.5rem]" alt="" />

            {/* shape2 */}
            <img src={shape2} className="mobile:w-[20px] absolute bottom-[30%] right-[10%] mobile:right-[7%] mobile:bottom-[60%]" alt="" />

            {/* jumbotron */}
            <div className='w-full flex flex-col items-center top-[20%] mobile:top-[25%] relative z-[200] gap-4 text-center container mx-auto mobile:px-4'>

                <p className='font-semibold text-[45px] mobile:text-4xl text-[#1D2433]'>
                    Comfortable Workspaces
                </p>

                <p className='text-[#1D2433] text-2xl font-normal mobile:font-semibold'>
                    Just for You
                </p>

                <p className='text-gray-800 text-base font-normal pc:w-[45%] mb-4'>
                    From cost savings to increased collaboration opportunities, our modern coworking spaces will make your work life more enjoyable and productive.
                </p>

                <BtnPrimary
                onClick={toggleSpaceModal}
                title={"Book a space now"} />
            </div>

            {/* Modal */}
            {/* logout modal */}
            <MyModal
            modalIsOpen={spaceModalState}
            toggleModal={toggleSpaceModal}
            disableBtn={true}>
                
                {/* search modal */}
                <SearchWidget />

            </MyModal>
        </div>
    )
}

export default SpaceHeader