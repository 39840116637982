import React from 'react'
import BtnPrimary from '../../../../Global/Redesign/BtnPrimary'
import { useHistory } from 'react-router-dom'
import { isEmpty } from '../../../../../helpers/isEmtpy'

const SpaceCard = ({
    image, 
    spaceName, 
    price,
    spaceId,
    duration,
    startDate,
    endDate
}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()
    const params = new URLSearchParams({
        duration: duration,
        start: startDate,
        end: endDate
    })

    return (
        <div className='flex flex-col rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] p-3 gap-4 bg-white'>
            
            {/* image */}
            <div className='w-full h-full'>
                <img src={image} className='w-full h-full object-cover rounded-[10px]' alt="" />
            </div>

            {/* texts */}
            <div className='w-full h-full flex flex-col gap-4'>

                {/* text */}
                <div className='flex flex-col gap-2 items-center'>

                    <p className='text-base font-medium text-[#0B8CBA]'>
                        {spaceName}
                    </p>

                    <p className='text-gray-600 text-base font-bold'>
                        NGN {price?.toLocaleString('en-US')} /day
                    </p>
                </div>

                {/* button */}
                <div className='w-full flex flex-col items-stretch'>

                    <BtnPrimary
                    disabled={isEmpty(duration)}
                    onClick={()=>{
                        history.push({
                            pathname: `/dashboard/space-booking/${spaceId}`,
                            search: params.toString()
                        })
                    }}
                    title={"Reserve this space"} />
                </div>
            </div>
        </div>
    )
}

export default SpaceCard