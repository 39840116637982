import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import shape from "../../../../assets/webpages/event/images/a-shape1.png";
import { getEventsForUsersRequest } from "../../../../redux/action";
import Programmes from "../../../Events/Programmes";
import CardContainer from "./CardContainer";

const EventsContainer = () => {

  // OBJECT INITIALIZATION
  const dispatch = useDispatch()

  
  // STATES
  const {
      events,
      eventsForUsersLoading
  } = useSelector((state)=>{
      const {events} = state.eventsReducer
      const {eventsForUsersLoading} = state.loadingIndicator
      return {
          events,
          eventsForUsersLoading
      }
  })


  // SIDE EFFECTS
  useEffect(()=>{
      dispatch(getEventsForUsersRequest())
  }, [])

  return (
    <div className="flex flex-col relative py-44 mobile:py-16 gap-16 mobile:gap-10 px-5 mobile:px-4 z-[1] w-full h-full">
      {/* shape */}
      <img
        src={shape}
        className="absolute right-[16%] mobile:hidden top-[4.5rem]"
        alt=""
      />

      <CardContainer
        loading={eventsForUsersLoading}
        events={events.filter((event)=>event.is_Ended===false)}
        title={`Upcoming Events in ${new Date().getFullYear()}`}
      />

      {/* <Programmes /> */}

      <CardContainer
        loading={eventsForUsersLoading}
        events={events.filter((event)=>event.is_Ended===true)}
        title={"Past Events"}
      />
    </div>
  );
};

export default EventsContainer;
