import { CircularProgress } from '@material-ui/core'
import React from 'react'

const BtnPrimary = ({ title, loading, onClick, disabled, type="button" }) => {
    return (
        <>
            <button
            type={type}
            disabled={disabled}
            className="h-[44px] mobile:h-[48px] bg-[#0B8CBA] flex justify-center items-center font-semibold text-base xl:text-sm lg:text-xs text-white px-7 rounded-[8px] hover:opacity-80 disabled:opacity-40 disabled:cursor-not-allowed"
            onClick={onClick}>

                {loading ? (
                    <div className="text-center">

                        <CircularProgress color={"inherit"} size="20px" />

                    </div>
                ) : title ? (
                    title
                ) : (
                    "Submit"
                )}
            </button>
        </>
    )
}

export default BtnPrimary