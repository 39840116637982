import React from 'react'
import CourseTab from './CourseTab'
import flakes from "../../assets/home/images/flakes.png"
import snowman from "../../assets/home/images/snowman.png"

const Commuity = () => {
    return (
        <div className='bg-[#1d2433] py-44 pc:px-10 mobile:py-20 mobile:px-5 relative overflow-hidden'>

            {/* text section */}
            <div className='flex flex-col text-white text-center gap-6 pc:px-32 relative z-[10]'>
                <p className='font-semibold mobile:text-2xl text-4xl leading-[54px]'>
                    A tech community for all
                </p>
                <p className='font-normal mobile:text-base text-xl leading-[30px] mobile:px-6 md:px-20'>
                    This platform opens a door for people to enter the tech arena, and it creates a wholesome community of tech experts and potential investors - think of it as a circular arrow in motion that touches and merges all corners of the tech universe. 
                </p>
            </div>

            {/* tab section */}
            <CourseTab />
        </div>
    )
}

export default Commuity