import React from "react";
import MemberCard from "./MemberCard";
import kachi from "./../../assets/management/images/kachi.png";
import esther from "./../../assets/management/images/esther.png";
import dolu from "./../../assets/management/images/dolu.png";
import Remi from "./../../assets/management/images/Remi.png";
import t_shape1 from "./../../assets/management/images/t-shape1.png";
import t_shape2 from "./../../assets/management/images/t-shape2.png";
import BreakTextPC from "../Global/BrTextPC";

const OurTeam = () => {
  return (
    <div className="py-32 flex flex-col mobile:px-5 mobile:py-16 px-8 mx-auto relative">
      <img
        src={t_shape1}
        className="absolute top-[9%] right-[22%] mobile:w-[20px] mobile:top-[4%] mobile:right-[2rem]"
        alt=""
      />
      <img
        src={t_shape2}
        className="absolute bottom-[1rem] left-[3rem] mobile:w-[20px]"
        alt=""
      />

      {/* text section */}
      <div className="flex flex-col gap-2 text-center">
        <p className="font-semibold text-base mobile:text-xs text-[#8B54F7]">
          OUR TEAM
        </p>

        <p className="font-semibold text-4xl mobile:text-2xl leading-[54px] text-[#1D2433]">
          Every great company has a great <BreakTextPC />
          team behind it. Meet ours
        </p>
      </div>

      {/* team gallery */}
      <div className="grid mobile:grid-cols-[auto] mobile:px-2 mobile:mt-12 lg:grid-cols-[auto_auto] grid-cols-[auto_auto_auto] items-center justify-center gap-12 mt-24">
        {/* kachi */}
        <MemberCard
          image={kachi}
          name="Barr. Kachi Aghasili"
          title="CEO & Founder"
        />

        {/* esther */}
        <MemberCard
          image={esther}
          name="Esther Nmesooma Anisi"
          title={"Digital Communications Director"}
        />

        {/* Dolu */}
        <MemberCard
          image={dolu}
          name="Engr. Dolu Okeowo"
          title="Chief consultant officer (USA)"
        />

        {/* kachi */}
        <MemberCard
          image={Remi}
          name="Chuks Remy"
          title="Chief technical officer"
        />
      </div>
    </div>
  );
};

export default OurTeam;
