import React, { useEffect, useState } from 'react'
import { BiUserCircle } from 'react-icons/bi'
import { FiCamera } from 'react-icons/fi'
import { useDispatch, useSelector } from 'react-redux'
import MyModal from '../../../../layouts/MyModal'
import { updateUserRequest } from '../../../../redux/action'
import ImageUploadView from './ImageUploadView'

const ProfileImage = ({
    toggleUploadFileModal, 
    uploadFileModalState
}) => {
    
    // DATA INITIALIZATION
    const dispatch = useDispatch()


    // STATES
    const [imageUrl, setImageUrl] = useState("")

    const { 
        user, 
        updateUserLoading,
        token
     } = useSelector((state) => {

        const { updateUserLoading } = state.loadingIndicator;

        const { user, token } = state.userData;

        return {
          user,
          updateUserLoading,
          token
        };
      });


    // HANDLERS
    const completeImageUpload = () => {
        const formData = {
            firstname: user.firstname,
            lastname: user.lastname,
            mobile: user.mobile,
            date_of_birth: user.date_of_birth,
            reason: user.reason,
            occupation: user.occupation,
            avatar: imageUrl
        }
        dispatch(updateUserRequest({ formData, token }));
    }


    // SIDE EFFECTS
    useEffect(()=>{
        setImageUrl(user?.avatar || "")
    }, [])
    
    //   checking for success
    // useEffect(() => {
    //     if (updateUserSuccess) {
    //         toggleUploadFileModal()
    //         toast.success("Image uploaded successfully!", {
    //             duration: 3000,
    //         })
    //     }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [updateUserSuccess]);

    return (
        <div className='flex justify-start items-end gap-5'>

            {/* Image */}
            <div className='flex items-center justify-center relative'>
                {
                    imageUrl?
                    <img
                    src={imageUrl}
                    className="w-[140px] h-[140px] mobile:max-w-[77px] mobile:max-h-[77px] rounded-[50%] object-cover border-4 border-[#F4EBFF]"
                    alt="" />:
                    <BiUserCircle 
                    className="mobile:text-[80px] text-[130px]" />

                }

                {/* Upload photo icon */}
                <span
                onClick={toggleUploadFileModal} 
                className='h-[40px] w-[40px] mobile:h-[22.22px] mobile:w-[22.22px] bg-[#0B8CBA] flex items-center justify-center rounded-[50%] absolute bottom-[10px] mobile:bottom-[5px] mobile:right-[0px] right-[2px] hover:opacity-80 cursor-pointer'>
                    <FiCamera color='#fff' className='text-2xl mobile:text-sm' />
                </span>
            </div>

            {/* Name and info */}
            <div className='flex flex-col gap-1'>
                <p className='font-semibold mobile:font-bold mobile:text-base text-xl text-[#344054] mt-0'>
                    {`${user.firstname} ${user.lastname}`}
                </p>

                <p className='text-[#667085] mobile:text-sm text-base font-normal mt-0'>
                    {user.email}
                </p>
            </div>


            {/* file upload modal */}
            <MyModal
            disableBtn={false}
            modalIsOpen={uploadFileModalState}
            toggleModal={toggleUploadFileModal}>

                <ImageUploadView
                handleUploadImage={completeImageUpload}
                uploadLoading={updateUserLoading}
                setImage={setImageUrl}
                closeModal={toggleUploadFileModal} />
            </MyModal>
        </div>
    )
}

export default ProfileImage