import React from 'react'
import SingleEventBody from '../../../../components/Redesign/Dashboard/Events/SingleEvent/SingleEventBody'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'

const SingleEvent = () => {
    return (
        <PageWrapper
        enableBackBtn={true}
        title={"Comfortable Workspaces"}
        subTitle={"Just for You"}>

            {/* body section */}
            <SingleEventBody />

        </PageWrapper>
    )
}

export default SingleEvent