import React from 'react'
import EventToggle from './EventToggle'

const Jumbotron = () => {
    return (
        <div className='w-full flex flex-col items-center top-[20%] mobile:top-[25%] relative z-[200] gap-4 text-center container mx-auto'>

            <EventToggle />

            <p className='text-[#101828] text-5xl mobile:text-4xl font-semibold'>
                Let's Connect and Educate
            </p>

            <p className='text-black font-semibold text-2xl mobile:font-normal mobile:text-base'>
                A tech platform designed to help you
            </p>
        </div>
    )
}

export default Jumbotron