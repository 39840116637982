import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";
import { clientErrorMessage, delay } from "./reusable";
import { REGISTER_CRITIQUE_EVENT_REQUEST } from "../types";
import { clearCritiqueEventSuccess, critiqueEventFailure, critiqueEventLoading, critiqueEventSuccess } from "../action";




// ajaxDBCall
const ajaxDBCalls = {

    // register critique for lunch event
    registerCritiqueEvent: async ({payload}) => {
      const response = await axios({
        method: "POST",
        url: "critique/register",
        data: payload.userData
      });
      return response;
    },
}


// WORKERS
function* registerCritiqueEvent(payload) {
    try {
        yield put(critiqueEventLoading(true));
        const response = yield call(ajaxDBCalls.registerCritiqueEvent, payload);
        yield put(critiqueEventSuccess(response.data));
        yield put(critiqueEventLoading(false));
        yield delay();
        yield put(clearCritiqueEventSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;
    
        if (err.response) {
          console.log("here's what's wrong", err.response.data.message);
    
          const { message } = err.response.data;
          errorMessage = message;
        }
    
        yield put(critiqueEventFailure(errorMessage));
        yield put(critiqueEventLoading(false));
        yield delay();
        yield put(critiqueEventFailure(""));
    }
}

// WATCHERS
function* registerCritiqueEventWatcher() {
    yield takeLatest(REGISTER_CRITIQUE_EVENT_REQUEST, registerCritiqueEvent)
}

// register digital marketing bootcamp saga
export default function* registerCritiqueEventSaga() {
    yield spawn(registerCritiqueEventWatcher);
}