import React from 'react'
import Footer2 from '../../../../layouts/Redesign/Footer2'
import BookSpace from '../Spaces/BookSpace'
import Story from './Story'
import Team from './Team'
import Values from './Values'
import story from "../../../../assets/webpages/aboutus/images/story-kachi.png"
import mission from "../../../../assets/webpages/aboutus/images/mission-guys.png"
import shape from "../../../../assets/webpages/aboutus/images/love-shape.png"

const AboutBody = () => {
    return (
        <div className='mobile:w-screen'>

            {/* Story */}
            <div className='py-40 mobile:py-20 2xl:w-[80%] container mobile:w-full mx-auto flex mobile:flex-col gap-4 mobile:gap-20 relative mobile:px-5'>

                {/* shape */}
                <img src={shape} className='absolute top-[3rem] right-[1.9rem] mobile:w-[20px] hidden mobile:inline' alt="" />

                <Story
                image={mission}
                mobileImage={story}
                subTopic={"ABOUT US"}
                title={
                    <p className='font-semibold text-[28px] text-[#1D2433] mt-0 mobile:text-center mobile:text-2xl'>
                        The story behind how <span className='text-[#0B8CBA]'>Ai Movement</span> started
                    </p>
                }
                desc={"Tech is the future, and the future is now. So, how do we move from the present to the future where we ought to be? \nTech is the future, and the future is now. So, how do we move from the present to the future where we ought to be? Tech is the future, and the future is now. So, how do we move from the present to the future where we ought to be?Tech is the future, and the future is now. So, how do we move from the present to the future where we ought to be?"} />

                <Story
                image={story}
                mobileImage={mission}
                subTopic={"OUR MISSION"}
                title={
                    <p className='font-semibold text-[28px] text-[#1D2433] mt-0 mobile:text-center mobile:text-2xl'>
                        The mission behind <span className='text-[#0B8CBA]'>Ai Movement</span>
                    </p>
                }
                desc={"Tech is the future, and the future is now. So, how do we move from the present to the future where we ought to be? \nTech is the future, and the future is now. So, how do we move from the present to the future where we ought to be? Tech is the future, and the future is now. So, how do we move from the present to the future where we ought to be?Tech is the future, and the future is now. So, how do we move from the present to the future where we ought to be?"}
                reverse={true} />
            </div>

            {/* Values */}
            <div className='w-full bg-[#1D2433] flex items-center justify-center'>
                <Values />
            </div>

            {/* Team members */}
            <Team />

            {/* BookSpace */}
            <BookSpace />
            
            {/* footer */}
            <Footer2 />

        </div>
    )
}

export default AboutBody