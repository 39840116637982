import React from 'react'

const DetailsCard = ({icon, textTitle, textDescription}) => {
    return (
        <div className="flex items-center justify-start gap-4 p-4 [box-shadow:0px_2px_8px_#e0e0e0] rounded-[8px] bg-white mobile:w-full h-fit">

            {/* icon */}
            <div className="h-[56px] mobile:h-[50px] mobile:w-[50px] bg-[#E1E6EF] rounded-[12px] flex justify-center items-center w-[56px]">
                {icon}
            </div>

            {/* texts */}
            <p className="flex flex-col items-start text-[#1D2433] opacity-80 text-base font-semibold w-[80%]">
                <span className="font-normal">
                    {textTitle}
                </span>

                <span className="text-left">
                    {textDescription}
                </span>
            </p>
        </div>
    )
}

export default DetailsCard