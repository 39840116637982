import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEventsForUsersRequest } from '../../../../redux/action'
import AllEvents from './AllEvents'
import LatestEvents from './LatestEvents'

const EventsBody = () => {

    // OBJECT INITIALIZATION
    const dispatch = useDispatch()

    
    // STATES
    const {
        events,
        eventsForUsersLoading
    } = useSelector((state)=>{
        const {events} = state.eventsReducer
        const {eventsForUsersLoading} = state.loadingIndicator
        return {
            events,
            eventsForUsersLoading
        }
    })


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getEventsForUsersRequest())
    }, [])

    return (
        <div className='w-full h-full flex flex-col gap-8'>

            {/* latest event */}
            <LatestEvents
            isEmpty={events && events.length!==0}
            events={events?.slice(-1)}
            loading={eventsForUsersLoading}  />

            {/* all events */}
            <AllEvents
            events={events}
            loading={eventsForUsersLoading} />

        </div>
    )
}

export default EventsBody