import React from 'react'

const Answer = ({index, number, question, answer}) => {

    return (
        <div className='flex items-start justify-center gap-12 mobile:border-b-2 mobile:border-[#F1F3F9] mobile:gap-8 mobile:pt-6 '>

            <p className={`${index % 2 === 0? "text-[#1D2433]":"text-[#0B8CBA]"} text-4xl mobile:text-[32px] font-semibold`}>
                0{index}
            </p>
            
            <div className='w-[60%] mobile:w-[80%] border-b-2 border-[#F1F3F9] h-full pb-10 mobile:pb-6 mobile:border-none'>
                <div className='flex mobile:flex-col w-full gap-28 mobile:gap-[24px]'>
                    <p className='font-bold text-base text-[#1D2433] mt-0'>
                        {question}
                    </p>

                    <p className='font-normal text-base text-gray-800 mt-0'>
                        {answer}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Answer