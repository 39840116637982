import axios from "./axios";
import { all, call, put, takeLatest, spawn } from "redux-saga/effects";

import { 
  BYPASS_REGISTER_REQUEST,
  CLUB_REGISTER_REQUEST,
  DELETE_USER_REQUEST,
  LOGIN_REQUEST, 
  REGISTER_REQUEST,
  STUDENT_REGISTER_REQUEST,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_REQUEST,
} from "../types";

import { clientErrorMessage, delay } from "./reusable";
import { 
  registerFailure, 
  registerLoading, 
  registerSuccess, 
  loginFailure, 
  loginLoading, 
  loginSuccess,
  updateUserFailure, 
  updateUserLoading, 
  updateUserSuccess, 
  clearRegisterSuccess,
  clearLoginSuccess,
  clearUpdateSuccess,
  studentRegisterLoading,
  studentRegisterSuccess,
  clearStudentRegisterSuccess,
  studentRegisterFailure,
  updateUserPasswordLoading,
  updateUserPasswordSuccess,
  clearUpdatePasswordSuccess,
  updateUserPasswordFailure,
  clubRegisterLoading,
  clubRegisterSuccess,
  clearClubRegisterSuccess,
  clubRegisterFailure,
  deleteUserLoading,
  deleteUserSuccess,
  clearDeleteUserSuccess,
  deleteUserFailure,
  studentByPassRegisterLoading,
  studentByPassRegisterSuccess,
  clearStudentByPassRegisterSuccess,
  studentByPassRegisterFailure
} from "../action";

const ajaxDBCalls = {

  // registration function
  register: async ({ formData, refId }) => {
    if (refId) {
      const response = await axios.post(
        `auth/register?refId=${refId}`,
        formData
      );
      return response;
    } else {
      const response = await axios.post(`auth/register`, formData);
      return response;
    }
  },

  // REDESIGN
  // student registration function
  studentRegister: async ({ formData, refId }) => {
    if (refId) {
      const response = await axios.post(
        `auth/registerforbootcamp?refId=${refId}`,
        formData
      );
      return response;
    } else {
      const response = await axios.post(`auth/registerforbootcamp`, formData);
      return response;
    }
  },

  // student registration function
  clubRegister: async ({ formData, refId }) => {
    if (refId) {
      const response = await axios.post(
        `auth/registerforclub?refId=${refId}`,
        formData
      );
      return response;
    } else {
      const response = await axios.post(`auth/registerforclub`, formData);
      return response;
    }
  },

  // login function
  login: async ({ formData }) => {
    const response = await axios.post(`auth/login`, formData);
    return response;
  },

  // update user function
  updateDetails: async ({formData, token}) => {
    const response = await axios({
        url: "auth/update",
        method: "PUT",
        data: formData,
        headers: {
            'content-type':'application/json',
            'authorization':`Bearer ${token}`
        }
    })
    return response;
  },

  // update user's password function
  updatePassword: async ({formData, token}) => {
    const response = await axios({
        url: "auth/updatepassword",
        method: "PUT",
        data: formData,
        headers: {
            'content-type':'application/json',
            'authorization':`Bearer ${token}`
        }
    })
    return response;
  },

  // delete user function
  deleteUser: async ({payload}) => {
      const response = await axios({
          method: "DELETE",
          url: "auth/delete",
          data: {
            user_id: payload
          }
      })

      return response
  },

  // BYPASS REGISTER
  // delete user function
  registerWithByPass: async (payload) => {
    const response = await axios({
      method: "POST",
      url: "auth/registerforbootcampbytoken",
      data: payload
    })

    return response
  },
};

// WORKER FUNCTIONS
// register function
function* register({ payload }) {
  try {
    yield put(registerLoading(true));

    const res = yield call(ajaxDBCalls.register, payload);

    yield put(registerSuccess(res.data));
    yield put(registerLoading(false));
    yield delay();
    yield put(clearRegisterSuccess());
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(registerFailure(errorMessage));
    yield put(registerLoading(false));
    yield delay();
    yield put(registerFailure(""));
  }
}


// login function
function* login({ payload }) {
  try {
    yield put(loginLoading(true));

    const res = yield call(ajaxDBCalls.login, payload);

    yield put(loginSuccess(res.data));
    yield put(loginLoading(false));
    yield delay();
    yield put(clearLoginSuccess());
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(loginFailure(errorMessage));
    yield put(loginLoading(false));
    yield delay();
    yield put(loginFailure(""));
  }
}


// update user function
function* updateUserDetails({payload}) {
  try {
      yield put(updateUserLoading(true));

      const res = yield call(ajaxDBCalls.updateDetails, payload);

      yield put(updateUserSuccess(res.data));
      yield put(updateUserLoading(false));
      yield delay();
      yield put(clearUpdateSuccess());
  } catch(err) {

      let errorMessage = "";
      if (err.request) errorMessage = clientErrorMessage;

      if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
      }

      yield put(updateUserFailure(errorMessage));
      yield put(updateUserLoading(false));
      yield delay();
      yield put(updateUserFailure(""));
  }
}


// update user' password function
function* updateUserPassword({payload}) {
  try {
      yield put(updateUserPasswordLoading(true));

      const res = yield call(ajaxDBCalls.updatePassword, payload);

      yield put(updateUserPasswordSuccess(res.data));
      yield put(updateUserPasswordLoading(false));
      yield delay();
      yield put(clearUpdatePasswordSuccess());
  } catch(err) {

      let errorMessage = "";
      if (err.request) errorMessage = clientErrorMessage;

      if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
      }

      yield put(updateUserPasswordFailure(errorMessage));
      yield put(updateUserPasswordLoading(false));
      yield delay();
      yield put(updateUserPasswordFailure(""));
  }
}


// REDESIGN
// student registration
function* studentRegister({ payload }) {
  try {
    yield put(studentRegisterLoading(true));

    const res = yield call(ajaxDBCalls.studentRegister, payload);

    yield put(studentRegisterSuccess(res.data));
    yield put(studentRegisterLoading(false));
    yield delay();
    yield put(clearStudentRegisterSuccess());
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(studentRegisterFailure(errorMessage));
    yield put(studentRegisterLoading(false));
    yield delay();
    yield put(studentRegisterFailure(""));
  }
}


// student registration
function* clubRegister({ payload }) {
  try {
    yield put(clubRegisterLoading(true));

    const res = yield call(ajaxDBCalls.clubRegister, payload);

    yield put(clubRegisterSuccess(res.data));
    yield put(clubRegisterLoading(false));
    yield delay();
    yield put(clearClubRegisterSuccess());
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(clubRegisterFailure(errorMessage));
    yield put(clubRegisterLoading(false));
    yield delay();
    yield put(clubRegisterFailure(""));
  }
}


// delete student from bootcamp
function* deleteUser(payload) {
  try {
      yield put(deleteUserLoading(true));
      const response = yield call(ajaxDBCalls.deleteUser, payload);
      yield put(deleteUserSuccess(response.data));
      yield put(deleteUserLoading(false));
      yield delay();
      yield put(clearDeleteUserSuccess())
  } catch (err) {
      let errorMessage = "";
      if (err.request) errorMessage = clientErrorMessage;
  
      if (err.response) {
        console.log("here's what's wrong", err.response.data);
  
        const { message } = err.response.data;
        errorMessage = message;
      }
  
      yield put(deleteUserFailure(errorMessage));
      yield put(deleteUserLoading(false));
      yield delay();
      yield put(deleteUserFailure(""));
  }
}

// BYPASS
// register using bypass
function* byPassRegister({ payload }) {
  try {
    yield put(studentByPassRegisterLoading(true));

    const res = yield call(ajaxDBCalls.registerWithByPass, payload);

    yield put(studentByPassRegisterSuccess(res.data));
    yield put(studentByPassRegisterLoading(false));
    yield delay();
    yield put(clearStudentByPassRegisterSuccess());
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("something is wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(studentByPassRegisterFailure(errorMessage));
    yield put(studentByPassRegisterLoading(false));
    yield delay();
    yield put(studentByPassRegisterFailure(""));
  }
}



//WATCHERS
function* registerWatcher() {
  yield takeLatest(REGISTER_REQUEST, register);
}
function* byPassRegisterWatcher() {
  yield takeLatest(BYPASS_REGISTER_REQUEST, byPassRegister);
}
function* loginWatcher() {
  yield takeLatest(LOGIN_REQUEST, login)
}
function* updateUserWatcher() {
  yield takeLatest(UPDATE_USER_REQUEST, updateUserDetails)
}
function* updateUserPasswordWatcher() {
  yield takeLatest(UPDATE_USER_PASSWORD_REQUEST, updateUserPassword)
}
function* studentRegisterWatcher() {
  yield takeLatest(STUDENT_REGISTER_REQUEST, studentRegister)
}
function* clubRegisterWatcher() {
  yield takeLatest(CLUB_REGISTER_REQUEST, clubRegister)
}
function* deleteUserWatcher() {
  yield takeLatest(DELETE_USER_REQUEST, deleteUser)
}

// Authentication saga
export default function* authSagas() {
  yield spawn(registerWatcher)
  yield spawn(byPassRegisterWatcher)
  yield spawn(loginWatcher)
  yield spawn(updateUserWatcher)
  yield spawn(studentRegisterWatcher)
  yield spawn(updateUserPasswordWatcher)
  yield spawn(clubRegisterWatcher)
  yield spawn(deleteUserWatcher)
}