import React from 'react'
import MyLink2 from '../../../components/Global/Redesign/MyLink2';
import { useHistory } from "react-router-dom";
import BtnPrimary from '../../../components/Global/Redesign/BtnPrimary';

const MobileNavList2 = ({ isOpen, closeNav }) => {

    // history object
    const history = useHistory();

    const body = document.getElementById("root");
    body.style.overflow = isOpen && "hidden";

    // mobile navbar translate styling
    const mobileContainer = !isOpen
        ? "mobile:translate-x-[-500rem]"
        : "mobile:translate-x-[0px]";
    const mobileDrawer = !isOpen
        ? "mobile:translate-x-[-500rem]"
        : "mobile:translate-x-[0px]";

    // cubic transition style
    const cubicTransition = {
        transition: "transform 1.2s ease-in-out",
        transitionFunction: "cubic-bezier(.22,.68,0,1.71)",
    };
    return (
        <>
            <div
                style={cubicTransition}
                onClick={closeNav}
                className={`hidden overflow-hidden mobile:flex w-screen justify-center items-start fixed top-[5.5rem] left-[0px] z-[50000] h-screen bg-[rgba(0,0,0,.6)] ${mobileContainer}`}
            >
                <ul
                style={cubicTransition}
                className={`flex flex-col justify-center w-full items-start text-left py-7 gap-2 bg-white opacity-100 ${mobileDrawer}`}
                >

                    {/* The club link */}
                    <li className="flex flex-col w-full items-stretch">
                        <MyLink2 to={"/club"}>The Club</MyLink2>
                    </li>

                    {/* Management team link */}
                    <li className="flex flex-col w-full items-stretch">
                        <MyLink2 to={"/management"}>Management team</MyLink2>
                    </li>

                    
                    {/* space booking */}
                    <li className="flex flex-col w-full items-stretch">
                        <MyLink2 to={"/spaces"}>Space Booking</MyLink2>
                    </li>

                    {/* Training center link link */}
                    <li className="flex flex-col w-full items-stretch">
                        <MyLink2 to={"/training"}>Training center</MyLink2>
                    </li>

                    {/* Events link */}
                    <li className="flex flex-col w-full items-stretch">
                        <MyLink2 to={"/events"}>Events</MyLink2>
                    </li>

                     {/*BootCamp */}
                     <li className="flex flex-col w-full items-stretch">
                        <MyLink2 to={"/bootcamp"}>BootCamps</MyLink2>
                    </li>

                    {/* Contact us */}
                    <li className="flex flex-col w-full items-stretch">
                        <MyLink2 to={"/investing"}>Investing</MyLink2>
                    </li>

                    {/* getting started button */}
                    <li className="flex flex-col w-full items-stretch mt-4 mb-2 border-t-2 border-[#F9FAFB] pt-5 px-5">
                        <BtnPrimary
                        onClick={() => history.push("/registration")}
                        title={"Get started"} />
                    </li>
                </ul>
            </div>
        </>
    )
}

export default MobileNavList2