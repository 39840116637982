import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";

import {
  GET_BOOTCAMP_FOR_USERS_REQUEST,
  GET_USERS_BOOTCAMP_REQUEST,
  GET_SPECIFIC_BOOTCAMP_REQUEST,
  PUT_BOOTCAMP_PAYMENT_REQUEST,
} from "../types";

import { clientErrorMessage, delay } from "./reusable";

import {
  getBootcampForUsersSuccess,
  getBootcampForUsersFailure,
  getBootcampForUsersLoading,
  getUsersBootcampSuccess,
  getUsersBootcampFailure,
  getUsersBootcampLoading,
  getSpecificBootcampSuccess,
  getSpecificBootcampLoading,
  getSpecificBootcampFailure,
  putBootcampPaymentLoading,
  putBootcampPaymentSuccess,
  putBootcampPaymentFailure,
} from "../action";

// ajaxDBCall
const ajaxDBCalls = {
  // get bootcamp for users
  getBootcampForUsers: async () => {
    const response = await axios({
      method: "GET",
      url: "bootcamp/users",
    });
    return response;
  },

  getUsersBootcamp: async ({ payload }) => {
    const response = await axios({
      method: "GET",
      url: "bootcamp/userbootcamps",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${payload}`,
      },
    });
    return response;
  },

  putBootcampPayment: async ({ formData, bootcampId }) => {
    console.log(formData, bootcampId);
    const response = await axios({
      url: `bootcamp/onlinepayment/${bootcampId}`,
      method: "PUT",
      data: formData,
    });
    return response;
  },

  getSpecificBootcamp: async ({ payload }) => {
    const response = await axios({
      method: "GET",
      url: `bootcamp/singlebootcamp/${payload}`,
    });

    return response;
  },
};

// WORKERS
function* getBootcampForUsers() {
  try {
    yield put(getBootcampForUsersLoading(true));

    const response = yield call(ajaxDBCalls.getBootcampForUsers);

    yield put(getBootcampForUsersSuccess(response.data));
    yield put(getBootcampForUsersLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("here's what's wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getBootcampForUsersFailure(errorMessage));
    yield put(getBootcampForUsersLoading(false));
    yield delay();
    yield put(getBootcampForUsersFailure(""));
  }
}

function* getUsersBootcamp(payload) {
  try {
    yield put(getUsersBootcampLoading(true));

    const response = yield call(ajaxDBCalls.getUsersBootcamp, payload);

    yield put(getUsersBootcampSuccess(response.data));
    yield put(getUsersBootcampLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("here's what's wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getUsersBootcampFailure(errorMessage));
    yield put(getUsersBootcampLoading(false));
    yield delay();
    yield put(getUsersBootcampFailure(""));
  }
}

function* putBootcampPayment({ payload }) {
  try {
    yield put(putBootcampPaymentLoading(true));

    const response = yield call(ajaxDBCalls.putBootcampPayment, payload);

    yield put(putBootcampPaymentSuccess(response.data));
    yield put(putBootcampPaymentLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("here's what's wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(putBootcampPaymentFailure(errorMessage));
    yield put(putBootcampPaymentLoading(false));
    yield delay();
    yield put(putBootcampPaymentFailure(""));
  }
}

function* getSpecificBootcamp(payload) {
  try {
    yield put(getSpecificBootcampLoading(true));

    const response = yield call(ajaxDBCalls.getSpecificBootcamp, payload);

    yield put(getSpecificBootcampSuccess(response.data));
    yield put(getSpecificBootcampLoading(false));
  } catch (err) {
    let errorMessage = "";
    if (err.request) errorMessage = clientErrorMessage;

    if (err.response) {
      console.log("here's what's wrong", err.response.data);

      const { message } = err.response.data;
      errorMessage = message;
    }

    yield put(getSpecificBootcampFailure(errorMessage));
    yield put(getSpecificBootcampLoading(false));
    yield delay();
    yield put(getSpecificBootcampFailure(""));
  }
}

// WATCHERS
function* getBootcampForUsersWatcher() {
  yield takeLatest(GET_BOOTCAMP_FOR_USERS_REQUEST, getBootcampForUsers);
}

function* getUsersBootcampWatcher() {
  yield takeLatest(GET_USERS_BOOTCAMP_REQUEST, getUsersBootcamp);
}

function* getSpecificBootcampWatcher() {
  yield takeLatest(GET_SPECIFIC_BOOTCAMP_REQUEST, getSpecificBootcamp);
}

function* putBootcampPaymentWatcher() {
  yield takeLatest(PUT_BOOTCAMP_PAYMENT_REQUEST, putBootcampPayment);
}

// bootcamp saga
export default function* bootcampSaga() {
  yield spawn(getBootcampForUsersWatcher);
  yield spawn(getUsersBootcampWatcher);
  yield spawn(getSpecificBootcampWatcher);
  yield spawn(putBootcampPaymentWatcher);
}
