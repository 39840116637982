import React from "react";
import Footer2 from "../../../../layouts/Redesign/Footer2";
import BookSpace from "../Spaces/BookSpace";
import EventsContainer from "./EventsContainer";
import Showcase from "./Showcase";
import SponsorEvent from "./SponsorEvent";

const EventBody = () => {
  return (
    <div className="mobile:w-screen w-full">
      {/* showcase component */}
      <Showcase />

      {/* upcoming and past events */}
      <EventsContainer />

      {/* Interested in sponsoring event */}
      <SponsorEvent />

      {/* Book a space */}
      <BookSpace />

      {/* Footer */}
      <Footer2 />
    </div>
  );
};

export default EventBody;
