import {useEffect} from 'react'
import jumbotron from "./../../../assets/dashboard/home/images/upcoming-events.png"
import image from "./../../../assets/dashboard/home/images/launch.png"
import SingleEvent from './SingleEvent'
import { useSelector, useDispatch } from 'react-redux';
import { getEventsForUsersRequest } from '../../../redux/action';
import { CircularProgress } from '@mui/material';
import EmptyEventSection from './EmptyEventSection';

const EventsSection = () => {

    // dispatch object
    const dispatch = useDispatch()

    // event states
    const {
        events,
        eventsForUsersLoading
    } = useSelector((state)=>{
        const {events} = state.eventsReducer
        const {eventsForUsersLoading} = state.loadingIndicator
        return {
            events,
            eventsForUsersLoading
        }
    })

    useEffect(()=>{
        dispatch(getEventsForUsersRequest())
    }, [])

    const MONTHS = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
    ]

    const DAY = [
        "Sunday",
        "Monday",
        "Tueday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ]

    const getData = {
        date: (prop)=>{
            return new Date(prop).getDate()
        },
        dayNum: (prop)=>{
            return new Date(prop).getDay()
        },
        month: (prop)=>{
            return new Date(prop).getMonth()
        },        
        year: (prop)=>{
            return new Date(prop).getFullYear()
        }
    }

    return (
        <div>
            {/* jumbotron */}
            <div className='relative'>
                <img src={jumbotron} className="rounded-10px object-cover w-full" alt="" />

                <p className='h-[40px] flex items-center justify-center font-semibold text-xl text-white bg-[#1B1F27] w-full absolute bottom-[0]'>
                    Upcoming Events
                </p>
            </div>

            <div className='flex flex-col items-center justify-center bg-white w-full rounded-b-[10px]'>

                {
                    eventsForUsersLoading?
                    <>
                        <div
                            className='flex items-center justify-center w-full h-[200px] py-3 px-4'
                            >
                            {" "}
                            <CircularProgress 
                            size={20} 
                            style={{ color: "#7F56D9" }} />
                            {" "}
                        </div>
                    </>:
                    !(events.length===0)?
                    <>
                        {
                            events.map((event)=>(
                                <SingleEvent
                                image={event.imgurl}
                                title={event.name}
                                date={`${getData.date(event.date)} ${MONTHS[getData.month(event.date)]}, ${getData.year(event.date)}`}
                                to={event._id}
                                key={event._id} />
                            ))
                        }
                    </>:
                    <EmptyEventSection />
                }
            </div>
        </div>
    )
}

export default EventsSection