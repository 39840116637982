import React from "react";
import { Link } from "react-router-dom";
import SubscribeForm from "./../SubscribeForm/index";
import { AiFillInstagram } from "react-icons/ai";
import { ReactComponent as Twitter } from "./../../../assets/global/svg/twitter.svg";
import logo from "./../../../assets/home/svg/logo-main.svg";
import xmas_image from "./../../../assets/home/images/footer-decor.png";

const Footer = () => {
  return (
    <div className="">
      {/* subscribe */}
      <div className="bg-[#f9fafb] flex justify-between items-start pt-28 mobile:gap-10 mobile:pt-12 pb-10 pc:px-32 lg:px-20 pc:gap-8 mobile:px-10 mobile:flex-col px-16 md:px-16">
        <div className="flex flex-col gap-1">
          <p className="font-semibold text-2xl leading-[36px] text-[#101828]">
            Subscribe to our Newsletter
          </p>

          <p className="font-normal text-xl mobile:text-base leading-[30px] text-[#667085]">
            Stay up to date with the latest news, announcements, and articles.
          </p>
        </div>

        <SubscribeForm />
      </div>

      {/* links */}
      <div className="pt-20 pb-20 px-32 mobile:px-5 relative">
        <div className="grid grid-cols-3 justify-center items-start mobile:flex mobile:flex-col mobile:gap-12 gap-[10rem] mx-auto mobile:px-5">
          <div className="flex flex-col gap-4">
            <p className=" mobile:hidden flex justify-start items-center font-semibold text-base">
              <img src={logo} alt="" />
            </p>

            {/* mobile footer logo */}
            <p>
              <img src={logo} className="pc:hidden w-[147px]" alt="" />
            </p>

            <p className="font-normal text-base text-[#667085]">
              Let’s Connect and Educate
            </p>
            <div>
              <p className="font-bold text-base text-[#667085]">Address</p>
              <p className="font-normal text-base text-[#667085]">
                No. 1 Snap Drive, Olive Gate Hotel Junction, Independence
                Layout, Enugu Nigeria
              </p>
            </div>
          </div>

          <ul className="flex flex-col justify-start font-semibold text-base text-[#6941c6] gap-3">
            {/* caption */}
            <li className="font-semibold text-sm text-black">Company</li>

            {/* links */}
            <li>
              <Link
                className="text-[#6941C6] font-semibold text-base transition-all hover:text-[#6941C6]"
                exact
                to={"/old/management"}
              >
                Management team
              </Link>
            </li>
            <li>
              <Link
                className="text-[#6941C6] font-semibold text-base transition-all hover:text-[#6941C6]"
                exact
                to={"/old/club"}
              >
                The Club
              </Link>
            </li>
            <li>
              <Link
                className="text-[#6941C6] font-semibold text-base transition-all hover:text-[#6941C6]"
                exact
                to={"/old/training"}
              >
                Training center
              </Link>
            </li>
          </ul>

          <ul className="flex flex-col justify-start font-semibold text-base text-[#6941c6] gap-3">
            {/* caption */}
            <li className="font-semibold text-sm text-black">Resources</li>

            {/* links */}
            <li>
              <Link
                className="text-[#6941C6] font-semibold text-base transition-all hover:text-[#6941C6]"
                exact
                to={"#"}
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                className="text-[#6941C6] font-semibold text-base transition-all hover:text-[#6941C6]"
                exact
                to={"#"}
              >
                Newsletter
              </Link>
            </li>
            <li>
              <Link
                className="text-[#6941C6] font-semibold text-base transition-all hover:text-[#6941C6]"
                exact
                to={"/old/events"}
              >
                Events
              </Link>
            </li>
            <li>
              <Link
                className="text-[#6941C6] font-semibold text-base transition-all hover:text-[#6941C6]"
                exact
                to={"/old/contactus"}
              >
                Help centre
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* social links */}
      <div className="flex mobile:flex-col-reverse justify-between mobile:items-center mobile:gap-7 mobile:pb-12 pb-20 border-t pt-10 mx-32 mobile:pt-7 mobile:mx-12">
        {/* copyright */}
        <p className="text-[#98a2b3] mobile:text-sm text-base font-normal">
          &copy; {`${new Date().getFullYear()}`} AI Movement. All rights
          reserved.
        </p>

        {/* social icons */}
        <div className="flex gap-5 mobile:justify-evenly mobile:gap-6 mobile:px-10">
          <a
            rel="noreferrer"
            href="https://twitter.com/AIMovementNG"
            target="_blank"
          >
            <Twitter />
          </a>
          <a
            rel="noreferrer"
            className="text-[#98A2B3] text-2xl hover:text-[#98A2B3]"
            href="https://instagram.com/aimovement_ng"
            target="_blank"
          >
            <AiFillInstagram />
          </a>

          {/* <Linkedin />
          <Facebook />
          <Github />
          <Behance />
          <Dribble /> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
