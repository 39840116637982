import React from 'react'
import shape1 from "../../../../assets/webpages/club/images/c-shape1.png"
import EmptyData from '../Registration/EmptyData'
import LoadingCircles from '../Registration/LoadingCircle'
import MembershipCard from './MembershipCard'

const ClubMembership = ({
    clubsPackages,
    clubsPackagesLoading
}) => {
    return (
        <div className='py-40 flex flex-col mobile:px-5 mobile:py-16 px-8 mx-auto relative gap-20 container text-white'>

            {/* shapes */}
            <img
            src={shape1}
            className="absolute top-[10%] right-[30%] mobile:w-[20px] mobile:top-[3rem] mobile:right-[4rem]"
            alt="" />

            {/* text section */}
            <div className="flex flex-col gap-6 text-center items-center w-full">
                <p className="mt-0 font-bold text-sm mobile:text-xs leading-none text-[#0B8CBA]">
                    Offers
                </p>

                <p className="mt-0 font-semibold text-[28px] leading-none mobile:text-2xl text-[#1D2433]">
                    Club membership options
                </p>

                <p className="mt-0 font-normal text-base text-gray-800">
                    Select the package below that suits your needs, click on become a member, fill the form, pay the membership fee and upload proof of payment.
                </p>
            </div>

            {
                clubsPackagesLoading?
                <LoadingCircles />:
                clubsPackages && clubsPackages.length !== 0?
                
                    // boxes
                    <div className='grid grid-cols-3 mobile:grid-cols-1 gap-6 container 2xl:w-[80%] md:!w-[50%] mx-auto'>
                        {
                            
                            clubsPackages.map((clubPackage, index)=>(
                                <MembershipCard
                                key={clubPackage._id}
                                packageName={clubPackage.name}
                                packagePrice={clubPackage.amount}
                                business_consultancy={clubPackage.business_consultancy}
                                discounted_services={clubPackage.discounted_services}
                                foreign_tech_consultancy={clubPackage.foreign_tech_consultancy}
                                investment_database={clubPackage.investment_database}
                                investment_pitch={clubPackage.investment_pitch}
                                life_insurance={clubPackage.life_insurance}
                                member_swag={clubPackage.member_swag}
                                networking_event={clubPackage.networking_event}
                                news_letter={clubPackage.news_letter}
                                reserved_office_space={clubPackage.reserved_office_space} />
                            ))
                        }
                    </div>:
                <EmptyData />
            }
        </div>
    )
}

export default ClubMembership