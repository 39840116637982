import React from 'react'
import { Route, Switch } from 'react-router-dom'
import TheClub from '.'
import AvailblePackages from './AvailblePackages'
import InvestorsDatabase from './InvestorsDatabase'
import SingleClubPack from './SingleClubPack'

const TheClubRouter = ({path}) => {
    
    return (
        <Switch>
            
            {/* index page */}
            <Route 
            exact
            path={path}
            component={TheClub}/>
            
            {/* Availble club packages page */}
            <Route 
            exact
            path={`${path}/availble-packages`}
            component={AvailblePackages}/>
            
            {/* single club package page */}
            <Route 
            exact
            path={`${path}/:clubId`}
            component={SingleClubPack}/>

            {/* investors database */}
            <Route 
            exact
            path={`${path}/investors/database`}
            component={InvestorsDatabase}/>

        </Switch>
    )
}

export default TheClubRouter