import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Footer2 from '../../../../layouts/Redesign/Footer2'
import jumbotronImage from "../../../../assets/webpages/training/images/jumbotron-image.png"
import { getBootcampForUsersRequest } from '../../../../redux/action'
import Courses from './Courses'
import TrainingCenterModal from '../../../../layouts/TrainingCenterModal'
import LearnMorePopup from './LearnMorePopup'
import SpecialTrack from './SpecialTrack'

const TrainingBody = () => {

    // STATES
    // bootcamps state
    const { 
        bootcamps,
        bootcampsLoading  
    } = useSelector((state)=>{
        const { bootcamps } = state.bootcampReducer
        const { bootcampsLoading } = state.loadingIndicator
        return { 
            bootcamps,
            bootcampsLoading 
        }
    })
    // learn more modal state
    const [learnMoreModalState, setLearnMoreModalState] = useState(false)
    const [modalData, setModalData] = useState(null)

    const toggleLearnMoreModal = (data) => {
        setModalData(data)
        setLearnMoreModalState(!learnMoreModalState)
    }


    // OBJECT INITIALIZATION
    const dispatch = useDispatch()


    // SIDE EFFECT
    useEffect(()=>{
        dispatch(getBootcampForUsersRequest())
    }, [])

    return (
        <div className='mobile:w-screen w-full'>

            {/* showcase image */}
            <div className="flex flex-col items-stretch [border:0px_transparent_solid] -mt-1 w-full">
                
                {/* first div */}
                <div className="w-full flex 2xl:h-[232px] h-[200px] md:!h-[140px] mobile:h-[55px] bg-[#055774]">
                </div>

                {/* second div */}
                <div className="w-full flex 2xl:h-[232px] h-[200px] md:!h-[140px] mobile:h-[55px] relative">

                    {/* image */}
                    <img src={jumbotronImage} className="w-[80%] absolute bottom-[0] right-[50%] translate-x-[50%]" alt="" />

                </div>
            </div>

            {/* courses */}
            <Courses
            bootcamps={bootcamps}
            bootcampsLoading={bootcampsLoading}
            toggleLearnMoreModal={toggleLearnMoreModal} />

            {/* special track */}
            <div className='w-full bg-[#1D2433] flex items-center justify-center'>
                
                <SpecialTrack
                bootcamps={bootcamps}
                bootcampsLoading={bootcampsLoading}
                toggleLearnMoreModal={toggleLearnMoreModal} />

            </div>

            {/* Footer */}
            <Footer2 />

            {/* Learn More Modal */}
            <TrainingCenterModal
            modalIsOpen={learnMoreModalState}
            toggleModal={toggleLearnMoreModal}
            disableBtn={false}
            >
                <LearnMorePopup
                moreInfo={modalData}
                closeModal={toggleLearnMoreModal} />
            </TrainingCenterModal>
        </div>
    )
}

export default TrainingBody