import { useState, useEffect } from "react";
import SideNav from "../../../layouts/Dashboard/SideNav";
import ProfileAvatar from "../../../components/Dashboard/Home/ProfileAvatar";
import BackBtn from "../../../components/Dashboard/Global/BackBtn";
import jumboImage from "./../../../assets/dashboard/events/images/jumbo-image.png";
import eventFullImage from "./../../../assets/dashboard/events/images/event-full-image.png";
import { ReactComponent as ExpandIcon } from "./../../../assets/dashboard/events/svg/expand-icon.svg";
import AboutEvent from "../../../components/Dashboard/Events/AboutEvent";
import EventInfo from "../../../components/Dashboard/Events/EventInfo";
import ViewEventImage from "../../../components/Dashboard/Events/ViewEventImage";
import ImageViewerModal from "../../../components/Dashboard/Events/ImageViewerModal";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSingleEventRequest } from "../../../redux/action";
import { Skeleton } from "@mui/material";
import { clearPutUserToEventSuccess } from "../../../redux/action";
import { toast } from "react-toastify";
import MyModal from "../../../layouts/MyModal";
import SuccessEventRegistrationView from "../../../components/Dashboard/Events/SuccessEventRegistrationView";

const EventDetails = () => {
  const [imageModalState, setImageModalState] = useState(false);
  const [successfullEvent, setSuccessEvent] = useState(false);

  // toggle saved
  const toggleSuccessEvent = () => {
    setSuccessEvent(!successfullEvent);
  };

  // image modal handler
  const toggleImageModal = () => {
    setImageModalState(!imageModalState);
  };

  // location object
  const location = useLocation();
  const eventId = location.pathname.split("/").pop();

  // dispatch object
  const dispatch = useDispatch();

  // user token and club subscription states
  const {
    singleEvents,
    singleEventLoading,
    user,
    putUserToEventLoading,
    putUserToEventSuccess,
    putUserToEventError,
  } = useSelector((state) => {
    const {
      success: { putUserToEvent: putUserToEventSuccess },
      errors: { putUserToEvent: putUserToEventError },
    } = state.ajaxStatuses;

    const { putUserToEventLoading } = state.loadingIndicator;

    const { user } = state.userData;
    const { singleEvents } = state.eventsReducer;
    const { singleEventLoading } = state.loadingIndicator;
    return {
      singleEvents,
      singleEventLoading,
      user,
      putUserToEventLoading,
      putUserToEventSuccess,
      putUserToEventError,
    };
  });

  useEffect(() => {
    dispatch(getSingleEventRequest(eventId));
  }, []);

  useEffect(() => {
    if (putUserToEventSuccess) {
      toggleSuccessEvent();
      setTimeout(() => {
        setSuccessEvent(false);
      }, 3000);

      dispatch(clearPutUserToEventSuccess());

      dispatch(getSingleEventRequest(eventId));
    }
  }, [putUserToEventSuccess]);

  useEffect(() => {
    if (putUserToEventError) {
      toast.error(putUserToEventError, {
        duration: 3000,
      });
    }
  }, [putUserToEventError]);

  return (
    <div className="flex justify-end mobile:justify-start mobile:w-screen mobile:flex-col min-h-screen w-full mobile:bg-[#E5E5E5]">
      {/* SideNav */}
      <SideNav />

      {/* Home Body */}
      <section className="w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12 mobile:pt-8">
        {/* header */}
        <header className="container mx-auto mobile:px-4 flex justify-between items-center">
          <div className="mobile:flex mobile:flex-col-reverse gap-3 flex flex-col mobile:gap-6">
            <p className="text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]">
              Events
            </p>

            <BackBtn />
          </div>

          {/* profile dp */}
          <div className="mobile:hidden">
            <ProfileAvatar />
          </div>
        </header>

        {singleEventLoading ? (
          <Skeleton
            variant="rectangular"
            sx={{
              width: "100%",
              minHeight: "80vh",
              borderRadius: "10px",
              marginTop: "3rem",
            }}
          />
        ) : 
        singleEvents && Object.keys(singleEvents).length !== 0 ? (
          <>
            {/* middle section */}
            <section className="flex flex-col w-full mt-12 gap-9 mobile:gap-16 mobile:px-0">
              {/* Jumbotron Container */}
              <div className="container mx-auto mobile:px-4">
                {/* jumbotron */}
                <div className="flex flex-col gap-8 mobile:gap-4">
                  {/* caption text */}
                  <p className="text-[#1d2433] text-[36px] leading-none font-semibold mobile:text-2xl w-[60%]">
                    {singleEvents.name}
                  </p>

                  {/* container */}
                  <div className="relative">
                    <img
                      src={singleEvents.imgurl}
                      className="h-[287px] w-full object-cover rounded-[10px] object-[10%_13%] mobile:h-[130px]"
                      alt=""
                    />

                    {/* dark transparent bg */}
                    <span className="absolute h-full w-full bg-black opacity-40 z-[1] rounded-[10px] top-0 left-0">
                      {""}
                    </span>

                    {/* view image button */}
                    <button
                      onClick={toggleImageModal}
                      className="flex flex-col text-[#1d2433] text-base font-normal justify-center items-center p-3 gap-2 absolute right-[15px] top-[15px] hover:bg-[#e3e3e3] transition-colors bg-white rounded-[4px] z-[2]"
                    >
                      <ExpandIcon className="h-[20px] w-[20px]" />
                      <span className="opacity-[0.65] mobile:hidden">
                        View full image
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              {/* descriptive areas */}
              <div className="flex mobile:flex-col justify-between gap-8">
                {/* About Event */}
                <div className="mobile:w-full w-[70%] lg:w-[60%]">
                  <AboutEvent
                    user={user}
                    putUserToEventLoading={putUserToEventLoading}
                    event={singleEvents}
                  />
                </div>

                {/* Event Info */}
                <div className="mobile:w-full w-[30%] lg:w-[40%]">
                  <EventInfo
                    user={user}
                    putUserToEventLoading={putUserToEventLoading}
                    event={singleEvents}
                  />
                </div>
              </div>
            </section>

             {/* modal for viewing and downloading image */}
              <ImageViewerModal
                modalIsOpen={imageModalState}
                toggleModal={toggleImageModal}
                disableBtn={true}
              >
                <ViewEventImage 
                closeModal={toggleImageModal} 
                image={singleEvents.imgurl} />
              </ImageViewerModal>
          </>
        ) : (
          <div className="h-full font-semibold text-5xl flex justify-center items-center">
            Nothing Here
          </div>
        )}
      </section>

      {/* Modal for displaying event registration status */}
      <MyModal
        disableBtn={true}
        modalIsOpen={successfullEvent}
        toggleModal={toggleSuccessEvent}
      >
        <SuccessEventRegistrationView />
      </MyModal>
    </div>
  );
};

export default EventDetails;
