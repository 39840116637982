import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DatePicker } from "rsuite";
import BtnSm from "../Global/BtnSm";
import shape1 from "./../../assets/auth/svg/caption-shape1.svg";
import shape2 from "./../../assets/auth/svg/caption-shape2.svg";
import Checkbox from "../Global/CheckBox";
import GoogleSignInBtn from "../Global/GoogleSignInBtn";
import { Link, useLocation, useHistory } from "react-router-dom";
import { registerRequest } from "../../redux/action/auth";
import { FormError } from "../Errors";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SignupForm = () => {
  const dispatch = useDispatch();
  const history = useHistory()


  let query = useQuery();
  const {
    getUserLoading,
    getUserError,
    registerSuccess,
    registerError,
    registerLoading,
    user,
    singleUser,
  } = useSelector((state) => {
    const {
      success: { register: registerSuccess },
      errors: { getUser: getUserError, register: registerError },
    } = state.ajaxStatuses;

    const { registerLoading, getUserLoading } = state.loadingIndicator;

    const { user, singleUser } = state.userData;

    return {
      getUserLoading,
      getUserError,
      registerSuccess,
      registerError,
      registerLoading,
      user,
      singleUser,
    };
  });

  useEffect(() => {
    if (registerError) {
      toast.error(registerError, {
        duration: 3000,
      });
    }
  }, [registerError]);

  useEffect(() => {
    if (registerSuccess) {
      toast.success(registerSuccess, {
        duration: 3000,
      });
      history.push("/old/dashboard/home")
    }
  }, [registerSuccess]);

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      mobile: "",
      email: "",
      date_of_birth: "",
      reason: "",
      occupation: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: yup.object({
      firstname: yup.string().required("First name is required").min(3).max(15),
      lastname: yup.string().required("First name is required").min(3).max(15),
      mobile: yup.string().required("Mobile number is required").min(10).max(14),
      email: yup
        .string()
        .email("Email is not valid")
        .required("Email is required"),
      date_of_birth: yup.date().required(),
      reason: yup.string().required().min(10).max(200),
      occupation: yup.string().required(),
      password: yup.string().required("Password is required"),
      password_confirmation: yup.string().required("Password is required"),
    }),

    onSubmit: (prop) => {
      if (prop.password !== prop.password_confirmation) {
        toast.error("Password does not match");
      } else {
        let formData = prop;
        let refId = query.get("refId");
        dispatch(registerRequest({ formData, refId }));
      }
    },
  });

  return (
    <div className="[box-shadow:0px_2px_8px_#e0e0e0] relative z-[5] flex flex-col my-24 justify-center items-center bg-white w-[45%] mobile:w-full mobile:shadow-none h-auto py-24 mobile:py-8 rounded-[8px] gap-6">
      {/* shapes and patterns */}
      <img
        src={shape1}
        className="mobile:hidden absolute top-[-5%] right-[-15%]"
        alt=""
      />
      <img
        src={shape2}
        className="mobile:hidden absolute bottom-[25%] left-[-25%]"
        alt=""
      />

      {/* form */}
      <form 
      className="flex flex-col justify-center items-center w-full gap-5">
        {/* form caption */}
        <div className=" text-center flex flex-col gap-6 mobile:gap-3 mobile:mb-5 mb-2">
          <p className="font-semibold mobile:text-2xl text-4xl text-[#1D2433]">
            Create your account
          </p>
          <p className="text-base font-normal text-[#667085]">
            Welcome! Please enter your details.
          </p>
        </div>

        {/* FORM CONTROLS */}
        {/* first name */}
        <label className="flex flex-col mobile:w-full w-[360px] gap-2">
          <span className="text-[#344054] text-sm font-semibold">
            First name
          </span>

          <input
            type="text"
            {...formik.getFieldProps("firstname")}
            className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
            placeholder="Enter your first name"
          />

          {formik.errors.firstname && formik.touched.firstname && (
            <FormError message={formik.errors.firstname} />
          )}
        </label>

        {/* last name */}
        <label className="flex flex-col mobile:w-full w-[360px] gap-2">
          <span className="text-[#344054] text-sm font-semibold">
            Last name
          </span>

          <input
            type="text"
            {...formik.getFieldProps("lastname")}
            className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
            placeholder="Enter your last name"
          />

          {formik.errors.lastname && formik.touched.lastname && (
            <FormError message={formik.errors.lastname} />
          )}
        </label>

        {/* email address */}
        <label className="flex flex-col mobile:w-full w-[360px] gap-2">
          <span className="text-[#344054] text-sm font-semibold">Email</span>

          <input
            type="email"
            {...formik.getFieldProps("email")}
            className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
            placeholder="Enter your email"
          />

          {formik.errors.email && formik.touched.email && (
            <FormError message={formik.errors.email} />
          )}
        </label>


        {/* date of birth */}
        <label className="flex flex-col mobile:w-full w-[360px] gap-2">
          <span className="text-[#344054] text-sm font-semibold">Date of Birth</span>

          <input
            type="date"
            {...formik.getFieldProps("date_of_birth")}
            className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
            placeholder="Enter date of birth"
          />

          {formik.errors.date_of_birth && formik.touched.date_of_birth && (
            <FormError message={formik.errors.date_of_birth} />
          )}
        </label>

        {/* phone number */}
        <label className="flex flex-col mobile:w-full w-[360px] gap-2">
          <span className="text-[#344054] text-sm font-semibold">
            Phone number
          </span>

          <input
            type="text"
            {...formik.getFieldProps("mobile")}
            className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
            placeholder="Enter your phone number"
          />

          {formik.errors.mobile && formik.touched.mobile && (
            <FormError message={formik.errors.mobile} />
          )}
        </label>

        {/* occupation*/}
        <label className="flex flex-col mobile:w-full w-[360px] gap-2">
          <span className="text-[#344054] text-sm font-semibold">
            Occupation
          </span>

          <input
            type="text"
            {...formik.getFieldProps("occupation")}
            className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
            placeholder="Enter your occupation"
          />

          {formik.errors.occupation && formik.touched.occupation && (
            <FormError message={formik.errors.occupation} />
          )}
        </label>

        {/* password */}
        <label className="flex flex-col mobile:w-full w-[360px] gap-2">
          <span className="text-[#344054] text-sm font-semibold">
            Password
          </span>

          <div className="flex flex-col gap-1">
            <input
              type="password"
              {...formik.getFieldProps("password")}
              className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
              placeholder="Create a password"
            />
            <span className="font-normal text-sm text-[#667085]">
              Must be at least 8 characters.
            </span>
          </div>

          {formik.errors.password && formik.touched.password && (
            <FormError message={formik.errors.password} />
          )}
        </label>
        

        {/* confirm password */}
        <label className="flex flex-col mobile:w-full w-[360px] gap-2">
          <span className="text-[#344054] text-sm font-semibold">
            Confirm password
          </span>

          <div className="flex flex-col gap-1">
            <input
              type="password"
              {...formik.getFieldProps("password_confirmation")}
              className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
              placeholder="Confirm your password"
            />
            <span className="font-normal text-sm text-[#667085]">
              Must be at least 8 characters.
            </span>
          </div>

          {formik.errors.password_confirmation && formik.touched.password_confirmation && (
            <FormError message={formik.errors.password_confirmation} />
          )}
        </label>
        

        {/* why you joined use */}
        <label className='flex flex-col mobile:w-full w-[360px] gap-2'>

            {/* label text */}
            <span className='font-semibold text-sm text-[#344054]'>
                Reason
            </span>

            {/* input field */}
            <textarea 
            {...formik.getFieldProps("reason")}
            className='h-[120px] pt-2 [resize:none] border border-[#d0d5dd] text-base font-normal rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal w-full placeholder:text-base placeholder:text-[#667085]' 
            placeholder='Why do you want to join us?' />

            {formik.errors.reason && formik.touched.reason && (
            <FormError message={formik.errors.reason} />
          )}
        </label>

        {/* send message button */}
        <div className="flex flex-col items-stretch mobile:w-full w-[360px] gap-5">

          {/* sign up */}
          <BtnSm
            onClick={formik.handleSubmit}
            loading={registerLoading}
            title={"Sign up"}
          />

          {/* google signin button */}
          {/* <GoogleSignInBtn title={"Sign up with Google"} /> */}
        </div>
      </form>

      {/* sign up/register */}
      <p className="text-[#667085] text-sm font-normal flex gap-[5px] mt-2">
        <span>Already have an account?</span>

        <Link to={"/old/login"} className="font-semibold text-sm text-[#6941C6]">
          Log in
        </Link>
      </p>
    </div>
  );
};

export default SignupForm;
