import React from "react";
import { ReactComponent as CheckYo } from "./../../../assets/home/svg/check-icon.svg";
import girl from "./../../../assets/home/images/girl.png";
import guy from "./../../../assets/home/images/guy.png";
import h_shape2 from "./../../../assets/home/svg/h-shape2.svg";
import h_shape3 from "./../../../assets/home/svg/h-shape3.svg";
import h_shape4 from "./../../../assets/home/svg/h-shape4.svg";
import h_shape5 from "./../../../assets/home/svg/h-shape5.svg";
import b_shape1 from "./../../../assets/home/images/b-shape4.png";
import guy2 from "./../../../assets/home/images/guy2.png";
import AboutUs from "../../../components/Home/AboutUs";
import Footer from "../../../components/Global/Footer";
import BtnSm from "../../../components/Global/BtnSm";
import Grow from "../../../components/Home/Grow";
import Package from "../../../components/Home/Package";
import Commuity from "../../../components/Home/Commuity";
import BreakTextsMobile from "../../../components/Global/BrTextsMobile";
import Header from "../../../layouts/Header";
import { useHistory } from "react-router-dom";
import FixedMessage from "../../../components/Global/FixedMessage";
import santa_rails from "./../../../assets/home/images/santa-rails.png";

const Home = () => {
  // history object
  const history = useHistory();

  return (
    <>
      {/* header */}
      <Header />

      {/* fixed message */}
      <FixedMessage />

      <div className="mobile:w-screen">
        {/* let's connect */}
        <div className="flex flex-col">
          <div className="flex flex-col items-center mobile:mt-24 pt-24 mobile:pt-16 gap-6 container mx-auto relative">
            {/* scabble shapes */}
            {/* <img
              src={h_shape1}
              className="absolute top-[20%] mobile:top-[5%] left-[8%] mobile:w-[20px]"
              alt=""
            /> */}
            <img
              src={h_shape2}
              className="absolute top-[44%] mobile:top-[33%] mobile:w-[20px] mobile:right-[20%] right-[35%]"
              alt=""
            />
            <img
              src={h_shape3}
              className="absolute bottom-[12rem] mobile:w-[20px] right-[3rem] hidden mobile:inline mobile:w-20px]"
              alt=""
            />

            {/* Welcome TEXTS!! */}
            <h1 className="font-semibold mobile:text-4xl text-6xl leading-[54px] mobile:leading-base mobile:text-center text-black">
              Let's Connect and
              <BreakTextsMobile /> Educate
            </h1>

            <h2 className="pc:mt-1 font-semibold mobile:text-base text-2xl leading-[36px] text-black">
              A tech platform designed to help you
            </h2>

            {/* highlights */}
            <div className="flex flex-col gap-2 mobile:gap-4">
              <p className="flex items-center mobile:items-start justify-center text-gray-500 font-normal text-xl mobile:text-base leading-[30px] gap-2">
                <CheckYo className="w-[32px] mobile:w-[24px]" />
                <span className="mobile:mt-1">Sharpen your tech skills</span>
              </p>

              <p className="flex items-center mobile:items-start justify-center text-gray-500 font-normal text-xl mobile:text-base leading-[30px] gap-2">
                <CheckYo className="w-[32px] mobile:w-[24px]" />
                <span className="mobile:mt-1">
                  Connect and exchange
                  <BreakTextsMobile /> ideas with great minds
                </span>
              </p>

              <p className="flex items-center mobile:items-start justify-center text-gray-500 font-normal text-xl mobile:text-base leading-[30px] gap-2">
                <CheckYo className="w-[32px] mobile:w-[24px]" />
                <span className="mobile:mt-1">
                  Gain access to capital
                  <BreakTextsMobile /> funds and projects with
                  <BreakTextsMobile /> attractive returns
                </span>
              </p>
            </div>

            {/* ctas container */}
            <div className="flex mobile:flex-col justify-center items-center mobile:items-stretch gap-3 mt-6 mobile:w-[90%]">
              <button
                onClick={() => history.push("/old/events")}
                className="h-[60px] bg-[#fff] flex justify-center items-center font-semibold mobile:text-base text-lg text-[#344054] px-7 rounded-[8px] hover:opacity-80 border border-[#d0d5dd] hover:bg-[#eee]"
              >
                View upcoming events
              </button>

              <button
                onClick={() => history.push("/old/login")}
                className="h-[60px] bg-[#7f56d9] flex justify-center items-center font-semibold mobile:text-base text-lg text-[#fff] px-7 rounded-[8px] hover:opacity-80"
              >
                Get started
              </button>
            </div>
          </div>

          {/* image highlights */}
          <div className="flex justify-center mt-24 relative h-[250px] mobile:h-[100px] md:!h-[170px] container mx-auto">
            {/* scrabbles */}
            <img
              src={h_shape3}
              className="mobile:w-[20px]absolute top-[-2.5rem] mobile:w-[20px] mobile:hidden right-[12rem]"
              alt=""
            />
            <img
              src={h_shape4}
              className="mobile:w-[20px]absolute top-[-1rem] mobile:w-[20px] left-[45%]"
              alt=""
            />

            <div className="flex gap-6 mobile:gap-2 md:!gap-4 absolute z-[1000] mobile:px-4 pc:px-10">
              <img
                src={h_shape5}
                className="absolute bottom-[1rem] left-[6%] mobile:w-[20px]"
                alt=""
              />

              {/* first image */}
              <div>
                <img
                  src={girl}
                  alt="a girl"
                  className="hover:scale-105 transition-all cursor-pointer mobile:w-[107px] md:!w-[180px]"
                />
              </div>

              {/* second image */}
              <div className="mt-[5rem]">
                <img
                  src={guy}
                  alt="a guy and a girl"
                  className="hover:scale-105 transition-all cursor-pointer mobile:w-[107px] md:!w-[180px]"
                />
              </div>

              {/* third image */}
              <div>
                <img
                  src={guy2}
                  alt="three people"
                  className="hover:scale-105 transition-all cursor-pointer mobile:w-[107px] md:!w-[180px]"
                />
              </div>
            </div>
          </div>

          {/* about us */}
          <AboutUs />

          {/* community page */}
          <Commuity />

          {/* build sustainable tech career */}
          <div>
            <Package />
          </div>

          {/* questions */}
          <div className="bg-[#f8f9fc] pt-32 flex flex-col justify-center pb-20 mobile:px-6 relative">
            
            {/* image groups */}
            {/* <div className="flex justify-center items-center relative gap-5">
                  <img src={avatar1} alt="" />
                  <img src={avatar2} alt="" />
                  <img src={avatar3} alt="" className="absolute bottom-[2px]" />
                </div> */}

            {/* text titles */}
            <div className="justify-center items-center flex flex-col mb-2 relative">
              <img
                src={b_shape1}
                className="absolute top-[-1rem] right-[30%] mobile:w-[20px] mobile:right-[10%]"
                alt=""
              />

              {/* heading */}
              <p className="text-center font-semibold text-xl leading-[24px] text-[#101828] mt-6">
                Still have questions?
              </p>

              {/* description pc */}
              <p className="mobile:hidden text-center font-normal text-base text-gray-500 mt-2 mb-8">
                Can’t find the answer you’re looking for? Please send us a
                message today and we will be in touch.
              </p>

              {/* description mobile */}
              <p className="pc:hidden text-center font-normal text-base text-gray-500 mt-2 mb-8">
                Can’t find the answer you’re looking for? Please chat to our
                friendly team.
              </p>

              {/* button */}
              <BtnSm
                onClick={() => history.push("/old/contactus")}
                title={"Chat with"}
              />
            </div>
          </div>

          {/* Grow your career */}
          <Grow />

          {/* Footer */}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;
