import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { loginRequest } from '../../../../redux/action';
import * as Yup from "yup"
import BtnPrimary from '../../../Global/Redesign/BtnPrimary';
import Checkbox from '../../../Global/CheckBox';
import { FormError } from '../../../Errors';
import { toast } from 'react-toastify';

const LoginForm = () => {

    // OBJECT INITIALIZATION
    const history = useHistory()
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup
                .string()
                .email("Email is not valid")
                .required("Email is required"),
            password: Yup.string().required("Password is required"),
        }),
    
        onSubmit: (prop) => {
            let formData = prop;
            dispatch(loginRequest({ formData }));
        },
    });


    // STATES
    const {
        getUserLoading,
        getUserError,
        loginSuccess,
        loginError,
        loginLoading,
        user,
        singleUser
      } = useSelector((state) => {
        const {
            success: { 
                login: loginSuccess 
            },
            errors: { 
                getUser: getUserError, 
                login: loginError 
            },
        } = state.ajaxStatuses;
        const { 
            loginLoading, 
            getUserLoading 
        } = state.loadingIndicator;
        const { 
            user, 
            singleUser 
        } = state.userData;
    
        return {
            getUserLoading,
            getUserError,
            loginSuccess,
            loginError,
            loginLoading,
            user,
            singleUser
        };
    });


    // SIDE EFFECTS
    // check for error
    useEffect(() => {
        if (loginError) {
            toast.error(loginError, {
            duration: 3000,
            });
        }
    }, [loginError]);
    // check for login succes
    useEffect(() => {
        if (loginSuccess) {
            toast.success(loginSuccess, {
                duration: 3000,
            });
            history.push("/dashboard/bootcamps")
        }
    }, [loginSuccess]);


    return (
        <>

            {/* form */}
            <form 
            onSubmit={(e)=>e.preventDefault()}
            className='flex flex-col justify-center items-center w-full gap-5'>

                {/* form caption */}
                <div className=' text-center flex flex-col mobile:gap-3 gap-6 mb-2 mobile:mb-5'>
                    <p className='font-semibold mobile:text-2xl text-4xl text-[#1D2433]'>
                        Log in to your account
                    </p>
                    <p className='text-base font-normal text-[#667085]'>
                        Welcome back! Please enter your details.
                    </p>
                </div>

                {/* FORM CONTROLS */}
                {/* email address */}
                <label className='flex flex-col mobile:w-full w-[360px] gap-2'>
                    <span className='text-[#344054] text-sm font-semibold'>
                        Email
                    </span>

                    <input 
                    type="email" 
                    {...formik.getFieldProps("email")}
                    className='border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full' 
                    placeholder='Enter your email' />

                    {formik.errors.email && formik.touched.email && (
                        <FormError message={formik.errors.email} />
                    )}
                </label>

                {/* password */}
                <label className='flex flex-col mobile:w-full w-[360px] gap-2'>
                    <span className='text-[#344054] text-sm font-semibold'>
                        Password
                    </span>

                    <input 
                    type="password" 
                    {...formik.getFieldProps("password")}
                    className='border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full' 
                    placeholder='Enter your password' />

                    {formik.errors.password && formik.touched.password && (
                        <FormError message={formik.errors.password} />
                    )}
                </label>

                {/* remember login & forgot password */}
                <label className='flex mobile:w-full w-[360px] gap-2 items-center justify-between'>

                    <div className='flex items-center justify-start gap-2'>

                        <Checkbox />
                        <span className='font-normal text-sm text-[#344054] mt-1 cursor-pointer'>
                            Remember for 30 days
                        </span>
                    </div>

                    <Link to="/forgot-password">
                        <span className='font-semibold text-sm text-[#0B8CBA]'>
                            Forgot password
                        </span>
                    </Link>
                </label>

                {/* send message button */}
                <div className='flex flex-col items-stretch mobile:w-full w-[360px] gap-5'>

                    {/* login */}
                    <BtnPrimary
                    onClick={formik.handleSubmit}
                    loading={loginLoading}
                    title={"Log in"} />

                    {/* google signin button */}
                    {/* <GoogleSignInBtn title={"Sign in with Google"} /> */}
                </div>
            </form>

            {/* sign up/register */}
            <p className='text-[#667085] text-sm font-normal flex gap-[5px] mt-2'>
                <span>
                    Don’t have an account? 
                </span>


                <Link
                to={"/registration"}
                className='font-semibold text-sm text-[#0B8CBA]'>
                    Sign up
                </Link>
            </p>
        </>
    )
}

export default LoginForm