import React from 'react'
import { Link } from 'react-router-dom'
import Form from './Form'

const RegistrationBody = () => {
    return (
        <div className='flex flex-col justify-center items-center w-full gap-5'>

            {/* form caption */}
            <div className=" text-center flex items-center flex-col gap-4 mobile:gap-3 mobile:mb-5 mb-2">

                <p className="font-semibold mobile:text-2xl text-4xl text-[#1D2433] leading-none">
                    Create your account
                </p>
                <p className="text-base font-normal text-[#667085] pc:w-[80%] mt-0">
                    We need your details to create a personalized space just for you
                </p>
            </div>

            {/* accordions */}
            <div className='flex flex-col w-full mobile:px-2'>

                {/* registration form */}
                <Form />
            </div>

            
            {/* sign up/register */}
            <p className="text-[#667085] text-sm font-normal flex gap-[5px] mt-2">
                <span>Already have an account?</span>

                <Link to={"/redesigned/login"} className="font-semibold text-sm text-[#0B8CBA]">
                    Log in
                </Link>
            </p>
        </div>
    )
}

export default RegistrationBody