import emptyCard from "./../../../assets/dashboard/global/images/empty-bootcamp.png"
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from "react";

const EmptyCourseCard = ({width, shadow}) => {

    // course url
    const coursesUrl = "/old/dashboard/courses"

    // history object
    const history = useHistory()

    // location object
    const location = useLocation()

    return (
        <div 
        style={{
            width: width,
            boxShadow: shadow && "0px 2px 8px rgba(0, 0, 0, 0.12)"
        }}
        className='bg-white rounded-[10px] p-6 flex gap-5 flex-col h-fit'>
            <p className='font-semibold text-[#1D2433] text-sm opacity-[0.65]'>
                Current bootcamp enrolled in
            </p>

            <div className='flex justify-start items-center gap-3'>
                <img className='w-[44px]' src={emptyCard} alt="" />
            </div>
            
            <div className='flex flex-col items-start gap-3'>
                <p className=" ">
                    No course registered for yet
                </p>                

                <button 
                disabled={location.pathname===coursesUrl}
                onClick={()=>history.push(coursesUrl)}
                className='text-[#344054] font-semibold text-sm border border-[#D0D5DD] h-[36px] px-4 rounded-[8px] disabled:cursor-not-allowed disabled:opacity-30'>
                    View all avalable courses
                </button>
            </div>
        </div>
    )
}

export default EmptyCourseCard