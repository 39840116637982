import image from "../../../../assets/redesign-dashboard/the-club/images/event-image.png"
import { FiCalendar } from "react-icons/fi"
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { getEventsForUsersRequest } from "../../../../redux/action"
import { getRTDate, MONTHS } from "../../../../helpers/getRTDate"
import UpcomingEventCard from "./UpcomingEventCard"
import EmptyEventSection from "../../../Dashboard/Home/EmptyEventSection"
import CircleLoading from "./CircleLoading"

const UpcomingEvents = () => {

    // OBJECT INITIALIZATION
    const history = useHistory()
    const dispatch = useDispatch()


    // STATES
    const {
        events,
        eventsForUsersLoading
    } = useSelector((state)=>{
        const {events} = state.eventsReducer
        const {eventsForUsersLoading} = state.loadingIndicator
        return {
            events,
            eventsForUsersLoading
        }
    })


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getEventsForUsersRequest())
    }, [])

    return (
        <div className="w-full h-fit flex flex-col border border-[#E1E6EF] rounded-[8px]">
            
            {/* info card */}
            <div className="flex items-center justify-center bg-[#EDFAFE] w-full p-5 flex-col gap-[11px] rounded-t-[8px]">

                {/* icon */}
                <span className="flex items-center justify-center h-[52px] w-[52px] bg-[#D4F4FF] rounded-[50%]">
                    <FiCalendar
                    className="text-2xl text-[#055774]" />
                </span>

                {/* text */}
                <p className="font-semibold text-base text-[#1D2433]">
                    Upcoming Events
                </p>

            </div>

            {/* informations */}
            <div className="flex flex-col">

                {
                    eventsForUsersLoading?
                    <CircleLoading />:
                    (events && events.length!==0)?
                    <>
                        {
                            events.slice(0, 3).map((event)=>(

                                <UpcomingEventCard
                                image={event.imgurl}
                                name={event.name}
                                date={`${getRTDate.date(event.date)} ${MONTHS[getRTDate.month(event.date)]}, ${getRTDate.year(event.date)}`}
                                path={event._id}
                                key={event._id} />
                            ))
                        }
                    </>:
                    <EmptyEventSection />
                }
            </div>

            {/* view all events */}
            <div className="flex flex-col justify-center items-center p-5 rounded-b-[8px] border-t-[0_1px_1px_1px] border-t-[#E1E6EF] bg-white">
                <p
                onClick={()=>history.push("/dashboard/events")}
                className="cursor-pointer hover:opacity-70 font-semibold text-sm text-[#0B8CBA]">
                    View all events
                </p>
            </div>
        </div>
    )
}

export default UpcomingEvents;