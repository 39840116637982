import { useEffect } from "react";
import launchImage from "./../../../assets/events/images/event-launching.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getEventsForUsersRequest } from "../../../redux/action";
import { Skeleton } from "@mui/material";
import EmptyEventSection from "../Home/EmptyEventSection";

const OnGoingEvents = () => {
  // location and history objects
  const location = useLocation();
  const history = useHistory();

  // dispatch object
  const dispatch = useDispatch();

  // user token and club subscription states
  const { events, eventsForUsersLoading } = useSelector((state) => {
    const { events } = state.eventsReducer;
    const { eventsForUsersLoading } = state.loadingIndicator;
    return {
      events,
      eventsForUsersLoading,
    };
  });

  useEffect(() => {
    dispatch(getEventsForUsersRequest());
  }, []);

  const MONTHS = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const DAY = [
    "Sunday",
    "Monday",
    "Tueday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const getData = {
    date: (prop) => {
      return new Date(prop).getDate();
    },
    dayNum: (prop) => {
      return new Date(prop).getDay();
    },
    month: (prop) => {
      return new Date(prop).getMonth();
    },
    year: (prop) => {
      return new Date(prop).getFullYear();
    },
  };

  return (
    <div className="flex flex-col gap-4">
      {/* caption */}
      <p className="font-semibold text-[#3F444D] text-xl">On-going Event</p>

      {eventsForUsersLoading ? (
        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            height: "30vh",
            borderRadius: "10px",
          }}
        />
      ) : events.length !== 0 ? (
        <>
          {events.slice(0,1).map(
            (event, index) => (
                <div
                  key={event._id}
                  className="flex mobile:flex-col mobile:gap-6 gap-12 [box-shadow:0px_2px_8px_#e0e0e0] bg-white mobile:p-4 px-9 py-7 rounded-[10px]"
                >
                  <div className="w-[40%] mobile:w-full relative">
                    {/* image */}
                    <img
                      src={event.imgurl}
                      className="object-cover object-top rounded-[10px] h-[200px] w-full mobile:w-full"
                      alt=""
                    />

                    {/* date stamp */}
                    <div className="hidden mobile:flex bg-[#F8F5FF] rounded-[8px] text-[#1D2433] absolute top-[12px] left-[12px] gap-2 py-4 px-5 flex-col items-center justify-center">
                      <span className="font-normal text-base leading-3">
                        {MONTHS[getData.month(event.date)]}
                      </span>
                      <span className="font-semibold text-2xl leading-3">
                        {getData.date(event.date)}
                      </span>
                    </div>
                  </div>

                  {/* desktop description */}
                  <div className="w-[55%] mobile:hidden flex flex-col gap-1 items-start justify-center leading-3">
                    <p className="font-semibold text-2xl text-[#1D2433]">
                      {event.name}
                    </p>

                    <p className="font-normal text-base text-[#1d2433a6]">
                      {`${getData.date(event.date)} ${
                        MONTHS[getData.month(event.date)]
                      }, ${getData.year(event.date)}`}
                    </p>

                    <p className="pr-4 text-xl font-normal text-[rgba(29,36,51,0.8)]">
                      {event.description.substring(0, 80).concat("...")}
                    </p>

                    <button
                      onClick={() =>
                        history.push(`${location.pathname}/${event._id}`)
                      }
                      className="h-[36px] mobile:h-[48px] bg-transparent flex justify-center border-2 border-[#D0D5DD] items-center font-semibold text-base lg:text-xs text-[#344054] px-7 rounded-[8px] hover:bg-[#f5f5f5] mt-3"
                    >
                      <span>Learn more</span>
                    </button>
                  </div>

                  {/* =====================================*/}
                  {/* mobile description */}
                  {/* =====================================*/}
                  <div className="hidden mobile:flex flex-col items-start justify-center py-4 gap-1 break-words pr-4">
                    <p className="flex flex-col text-xs font-normal text-[#6c707a]">
                      <span>
                        {`${
                          DAY[getData.dayNum(event.date)]
                        } ${event.time.concat(" pm")}`}
                      </span>
                      <span>{event.location}</span>
                    </p>

                    <p className="text-xl xl:text-sm leading-[24px] font-semibold text-[#1D2433]">
                      {event.name}
                    </p>

                    <p className="font-normal text-sm text-[#1D2433] opacity-80">
                      {event.description.substring(0, 80).concat("...")}
                    </p>

                    <Link
                      to={`${location.pathname}/${event._id}`}
                      className="font-semibold hover:text-[#8B54F7] text-base mobile:text-xs text-[#8B54F7] xl:text-sm mt-3"
                    >
                      LEARN MORE
                    </Link>
                  </div>
                </div>
              )
          )}
        </>
      ) : (
        <EmptyEventSection />
      )}
    </div>
  );
};

export default OnGoingEvents;
