import { BiErrorCircle } from "react-icons/bi";

export const FormError = ({ message }) => {
  return (
    <div className="flex items-center text-red-500 text-xs font-body font-light mt-2">
      <BiErrorCircle className="mr-2 h-4" />
      <p>{message}</p>
    </div>
  );
};
