import React from 'react'
import { HiUserCircle } from 'react-icons/hi'
import tempImage from "../../../../assets/redesign-dashboard/bootcamps/images/avatar.png"

const InstructorCard = ({image, name, designation, description}) => {
    return (
        <div className='flex flex-col gap-4'>

            {/* caption */}
            <p className='font-medium text-base text-[#3F444D]'>
                Course Instructor
            </p>

            {/* card */}
            <div className="flex flex-col bg-white border border-[#E1E6EF] p-5 gap-5 rounded-[10px]">

                {/* image and basic details */}
                <section className='flex gap-4'>

                    {/* image */}
                    {/* <div className='w-[52px] h-[52px] rounded-[10px]'>
                        <img src={tempImage} className="w-full h-full rounded-[10px]" alt="" />
                    </div> */}
                    <HiUserCircle className='text-6xl' />

                    {/* texts */}
                    <div className='flex flex-col w-[85%]'>
                        <p className='mt-0 font-bold text-base text-[#3F444D]'>
                            Engr Jideobi Nna
                        </p>

                        <p className='mt-0 text-base font-normal text-[#3F444D]'>
                            Senior Front-end Dev
                        </p>
                    </div>
                </section>

                {/* description */}
                <p className='text-gray-800 font-normal text-sm'>
                    Jideobi Nna is a leading tech enthusiasts, programmer and teacher with our five year of experience working in the tech industry.
                </p>
            </div>
        </div>
    )
}

export default InstructorCard