import React from 'react'
import shape1 from "../../../../assets/webpages/aboutus/images/t-shape1.png"
import ValueBox from './ValueBox'
import {BiLink} from "react-icons/bi"
import {FiBox} from "react-icons/fi"
import {BiAward} from "react-icons/bi"
import {TbRocket} from "react-icons/tb"

const Values = () => {
    return (
        <div className='py-40 flex flex-col mobile:px-5 mobile:py-16 px-8 mx-auto relative gap-10 container text-white'>

            {/* shapes */}
            <img
            src={shape1}
            className="absolute top-[10%] left-[10%] mobile:w-[20px] mobile:top-[3rem] mobile:left-[4rem]"
            alt="" />

            {/* text section */}
            <div className="flex flex-col gap-3 text-center items-center w-full">
                <p className="mt-0 font-bold text-sm mobile:text-xs leading-none">
                    OUR VALUES
                </p>

                <p className="mt-0 font-semibold text-[32px] mobile:text-2xl leading-none">
                    The core values behind our work
                </p>

                <p className="mt-0 font-normal text-base mobile:text-base leading-none">
                    Tech is the future, and the future is now. So, how do we move from the present to the future where we ought to be?
                </p>
            </div>

            {/* boxes */}
            <div className='grid grid-cols-2 mobile:grid-cols-1 gap-6 container 2xl:w-[80%] mx-auto'>

                <ValueBox
                icon={<FiBox className="text-xl text-[#101828]" />}
                title={"Innovation"}
                desc="Our products and services always come with our trademark of innovation and creativity such that we bang on the very borders of the human imagination" />

                <ValueBox
                icon={<BiLink className="text-xl text-[#101828]" />}
                title={"Teamwork"}
                desc="Community is at the forefront of all our policies and activities as we believe in connecting and networking together to build a thriving ecosystem." />

                <ValueBox
                icon={<BiAward className="text-xl text-[#101828]" />}
                title={"Commitment"}
                desc="We create a community where everyone can grow regardless of their current level or achievement with so many supportive factors; project pitch, investment, encouragement and so many more." />

                <ValueBox
                icon={<TbRocket className="text-xl text-[#101828]" />}
                title={"Growth"}
                desc="We are committed to seeing the success of our community’s goals and ambitions. We believe that “where the heart is willing, it will find a thousand ways”. We ensure to fulfill any promise made to our customers and students." />
            </div>
            
        </div>
    )
}

export default Values