import { 
    GET_CLUBS_PACKAGES_SUCCESS,
    GET_SPECIFIC_PACKAGE_SUCCESS
} from "../types";

const initialState = () => ({
    clubsPackages: [],
    specificPackage: {}
})

const clubPackagesReducer = (state = initialState(), {type, payload}) => {
    switch(type) {
        case GET_CLUBS_PACKAGES_SUCCESS:
            return {
                ...state,
                clubsPackages: payload.clubs
            }

        case GET_SPECIFIC_PACKAGE_SUCCESS:
            return {
                ...state,
                specificPackage: payload.club
            }
        
        default: 
            return state;
    }
}

export default clubPackagesReducer;