
import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import cloudimage from "../../assets/bootcamp-form/svg/cloudimage.svg";
import date from "../../assets/bootcamp-form/svg/date.svg";
import girlimage from "../../assets/bootcamp-form/svg/girlimage.svg";
import cloudbg from "../../assets/bootcamp-form/svg/cloudbg.svg";

const Header = () => {
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };

        handleResize(); 
        window.addEventListener('resize', handleResize); 

        return () => {
            window.removeEventListener('resize', handleResize); 
        };
    }, []);

    return (
        <div className='flex flex-col gap-5 relative py-5 px-3 pc:h-fit mobile:py-7 mobile:pb-2 mobile:mt-10 mobile:min-h-[280px] bg-[#D4F4FF] w-full' style={{ backgroundImage: `url(${cloudbg})`, backgroundSize: 'cover' }}>
            {/* navbar */}
            <Navbar />
            {/* Desktop View */}
            {isDesktop && (
                <div className='flex  items-center justify-around  mt-9 md:mt-0'>
                    <div>
                        <img src={cloudimage} alt='cloud'/>
                    </div>
                    <div className='flex flex-row justify-between items-center'>
                        <div>
                            <img src={date} alt='date '/>
                        </div>
                        <div>
                            <img src={girlimage} alt='girl' />
                        </div>
                    </div>
                </div>
            )}
            {/* Mobile View */}
            {!isDesktop && (
                <div className='flex flex-col-reverse items-center justify-around  mt-9 md:mt-0'>
                    <div>
                        <img src={cloudimage} alt='cloud' className='mt-[-3rem]'/>
                    </div>
                    <div className='flex flex-row-reverse justify-between items-center'>
                        <div>
                            <img src={date} alt='date' className='max-w-xs ml-[-5rem] w-[6rem] '/>
                        </div>
                        <div>
                            <img src={girlimage} alt='girl' className='max-w-xs'/>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Header;



