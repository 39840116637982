import React, { useEffect, useState } from 'react'
import PackFeatures from './PackFeatures'
import UpcomingEvents from './UpcomingEvents'
import RedirectCard from './RedirectCard'
import image1 from "../../../../assets/redesign-dashboard/the-club/images/view-courses.png"
import image2 from "../../../../assets/redesign-dashboard/the-club/images/get-started.png"
import NoSubscription from './NoSubscription'
import DBCard from './DBCard'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUserClubSubsciptionRequest } from '../../../../redux/action'
import { Skeleton } from '@mui/material'
import { getTimeSpent } from '../../../../helpers'

const TheClubBody = () => {

    // STATES
    const { 
        userClubSubscriptions, 
        userClubSubscriptionsLoading, 
        token 
    } = useSelector((state) => {
        const { token } = state.userData;

        const { userClubSubscriptions } = state.clubSubscriptionReducer;
        const { userClubSubscriptionsLoading } = state.loadingIndicator;

        return {
            userClubSubscriptions,
            userClubSubscriptionsLoading,
            token,
        };
    });


    // DATA INITIALIZATION
    const dispatch = useDispatch();
    const location = useLocation()


    // SIDE EFFECTS
    useEffect(() => {
        dispatch(getUserClubSubsciptionRequest(token));
    }, []);  
    useEffect(() => {
        console.log(userClubSubscriptions)
    }, [userClubSubscriptions]);    

    return (
        <div className='flex mobile:flex-col w-full h-full gap-10'>

            {/* section filled with image and text details */}
            <div className='h-full w-[70%] mobile:w-full flex flex-col gap-10'>

                {/* package card info */}
                {
                    userClubSubscriptionsLoading?
                    <Skeleton
                    variant="rectangular"
                    sx={{
                    width: "100%",
                    height: "50vh",
                    borderRadius: "10px",
                    }} />:
                    !(userClubSubscriptions && userClubSubscriptions.length!==0)?
                    <Redirect to={`${location.pathname}/availble-packages`} />
                    :
                    userClubSubscriptions.slice(0,1).map((clubSub)=>(
                        <div 
                        key={clubSub._id}
                        className='flex flex-col gap-10 w-full'>

                            <div className='flex mobile:flex-col w-[50%] mobile:w-full gap-5'>
                                <DBCard
                                status={"CLUB"}
                                title={"Current club package"}
                                packageName={"Advanced"}
                                durationMonth={clubSub.club_id.duration}
                                durationText={"months membership"}
                                progressPercent={Math.floor((getTimeSpent(clubSub.start_date) / clubSub.club_id.duration) * 100)}
                                monthsRemaining={clubSub.club_id.duration - getTimeSpent(clubSub.start_date)}
                                isCompleted={getTimeSpent(clubSub.start_date) >= clubSub.club_id.duration}  />       
                            </div>

                            {/* subscription card info */}
                            <PackFeatures />
                        </div>
                    ))
                }

                
                {/* redirection cards */}
                <div className='flex gap-10 w-[50%] mobile:w-full mobile:flex-col'>

                    <RedirectCard
                    title={"Interested in learning a new skill?"}
                    path={"/dashboard/bootcamps"}
                    linkText={"View our courses"}
                    image={image1} />

                </div>
            </div>

            {/* upcomoing event section */}
            <div className='h-full w-[30%] mobile:w-full flex flex-col'>
                <UpcomingEvents />
            </div>
        </div>
    )
}

export default TheClubBody