import React from "react";
import BreakTextPC from "../Global/BrTextPC";
import investLady from "./../../assets/club/images/invest-lady.png";
import aboutUsDude from "./../../assets/club/images/aboutus-dude.png";
import a_shape1 from "./../../assets/club/images/a-shape1.png";

const AboutUs = () => {
  return (
    <div className="flex mobile:flex-col justify-center  items-center pt-32 pb-36 gap-16 mobile:pt-6 mobile:pb-16 mobile:gap-28 px-6">
      {/* first container */}
      <div className="flex flex-col gap-32 mobile:gap-8 w-[35%] mobile:w-full mobile:text-center mobile:items-center">
        {/* texts */}
        <div className="flex flex-col gap-2 mobile:px-2">
          <p className="text-base mobile:text-xs font-semibold text-[#8B54F7]">
            ABOUT US
          </p>
          <p className="font-semibold mobile:text-2xl leading-[54px] text-4xl text-black">
            What’s this club about?
          </p>
          <p className="mt-2 font-normal text-base text-[#6c717a] mobile:text-left md:px-24">
            The club is a community of private investors, venture capital a
            companies, institutional investors and technology experts.
          </p>
          <p className="font-normal text-base text-[#6c717a] mobile:text-left md:px-24">
            It is for those who would like to know more about technology trends
            and are interested in investments in cryptocurrency, blockchain,
            artificial intelligence, autonomous driving, robotics and other
            disruptive technologies.
          </p>
          <p className="font-normal text-base text-[#6c717a] mobile:text-left md:px-24">
            The club combines networking, education and investment opportunities
            in tech sector under one umbrella and provide access to the
            competitive VC funds with attractive returns.
          </p>
        </div>

        {/* image */}
        <div className="relative">
          {/* scrable */}
          <img
            src={a_shape1}
            className="hidden mobile:inline absolute mobile:w-[20px] mobile:top-[-2.5rem] mobile:right-[2rem]"
            alt=""
          />

          <img className="min-w-[25%] mobile:hidden" src={investLady} alt="" />
          <img
            className="min-w-[25%] hidden mobile:inline"
            src={aboutUsDude}
            alt=""
          />
        </div>
      </div>

      {/* second container */}
      <div className="flex flex-col-reverse mobile:flex-col items-end gap-32 mobile:gap-8 w-[35%] mobile:w-full mobile:text-center mobile:items-center">
        {/* texts */}
        <div className="flex flex-col gap-2 mobile:px-2">
          <p className="font-semibold mobile:text-2xl text-4xl leading-[54px] text-black">
            Invest into great tech projects
          </p>
          <p className="mt-2 font-normal text-base text-[#6c717a] mobile:text-left md:px-24">
            A new exclusive Club for investors and tech professionals aims to
            create a community of potential investors and industry experts,
            which will provide a platform for investing, networking as well as
            an exchange of ideas and experience. Access to excellent tech
            companies seeking investment which are often hidden or closed for
            many investors – the pooling of professional expertise and
            experienced investors, completely turns this around, opening up a
            wealth of previously closed opportunities. So the Club has a very
            simple aim, to bring together investors and late stage tech
            companies to create value.
          </p>
        </div>

        {/* image */}
        <div className="relative">
          {/* scrable */}
          <img
            src={a_shape1}
            className="mobile:hidden absolute top-[-8rem] right-[50%] mobile:w-[20px] mobile:top-[0] mobile:right-[0]"
            alt=""
          />

          {/* image */}
          <img className="min-w-[25%] mobile:hidden" src={aboutUsDude} alt="" />
          <img
            className="min-w-[25%] mobile:inline hidden"
            src={investLady}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
