import React from 'react'
import Footer2 from '../../../../layouts/Redesign/Footer2'
import AboutUs from './AboutUs'
import Accordion from './Accordion'
import GrowCareer from './GrowCareer'
import Questions from './Questions'
import Showcase from './Showcase'

const HomeBody = () => {
    return (
        <div className='mobile:w-screen'>

            {/* showcase: join us today */}
            <div className='flex justify-center bg-[#F8F8F8] mobile:h-[1460px]'>
                <Showcase />
            </div>

            {/* About us */}
            <div className="flex justify-center bg-[#F8F8F8]">
                <AboutUs />
            </div>

            {/* Accordion */}
            <div className='bg-[#055774] flex justify-center'>
                <Accordion />
            </div>

            {/* Get in touch */}
            <div className="bg-[#F9FAFB] flex justify-center">
                <Questions />
            </div>

            {/* Grow your career */}
            <GrowCareer />

            {/* Footer */}
            <Footer2 />
        </div>
    )
}

export default HomeBody