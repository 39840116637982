import React from 'react'
import { BsFillCheckCircleFill } from "react-icons/bs"

const SavedAccountInfo = ({closeModal}) => {
    return (
        <div className="p-5 bg-white flex flex-col justify-center items-stretch gap-4">
            
            {/* icon container */}
            <div className='flex justify-center items-center text-[#8B54F7]'>
                <BsFillCheckCircleFill size={64} />
            </div>

            {/* text container */}
            <div className='flex flex-col items-center'>
                <p className='text-2xl font-semibold text-[#1A1C1D]'>
                    Awesome!
                </p>

                <p className='font-medium text-xs text-[#202223]'>
                    Saved succesfully
                </p>
            </div>

            {/* back to settings button */}
            <button 
            onClick={closeModal}
            className='mt-3 h-[44px] bg-[#7F56D9] flex justify-center items-center font-semibold text-base text-white px-7 lg:text-xs rounded-[8px] hover:opacity-80'>
                Back to Settings
            </button>
        </div>
    )
}

export default SavedAccountInfo