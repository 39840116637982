

import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';

const ArrowBtn = ({ onClick, className, text }) => {
  return (
    <div
      onClick={onClick}
      className={`items-center gap-2 cursor-pointer hover:opacity-80 inline-flex ${className}`}
    >
      <span className='text-[#0B8CBA] font-semibold text-sm'>
        {text}
      </span>

      <IoIosArrowForward className="text-2xl text-[#0B8CBA]" />
    </div>
  );
};

ArrowBtn.defaultProps = {
  text: 'View other courses', // Default text if not provided as a prop
};

export default ArrowBtn;
