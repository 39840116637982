import { useState } from 'react'
import MyModal from '../../../../layouts/MyModal'
import BtnPrimary from '../../../Global/Redesign/BtnPrimary'
import SearchWidget from './SearchWidget'

const BookSpace = () => {

    // STATES
    const [spaceModalState, setSpaceModalState] = useState(false)


    // HANDLERS
    const toggleSpaceModal = () => {
        setSpaceModalState(!spaceModalState)
    }


    return (
        <div className="flex flex-col items-stretch [border:0px_transparent_solid] w-full">
                
            {/* first div */}
            <div className="w-full flex h-[172px] mobile:h-[128px]">

            </div>

            {/* second div */}
            <div className="w-full flex h-[172px] mobile:h-[128px] relative bg-[#F8F8F8]">

                {/* card */}
                <div className='p-3 mobile:p-3 bg-[#1D2433] h-[344px] mobile:h-[256px] rounded-[10px] flex flex-col absolute bottom-[0] right-[50%] translate-x-[50%] mobile:w-[95%] w-[75%] items-center justify-center gap-8 text-center'>

                    <p className='text-white font-semibold text-[32px] mobile:text-2xl w-[70%]'>
                        Book a space today and start working faster and better.
                    </p>

                    <BtnPrimary
                    onClick={toggleSpaceModal}
                    title={"Book a space now"} />

                </div>
            </div>

            {/* Modal */}
            {/* logout modal */}
            <MyModal
            modalIsOpen={spaceModalState}
            toggleModal={toggleSpaceModal}
            disableBtn={true}>
                
                {/* search modal */}
                <SearchWidget
                toggleDrawer={toggleSpaceModal} />

            </MyModal>
        </div>
    )
}

export default BookSpace