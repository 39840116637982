import { GET_SINGLE_SPACE_SUCCESS, GET_SPACES_SUCCESS, GET_USER_SPACES_SUCCESS } from "../types"

const initialState = {
    spaces: [],
    singleSpace: {},
    userSpaces: []
}

const spacesReducer = (state = initialState, { type, payload }) => {
  switch (type) {

  case GET_SPACES_SUCCESS:
    return { 
        ...state, 
        spaces: payload.availableSpaces
    }

  case GET_SINGLE_SPACE_SUCCESS:
    return { 
        ...state, 
        singleSpace: payload.space
    }

  case GET_USER_SPACES_SUCCESS:
    return { 
        ...state, 
        userSpaces: payload.bookings
    }

  default:
    return state
  }
}

export default spacesReducer