import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import jumbotronImage from "../../../../assets/webpages/event/images/jumbotron-image.png";
import { getEventsForUsersRequest } from "../../../../redux/action";
import { useSelector, useDispatch } from "react-redux";
import { Skeleton } from "@mui/material";

const EventJumbotron = () => {
  // OBJECT INITIALIZATION
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  params.set("status", "UPCOMING");

  // history object

  // dispatch object
  const dispatch = useDispatch();

  // event states
  const { events, eventsForUsersLoading } = useSelector((state) => {
    const { events } = state.eventsReducer;
    const { eventsForUsersLoading } = state.loadingIndicator;
    return {
      events,
      eventsForUsersLoading,
    };
  });

  useEffect(() => {
    dispatch(getEventsForUsersRequest());
  }, []);

  const MONTHS = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const DAY = [
    "Sunday",
    "Monday",
    "Tueday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const getData = {
    date: (prop) => {
      return new Date(prop).getDate();
    },
    dayNum: (prop) => {
      return new Date(prop).getDay();
    },
    month: (prop) => {
      return new Date(prop).getMonth();
    },
    year: (prop) => {
      return new Date(prop).getFullYear();
    },
  };

  return (
    <>
      {eventsForUsersLoading ? (
        <div className="flex items-center justify-center rounded-[20px] h-[50vh] mobile:h-[85vh] w-[80%] mobile:w-[90%] right-[50%] translate-x-[50%] md:!w-[50%] bottom-[0] absolute">
          <Skeleton
            variant="rectangular"
            sx={{
              zIndex: "1200",
              width: "100%",
              height: "100%",
              borderRadius: "20px",
            }}
          />
        </div>
      ) : (
        <div className="flex mobile:flex-col justify-center items-center mobile:h-fit [box-shadow:0px_8px_32px_#d6d6d6] mobile:[box-shadow:0px_2px_8px_#d6d6d6] absolute bottom-[0] rounded-[20px] bg-white mobile:py-5 mobile:px-3 pc:h-[464px] md:!w-[50%] w-[80%] mobile:w-[90%] right-[50%] translate-x-[50%] ">
          {/* images */}
          <div className="w-full mobile:h-[300px] pc:w-[50%] relative h-full">
            <img
              src={events[events.length - 1].imgurl}
              className="object-cover mobile:w-full rounded-l-[20px] mobile:rounded-[10px] h-full pc:w-full"
              alt=""
            />

            {/* date stamp */}
            <div className="flex bg-[#F8F5FF] rounded-[8px] text-[#1D2433] absolute mobile:top-[15px] mobile:left-[15px] top-[25px] left-[25px] mobile:gap-1 gap-2 py-4 px-5 mobile:py-[4px] mobile:px-4 flex-col items-center justify-center z-[2]">
              <span className="font-semibold mobile:text-base mobile:font-bold text-4xl leading-[1]">
                {MONTHS[getData.month(events[events.length - 1].date)]}
              </span>
              <span className="font-normal mobile:text-xs text-2xl leading-[1]">
                {`${getData.date(events[events.length - 1].date)}`}
              </span>
            </div>

            {/* dark transparent bg */}
            <span className="absolute h-full w-full top-0 left-0 pc:rounded-[20px_0_0_20px] bg-black opacity-40 z-[1] rounded-[10px] ">
              {""}
            </span>
          </div>

          <div className="w-[50%] mobile:w-full flex flex-col justify-center items-center m-auto">
            {/* texts container */}
            <div className="flex flex-col mobile:w-full w-[60%] md:w-[80%] gap-3 py-12 mobile:py-8 mobile:pb-5 md:text-center">
              {/* time and place */}
              <p className="flex flex-col mobile:text-xs text-sm font-semibold text-[#6c707a]">
                <span>{`${
                  DAY[getData.dayNum(events[events.length - 1].date)]
                } ${events[events.length - 1].time}`}</span>
                <span>{events[events.length - 1].location}</span>
              </p>

              {/* title */}
              <p className="text-4xl lg:text-xl mobile:text-2xl xl:text-2xl leading-[54px] font-semibold">
                {events[events.length - 1].name}
              </p>

              {/* description */}
              <p className="font-normal text-base text-[#6c707a]">
                {events[events.length - 1].description
                  .substring(0, 80)
                  .concat("...")}
              </p>

              {/* read more link */}
              <p
                onClick={() =>
                  history.push({
                    pathname: `/events/sdfadsfadfafsd`,
                    search: params.toString(),
                  })
                }
                className="font-semibold mobile:text-xs text-base text-[#0B8CBA] xl:text-sm cursor-pointer"
              >
                LEARN MORE
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EventJumbotron;
