import React from 'react'
import { useSelector } from 'react-redux';
import EventsBody from '../../../../components/Redesign/Dashboard/Events/EventsBody'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'

const Events = () => {

    // STATES
    const { firstname } = useSelector((state) => {
        const {
            user: { firstname },
        } = state.userData;
        return {
            firstname
        };
    });

    return (
        <PageWrapper
        title={`Hello ${firstname}`}
        subTitle={"Happy to see you back!"}>

            {/* body section */}
            <EventsBody />

        </PageWrapper>
    )
}

export default Events