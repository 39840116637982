import React from 'react'
import Footer from '../../../components/BootcampForm/Footer'
import Body from '../../../components/DigMarketingForm/Body'
import Header from '../../../components/DigMarketingForm/Header'
import SuccessMessage from '../../../components/DigMarketingForm/SuccessMessage'

const ThankyouPage = () => {
    return (
        <div className='flex flex-col w-full min-h-screen'>
            
            {/* Header */}
            <Header />

            {/* Body */}
            <Body>
                
                {/* Success message */}
                <SuccessMessage />

            </Body>

            {/* Footer */}
            <Footer />

        </div>
    )
}

export default ThankyouPage