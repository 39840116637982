import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import MyModal from '../../../../layouts/MyModal'
import { 
    deleteUserRequest, 
    logout, 
    clearDeleteUserSuccess,
    deleteUserFailure 
} from '../../../../redux/action'
import DeleteAccountView from '../../../Dashboard/Settings/DeleteAccountView'

const DeleteAccountBtn = () => {

    // DATA INITIALIZATION
    const dispatch = useDispatch()


    // STATES
    const [isOpen, setIsOpen] = useState(false)
    const { 
        userId,
        deleteUserSuccess,
        deleteUserError,
        deleteUserLoading 
    } = useSelector(state=>{
        const {
            _id: userId
        } = state.userData.user

        const {
            success: { 
                deleteUser: deleteUserSuccess 
            },
            errors: { 
                deleteUser: deleteUserError 
            },
        } = state.ajaxStatuses;
    
        const { 
            deleteUserLoading 
        } = state.loadingIndicator;

        return {
            userId,
            deleteUserSuccess,
            deleteUserError,
            deleteUserLoading
        }
    })

      
    // HANDLERS
    const toggleDeleteModal = () => {
        setIsOpen(!isOpen)
    }
    const handleDeleteUser = () => {
        dispatch(deleteUserRequest(userId))
    }
    const closeModal = () => {
        setIsOpen(false)
    }


    // SIDE EFFECTS
    useEffect(()=>{
        if(deleteUserError) {
            closeModal()
        }
    }, [deleteUserError])
    useEffect(()=>{
        if(deleteUserSuccess) {
            closeModal()
        }
    }, [deleteUserSuccess])

    return (
        <>
            <span 
            onClick={toggleDeleteModal}
            className='text-[#0B8CBA] text-base font-normal block cursor-pointer hover:opacity-70'>
                Delete account permanently
            </span>

            {/* delete modal */}
            <MyModal
            disableBtn={true}
            modalIsOpen={isOpen}
            toggleModal={toggleDeleteModal}>

                <DeleteAccountView 
                loading={deleteUserLoading}
                onDelete={handleDeleteUser}
                closeModal={toggleDeleteModal} />
            </MyModal>
        </>
    )
}

export default DeleteAccountBtn