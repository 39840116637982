import React from "react";
import thumbnail from "./../../assets/home/images/thumbnail.png";
import play from "./../../assets/home/svg/play-btn.svg";

const Video = () => {
  return (
    <div className="mt-20 flex flex-col justify-center items-center container mx-auto mobile:px-2 md:px-12">
      {/* interface */}
      {/* <div className='flex flex-col justify-center items-center relative'> */}

      {/* video thumbnail */}
      {/* <img src={thumbnail} alt="thumbnail" /> */}

      {/* play button */}
      {/* <img src={play} alt="play button" className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] cursor-pointer' /> */}

      {/* duration */}
      {/* <span className='bg-white z-[1000] px-2 py-[2px] rounded-[4px] font-normal text-[14px] leadings-[20px] absolute bottom-2 left-3'>
                1:57
            </span> */}
      {/* </div> */}
      <iframe
        className="rounded-[20px] mobile:rounded-[10px] mobile:h-[300px] mobile:w-[100%]"
        width="70%"
        height="515"
        src="https://www.youtube.com/embed/jR6TJc6fnj8"
        title="YouTube video player"
        frameBorder={"0"}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscrope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Video;
