import React, { useEffect, useState } from 'react'
import SummaryCard from './SummaryCard'
import { Skeleton } from '@mui/material'
import { getSingleSpaceRequest } from '../../../../../redux/action'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from '../../../../../helpers/isEmtpy'
import { useLocation } from 'react-router-dom'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import MyModal from '../../../../../layouts/MyModal'
import SavedAccountView from '../../Settings/SavedAccountView'

const SingleSpaceBody = () => {

    // OBJECT INITIALIZATION
    const history = useHistory()
    const dispatch = useDispatch()
    const location = useLocation()
    const { spaceId } = useParams()
    const param = new URLSearchParams(location.search)
    const duration = param?.get("duration")
    const start_date = param?.get("start")
    const end_date = param?.get("end")
    

    // STATES
    const [successfullPayment, setSuccessfullPayment] = useState(false)
    const { 
        singleSpace,
        getSingleSpaceLoading,
        spacePaymentSuccess,
        spacePaymentError
    } = useSelector(state=>{
        const { singleSpace } = state.spacesReducer
        const { 
            getSingleSpaceLoading
        } = state.loadingIndicator
        const {
            success: { 
                spacePayment: spacePaymentSuccess 
            },
            errors: { 
                spacePayment: spacePaymentError 
            },
        } = state.ajaxStatuses;
        return {
            getSingleSpaceLoading,
            singleSpace,
            spacePaymentSuccess,
            spacePaymentError
        }
    })


    // HANDLERS
    const toggleSuccessPaymentModal = () => {
        setSuccessfullPayment(!successfullPayment)
    }


    // SIDE EFFECTS
    useEffect(()=>{
        dispatch(getSingleSpaceRequest(spaceId))
    }, [])
    useEffect(() => {
        if (spacePaymentError) {
            toast.error(spacePaymentError, {
                duration: 3000,
            });
        }
    }, [spacePaymentError]);
    
      // success message
      useEffect(() => {
        if (spacePaymentSuccess) {
            toggleSuccessPaymentModal()
            setTimeout(() => {
                setSuccessfullPayment(false)
                history.push("/dashboard/space-booking")
            }, 3000);
        }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spacePaymentSuccess]);

    return (
        <div className='flex mobile:flex-col w-full h-full gap-10'>

            {
                getSingleSpaceLoading?
                <Skeleton
                variant="rectangular"
                sx={{
                width: "100%",
                height: "70vh",
                borderRadius: "10px",
                }} />:
                !isEmpty(singleSpace)?
                <>
            
                    {/* space info*/}
                    <div className='h-full w-[70%] mobile:w-full flex flex-col gap-10'>

                        <div className='gap-10 flex mobile:flex-col-reverse p-5 rounded-[10px] bg-white border border-[#E1E6EF]'>

                            <section className='flex flex-col gap-4 items-start w-[60%] mobile:w-full'>

                                {/* caption text */}
                                <p className='mt-0 font-semibold text-sm text-gray-600'>
                                    Current space booked
                                </p>

                                {/* duration */}
                                <div className='flex items-center gap-4 w-full'>
                                    <p className='mt-0 font-medium text-base text-[#1D2433]'>
                                        Duration:
                                    </p>

                                    <p className='mt-0 text-gray-800 font-normal text-sm'>
                                        {duration}day(s)
                                    </p>
                                </div>

                                {/* total costs */}
                                <div className='flex items-center gap-4 w-full'>
                                    <p className='mt-0 font-medium text-base text-[#1D2433]'>
                                        Total Cost:
                                    </p>

                                    <p className='mt-0 text-gray-800 font-normal text-sm'>
                                        NGN {(+singleSpace.new_price * +duration).toLocaleString('en-US')}
                                    </p>
                                </div>
                            </section>

                            {/* image section */}
                            <div className='h-full w-[40%] mobile:w-full'>
                                <img src={singleSpace.imgurl} className='object-cover h-[174px] w-full rounded-[10px]' alt="" />
                            </div>
                        </div>
                    </div>

                    {/* summary */}
                    <div className='h-full w-[30%] mobile:w-full flex flex-col'>

                        <SummaryCard
                        price={singleSpace.new_price}
                        duration={duration}
                        startDate={start_date}
                        endDate={end_date}
                        spaceId={singleSpace._id}/>

                    </div>
                </>:
                <div className='flex justify-center items-center w-full h-[70vh] text-2xl rounded-[10px]'>Nothing Here</div>
            }

            {/* successful message modal */}
            <MyModal
            disableBtn={true}
            modalIsOpen={successfullPayment}
            toggleModal={toggleSuccessPaymentModal}>

                <SavedAccountView
                text='Payment Successful!'
                closeModal={toggleSuccessPaymentModal} />
            </MyModal>
        </div>
    )
}

export default SingleSpaceBody