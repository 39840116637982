import React from 'react'
import AvailablePackagesBody from '../../../../components/Redesign/Dashboard/TheClub/AvailablePackages/Body'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'

const AvailblePackages = () => {
    return (
        <PageWrapper
        enableBackBtn={true}
        title={"The Club"}
        subTitle={"More than a tech community"}>

            {/* body section */}
            <AvailablePackagesBody />

        </PageWrapper>
    )
}

export default AvailblePackages