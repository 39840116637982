import React from 'react'
import about from "../../../../assets/webpages/club/images/club.png"
import invest from "../../../../assets/webpages/club/images/invest.png"
import InfoCard from './InfoCard'

const ClubInfo = () => {
    return (
        <div className='py-[220px] mobile:py-20 2xl:w-[80%] container mobile:w-full mx-auto flex mobile:flex-col gap-4 mobile:gap-20 relative mobile:px-5'>

                <InfoCard
                image={invest}
                mobileImage={about}
                subTopic={"ABOUT US"}
                title={"What’s this club about?"}
                desc={"The club is a community of Family offices, private investors, venture capital companies, institutional investors and technology experts. \nIt is for those who would like to know more about technology trends and are interested in investments in cryptocurrency, blockchain, artificial intelligence, autonomous driving, robotics and other disruptive technologies.\nThe club combines networking, education and investment opportunities in tech sector under one umbrella and provide access to the competitive VC funds with attractive returns."} />

                <InfoCard
                image={about}
                mobileImage={invest}
                subTopic={""}
                title={"Invest into great tech projects"}
                desc={"A new exclusive Club for investors and tech professionals aims to create a community of potential investors and industry experts, which will provide a platform for investing, networking as well as an exchange of ideas and experience. Access to excellent tech companies seeking investment which are often hidden or closed for many investors – the pooling of professional expertise and experienced investors, completely turns this around, opening up a wealth of previously closed opportunities. So the Club has a very simple aim, to bring together investors and late stage tech companies to create value. "}
                reverse={true} />
        </div>
    )
}

export default ClubInfo