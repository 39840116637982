import React from 'react'
import Pathways from './Pathways'
import { Skeleton } from '@mui/material'

const SpecialTrack = ({loadingState, bootcamps}) => {

    return (
        <div className='flex flex-col gap-4'>
            
            {/* pathway1 */}
            {
                loadingState? 
                <Skeleton 
                animation="wave"
                variant="rectangular" 
                sx={{ 
                    width: '100%', 
                    height: "45vh",
                    borderRadius: "10px"
                 }} />:
                bootcamps.length===0?
                <div>empty</div>:
                bootcamps.map((bootcamp)=>(
                    bootcamp.course_type==="pathway"&&
                    <Pathways
                    number={bootcamp.pathway.split(" ")[1]}
                    key={bootcamp._id}
                    title={bootcamp.name}
                    courseType={`${bootcamp.description.split(" ")[0]} - ${bootcamp.duration} Months Course`}
                    to={bootcamp._id} />
                ))
            }
        </div>
    )
}

export default SpecialTrack