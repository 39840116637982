import React from 'react'
import bShape1 from "../../../../assets/webpages/login/images/b-shape2.png"
import bShape2 from "../../../../assets/webpages/login/images/b-shape3.png"
import Header from './Header'

const Body = ({children}) => {
    return (
        <div className='pc:mb-[3rem] mobile:mt-[3rem] w-full bg-white relative h-full z-[5] flex flex-col items-center justify-center py-20 mobile:py-12 px-20 mobile:px-2 container mx-auto'>

            {/* shapes */}
            <img src={bShape2} className='absolute top-[10%] right-[15%] lg:right-[12%] z-[90] mobile:hidden' alt="" />
            <img src={bShape1} className='absolute bottom-[35%] left-[12%] z-[90] mobile:hidden' alt="" />

            {/* Children component */}
            <div className='[box-shadow:0px_2px_8px_#e0e0e0] relative z-[5] flex flex-col my-4 justify-center items-center bg-white w-[58%] mobile:w-full mobile:shadow-none h-auto py-8 mobile:py-12 rounded-[8px] gap-6 mobile:px-2 px-24 lg:px-12 lg:w-[64%]'>

                <Header />

                {children}

            </div>

        </div>
    )
}

export default Body