import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CourseCard from './CourseCard';
import { getBootcampForUsersRequest } from '../../redux/action';
import { Skeleton } from '@mui/material';
import o_shape1 from "./../../assets/trainingcenter/images/o-shape1.png"
import LearnMorePopup from './LearnMorePopup';
import TrainingCenterModal from '../../layouts/TrainingCenterModal';

const AvailableCourses = () => {

    // learn more modal state
    const [learnMoreModalState, setLearnMoreModalState] = useState(false)
    const [modalData, setModalData] = useState(null)

    const toggleLearnMoreModal = (data) => {
        setModalData(data)
        setLearnMoreModalState(!learnMoreModalState)
    }

    // dispatch object
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getBootcampForUsersRequest())
    }, [])

    // bootcamps state
    const { bootcamps,bootcampsLoading  } = useSelector((state)=>{
        const { bootcamps } = state.bootcampReducer
        const { bootcampsLoading } = state.loadingIndicator
        return { bootcamps,bootcampsLoading }
    })

    return (
        <div className='py-32 flex flex-col gap-12 mobile:pt-8 mobile:pb-16 relative'>

            {/* shape */}
            <img src={o_shape1} className="absolute top-[6rem] right-[22%] mobile:w-[20px] mobile:right-[3rem] mobile:top-[2rem]" alt="" />

            {/* texts */}
            <div className='flex flex-col text-center gap-3 px-6 mobile:px-5'>
                <p className='font-semibold mobile:text-xs text-base text-[#8B54F7]'>
                    OUR COURSES
                </p>

                <p className='font-semibold mobile:text-2xl text-4xl mb-2 text-black'>
                    Here are the available courses we offer
                </p>

                <p className='md:px-8 mobile:text-base mobile:text-[#1D2433] opacity-[0.65]'>
                    Select the pathway below that you are interested in, click on apply, fill the form and an admissions officer will review your application <br/>and reach out to you.
                </p>
            </div>

            {/* courses */}
            <div className='flex justify-center items-start gap-12 px-8 mobile:flex-col mobile:px-6 mobile:items-center'>
                
                {
                    bootcampsLoading?
                    <>
                    <Skeleton 
                    variant="rectangular" 
                    sx={{ 
                        width: '70%', 
                        height: "60vh",
                        borderRadius: "10px"
                    }} />
                    <Skeleton 
                    variant="rectangular" 
                    sx={{ 
                        width: '70%', 
                        height: "60vh",
                        borderRadius: "10px"
                    }} />
                    <Skeleton 
                    variant="rectangular" 
                    sx={{ 
                        width: '70%', 
                        height: "60vh",
                        borderRadius: "10px"
                    }} />
                    </>: 
                    bootcamps.map((object)=>(
                        object.course_type==="regular"&&
                        <CourseCard 
                        data={object} 
                        key={object._id}
                        onLearnMoreClick={()=>toggleLearnMoreModal(object)} />
                    ))
                }

            </div>

            {/* Learn More Modal */}
            <TrainingCenterModal
            modalIsOpen={learnMoreModalState}
            toggleModal={toggleLearnMoreModal}
            disableBtn={false}
            >
                <LearnMorePopup
                path='/old/login'
                moreInfo={modalData}
                closeModal={toggleLearnMoreModal} />
            </TrainingCenterModal>
        </div>
    )
}

export default AvailableCourses