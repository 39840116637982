import React from "react";
import missionPic from "./../../assets/management/images/mng-mission.png";
import aboutUsKachi from "./../../assets/management/images/aboutus-kachi.png";
import loveShape from "./../../assets/management/svg/mng-love.svg";
import squiblyShape from "./../../assets/management/images/m-shape2.png";

const Mission = () => {
  return (
    <div className="flex mobile:flex-col justify-center  items-center pt-32 mobile:pt-16 pb-36 mobile:pb-16 gap-12 mobile:px-7 mobile:gap-20 relative">
      <img
        src={loveShape}
        className="hidden mobile:inline absolute right-[3rem] top-[4rem] mobile:w-[20px]"
        alt=""
      />

      <img
        src={squiblyShape}
        className="absolute right-[3rem] bottom-[1rem] mobile:w-[20px]"
        alt=""
      />

      <div className="flex flex-col mobile:gap-10 gap-32 w-[38%] mobile:w-full mobile:items-center">
        <div className="flex flex-col gap-2 mobile:text-center">
          <p className="text-base mobile:text-xs font-semibold text-[#8B54F7]">
            ABOUT US
          </p>

          <p className="font-semibold mobile:text-2xl text-4xl text-black mobile:my-1">
            The story behind how{" "}
            <span className="text-[#8B54F7]">AI Movement</span> started
          </p>

          <p className="mt-2 font-normal text-base text-[#6c717a]">
            Tech is the future, and the future is now. So, how do we move from
            the present to the future where we ought to be? AI Movement offers
            both a radical tech training center and a new exclusive club for
            tech professionals and investors. This platform opens a door for
            people to enter the tech arena, and it creates a wholesome community
            of tech experts and potential investors - think of it as a circular
            arrow in motion that touches and merges all corners of the tech
            universe. On this platform you will have sustainable career building
            opportunities, and you will find various networking and unique
            investment opportunities. As a student, you will be exposed to
            multiple viable projects that will equip you with the radical skills
            required for a sustainable and rewarding tech career; as a member,
            you will be presented with numerous opportunities to foster an
            exchange of ideas and experience; and as an investor, you will be
            provided access to exclusive tech companies seeking investment. So,
            AI Movement’s aim is simple, to move us from where we currently are
            into the invigorating tech future.
          </p>
        </div>

        <div>
          <img className="min-w-[25%] mobile:hidden" src={missionPic} alt="" />
          <img
            className="min-w-[25%] hidden mobile:inline"
            src={aboutUsKachi}
            alt=""
          />
        </div>
      </div>

      <div className="flex flex-col-reverse mobile:flex-col items-end mobile:gap-10 gap-32 w-[38%] mobile:w-full mobile:items-center">
        <div className="flex flex-col gap-2 mobile:text-center">
          <p className="text-base mobile:text-xs font-semibold text-[#8B54F7]">
            OUR MISSION
          </p>
          <p className="font-semibold mobile:text-2xl text-4xl text-black">
            The mission behind how{" "}
            <span className="text-[#8B54F7]">AI Movement</span> started
          </p>
          <p className="mt-2 font-normal text-base text-[#6c717a]">
            Our mission is two dimensional. First, we adequately train a host of
            students by deeply immersing them in projects that provide them with
            the necessary skills for a successful career in the tech industry.
          </p>
          <p className="font-normal text-base text-[#6c717a]">
            Second, we provide a platform that synthesizes networking, education
            and investment opportunities in the tech sector to generate
            attractive investment returns and outcomes
          </p>
        </div>

        <div className="relative">
          <img
            src={loveShape}
            className="absolute left-[-20%] top-[4rem] mobile:hidden"
            alt=""
          />

          <img
            className="min-w-[25%] mobile:hidden"
            src={aboutUsKachi}
            alt=""
          />
          <img
            className="min-w-[25%] hidden mobile:inline"
            src={missionPic}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Mission;
