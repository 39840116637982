import React from 'react'
import InvestmentPaymentBody from '../../../../components/Redesign/Dashboard/Investing/InvestmentPayment/Body'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'

const Payment = () => {
  return (
    <PageWrapper
    enableBackBtn={true}
    title={"Investing"}
    subTitle={"Gain profit by investing in startups with the right vision"}>

        {/* body section */}
        <InvestmentPaymentBody />

    </PageWrapper>
  )
}

export default Payment