import React from 'react'
import AvailableSpacesBook from '../../../../components/Redesign/Dashboard/SpaceBooking/AvailableSpaces/AvailableSpacesBook'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'

const AvailableSpaces = () => {
    return (
        <PageWrapper
        enableBackBtn={true}
        title={"Comfortable Workspaces"}
        subTitle={"Just for You"}>

            {/* body section */}
            <AvailableSpacesBook />

        </PageWrapper>
    )
}

export default AvailableSpaces