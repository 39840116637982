import {
  GET_BOOTCAMP_FOR_USERS_REQUEST,
  GET_BOOTCAMP_FOR_USERS_SUCCESS,
  GET_BOOTCAMP_FOR_USERS_LOADING,
  GET_BOOTCAMP_FOR_USERS_FAIL,
  GET_USERS_BOOTCAMP_REQUEST,
  GET_USERS_BOOTCAMP_SUCCESS,
  GET_USERS_BOOTCAMP_FAIL,
  GET_USERS_BOOTCAMP_LOADING,
  GET_SPECIFIC_BOOTCAMP_REQUEST,
  GET_SPECIFIC_BOOTCAMP_SUCCESS,
  GET_SPECIFIC_BOOTCAMP_FAIL,
  GET_SPECIFIC_BOOTCAMP_LOADING,
  PUT_BOOTCAMP_PAYMENT_REQUEST,
  PUT_BOOTCAMP_PAYMENT_SUCCESS,
  PUT_BOOTCAMP_PAYMENT_FAIL,
  PUT_BOOTCAMP_PAYMENT_LOADING,
  CLEAR_PUT_BOOTCAMP_PAYMENT_SUCCESS,
} from "../types";

// bootcamp for all users
export const getBootcampForUsersRequest = () => {
  return {
    type: GET_BOOTCAMP_FOR_USERS_REQUEST,
  };
};

export const getBootcampForUsersSuccess = (prop) => {
  return {
    type: GET_BOOTCAMP_FOR_USERS_SUCCESS,
    payload: prop,
  };
};

export const getBootcampForUsersFailure = (errors) => {
  return {
    type: GET_BOOTCAMP_FOR_USERS_FAIL,
    payload: { errors },
  };
};

export const getBootcampForUsersLoading = (loading) => {
  return {
    type: GET_BOOTCAMP_FOR_USERS_LOADING,
    payload: loading,
  };
};

// bootcamp for all users
export const putBootcampPaymentRequest = (prop) => {
  return {
    type: PUT_BOOTCAMP_PAYMENT_REQUEST,
    payload: prop,
  };
};

export const putBootcampPaymentSuccess = (prop) => {
  return {
    type: PUT_BOOTCAMP_PAYMENT_SUCCESS,
    payload: prop,
  };
};

export const putBootcampPaymentFailure = (errors) => {
  return {
    type: PUT_BOOTCAMP_PAYMENT_FAIL,
    payload: { errors },
  };
};

export const putBootcampPaymentLoading = (loading) => {
  return {
    type: PUT_BOOTCAMP_PAYMENT_LOADING,
    payload: loading,
  };
};

export const clearputBootcampPaymentSuccess = (loading) => {
  return {
    type: CLEAR_PUT_BOOTCAMP_PAYMENT_SUCCESS,
    payload: loading,
  };
};

// single user's bootcamp
export const getUsersBootcampRequest = (prop) => {
  return {
    type: GET_USERS_BOOTCAMP_REQUEST,
    payload: prop,
  };
};

export const getUsersBootcampSuccess = (prop) => {
  return {
    type: GET_USERS_BOOTCAMP_SUCCESS,
    payload: prop,
  };
};

export const getUsersBootcampFailure = (errors) => {
  return {
    type: GET_USERS_BOOTCAMP_FAIL,
    payload: { errors },
  };
};

export const getUsersBootcampLoading = (loading) => {
  return {
    type: GET_USERS_BOOTCAMP_LOADING,
    payload: loading,
  };
};

// specific bootcamp by id
export const getSpecificBootcampRequest = (prop) => {
  return {
    type: GET_SPECIFIC_BOOTCAMP_REQUEST,
    payload: prop,
  };
};

export const getSpecificBootcampSuccess = (prop) => {
  return {
    type: GET_SPECIFIC_BOOTCAMP_SUCCESS,
    payload: prop,
  };
};

export const getSpecificBootcampFailure = (errors) => {
  return {
    type: GET_SPECIFIC_BOOTCAMP_FAIL,
    payload: { errors },
  };
};

export const getSpecificBootcampLoading = (loading) => {
  return {
    type: GET_SPECIFIC_BOOTCAMP_LOADING,
    payload: loading,
  };
};
