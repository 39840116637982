import React from 'react'
import SpaceBody from '../../../../components/Redesign/Webpage/Spaces/SpaceBody'
import SpaceHeader from '../../../../components/Redesign/Webpage/Spaces/SpaceHeader'
import Wrapper from '../../../../layouts/Redesign/Wrapper'

const Spaces = () => {
    return (
        <Wrapper>

            {/* Space Header */}
            <SpaceHeader />

            {/* Space  Body */}
            <SpaceBody />

        </Wrapper>
    )
}

export default Spaces