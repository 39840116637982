import { Skeleton } from '@mui/material'
import React from 'react'
import jumbotronImage from "../../../../../assets/webpages/event/images/event-image.png"

const Showcase = ({loading, image, month, day, notEmpty}) => {

    return (
        <div className="flex flex-col items-stretch [border:0px_transparent_solid] -mt-1 w-full">
                
            {/* first div */}
            <div className="w-full flex mobile:h-[150px] 2xl:h-[200px] h-[200px] bg-[#FFF8EB]">
            </div>

            {/* second div */}
            <div className="w-full flex mobile:h-[150px] 2xl:h-[200px] h-[200px] relative">

                {
                    loading?
                    <div className="flex items-center justify-center rounded-[20px] h-[50vh] mobile:h-[300px] w-[80%] mobile:w-[90%] right-[50%] translate-x-[50%] md:!w-[50%] bottom-[0] absolute">
                        <Skeleton
                        variant="rectangular"
                        sx={{
                        zIndex: "1200",
                        width: "100%",
                        height: "100%",
                        borderRadius: "20px",
                        }} />
                    </div>:
                    notEmpty?

                    <div
                    className="flex mobile:flex-col justify-center items-center [box-shadow:0px_8px_32px_#d6d6d6] mobile:[box-shadow:0px_2px_8px_#d6d6d6] absolute bottom-[0px] rounded-[20px] bg-white pc:h-[464px] md:!w-[50%] w-[80%] mobile:w-[90%] right-[50%] translate-x-[50%] mobile:h-[300px]">

                        {/* images */}
                        <div className="w-full relative h-full">

                            <img
                            src={image}
                            className="object-cover mobile:w-full rounded-[20px] mobile:h-full object-[center_top] mobile:rounded-[10px] h-full pc:w-full"
                            alt=""
                            />

                            {/* date stamp */}
                            <div className="flex bg-[#F8F5FF] rounded-[10px] text-[#1D2433] absolute mobile:top-[15px] mobile:left-[15px] top-[25px] left-[25px] py-4 px-5 mobile:py-[4px] mobile:px-4 flex-col items-center justify-center z-[2]">
                                <span className="font-semibold mobile:text-base mobile:font-bold text-4xl leading-[1]">
                                    {day}
                                </span>
                                <span className="font-normal mobile:text-xs text-2xl leading-[1]">
                                    {month}
                                </span>
                            </div>

                            {/* dark transparent bg */}
                            <span className="absolute h-full w-full top-0 left-0 pc:rounded-[20px] bg-black opacity-40 z-[1] rounded-[10px] ">
                                {""}
                            </span>
                        </div>
                        
                    </div>:
                    <div className='flex justify-center items-center w-full h-[45vh] text-xl border-2 border-gray-100 rounded-[10px]'>Nothing Here</div>
                }

            </div>
        </div>
    )
}

export default Showcase