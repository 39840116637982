import React from "react";
import CaptionJumbotron from "../../../components/Events/CaptionJumbotron";
import Programmes from "../../../components/Events/Programmes";
import FixedMessage from "../../../components/Global/FixedMessage";
import Footer from "../../../components/Global/Footer";
import JoinUs from "../../../components/Global/JoinUs";
import Header from "../../../layouts/Header";

const Events = () => {
  return (
    <>
      {/* Header */}
      <Header
      bgColor="bg-[#FFF8EB]" />

      {/* fixed message */}
      <FixedMessage />

      <div className="mobile:w-screen mobile:py-16 mobile:pb-6">
        {/* caption section */}
        <CaptionJumbotron />

        {/* events */}
        <Programmes />

        {/* Join Us */}
        <JoinUs />

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default Events;
