import { useEffect, useState } from 'react'
import { motion } from "framer-motion";
import GreenCheck from '../../../../Global/GreenCheck';
import StepBtn from '../Student/StepBtn';

const InvestorBenefits = ({
    pages,
    setPages,
    x,
    setX,
    disableBtn
}) => {

    // STATES
    const [cardID, setCardID] = useState(null)


    // SIDE EFFECTS
    useEffect(()=> {
        disableBtn({
            back: true,
            next: cardID===null
        })
    }, [cardID])

    return (
        <motion.div 
        initial={{ x: x }}
        transition={{ duration: 1 }}
        animate={{ x: 0 }}
        exit={{ x: x }}
        className='flex flex-col w-full gap-6'>

            {/* card */}
            <div className='rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] flex flex-col w-full gap-8 p-[40px_12px_20px]'>

                {/* benefits */}
                <div className='flex flex-col items-center gap-7 w-full'>

                    {/* caption */}
                    <p className='text-[22px] font-semibold text-[#3F444D]'>
                        Investor Benefits
                    </p>

                    {/* list */}
                    <div className='flex flex-col gap-2 w-fit'>

                        {/* member swag */}
                        <div className='flex items-center gap-2'>
                            <GreenCheck />

                            <span className='text-gray-800 text-sm font-normal'>
                                Member Swag
                            </span>
                        </div>

                        {/* newsletter */}
                        <div className='flex items-center gap-2'>
                            <GreenCheck />

                            <span className='text-gray-800 text-sm font-normal'>
                                Newsletter
                            </span>
                        </div>
                    </div>
                </div>

                {/* Buttons */}
                <div className='flex items-stretch flex-col gap-3'>

                    {/* amount */}
                    <p className='font-semibold text-[#0B8CBA] text-[22px] text-center w-full'>
                        NGN 240,000
                    </p>

                    <button
                    onClick={()=>setCardID(1)}
                    className={`text-base font-semibold text-[#0B8CBA] h-[44px] rounded-[8px] transition-[.4s_ease-in-out_all] border border-[#055774] px-2 w-full ${cardID===1 ?"bg-[#0B8CBA] text-white hover:opacity-60":"hover:bg-blue-100 "}`}>
                        Pay full amount now
                    </button>

                    <button
                    onClick={()=>setCardID(2)}
                    className={`py-2 rounded-[8px] border border-[#055774] px-2 w-full transition-[.4s_ease-in-out_all] text-[#0B8CBA] flex flex-col gap-2 items-center ${cardID===2 ?"bg-[#0B8CBA] text-white hover:opacity-60":"hover:bg-blue-100 "}`}>

                        <span className='text-base font-semibold'>
                            Pay by instalment
                        </span>

                        <span className='text-xs font-normal'>
                            NGN 120,000 X2
                        </span>
                    </button>
                </div>
            </div>
        </motion.div>
    )
}

export default InvestorBenefits