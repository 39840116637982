import style from "./checkbox.module.css"

const Checkbox = ({isChecked, onChange}) => {
    return (
      <div className={style.checkboxContainer}>
          <label className={style.lbContainer}>
              <input type="checkbox" name="agreeTerms" id="checkBox" checked={isChecked} onChange={onChange} />
          </label>
      </div>
    )
  }
  
  export default Checkbox