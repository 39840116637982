import React, { useState } from "react";
import sideNavLogo from "./../../assets/dashboard/home/svg/aimove-logo.svg";
import ActiveLink from "../../components/Dashboard/SideNav/ActiveLink";
import { BiHomeAlt } from "react-icons/bi";
import { FiBookOpen, FiSettings } from "react-icons/fi";
import { FaChalkboardTeacher, FaUsers } from "react-icons/fa";
import {
  AiOutlineCalendar,
  AiOutlineFundProjectionScreen,
} from "react-icons/ai";
import { HiOutlineLogout } from "react-icons/hi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import MobileNavBar from "./MobileNavBar";
import NavBtn from "../../components/Global/NavBtn";
import MyModal from "./../../layouts/MyModal";
import LogoutModalView from "../../components/Dashboard/SideNav/LogoutModalView";

const SideNav = () => {
  const [isNavOpen, setNavState] = useState(false);

  const transformMobile = !isNavOpen
    ? "mobile:translate-x-[-500rem]"
    : "mobile:translate-x-[0px]";

  // logout modal state
  const [logoutModalState, setLogoutModalState] = useState(false);

  const toggleLogoutModal = () => {
    setLogoutModalState(!logoutModalState);
  };

  return (
    <>
      {/* mobile nav bar */}
      <MobileNavBar toggleNav={() => setNavState(!isNavOpen)} />

      {/* sidenav drawer */}
      <div
        style={{
          transition: "transform 1.2s ease-in-out",
          transitionFunction: "cubic-bezier(.22,.68,0,1.71)",
        }}
        className={`bg-[#5221B5] fixed h-screen w-[20vw] mobile:z-[1000] mobile:min-h-screen mobile:w-full px-6 mobile:px-7 py-8 flex flex-col gap-10 mobile:gap-[4.1rem] ${transformMobile} top-[0] left-[0]`}
      >
        {/* logo */}
        <div className="container mx-auto flex justify-between items-center">
          <img src={sideNavLogo} className="mobile:w-[137px]" alt="" />

          {/* close btn mobile */}
          <NavBtn onClick={() => setNavState(!isNavOpen)}>
            <FontAwesomeIcon color="#fff" size={"2xl"} icon={faXmark} />
          </NavBtn>
        </div>

        {/* sidenav items */}
        <ul className="container mx-auto flex flex-col gap-2 mobile:gap-4">
          <li>
            <ActiveLink to={"/old/dashboard/home"}>
              <BiHomeAlt size={22} />
              Home
            </ActiveLink>
          </li>

          <li>
            <ActiveLink to={"/old/dashboard/courses"}>
              <FiBookOpen size={18} />
              Courses
            </ActiveLink>
          </li>

          <li>
            <ActiveLink to={"/old/dashboard/theclub"}>
              <FaUsers size={18} />
              The Club
            </ActiveLink>
          </li>

          <li>
            <ActiveLink to={"/old/dashboard/events"}>
              <AiOutlineCalendar size={18} />
              Events
            </ActiveLink>
          </li>

          {/* <li>
              <ActiveLink to={"/dashboard/space-booking"}>
                  <FaChalkboardTeacher size={18} />
                  Space Booking
              </ActiveLink>
          </li> */}

          <li>
            <ActiveLink to={"/old/dashboard/settings"}>
              <FiSettings size={18} />
              Settings
            </ActiveLink>
          </li>

          <li>
            <span
              onClick={toggleLogoutModal}
              className="text-white font-semibold mobile:font-normal mobile:text-base text-base lg:text-xs xl:text-xs flex justify-start items-center transition-all hover:text-[#5221B5] hover:bg-[#f9f5ff75] rounded-[6px] h-[40px] gap-[8px] mobile:gap-3 px-4 hover:no-underline"
            >
              <HiOutlineLogout size={18} />
              Logout
            </span>
          </li>
        </ul>
      </div>

      {/* logout modal */}
      <MyModal
        modalIsOpen={logoutModalState}
        toggleModal={toggleLogoutModal}
        disableBtn={true}
      >
        <LogoutModalView closeModal={toggleLogoutModal} />
      </MyModal>
    </>
  );
};

export default SideNav;
