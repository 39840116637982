import {
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  REGISTER_SUCCESS_CLEAR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGIN_SUCCESS_CLEAR,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_SUCCESS_CLEAR,
  GET_BOOTCAMP_FOR_USERS_SUCCESS,
  GET_BOOTCAMP_FOR_USERS_FAIL,
  GET_USERS_BOOTCAMP_SUCCESS,
  GET_USERS_BOOTCAMP_FAIL,
  GET_SPECIFIC_BOOTCAMP_SUCCESS,
  GET_SPECIFIC_BOOTCAMP_FAIL,
  GET_CLUBS_PACKAGES_SUCCESS,
  GET_CLUBS_PACKAGES_FAIL,
  GET_SPECIFIC_PACKAGE_SUCCESS,
  GET_SPECIFIC_PACKAGE_FAIL,
  PUT_BOOTCAMP_PAYMENT_SUCCESS,
  PUT_BOOTCAMP_PAYMENT_FAIL,
  CLEAR_PUT_BOOTCAMP_PAYMENT_SUCCESS,
  GET_EVENTS_FOR_USERS_SUCCESS,
  GET_EVENTS_FOR_USERS_FAIL,
  GET_USER_EVENTS_SUCCESS,
  GET_USER_EVENTS_FAIL,
  GET_SINGLE_EVENT_SUCCESS,
  GET_SINGLE_EVENT_FAIL,
  GET_USER_CLUB_SUBSCRIPTION_SUCCESS,
  GET_USER_CLUB_SUBSCRIPTION_FAIL,
  PUT_USER_TO_EVENT_SUCCESS,
  CLEAR_PUT_USER_TO_EVENT_SUCCESS,
  PUT_USER_TO_EVENT_FAIL,
  PUT_CLUB_SUBSCRIPTION_PAYMENT_SUCCESS,
  CLEAR_CLUB_SUBSCRIPTION_PAYMENT_SUCCESS,
  PUT_CLUB_SUBSCRIPTION_PAYMENT_FAIL,
  GET_SPACES_FAIL,
  GET_SPACES_SUCCESS,
  GET_SINGLE_SPACE_SUCCESS,
  GET_SINGLE_SPACE_FAIL,
  GET_USER_SPACES_SUCCESS,
  GET_USER_SPACES_FAIL,
  REGISTER_BOOTCAMP_SUCCESS,
  CLEAR_REGISTER_BOOTCAMP_SUCEESS,
  REGISTER_BOOTCAMP_FAIL,
  REGISTER_DIGMARK_BOOTCAMP_SUCCESS,
  REGISTER_DIGMARK_BOOTCAMP_FAIL,
  CLEAR_REGISTER_DIGMARK_BOOTCAMP_SUCEESS,
  REGISTER_CRITIQUE_EVENT_SUCCESS,
  REGISTER_CRITIQUE_EVENT_FAIL,
  CLEAR_REGISTER_CRITIQUE_EVENT_SUCEESS,
  STUDENT_REGISTER_SUCCESS,
  STUDENT_REGISTER_SUCCESS_CLEAR,
  STUDENT_REGISTER_FAIL,
  UPDATE_USER_PASSWORD_FAIL,
  UPDATE_USER_PASSWORD_SUCCESS,
  CLEAR_UPDATE_USER_PASSWORD_SUCCESS,
  CLUB_REGISTER_SUCCESS,
  CLUB_REGISTER_FAIL,
  CLUB_REGISTER_SUCCESS_CLEAR,
  REGISTER_GENERIC_EVENT_SUCCESS,
  CLEAR_REGISTER_GENERIC_EVENT_SUCEESS,
  REGISTER_GENERIC_EVENT_FAIL,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CLEAR_DELETE_USER_SUCCESS,
  BYPASS_REGISTER_SUCCESS,
  BYPASS_REGISTER_FAIL,
  BYPASS_REGISTER_SUCCESS_CLEAR,
  SPACE_REGISTRATION_SUCCESS,
  SPACE_REGISTRATION_FAIL,
  SPACE_REGISTRATION_SUCCESS_CLEAR,
  SPACE_PAYMENT_SUCCESS,
  SPACE_PAYMENT_FAIL,
  SPACE_PAYMENT_SUCCESS_CLEAR
} from "../types";

const initialState = {
  errors: {},
  success: {},
};

const ajaxStatuses = (state = initialState, { type, payload }) => {
  switch (type) {
    // register
    case REGISTER_FAIL:
      state.errors.register = payload.errors;
      return { ...state };
    case REGISTER_SUCCESS:
      state.success.register = payload.message;
      return { ...state };
    case REGISTER_SUCCESS_CLEAR:
      state.success.register = "";
      return { ...state };

    // login
    case LOGIN_FAIL:
      state.errors.login = payload.errors;
      return { ...state };
    case LOGIN_SUCCESS:
      state.success.login = payload.message;
      return { ...state };
    case LOGIN_SUCCESS_CLEAR:
      state.success.login = "";
      return { ...state };

    // update user
    case UPDATE_USER_FAIL:
      state.errors.updateUser = payload.errors;
      return { ...state };
    case UPDATE_USER_SUCCESS:
      state.success.updateUser = payload.message;
      return { ...state };
    case UPDATE_SUCCESS_CLEAR:
      state.success.updateUser = "";
      return { ...state };

    // update user password
    case UPDATE_USER_PASSWORD_FAIL:
      state.errors.updateUserPassword = payload.errors;
      return { ...state };
    case UPDATE_USER_PASSWORD_SUCCESS:
      state.success.updateUserPassword = payload.message;
      return { ...state };
    case CLEAR_UPDATE_USER_PASSWORD_SUCCESS:
      state.success.updateUserPassword = "";
      return { ...state };

    // delete user
    case DELETE_USER_SUCCESS:
      state.success.deleteUser = payload.message;
      return { ...state };

    case DELETE_USER_FAIL:
      state.errors.deleteUser = payload.errors;
      return { ...state };

    case CLEAR_DELETE_USER_SUCCESS:
      state.success.deleteUser = "";
      return { ...state };

    // get bootcamp for all users
    case GET_BOOTCAMP_FOR_USERS_SUCCESS:
      state.success.getBootcampForUsers = payload.message;
      return { ...state };
    case GET_BOOTCAMP_FOR_USERS_FAIL:
      state.errors.getBootcampForUsers = payload.message;
      return { ...state };

    // get user's bootcamp
    case GET_USERS_BOOTCAMP_SUCCESS:
      state.success.getusersBootcamp = payload.message;
      return { ...state };
    case GET_USERS_BOOTCAMP_FAIL:
      state.errors.getusersBootcamp = payload.message;
      return { ...state };

    // post request status to bootcamp after payment
    case PUT_BOOTCAMP_PAYMENT_SUCCESS:
      state.success.putBootcampPayment = payload.message;
      return { ...state };
    case CLEAR_PUT_BOOTCAMP_PAYMENT_SUCCESS:
      state.success.putBootcampPayment = "";
      return { ...state };
    case PUT_BOOTCAMP_PAYMENT_FAIL:
      state.errors.putBootcampPayment = payload.message;
      return { ...state };

    // get specific bootcamp by id
    case GET_SPECIFIC_BOOTCAMP_SUCCESS:
      state.success.getSpecificBootcamp = payload.message;
      return { ...state };
    case GET_SPECIFIC_BOOTCAMP_FAIL:
      state.errors.getSpecificBootcamp = payload.message;
      return { ...state };

    // get all club packages
    case GET_CLUBS_PACKAGES_SUCCESS:
      state.success.getClubsPackages = payload.message;
      return { ...state };
    case GET_CLUBS_PACKAGES_FAIL:
      state.errors.getClubsPackages = payload.message;
      return { ...state };

    // get all club packages
    case GET_SPECIFIC_PACKAGE_SUCCESS:
      state.success.getSpecificPackage = payload.message;
      return { ...state };
    case GET_SPECIFIC_PACKAGE_FAIL:
      state.error.getSpecificPackage = payload.message;
      return { ...state };

    // GET events for users
    case GET_EVENTS_FOR_USERS_SUCCESS:
      state.success.getEventsForUsers = payload.message;
      return { ...state };
    case GET_EVENTS_FOR_USERS_FAIL:
      state.error.getEventsForUsers = payload.message;
      return { ...state };

    // GET user events
    case GET_USER_EVENTS_SUCCESS:
      state.success.getUserEvents = payload.message;
      return { ...state };
    case GET_USER_EVENTS_FAIL:
      state.error.getUserEvents = payload.message;
      return { ...state };

    // GET single events
    case GET_SINGLE_EVENT_SUCCESS:
      state.success.getSingleEvents = payload.message;
      return { ...state };
    case GET_SINGLE_EVENT_FAIL:
      state.error.getSingleEvents = payload.message;
      return { ...state };

  // GET user club subscriptions
  case GET_USER_CLUB_SUBSCRIPTION_SUCCESS:
    state.success.getUserClubSubscriptions = payload.message;
    return { ...state };
  case GET_USER_CLUB_SUBSCRIPTION_FAIL:
    state.error.getUserClubSubscriptions = payload.message;
    return { ...state };

  // PUT user to event
  case PUT_USER_TO_EVENT_SUCCESS:
    state.success.putUserToEvent = payload.message;
    return {...state}
  case CLEAR_PUT_USER_TO_EVENT_SUCCESS:
    state.success.putUserToEvent = "";
    return {...state}
  case PUT_USER_TO_EVENT_FAIL:
    state.errors.putUserToEvent = payload.message;
    return {...state}

  // PUT club subscription payment for user
  case PUT_CLUB_SUBSCRIPTION_PAYMENT_SUCCESS:
    state.success.putClubSubscriptionPayment = payload.message;
    return {...state}
  case CLEAR_CLUB_SUBSCRIPTION_PAYMENT_SUCCESS:
    state.success.putClubSubscriptionPayment = "";
    return {...state}
  case PUT_CLUB_SUBSCRIPTION_PAYMENT_FAIL:
    state.errors.putClubSubscriptionPayment = payload.message;
    return {...state}

  // POST register bootcamp
  case REGISTER_BOOTCAMP_SUCCESS:
    state.success.registerBootcamp = payload.message;
    return {...state}
  case CLEAR_REGISTER_BOOTCAMP_SUCEESS:
    state.success.registerBootcamp = "";
    return {...state}
  case REGISTER_BOOTCAMP_FAIL:
    state.errors.registerBootcamp = payload;
    return {...state}

  // POST register digital marketing bootcamp
  case REGISTER_DIGMARK_BOOTCAMP_SUCCESS:
    state.success.registerDigMarkBootcamp = payload.message;
    return {...state}
  case CLEAR_REGISTER_DIGMARK_BOOTCAMP_SUCEESS:
    state.success.registerDigMarkBootcamp = "";
    return {...state}
  case REGISTER_DIGMARK_BOOTCAMP_FAIL:
    state.errors.registerDigMarkBootcamp = payload;
    return {...state}


  // GET spaces
  case GET_SPACES_SUCCESS:
    state.success.getSpaces = payload.message;
    return { ...state };
  case GET_SPACES_FAIL:
    state.errors.getSpaces = payload.message;
    return { ...state };

  // GET single space
  case GET_SINGLE_SPACE_SUCCESS:
    state.success.getSingleSpace = payload.message;
    return { ...state };
  case GET_SINGLE_SPACE_FAIL:
    state.error.getSingleSpace = payload.message;
    return { ...state };

  // GET user's spaces
  case GET_USER_SPACES_SUCCESS:
    state.success.getUserSpaces = payload.message;
    return { ...state };
  case GET_USER_SPACES_FAIL:
    state.error.getUserSpaces = payload.message;
    return { ...state };

  // POST register for the ciritique for lunch event
  case REGISTER_CRITIQUE_EVENT_SUCCESS:
    state.success.registerCritiqueEvent = payload.message;
    return {...state}
  case CLEAR_REGISTER_CRITIQUE_EVENT_SUCEESS:
    state.success.registerCritiqueEvent = "";
    return {...state}
  case REGISTER_CRITIQUE_EVENT_FAIL:
    state.errors.registerCritiqueEvent = payload;
    return {...state}

  // POST register for the generic event
  case REGISTER_GENERIC_EVENT_SUCCESS:
    state.success.registerGenericEvent = payload.message;
    return {...state}
  case CLEAR_REGISTER_GENERIC_EVENT_SUCEESS:
    state.success.registerGenericEvent = "";
    return {...state}
  case REGISTER_GENERIC_EVENT_FAIL:
    state.errors.registerGenericEvent = payload;
    return {...state}

  // REDESIGN
  // POST register for the ciritique for lunch event
  case STUDENT_REGISTER_SUCCESS:
    state.success.studentRegister = payload.message;
    return {...state}
  case STUDENT_REGISTER_FAIL:
    state.errors.studentRegister = "";
    return {...state}
  case STUDENT_REGISTER_SUCCESS_CLEAR:
    state.success.studentRegister = "";
    return {...state}

  // POST register for the ciritique for lunch event
  case CLUB_REGISTER_SUCCESS:
    state.success.clubRegister = payload.message;
    return {...state}
  case CLUB_REGISTER_FAIL:
    state.errors.clubRegister = "";
    return {...state}
  case CLUB_REGISTER_SUCCESS_CLEAR:
    state.success.clubRegister = "";
    return {...state}

  // BYPASS register Students
  case BYPASS_REGISTER_SUCCESS:
    state.success.bypassRegister = payload.message;
    return {...state}
  case BYPASS_REGISTER_FAIL:
    state.errors.bypassRegister = "";
    return {...state}
  case BYPASS_REGISTER_SUCCESS_CLEAR:
    state.success.bypassRegister = "";
    return {...state}

  // space registration
  case SPACE_REGISTRATION_SUCCESS:
    state.success.spaceRegistration = payload.message;
    return {...state}
  case SPACE_REGISTRATION_FAIL:
    state.errors.spaceRegistration = "";
    return {...state}
  case SPACE_REGISTRATION_SUCCESS_CLEAR:
    state.success.spaceRegistration = "";
    return {...state}

  // space payment 
  case SPACE_PAYMENT_SUCCESS:
    state.success.spacePayment = payload.message;
    return {...state}
  case SPACE_PAYMENT_FAIL:
    state.errors.spacePayment = "";
    return {...state}
  case SPACE_PAYMENT_SUCCESS_CLEAR:
    state.success.spacePayment = "";
    return {...state}

    default:
      return state;
  }
};

export default ajaxStatuses;
