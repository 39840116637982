// dispatching and clearing errors
export const DISPATCH_ERROR = "DISPATCH_ERROR";
export const CLEAR_ERROR_STATE = "CLEAR_ERROR_STATE";

// logout
export const LOGOUT = "LOGOUT";

// registrationn
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_LOADING = "REGISTER_LOADING";
export const REGISTER_SUCCESS_CLEAR = "REGISTER_SUCCESS_CLEAR";

// login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS_CLEAR = "LOGIN_SUCCESS_CLEAR";

// updating user info
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const UPDATE_SUCCESS_CLEAR = "UPDATE_SUCCESS_CLEAR";

// UPDATE USER PASSWORD
export const UPDATE_USER_PASSWORD_REQUEST = "UPDATE_USER_PASSWORD_REQUEST";
export const UPDATE_USER_PASSWORD_FAIL = "UPDATE_USER_PASSWORD_FAIL";
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_LOADING = "UPDATE_USER_PASSWORD_LOADING";
export const CLEAR_UPDATE_USER_PASSWORD_SUCCESS = "CLEAR_UPDATE_USER_PASSWORD_SUCCESS";

// DELETE USER
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_LOADING = "DELETE_USER_LOADING";
export const CLEAR_DELETE_USER_SUCCESS = "CLEAR_DELETE_USER_SUCCESS";

// create bootcamp
export const CREATE_BOOTCAMP_REQUEST = "CREATE_BOOTCAMP_REQUEST";
export const CREATE_BOOTCAMP_FAIL = "CREATE_BOOTCAMP_FAIL";
export const CREATE_BOOTCAMP_SUCCESS = "CREATE_BOOTCAMP_SUCCESS";
export const CREATE_BOOTCAMP_LOADING = "CREATE_BOOTCAMP_LOADING";
export const CREATE_BOOTCAMP_SUCCESS_CLEAR = "CREATE_BOOTCAMP_SUCCESS_CLEAR";

// post bootcamp payment
export const PUT_BOOTCAMP_PAYMENT_REQUEST = "PUT_BOOTCAMP_PAYMENT_REQUEST";
export const PUT_BOOTCAMP_PAYMENT_FAIL = "PUT_BOOTCAMP_PAYMENT_FAIL";
export const PUT_BOOTCAMP_PAYMENT_SUCCESS = "PUT_BOOTCAMP_PAYMENT_SUCCESS";
export const PUT_BOOTCAMP_PAYMENT_LOADING = "PUT_BOOTCAMP_PAYMENT_LOADING";
export const CLEAR_PUT_BOOTCAMP_PAYMENT_SUCCESS =
  "CLEAR_PUT_BOOTCAMP_PAYMENT_SUCCESS";

// GET bootcamp for users
export const GET_BOOTCAMP_FOR_USERS_REQUEST = "GET_BOOTCAMP_FOR_USERS_REQUEST";
export const GET_BOOTCAMP_FOR_USERS_FAIL = "GET_BOOTCAMP_FOR_USERS_FAIL";
export const GET_BOOTCAMP_FOR_USERS_SUCCESS = "GET_BOOTCAMP_FOR_USERS_SUCCESS";
export const GET_BOOTCAMP_FOR_USERS_LOADING = "GET_BOOTCAMP_FOR_USERS_LOADING";

// GET user's for users
export const GET_USERS_BOOTCAMP_REQUEST = "GET_USERS_BOOTCAMP_REQUEST";
export const GET_USERS_BOOTCAMP_FAIL = "GET_USERS_BOOTCAMP_FAIL";
export const GET_USERS_BOOTCAMP_SUCCESS = "GET_USERS_BOOTCAMP_SUCCESS";
export const GET_USERS_BOOTCAMP_LOADING = "GET_USERS_BOOTCAMP_LOADING";

// GET user's specific for users
export const GET_SPECIFIC_BOOTCAMP_REQUEST = "GET_SPECIFIC_BOOTCAMP_REQUEST";
export const GET_SPECIFIC_BOOTCAMP_FAIL = "GET_SPECIFIC_BOOTCAMP_FAIL";
export const GET_SPECIFIC_BOOTCAMP_SUCCESS = "GET_SPECIFIC_BOOTCAMP_SUCCESS";
export const GET_SPECIFIC_BOOTCAMP_LOADING = "GET_SPECIFIC_BOOTCAMP_LOADING";

// GET Club packages
export const GET_CLUBS_PACKAGES_REQUEST = "GET_CLUBS_PACKAGES_REQUEST";
export const GET_CLUBS_PACKAGES_FAIL = "GET_CLUBS_PACKAGES_FAIL";
export const GET_CLUBS_PACKAGES_SUCCESS = "GET_CLUBS_PACKAGES_SUCCESS";
export const GET_CLUBS_PACKAGES_LOADING = "GET_CLUBS_PACKAGES_LOADING";

// GET Specific Club packages
export const GET_SPECIFIC_PACKAGE_REQUEST = "GET_SPECIFIC_PACKAGE_REQUEST";
export const GET_SPECIFIC_PACKAGE_FAIL = "GET_SPECIFIC_PACKAGE_FAIL";
export const GET_SPECIFIC_PACKAGE_SUCCESS = "GET_SPECIFIC_PACKAGE_SUCCESS";
export const GET_SPECIFIC_PACKAGE_LOADING = "GET_SPECIFIC_PACKAGE_LOADING";

// GET events for users
export const GET_EVENTS_FOR_USERS_REQUEST = "GET_EVENTS_FOR_USERS_REQUEST"
export const GET_EVENTS_FOR_USERS_FAIL = "GET_EVENTS_FOR_USERS_FAIL"
export const GET_EVENTS_FOR_USERS_SUCCESS = "GET_EVENTS_FOR_USERS_SUCCESS"
export const GET_EVENTS_FOR_USERS_LOADING = "GET_EVENTS_FOR_USERS_LOADING"

// GET user events
export const GET_USER_EVENTS_REQUEST = "GET_USER_EVENTS_REQUEST"
export const GET_USER_EVENTS_FAIL = "GET_USER_EVENTS_FAIL"
export const GET_USER_EVENTS_SUCCESS = "GET_USER_EVENTS_SUCCESS"
export const GET_USER_EVENTS_LOADING = "GET_USER_EVENTS_LOADING"

// GET specific events
export const GET_SINGLE_EVENT_REQUEST = "GET_SINGLE_EVENT_REQUEST"
export const GET_SINGLE_EVENT_FAIL = "GET_SINGLE_EVENT_FAIL"
export const GET_SINGLE_EVENT_SUCCESS = "GET_SINGLE_EVENT_SUCCESS"
export const GET_SINGLE_EVENT_LOADING = "GET_SINGLE_EVENT_LOADING"

// GET user club subscriptions
export const GET_USER_CLUB_SUBSCRIPTION_REQUEST = "GET_USER_CLUB_SUBSCRIPTION_REQUEST";
export const GET_USER_CLUB_SUBSCRIPTION_FAIL = "GET_USER_CLUB_SUBSCRIPTION_FAIL";
export const GET_USER_CLUB_SUBSCRIPTION_SUCCESS = "GET_USER_CLUB_SUBSCRIPTION_SUCCESS";
export const GET_USER_CLUB_SUBSCRIPTION_LOADING = "GET_USER_CLUB_SUBSCRIPTION_LOADING";

// PUT user to event
export const PUT_USER_TO_EVENT_REQUEST = "PUT_USER_TO_EVENT_REQUEST"
export const PUT_USER_TO_EVENT_SUCCESS = "PUT_USER_TO_EVENT_SUCCESS"
export const PUT_USER_TO_EVENT_FAIL = "PUT_USER_TO_EVENT_FAIL"
export const PUT_USER_TO_EVENT_LOADING = "PUT_USER_TO_EVENT_LOADING"
export const CLEAR_PUT_USER_TO_EVENT_SUCCESS = "CLEAR_PUT_USER_TO_EVENT_SUCCESS"

// PUT club subscription payment
export const PUT_CLUB_SUBSCRIPTION_PAYMENT_REQUEST = "PUT_CLUB_SUBSCRIPTION_PAYMENT_REQUEST";
export const PUT_CLUB_SUBSCRIPTION_PAYMENT_FAIL = "PUT_CLUB_SUBSCRIPTION_PAYMENT_FAIL";
export const PUT_CLUB_SUBSCRIPTION_PAYMENT_SUCCESS = "PUT_CLUB_SUBSCRIPTION_PAYMENT_SUCCESS";
export const PUT_CLUB_SUBSCRIPTION_PAYMENT_LOADING = "PUT_CLUB_SUBSCRIPTION_PAYMENT_LOADING";
export const CLEAR_CLUB_SUBSCRIPTION_PAYMENT_SUCCESS = "CLEAR_CLUB_SUBSCRIPTION_PAYMENT_SUCCESS";

// GET SPACES
export const GET_SPACES_REQUEST = "GET_SPACES_REQUEST"
export const GET_SPACES_FAIL = "GET_SPACES_FAIL"
export const GET_SPACES_SUCCESS = "GET_SPACES_SUCCESS"
export const GET_SPACES_LOADING = "GET_SPACES_LOADING"

// GET SINGLE SPACES
export const GET_SINGLE_SPACE_REQUEST = "GET_SINGLE_SPACE_REQUEST"
export const GET_SINGLE_SPACE_FAIL = "GET_SINGLE_SPACE_FAIL"
export const GET_SINGLE_SPACE_SUCCESS = "GET_SINGLE_SPACE_SUCCESS"
export const GET_SINGLE_SPACE_LOADING = "GET_SINGLE_SPACE_LOADING"

// GET USER'S SPACE
export const GET_USER_SPACES_REQUEST = "GET_USER_SPACES_REQUEST"
export const GET_USER_SPACES_SUCCESS = "GET_USER_SPACES_SUCCESS"
export const GET_USER_SPACES_FAIL = "GET_USER_SPACES_FAIL"
export const GET_USER_SPACES_LOADING = "GET_USER_SPACES_LOADING"

// REGISTER BOOTCAMP
export const REGISTER_BOOTCAMP_REQUEST = "REGISTER_BOOTCAMP_REQUEST"
export const REGISTER_BOOTCAMP_SUCCESS = "REGISTER_BOOTCAMP_SUCCESS"
export const REGISTER_BOOTCAMP_FAIL = "REGISTER_BOOTCAMP_FAIL"
export const REGISTER_BOOTCAMP_LOADING = "REGISTER_BOOTCAMP_LOADING"
export const CLEAR_REGISTER_BOOTCAMP_SUCEESS = "CLEAR_REGISTER_BOOTCAMP_SUCEESS" 

// REGISTER BOOTCAMP
export const REGISTER_DIGMARK_BOOTCAMP_REQUEST = "REGISTER_DIGMARK_BOOTCAMP_REQUEST"
export const REGISTER_DIGMARK_BOOTCAMP_SUCCESS = "REGISTER_DIGMARK_BOOTCAMP_SUCCESS"
export const REGISTER_DIGMARK_BOOTCAMP_FAIL = "REGISTER_DIGMARK_BOOTCAMP_FAIL"
export const REGISTER_DIGMARK_BOOTCAMP_LOADING = "REGISTER_DIGMARK_BOOTCAMP_LOADING"
export const CLEAR_REGISTER_DIGMARK_BOOTCAMP_SUCEESS = "CLEAR_REGISTER_DIGMARK_BOOTCAMP_SUCEESS" 

// REGISTER for the critique and lunch event
export const REGISTER_CRITIQUE_EVENT_REQUEST = "REGISTER_CRITIQUE_EVENT_REQUEST"
export const REGISTER_CRITIQUE_EVENT_SUCCESS = "REGISTER_CRITIQUE_EVENT_SUCCESS"
export const REGISTER_CRITIQUE_EVENT_FAIL = "REGISTER_CRITIQUE_EVENT_FAIL"
export const REGISTER_CRITIQUE_EVENT_LOADING = "REGISTER_CRITIQUE_EVENT_LOADING"
export const CLEAR_REGISTER_CRITIQUE_EVENT_SUCEESS = "CLEAR_REGISTER_CRITIQUE_EVENT_SUCEESS" 

// REGISTER  for event: GENERIC - EVENT
export const REGISTER_GENERIC_EVENT_REQUEST = "REGISTER_GENERIC_EVENT_REQUEST"
export const REGISTER_GENERIC_EVENT_SUCCESS = "REGISTER_GENERIC_EVENT_SUCCESS"
export const REGISTER_GENERIC_EVENT_FAIL = "REGISTER_GENERIC_EVENT_FAIL"
export const REGISTER_GENERIC_EVENT_LOADING = "REGISTER_GENERIC_EVENT_LOADING"
export const CLEAR_REGISTER_GENERIC_EVENT_SUCEESS = "CLEAR_REGISTER_GENERIC_EVENT_SUCEESS" 

// REDESIGNED - DASHBOARD
// Student registration
export const STUDENT_REGISTER_SUCCESS = "STUDENT_REGISTER_SUCCESS";
export const STUDENT_REGISTER_FAIL = "STUDENT_REGISTER_FAIL";
export const STUDENT_REGISTER_REQUEST = "STUDENT_REGISTER_REQUEST";
export const STUDENT_REGISTER_LOADING = "STUDENT_REGISTER_LOADING";
export const STUDENT_REGISTER_SUCCESS_CLEAR = "STUDENT_REGISTER_SUCCESS_CLEAR";

// Club registration
export const CLUB_REGISTER_SUCCESS = "CLUB_REGISTER_SUCCESS";
export const CLUB_REGISTER_FAIL = "CLUB_REGISTER_FAIL";
export const CLUB_REGISTER_REQUEST = "CLUB_REGISTER_REQUEST";
export const CLUB_REGISTER_LOADING = "CLUB_REGISTER_LOADING";
export const CLUB_REGISTER_SUCCESS_CLEAR = "CLUB_REGISTER_SUCCESS_CLEAR";

// BY-PASS registration
export const BYPASS_REGISTER_SUCCESS = "BYPASS_REGISTER_SUCCESS";
export const BYPASS_REGISTER_FAIL = "BYPASS_REGISTER_FAIL";
export const BYPASS_REGISTER_REQUEST = "BYPASS_REGISTER_REQUEST";
export const BYPASS_REGISTER_LOADING = "BYPASS_REGISTER_LOADING";
export const BYPASS_REGISTER_SUCCESS_CLEAR = "BYPASS_REGISTER_SUCCESS_CLEAR";

// space registration
export const SPACE_REGISTRATION_SUCCESS = "SPACE_REGISTRATION_SUCCESS";
export const SPACE_REGISTRATION_FAIL = "SPACE_REGISTRATION_FAIL";
export const SPACE_REGISTRATION_REQUEST = "SPACE_REGISTRATION_REQUEST";
export const SPACE_REGISTRATION_LOADING = "SPACE_REGISTRATION_LOADING";
export const SPACE_REGISTRATION_SUCCESS_CLEAR = "SPACE_REGISTRATION_SUCCESS_CLEAR";

// book space payment
export const SPACE_PAYMENT_SUCCESS = "SPACE_PAYMENT_SUCCESS";
export const SPACE_PAYMENT_FAIL = "SPACE_PAYMENT_FAIL";
export const SPACE_PAYMENT_REQUEST = "SPACE_PAYMENT_REQUEST";
export const SPACE_PAYMENT_LOADING = "SPACE_PAYMENT_LOADING";
export const SPACE_PAYMENT_SUCCESS_CLEAR = "SPACE_PAYMENT_SUCCESS_CLEAR";