import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import Body from '../../../../components/Redesign/Webpage/Login/Body'
import Footer from '../../../../components/Redesign/Webpage/Login/Footer'
import Header from '../../../../components/Redesign/Webpage/Login/Header'
import LoginForm from '../../../../components/Redesign/Webpage/Login/LoginForm'

const Login = () => {

    // OBJECT INITIALIZATION
    const history = useHistory();


    // STATES
    const { isLoggedIn, user } = useSelector((state) => {
        const { isLoggedIn } = state.userData;
        const { user } = state.userData;
        return {
            isLoggedIn,
            user,
        };
    });

    return (
        <div className='flex flex-col w-full min-h-screen'>

            {
                (Object.keys(user).length !== 0) ?
                <Redirect
                to={"/dashboard/bootcamps"} />:
                <>
                    {/* body */}
                    <Body>
                        <LoginForm />
                    </Body>

                    {/* footer */}
                    <Footer />
                </>
            }
        </div>
    )
}

export default Login