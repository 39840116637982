import React from 'react'
import aShape1 from "../../../../assets/webpages/home/images/ashape1.png"
import aShape2 from "../../../../assets/webpages/home/images/a-shape2.png"
import kachiLogo from "../../../../assets/webpages/home/images/kachi-logo.png"
import kachiDp from "../../../../assets/webpages/home/images/kachi-dp.png"
import { HiAcademicCap } from "react-icons/hi"
import { FaUsers } from "react-icons/fa"
import { IoMdArrowForward } from "react-icons/io"
import AboutCard from './AboutCard'
import { Link } from 'react-router-dom'
import Video2 from '../../../Home/Video2'

const AboutUs = () => {
    return (
        <div className='flex items-center flex-col container mx-auto w-[85%] lg:w-full mobile:w-full gap-20 pb-20 mobile:px-4'>

            {/* heading texts */}
            <div className='w-[60%] mobile:w-full flex flex-col gap-3 text-center items-center'>
                <p className='text-[#0B8CBA] font-semibold text-base leading-none mobile:font-bold mobile:text-xs'>
                    ABOUT US
                </p>

                <div className='text-4xl mobile:text-2xl text-black font-semibold leading-none relative w-fit'>

                    <span>More than a tech community</span>

                    {/* shape */}
                    <img src={aShape1} className={"mobile:w-[36px] absolute top-[-2rem] right-[-3rem] mobile:right-[1rem] mobile:top-[-1.8rem]"} alt="" />
                </div>

                <p className='text-gray-800 text-xl mobile:text-base font-normal'>
                    We are not just about technology. We are enthusiastic about what the future holds, and our focus is on using technology to create a better future for everyone.
                </p>

                <p className='font-medium text-xl text-black leading-none mobile:text-base'>
                    Here’s how we plan on doing that
                </p>
            </div>

            {/* About cards */}
            <div className='grid grid-cols-3 mobile:grid-cols-[auto] gap-5 items-center container mx-auto pc:px-6'>

                <AboutCard
                icon={<HiAcademicCap className='text-3xl' color='#000000' />}
                title="Make tech education accessible"
                description={"AI Movement offers tech training bootcamps both online and physically to train the next generation of tech experts."}
                linkText="View available courses"
                linkPath={"/training"} />

                <AboutCard
                icon={<FaUsers className='text-3xl' color='#000000' />}
                title="Community building and Networking"
                description={"Everyone can learn how to code, everyone can learn how to invest in the next big thing,but not everyone has the courage to say hello to their next-door neighbor, or ability to spot the next big trend, or know how to begin riding this massive tech wave. So these are the reasons why you should consider becoming a member or student."}
                linkText="Become a member"
                linkPath={"/club"} />

                <AboutCard
                icon={<FaUsers className='text-3xl' color='#000000' />}
                title="Diverse Investment opportunities"
                description={"We curate the best and most viable startups with a high probability of profitable ROI for our investors."}
                linkText="View available Options"
                linkPath={"/investing"} />
            </div>

            {/* Co working space information */}
            <div className='flex flex-col gap-5 relative border-[4px] border-white rounded-[10px] w-[50%] p-[48px] mobile:px-5 items-center text-center mobile:w-full'>

                {/* love shape */}
                <img src={aShape2} className={"absolute bottom-[50%] translate-y-[50%] right-[5%] mobile:w-[17px]"} alt="" />

                {/* text */}
                <p className='text-black font-semibold mobile:font-medium text-xl'>
                    We also offer Co-working Spaces.
                </p>

                <Link
                to={"/spaces"}
                className='flex gap-2 items-center justify-center h-[44px] border border-[#0B8CBA] rounded-[8px] px-[20px] hover:no-underline hover:bg-[#0b8bba20]'>
                    <span 
                    className='text-[#055774] font-semibold text-base no-underline'>
                        Check out our spaces
                    </span>
 
                    <IoMdArrowForward
                    className='text-xl text-black' />
                </Link>
            </div>

            {/* CE0 video introduction */}
            <div className='flex flex-col gap-3 w-full items-center'>
                <Video2 />

                <div className='flex flex-col gap-5 w-[68%] mx-auto items-center'>

                    <p className='text-black mobile:text-[#3F444D] font-normal text-xl mobile:font-semibold mobile:text-xs text-center'>
                        <span className="text-[#8B54F7] mobile:text-[#3F444D]">AI Movement’s</span> aim is simple, to move us from where we currently are into a new invigorating tech future.
                    </p>

                    <img className='w-[72px] h-[72px] mobile:h-[64px] mobile:w-[64px] rounded-[50%] object-cover' src={kachiDp} alt="" />

                    <div className='flex flex-col gap-1 items-center'>

                        <p className='text-[#101828] font-semibold text-base text-center'>
                            Kachi Aghasili
                        </p>

                        <div className='flex items-center justify-center gap-3'>
                            <p className='text-gray-500 font-normal text-base mobile:text-sm'>
                                Founder & CEO
                            </p>

                            <img src={kachiLogo} className='h-[38px] w-[141px] mobile:w-[103px] mobile:h-[30px]' alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs