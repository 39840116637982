import React from 'react'
import { useHistory } from 'react-router-dom'
import ArrowBtn from '../../Bootcamps/ArrowBtn'
import InvestmentCard from '../InvestmentCard'

const PackageFeature = ({disableRouting}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()


    // HANDLERS
    const handleClick = () => {
        history.push("/dashboard/investing/active-investment")
    }

    return (
        <div className='gap-5 flex flex-col'>

            {/* caption and link */}
            <div className='flex w-full justify-between items-center'>

                {/* caption */}
                <p className='font-medium text-base text-[#1D2433]'>
                    Advanced Package Features
                </p>

                {/* arrow botton for pc view */}
                {
                    !disableRouting &&
                    <ArrowBtn
                    text='Learn more'
                    onClick={handleClick}
                    className="mobile:hidden" />
                }
            </div>

            {/* cards */}
            <div className='grid grid-cols-3 mobile:grid-cols-1 gap-5'>

                <InvestmentCard />
                <InvestmentCard />
                <InvestmentCard />
            
            </div>

            {/* arrow botton for mobile view */}
            {
                !disableRouting &&
                <ArrowBtn
                text='Learn more'
                onClick={handleClick}
                className="hidden mobile:inline-flex" />
            }
        </div>
    )
}

export default PackageFeature