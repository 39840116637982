import React from 'react'
import ProfileAvatar from '../Home/ProfileAvatar'
import ProfileAccount from './ProfileAccount'

const SettingsBody = () => {
    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>

            {/* header */}
            <header className='container mx-auto mobile:px-4 flex justify-between items-center'>
                <div>
                    <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                        User Profile
                    </p>
                </div>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* profile settings section */}
            <section className='w-full bg-white mobile:bg-transparent rounded-[10px] mt-9 mobile:mt-0 container mx-auto'>
                <ProfileAccount />
            </section>                       
        </section>
    )
}

export default SettingsBody