import React from 'react'
import { NavLink } from 'react-router-dom'

const MyLink = ({to, children}) => {

    return (
        <>
            <NavLink exact to={to} className="text-[#667085] mobile:text-[#101828] font-semibold text-base lg:text-sm xl:text-xs 2xl:text-lg flex justify-center items-center transition-all hover:no-underline hover:text-[#6941C6] hover:bg-[#F9F5FF] rounded-[8px] mobile:rounded-none h-[44px] px-5 outline-none mobile:justify-start"
            activeClassName='pc:[outline:1px_solid_#d0d5dd] mobile:text-[#6941C6] mobile:bg-[#F8F5FF]'>
                {children}
            </NavLink>
        </>
    )
}

export default MyLink