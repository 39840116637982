import BtnPrimary from '../../../../Global/Redesign/BtnPrimary'
import { FormError } from '../../../../Errors'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from "yup"
import logo from "../../../../../assets/bootcamp-form/images/form-logo.png"
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { spaceRegistrationRequest } from '../../../../../redux/action'

const RegistrationForm = ({
    closeModal, 
    toggleSpaceModal,
    setHasReg,
    setUserDetails
}) => {

    // STATES
    const {
        spaceRegistrationLoading,
        spaceRegistrationSuccess,
        spaceRegistrationError
    } = useSelector((state) => {

        const {
            success: { 
                spaceRegistration: spaceRegistrationSuccess 
            },
            errors: { 
                spaceRegistration: spaceRegistrationError 
            },
        } = state.ajaxStatuses;
        const { 
            spaceRegistrationLoading
        } = state.loadingIndicator;
    
        return {
            spaceRegistrationLoading,
            spaceRegistrationSuccess,
            spaceRegistrationError
        };
    });


    // OBJECT INITIALIZATION
    const dispatch = useDispatch()
    const history = useHistory()
    const formik = useFormik({
        initialValues: {
            firstname: "",
            lastname: "",
            mobile: "",
            email: "",
            occupation: "",
            password: "",
            password_confirmation: "",
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required("First name is required").min(3).max(15),
            lastname: Yup.string().required("First name is required").min(3).max(15),
            mobile: Yup.string().required("Mobile number is required").min(10).max(14),
            email: Yup
                .string()
                .email("Email is not valid")
                .required("Email is required"),
            occupation: Yup.string(),
            password: Yup.string().required("Password is required"),
            password_confirmation: Yup.string().required("Password is required"),
        }),
        onSubmit: (formData) => {
            setUserDetails(userDetails=>({
                name: `${formData.firstname} ${formData.lastname}`,
                phone: formData.mobile,
                email: formData.email
            }))
            dispatch(spaceRegistrationRequest(formData))
        },
    });


    // SIDE EFFECTS
    useEffect(() => {
        if (spaceRegistrationError) {
            toast.error(spaceRegistrationError, {
                duration: 3000,
            });
        }
    }, [spaceRegistrationError]);
    
      // success message
      useEffect(() => {
        if (spaceRegistrationSuccess) {
            toast.success(spaceRegistrationSuccess, {
                duration: 3000,
            });

            // toggle MAKE PAYMENT modal
            setHasReg(true)
            closeModal()
            toggleSpaceModal()
        }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spaceRegistrationSuccess]);

    return (
        <div className='flex flex-col h-full w-full py-12 gap-8'>

            {/* container */}
            <div className='w-full flex-col flex gap-3 items-center'>

                <img src={logo} className='w-[141px]' alt="" />

                {/* title */}
                <p className="font-semibold mobile:text-xl text-3xl text-[#1D2433] leading-none">
                    Create your account
                </p>
            </div>

            <form 
            onSubmit={(e)=>e.preventDefault()}
            className="flex flex-col justify-center items-center w-full gap-5">

                {/* FORM CONTROLS */}
                {/* first name */}
                <label className="flex flex-col mobile:w-full w-[360px] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        First name
                    </span>

                    <input
                    type="text"
                    {...formik.getFieldProps("firstname")}
                    className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                    placeholder="Enter your first name" />

                    {formik.errors.firstname && formik.touched.firstname && (
                        <FormError message={formik.errors.firstname} />
                    )}
                </label>

                {/* last name */}
                <label className="flex flex-col mobile:w-full w-[360px] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        Last name
                    </span>

                    <input
                    type="text"
                    {...formik.getFieldProps("lastname")}
                    className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                    placeholder="Enter your last name" />

                    {formik.errors.lastname && formik.touched.lastname && (
                        <FormError message={formik.errors.lastname} />
                    )}
                </label>

                {/* email address */}
                <label className="flex flex-col mobile:w-full w-[360px] gap-2">
                    <span className="text-[#344054] text-sm font-semibold">Email</span>

                    <input
                    type="email"
                    {...formik.getFieldProps("email")}
                    className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                    placeholder="Enter your email" />

                    {formik.errors.email && formik.touched.email && (
                        <FormError message={formik.errors.email} />
                    )}
                </label>

                {/* phone number */}
                <label className="flex flex-col mobile:w-full w-[360px] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        Phone number
                    </span>

                    <input
                        type="text"
                        {...formik.getFieldProps("mobile")}
                        className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                        placeholder="Enter your phone number"
                    />

                    {formik.errors.mobile && formik.touched.mobile && (
                        <FormError message={formik.errors.mobile} />
                    )}
                </label>

                {/* reason */}
                <label className="flex flex-col mobile:w-full w-[360px] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        Occupation
                    </span>

                    <input
                    type="text"
                    {...formik.getFieldProps("occupation")}
                    className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                    placeholder="Why do you want to join us" />

                    {formik.errors.occupation && formik.touched.occupation && (
                        <FormError message={formik.errors.occupation} />
                    )}
                </label>

                {/* password */}
                <label className="flex flex-col mobile:w-full w-[360px] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        Password
                    </span>

                    <div className="flex flex-col gap-1">

                        <input
                        type="password"
                        {...formik.getFieldProps("password")}
                        className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                        placeholder="Create a password"
                        />
                        <span className="font-normal text-sm text-[#667085]">
                            Must be at least 8 characters.
                        </span>

                    </div>

                    {formik.errors.password && formik.touched.password && (
                        <FormError message={formik.errors.password} />
                    )}
                </label>

                {/* confirm password */}
                <label className="flex flex-col mobile:w-full w-[360px] gap-2">

                    <span className="text-[#344054] text-sm font-semibold">
                        Confirm Password
                    </span>

                    <div className="flex flex-col gap-1">

                        <input
                        type="password"
                        {...formik.getFieldProps("password_confirmation")}
                        className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                        placeholder="Confirm your password"
                        />
                        <span className="font-normal text-sm text-[#667085]">
                            Must be at least 8 characters.
                        </span>

                    </div>

                    {formik.errors.password_confirmation && formik.touched.password_confirmation && (
                        <FormError message={formik.errors.password_confirmation} />
                    )}
                </label>

                {/* sign up button */}
                <div className="flex flex-col items-stretch mobile:w-full w-[360px] gap-5">

                    {/* sign up */}
                    { 
                        <BtnPrimary
                        title={"Sign up"}
                        loading={spaceRegistrationLoading}
                        onClick={formik.handleSubmit} />
                    }
                    
                </div>

                {/* sign up/register */}
                <div className="text-[#667085] text-sm font-normal flex gap-[5px] mt-2">
                    <span>Already have an account?</span>

                    <span 
                    onClick={()=>{
                        closeModal()
                        history.push("/login")
                    }} className="font-semibold text-sm text-[#0B8CBA]">
                        Log in
                    </span>
                </div>
            </form>
        </div>
    )
}

export default RegistrationForm