import React from 'react'
import BtnPrimary from '../../../Global/Redesign/BtnPrimary'
import Header2 from '../../../../layouts/Redesign/Header2'
import shape1 from "../../../../assets/webpages/aboutus/images/s-shape1.png"
import shape2 from "../../../../assets/webpages/aboutus/images/s-shape2.png"
import shape3 from "../../../../assets/webpages/aboutus/images/s-shape3.png"

const AboutHeader = () => {
    return (
        <div className='flex items-center justify-start bg-[#F0F5FF] h-[75vh] 2xl:h-[86vh] mobile:h-[67vh] relative flex-col top-0'>

            {/* header */}
            <Header2 />

            {/* SHAPES */}
            {/* shape1 */}
            <img src={shape1} className="mobile:w-[20px] absolute top-[30%] left-[15%] mobile:left-[2rem] mobile:top-[7.5rem]" alt="" />

            {/* shape2 */}
            <img src={shape2} className="mobile:w-[20px] absolute bottom-[32%] right-[15%] mobile:right-[7%] mobile:bottom-[60%]" alt="" />

            {/* shape3 */}
            <img src={shape3} className="mobile:w-[20px] absolute bottom-[2rem] translate-x-[50%] right-[50%]" alt="" />

            {/* jumbotron */}
            <div className='w-full flex flex-col items-center top-[20%] mobile:top-[25%] relative z-[200] gap-4 text-center container mx-auto mobile:px-4'>

                <p className='font-semibold text-[45px] mobile:text-4xl text-[#1D2433]'>
                    So you want to hear our story...
                </p>

                <p className='text-[#1D2433] text-2xl font-normal mobile:font-semibold mobile:text-[22px]'>
                    Well we enjoy telling it too!!
                </p>

                <p className='text-gray-800 text-base font-normal pc:w-[45%] mb-4 mobile:text-gray-500'>
                    AI Movement is a community of private investors, institutional investors, and technology experts. We seek to identify, understand, and resolve core humanitarian issues through advanced technology.
                </p>
            </div>
        </div>
    )
}

export default AboutHeader