import { useState } from 'react'
import { RiArrowDownSFill } from "react-icons/ri"

const Accordion = ({label, couseTitle, courseItems}) => {

    const [isOpen, setIsOpen] = useState(false)

    const toggleAccordion = () => {
        setIsOpen(!isOpen)
    }

    // dynamic styles
    const arrowStyle = isOpen? "rotate-180":"rotate-0"

    return (
        <div className='flex flex-col gap-5 pb-4 border-b-[1.5px] border-[#E1E6Ef]'>

            {/* Accordion Header */}
            <div className='flex justify-between items-center'>
                <div className='flex flex-col'>
                    <p className='text-[#1d2433] opacity-[65%] text-sm font-semibold'>
                        Course Overview
                    </p>
                    <p className='text-xs text-[#8b54f7] font-semibold'>
                        {couseTitle}
                    </p>
                </div>

                <span 
                onClick={toggleAccordion}
                className='cursor-pointer h-[32px] flex justify-center items-center w-[32px] hover:bg-[#e9e5e5] [transition:color_.4s_ease-in-out] rounded-[50%]'>

                    <RiArrowDownSFill 
                    color='#5c5f62' 
                    className={`[transition:translate_.4s_ease-in-out] ${arrowStyle}`}
                    size={18} />
                </span>
            </div>


            {/* Accordion items */}
            {
                isOpen?
                <div className='pl-12 pt-1'>
                    <ul className='text-[#1d2433] opacity-[0.65] flex flex-col gap-3 text-sm font-normal list-disc'>
                        {
                            courseItems.map((item, index)=>(
                                <li key={index}>{item}</li>
                            ))
                        }
                    </ul>
                </div>
                :null
            }
        </div>
    )
}

export default Accordion