import React, { useEffect, useState } from 'react'
import MyModal from '../../../../layouts/MyModal'
import BtnPrimary from '../../../Global/Redesign/BtnPrimary'
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { FormError } from '../../../Errors';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SavedAccountView from './SavedAccountView';
import DeleteAccountBtn from './DeleteAccountBtn';
import { updateUserPasswordRequest } from '../../../../redux/action';

const EditPasswordForm = () => {

    //  OBJECT INITIALIZATION
    const dispatch = useDispatch()


    // STATES
    const [savedAccountInfo, setSavedAccountInfo] = useState(false);
    const {
        updateUserPasswordSuccess,
        updateUserPasswordError,
        updateUserPasswordLoading,
        user,
        token,
      } = useSelector((state) => {
        const {
            success: { 
                updateUserPassword: updateUserPasswordSuccess 
            },
            errors: { 
                updateUserPassword: updateUserPasswordError 
            },
        } = state.ajaxStatuses;
    
        const { updateUserPasswordLoading } = state.loadingIndicator;
    
        const { user, token, singleUser } = state.userData;
    
        return {
          updateUserPasswordSuccess,
          updateUserPasswordError,
          updateUserPasswordLoading,
          user,
          token,
          singleUser,
        };
    });


    // HANDLERS
    const toggleSavedAccountInfo = () => {
        setSavedAccountInfo(!savedAccountInfo);
    };


    // SIDE EFFECTS    
    // checking for errors
    useEffect(() => {
        if (updateUserPasswordError) {
            toast.error(updateUserPasswordError, {
                duration: 3000,
            });
        }
    }, [updateUserPasswordError]);
    
    //   checking for success
    useEffect(() => {
        if (updateUserPasswordSuccess) {
            toggleSavedAccountInfo();
            setTimeout(() => {
                setSavedAccountInfo(false);
            }, 2000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateUserPasswordSuccess]);

    return (
        <Formik
        enableReinitialize
        initialValues= {{
            password: "",
            newPassword: "",
            repeatedPassword: ""
        }}
        validationSchema= {
            Yup.object({
                password: Yup.string().required(),
                newPassword: Yup.string().required(),
                repeatedPassword: Yup.string().required()
            }
        )}
        onSubmit= {({password, newPassword, repeatedPassword}) => {
            const formData = {
                password,
                newPassword
            }
            if(newPassword === repeatedPassword) {
                // console.log(formData);
                dispatch(updateUserPasswordRequest({ formData, token }));
            } else {
                toast.error("newPassword doesn't match repeatedPassword!", {
                    duration: 3000
                })
            }
        }}>
            {
                ({
                    values,
                    dirty,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    field,
                }) => {
                    return (
                        <div className='flex flex-col gap-4 w-full'>
                            
                            {/* form container */}
                            <div className='pb-5 border-b border-[#E1E6EF] flex justify-start items-start w-full'>
                                <form
                                className='flex flex-col gap-5 pt-2 w-[55%] mobile:w-full'
                                onSubmit={(e)=>e.preventDefault()}>

                                    {/* form caption */}
                                    <p className='font-semibold text-xl text-[#3F444D] mobile:font-medium mobile:text-base'>
                                        Change Password
                                    </p>

                                    {/* old password input container */}
                                    <label className='flex flex-col gap-2'>

                                        {/* label text */}
                                        <span className='font-semibold text-sm text-[#344054]'>
                                            Old password
                                        </span>

                                        {/* input field */}
                                        <Field 
                                        name={"password"}
                                        value={values.password}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="password" 
                                        className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                                        placeholder='Enter old password' />

                                        {errors.password && 
                                        touched.password && 
                                        (
                                            <FormError message={errors.password} />
                                        )}
                                    </label>

                                    {/* new password input container */}
                                    <label className='flex flex-col gap-2'>

                                        {/* label text */}
                                        <span className='font-semibold text-sm text-[#344054]'>
                                            New password
                                        </span>

                                        {/* input field */}
                                        <Field 
                                        name={"newPassword"}
                                        value={values.newPassword}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="password" 
                                        className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                                        placeholder='Enter new password' />

                                        {errors.newPassword && 
                                        touched.newPassword && 
                                        (
                                            <FormError message={errors.newPassword} />
                                        )}
                                    </label>

                                    {/* repeat new password input container */}
                                    <label className='flex flex-col gap-2'>

                                        {/* label text */}
                                        <span className='font-semibold text-sm text-[#344054]'>
                                            Repeat new password
                                        </span>

                                        {/* input field */}
                                        <Field 
                                        name={"repeatedPassword"}
                                        value={values.repeatedPassword}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="password" 
                                        className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                                        placeholder='Repeat new password' />

                                        {errors.repeatedPassword && 
                                        touched.repeatedPassword && 
                                        (
                                            <FormError message={errors.repeatedPassword} />
                                        )}
                                    </label>

                                    {/* save info button */}
                                    <BtnPrimary
                                    loading={updateUserPasswordLoading}
                                    onClick={handleSubmit}
                                    title={"Save changes"} />
                                </form>
                            </div>

                            {/* delete account */}
                            <DeleteAccountBtn />

                            {/* modal for  saved success message */}
                            <MyModal
                            disableBtn={true}
                            modalIsOpen={savedAccountInfo}
                            toggleModal={toggleSavedAccountInfo}>

                                <SavedAccountView 
                                closeModal={toggleSavedAccountInfo} />
                            </MyModal>
                        </div>
                        )
                    }
                }
        </Formik>
    )
}

export default EditPasswordForm