import {
    GET_EVENTS_FOR_USERS_SUCCESS,
    GET_USER_EVENTS_SUCCESS,
    GET_SINGLE_EVENT_SUCCESS
} from "../types"

const initialState = () => ({
    events: [],
    singleEvents: {},
    userEvents: []
})

const eventsReducer = (state = initialState(), {type, payload}) => {

    switch (type) {

        case GET_EVENTS_FOR_USERS_SUCCESS:
            return {
                ...state,
                events: payload.event
            }
            
        case GET_USER_EVENTS_SUCCESS:
            return {
                ...state,
                userEvents: payload.events
            }

        case GET_SINGLE_EVENT_SUCCESS:
            return {
                ...state,
                singleEvents: payload.event
            }

        default:
            return state;
    }
}

export default eventsReducer;