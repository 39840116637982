import { useEffect, useState } from "react";
import BtnPrimary from "../../../../Global/Redesign/BtnPrimary";
import { usePaystackPayment } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { studentByPassRegisterRequest } from "../../../../../redux/action";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PaymentVerificationView = ({ 
    closeModal, 
    formik,
    price,
    setReferenceId,
    bootcampId
}) => {

    // STATES
    const [codeByPass, setCodeByPass] = useState("")
    const {
        bypassRegisterSuccess,
        bypassRegisterError,
        bypassRegisterLoading,
    } = useSelector((state) => {

        // ajax statuses
        const {
            success: { 
                bypassRegister: bypassRegisterSuccess 
            },
            errors: { 
                bypassRegister: bypassRegisterError
            },
        } = state.ajaxStatuses;

        // loading indicator
        const { 
            bypassRegisterLoading 
        } = state.loadingIndicator;
    
        return {
            bypassRegisterSuccess,
            bypassRegisterError,
            bypassRegisterLoading,
        }
    })


    // DATA INITIALIZATION
    const history = useHistory()
    const dispatch = useDispatch()
    const config = {
        email: formik.getFieldProps("email").value,
        amount: Number(price) * 100,
        metadata: {
            name: formik.getFieldProps("firstname").value + " " + formik.getFieldProps("lastname").value,
            phone: formik.getFieldProps("mobile").value,
        },
        publicKey: `${process.env.REACT_APP_LIVE_PAYSTACK_KEY}`,
    }
    const initializePayment = usePaystackPayment(config)


    // HANDLERS
    // paystack handlers
    const onSuccess = (e) => {
        closeModal()
        setReferenceId(e.reference)
        formik.handleSubmit()
    }
    const onClose = () => {}
    const handleByPassReg = () => {

        // setting formData
        const {reference: _,...formData} = formik.values
        formData.bootcamp_id = bootcampId
        formData.occupation = "student"

        // making call
        dispatch(studentByPassRegisterRequest(formData))
    }


    // SIDE EFFECT
    // check for errors
    useEffect(() => {
        if (bypassRegisterError) {
            toast.error(bypassRegisterError, {
                duration: 3000,
            });
        }
    }, [bypassRegisterError]);

    // success message
    useEffect(() => {
        if (bypassRegisterSuccess) {
            toast.success(bypassRegisterSuccess, {
                duration: 3000,
            });
            // onByPassSuccess()
            history.replace("/dashboard/bootcamps")
            console.log("oh yea, success!")
        }
    }, [bypassRegisterSuccess]);

    return (
        <div className="flex flex-col w-full bg-white gap-8 p-6 mobile:p-3 mobile:pt-5">

            {/* title */}
            <h3 className="text-[2xl] font-semibold text-[#1D2433] text-center mobile:text-lg">
                Payment verification
            </h3>

            {/* first button card */}
            <div 
            onClick={()=>{
                initializePayment(onSuccess, onClose)
            }}
            className="bg-white p-3 flex flex-col gap-4 cursor-pointer hover:bg-gray-50 [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] rounded-[10px]">
                {/* title */}
                <h2 className="font-semibold text-sm text-[#055774]">
                Proceed to Online payment page
                </h2>

                {/* text */}
                <h4 className="text-xs font-normal text-[#3F444D]">
                For first time users who have not made any payment yet. Payment
                validates your access into the academy and your personalized
                dashboard.
                </h4>
            </div>

            {/* second button card */}
            <div className='bg-white p-3 flex flex-col gap-4 [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] rounded-[10px]'>

                {/* title */}
                <h2 className='font-semibold text-sm text-[#055774]'>
                    I have my unique code
                </h2>

                {/* text */}
                <h4 className='text-xs font-normal text-[#3F444D]'>
                    For students that have paid physically at the office. Input the code sent to your email to gain access to your dashboard.
                </h4>

                <input
                value={codeByPass}
                onChange={(e) => setCodeByPass(e.target.value)}
                type="text"
                className="border border-[#d0d5dd] font-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] w-full"
                placeholder="Enter code here"
                />

                <BtnPrimary
                onClick={()=>handleByPassReg()}
                loading={bypassRegisterLoading}
                disabled={codeByPass !== formik.values.email || bypassRegisterLoading} 
                title="Take me to my dashboard" />
            </div>
        </div>
    );
};

export default PaymentVerificationView;
