import React, { useEffect, useState } from 'react'
import MyModal from '../../../../layouts/MyModal'
import BtnPrimary from '../../../Global/Redesign/BtnPrimary'
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { FormError } from '../../../Errors';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import SavedAccountView from './SavedAccountView';
import DeleteAccountBtn from './DeleteAccountBtn';
import { updateUserRequest } from '../../../../redux/action';

const EditProfileForm = () => {

    //  OBJECT INITIALIZATION
    const dispatch = useDispatch()


    // STATES
    const [savedAccountInfo, setSavedAccountInfo] = useState(false);

    // redux state
    const {
        updateUserSuccess,
        updateUserError,
        updateUserLoading,
        user,
        token,
      } = useSelector((state) => {
        const {
          success: { updateUser: updateUserSuccess },
          errors: { updateUser: updateUserError },
        } = state.ajaxStatuses;
    
        const { updateUserLoading } = state.loadingIndicator;
    
        const { user, token, singleUser } = state.userData;
    
        return {
          updateUserSuccess,
          updateUserError,
          updateUserLoading,
          user,
          token,
          singleUser,
        };
    });


    // HANDLERS
    const toggleSavedAccountInfo = () => {
        setSavedAccountInfo(!savedAccountInfo);
    };


    // SIDE EFFECTS    
    // checking for errors
    useEffect(() => {
        if (updateUserError) {
            toast.error(updateUserError, {
                duration: 3000,
            });
        }
    }, [updateUserError]);
    
    //   checking for success
    useEffect(() => {
        if (updateUserSuccess) {
            toggleSavedAccountInfo();
            setTimeout(() => {
            setSavedAccountInfo(false);
            }, 2000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateUserSuccess]);

    return (
        <Formik
        enableReinitialize
        initialValues= {{
            firstname: user.firstname,
            lastname: user.lastname,
            mobile: user.mobile,
            email: user.email,
            date_of_birth: user.date_of_birth,
            reason: user.reason,
            occupation: user.occupation,
        }}
        validationSchema= {Yup.object({
            firstname: Yup.string().min(3).max(15),
            lastname: Yup.string().min(3).max(15),
            mobile: Yup.string().min(10).max(14),
            date_of_birth: Yup.string(),
            reason: Yup.string().min(10).max(200),
            occupation: Yup.string(),
        })}
        onSubmit= {({
            firstname,
            lastname,
            mobile,
            date_of_birth,
            reason,
            occupation
        }) => {
            const formData = {
                firstname,
                lastname,
                mobile,
                date_of_birth,
                reason,
                occupation
            }
            // console.log(formData);
            dispatch(updateUserRequest({ formData, token }));
        }}>
            {({
                values,
                dirty,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                field,
                })=>{
                    return (
                        <div className='flex flex-col gap-4 w-full'>

                            {/* form container */}
                            <div className='pb-5 border-b border-[#E1E6EF] flex justify-start items-start w-full'>

                                {/* form */}
                                <form
                                className="flex flex-col gap-5 pt-2 w-[55%] mobile:w-full"
                                onSubmit={(e) => e.preventDefault()}>

                                    {/* form caption */}
                                    <p className="font-semibold mobile:font-medium mobile:text-base text-xl text-[#3F444D]">
                                        Personal Information
                                    </p>

                                    {/* first name input container */}
                                    <label className="flex flex-col gap-2">

                                        {/* label text */}
                                        <span className="font-semibold text-sm text-[#344054]">
                                            First name
                                        </span>

                                        {/* input field */}
                                        <Field
                                        type="text"
                                        name="firstname"
                                        value={values.firstname}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                                        placeholder="Enter your first name"
                                        />

                                        {errors.firstname && 
                                        touched.firstname && 
                                        (
                                            <FormError message={errors.firstname} />
                                        )}
                                    </label>

                                    {/* last name input container */}
                                    <label className="flex flex-col gap-2">

                                        {/* label text */}
                                        <span className="font-semibold text-sm text-[#344054]">
                                            Last name
                                        </span>

                                        {/* input field */}
                                        <Field
                                        type="text"
                                        name="lastname"
                                        value={values.lastname}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                                        placeholder="Enter your last name" />

                                        {errors.lastname && 
                                        touched.firstname && 
                                        (
                                            <FormError message={errors.lastname} />
                                        )}
                                    </label>

                                    {/* email input container */}
                                    <label className="flex flex-col gap-2">

                                        {/* label text */}
                                        <span className="font-semibold text-sm text-[#344054]">Email</span>

                                        {/* input field */}
                                        <Field
                                        name="email"
                                        disabled={true}
                                        type="email"
                                        className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal disabled:bg-[#f9f5fc] disabled:cursor-not-allowed placeholder:text-base placeholder:text-[#667085]"
                                        value={values.email}
                                        placeholder="Enter your email" />
                                    </label>

                                    {/* phone number input container */}
                                    <label className="flex flex-col gap-2">

                                        {/* label text */}
                                        <span className="font-semibold text-sm text-[#344054]">
                                            Phone number
                                        </span>

                                        {/* input field */}
                                        <Field
                                        type="text"
                                        name="mobile"
                                        value={values.mobile}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                                        placeholder="Enter your phone number" />

                                        {errors.firstname && 
                                        touched.firstname && 
                                        (
                                            <FormError message={errors.firstname} />
                                        )}
                                    </label>

                                    {/* occupation input container */}
                                    {/* <label className="flex flex-col gap-2">

                                        label text
                                        <span className="font-semibold text-sm text-[#344054]">
                                            Occupation
                                        </span>

                                        input field
                                        <Field
                                        type="text"
                                        value={values.occupation}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        className="border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                                        placeholder="Enter your occupation"
                                        />

                                        {errors.firstname && 
                                        formik.occupation && 
                                        (
                                            <FormError message={errors.occupation} />
                                        )}
                                    </label> */}

                                    {/* About input container */}
                                    {/* <label className="flex flex-col gap-2">

                                        label text
                                        <span className="font-semibold text-sm text-[#344054]">Reason</span>

                                        input field
                                        <textarea
                                        value={values.reason}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        className="h-[120px] pt-2 [resize:none] border border-[#d0d5dd] text-base font-normal rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]"
                                        placeholder="Tell us a why you want to join us"
                                        />

                                        {errors.firstname && 
                                        touched.firstname && 
                                        (
                                            <FormError message={errors.firstname} />
                                        )}
                                    </label> */}

                                    {/* save info button */}
                                    <BtnPrimary
                                    onClick={handleSubmit}
                                    disabled={!dirty || updateUserLoading}
                                    loading={updateUserLoading}
                                    title={"Save Changes"} />
                                </form>
                            </div>

                            {/* delete account */}
                            <DeleteAccountBtn />

                            {/* modal for  saved success message */}
                            <MyModal
                            disableBtn={true}
                            modalIsOpen={savedAccountInfo}
                            toggleModal={toggleSavedAccountInfo}>

                                <SavedAccountView 
                                closeModal={toggleSavedAccountInfo} />
                            </MyModal>
                        </div>
                    )
                }
            }
        </Formik>
    )
}

export default EditProfileForm