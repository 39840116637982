import React from 'react'
import { FiCheckCircle } from 'react-icons/fi'
import CancelIcon from '../CancelIcon'

const DynamicClubTag = ({status, title}) => {
    return (
        <>
            {
                status?
                <div className='flex items-center gap-1.5'>
                    <span className='min-w-[32px] bg-[#F8F9FC] flex justify-center items-center rounded-[50%] p-1 min-h-[32px]'>
                        <FiCheckCircle color='#08875D' size={15} />
                    </span>
                    <span className='font-normal text-xs text-[#667085]'>
                        {title}
                    </span>
                </div>:
                <div className='font-semibold text-xs text-[#667085] flex items-center gap-1.5'>
                    <CancelIcon />
                    <span className='font-normal text-xs text-[#667085]'>
                        {title}
                    </span>
                </div>
            }
        </>
    )
}

export default DynamicClubTag