import React from "react";
import Navbar from "../BootcampForm/Navbar";
import jumbotronImage from "../../assets/form/generic-form/images/refresh-header-image.png";
import bgImage from "../../assets/form/generic-form/images/refresh-bg.png";
import DateLocation from "./DateLocation";

const FormHeader = () => {
  // DATA INITIALIZATION
  const headLines = [
    "YOGA",
    "PLAYSTATION GAMES",
    "HEALTHY LIFESTYLE COACHING",
    "MENTAL HEALTH COACHING",
    "BOARD GAMES",
  ];
  return (
    <div className="flex items-center justify-center gap-5 top-0 relative mobile:pb-12 pb-16 w-full overflow-hidden bg-gray-100">
      {/* Image */}
      {/* left arc */}
      <img
        src={bgImage}
        className="absolute mobile:h-[60vh] h-full bottom-0 left-0 w-full object-cover"
        alt=""
      />
      <img
        src={bgImage}
        className="absolute mobile:h-[60vh] h-full top-0 left-0 w-full object-cover"
        alt=""
      />

      {/* container */}
      <div className="w-[80%] h-full gap-10 lg:gap-10 relative z-[6] flex flex-col pt-10 mobile:w-full mobile:mt-[120px]">
        <Navbar />

        <div className="flex mobile:flex-col mobile:w-full w-full items-center gap-5 mobile:px-4 mx-auto">
          {/* image */}
          <div className="mobile:w-full container mx-auto w-[80%] flex flex-col gap-4">
            {/* image */}
            <img
              src={jumbotronImage}
              className="h-full object-cover mobile:w-full pc:min-w-full mobile:mx-auto"
              alt=""
            />

            {/* info labels */}
            <div className="w-full md:!w-[65%] pc:w-[90%] grid grid-cols-2 gap-2 mx-auto">
              {headLines.map((headline, index) => (
                <span className="py-2 h-fit w-full flex items-center pl-4 pr-3 bg-[#055774] text-white text-base font-medium mobile:leading-4 leading-5 mobile:text-[10px]">
                  {headline}
                </span>
              ))}
            </div>
          </div>

          {/* header information */}
          <div className="flex gap-5 flex-col justify-start h-full mb-auto pc:w-[30%] mobile:mt-3 ml-4 pc:pt-6">
            {/* date and location */}
            <DateLocation
              date={"29th Apr, 2023"}
              time={"09am - 4pm"}
              address={
                "No. 1 Snap Drive, Olive Gate Hotel Junction, Independence Layout, Enugu Nigeria"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormHeader;
