import React from 'react'
import Header2 from '../../../../layouts/Redesign/Header2'
import shape1 from "../../../../assets/webpages/event/images/e-shape2.png"
import shape2 from "../../../../assets/webpages/event/images/e-shape1.png"

const EventHeader = () => {
    return (
        <div className='flex items-center justify-start bg-[#FFF8EB] h-[57vh] mobile:h-[48vh] relative flex-col top-0'>

            {/* header */}
            <Header2 />

            {/* SHAPES */}
            {/* shape1 */}
            <img src={shape1} className="mobile:w-[20px] absolute top-[43%] left-[16%] mobile:left-[2rem] mobile:top-[7.5rem]" alt="" />

            {/* shape2 */}
            <img src={shape2} className="mobile:w-[20px] absolute top-[40%] right-[15%] mobile:right-[7%] mobile:top-[30%]" alt="" />

            {/* jumbotron */}
            <div className='w-full flex flex-col items-center top-[20%] mobile:top-[38%] relative z-[200] gap-4 text-center container mx-auto mobile:px-4'>

                <p className='font-semibold text-[45px] mobile:text-4xl text-[#1D2433]'>
                    Events
                </p>

                <p className='text-[#1D2433] text-2xl font-semibold mobile:font-normal mobile:text-[22px]'>
                    Stay updated on our latest programmes and events
                </p>
            </div>
        </div>
    )
}

export default EventHeader