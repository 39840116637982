import React from "react";
import { AiFillInstagram } from "react-icons/ai";
import { ReactComponent as Twitter } from "../../../../assets/bootcamp-form/svg/twitter.svg";

const Footer = () => {
    return (
        <div className="flex mobile:flex-col justify-between mobile:items-center mobile:gap-7 mobile:pb-12 pb-20 border-t pt-10 mx-12 mobile:pt-7 mobile:mx-5 border-[#EAECF0] mt-24">

            {/* copyright */}
            <p className="text-[#98a2b3] mobile:text-sm text-base font-normal">
                &copy; {`${new Date().getFullYear()}`} AI Movement. All rights reserved.
            </p>

            {/* social icons */}
            <div className="flex gap-5 mobile:justify-evenly mobile:gap-6 mobile:px-10">
                <a
                rel="noreferrer"
                href="https://twitter.com/AIMovementNG"
                target="_blank">
                    <Twitter />
                </a>

                <a
                rel="noreferrer"
                className="text-[#98A2B3] text-2xl hover:text-[#98A2B3]"
                href="https://instagram.com/aimovement_ng"
                target="_blank">
                    <AiFillInstagram />
                </a>
            </div>
        </div>
    )
}

export default Footer