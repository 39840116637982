import React from 'react'
import HomeBody from '../../../../components/Redesign/Webpage/Home/HomeBody'
import HomeHeader from '../../../../components/Redesign/Webpage/Home/HomeHeader'
import Wrapper from '../../../../layouts/Redesign/Wrapper'

const Home = () => {
    return (
        <Wrapper>
            
            {/* Home header and jumbotron */}
            <HomeHeader />

            {/* Home body */}
            <HomeBody />
            
        </Wrapper>
    )
}

export default Home