import React from "react";
import { Switch, Route } from "react-router-dom";
import TheClub from ".";
import ClubGetStarted from "./ClubGetStarted";

const ClubRouter = ({path}) => {
    return (
        <Switch>

            <Route 
            exact 
            path={path}
            component={TheClub} />
            
            <Route
            exact
            path={`${path}/:packageType`}
            component={ClubGetStarted} />
        </ Switch>
    )
}

export default ClubRouter