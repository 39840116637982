import { Route, Switch } from "react-router-dom";
import Spaces from ".";
import AvailableSpaces from "./AvailableSpaces";
import Register from "./Register";


const HomepageSpaceRouter = ({path}) => {

    return (
        <Switch>

            <Route 
            exact 
            path={path}
            component={Spaces} />

            <Route 
            path={`${path}/available`} 
            component={AvailableSpaces} />

            <Route 
            path={`${path}/register`} 
            component={Register} />
        </ Switch>
    )
}

export default HomepageSpaceRouter;