import { useState } from 'react'
import MyModal from '../../../layouts/MyModal'
import DeleteAccountView from './DeleteAccountView'
import SavedAccountInfo from './SavedAccountInfo'

const AccountForm = () => {

    // modal states for delete account
    const [deleteAccountModal, setDeleteAccountModal] = useState(false)

    // modal state for saved account info
    const [savedAccountInfo, setSavedAccountInfo] = useState(false)

    // toggle delete account modal
    const toggleDeleteAccountModal = () => {
        setDeleteAccountModal(!deleteAccountModal)
    }

    // toggle saved account info
    const toggleSavedAccountInfo = () => {
        setSavedAccountInfo(!savedAccountInfo)
    }

    return (
        <div className='mt-10'>
            <form
            className='flex flex-col gap-5'
            onSubmit={(e)=>e.preventDefault()}>

                {/* form caption */}
                <p className='font-semibold text-xl text-[#3F444D]'>
                    Change Password
                </p>

                {/* old password input container */}
                <label className='flex flex-col gap-2'>

                    {/* label text */}
                    <span className='font-semibold text-sm text-[#344054]'>
                        Old password
                    </span>

                    {/* input field */}
                    <input 
                    type="password" 
                    className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                    placeholder='Enter old password' />
                </label>

                {/* new password input container */}
                <label className='flex flex-col gap-2'>

                    {/* label text */}
                    <span className='font-semibold text-sm text-[#344054]'>
                        New password
                    </span>

                    {/* input field */}
                    <input 
                    type="password" 
                    className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                    placeholder='Enter new password' />
                </label>

                {/* repeat new password input container */}
                <label className='flex flex-col gap-2'>

                    {/* label text */}
                    <span className='font-semibold text-sm text-[#344054]'>
                        Repeat new password
                    </span>

                    {/* input field */}
                    <input 
                    type="password" 
                    className='border border-[#d0d5dd] text-base font-normal h-[44px] rounded-[8px] px-3 outline-[#d0d5dd] placeholder:font-normal placeholder:text-base placeholder:text-[#667085]' 
                    placeholder='Repeat new password' />
                </label>

                {/* save info button */}
                <button 
                onClick={toggleSavedAccountInfo}
                className='mt-3 h-[44px] bg-[#7F56D9] flex justify-center items-center font-semibold text-base text-white px-7 lg:text-xs rounded-[8px] hover:opacity-80'>
                    Save
                </button>

                {/* modal for  saved success message */}
                <MyModal
                disableBtn={true}
                modalIsOpen={savedAccountInfo}
                toggleModal={toggleSavedAccountInfo}>
                    <SavedAccountInfo
                    closeModal={toggleSavedAccountInfo} />
                </MyModal>
            </form>

            <div className='mt-10 border-t border-[#ccc] pt-6 font-normal text-base text-[#8B54F7]'>
                <span
                onClick={toggleDeleteAccountModal} 
                className='hover:text-red-600 cursor-pointer transition-colors'>
                    Delete account permanently
                </span>

                {/* delete account modal */}
                <MyModal
                disableBtn={false}
                modalIsOpen={deleteAccountModal}
                toggleModal={toggleDeleteAccountModal}>
                    <DeleteAccountView
                    closeModal={toggleDeleteAccountModal} />
                </MyModal>
            </div>
        </div>
    )
}

export default AccountForm