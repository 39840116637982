import {
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_LOADING,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_SUCCESS_CLEAR,
  LOGIN_FAIL,
  LOGIN_LOADING,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_SUCCESS_CLEAR,
  STUDENT_REGISTER_SUCCESS_CLEAR,
  STUDENT_REGISTER_LOADING,
  STUDENT_REGISTER_FAIL,
  STUDENT_REGISTER_SUCCESS,
  STUDENT_REGISTER_REQUEST,
  CLUB_REGISTER_REQUEST,
  CLUB_REGISTER_SUCCESS,
  CLUB_REGISTER_FAIL,
  CLUB_REGISTER_LOADING,
  DELETE_USER_REQUEST,
  DELETE_USER_LOADING,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CLEAR_DELETE_USER_SUCCESS,
  BYPASS_REGISTER_REQUEST,
  BYPASS_REGISTER_SUCCESS,
  BYPASS_REGISTER_FAIL,
  BYPASS_REGISTER_LOADING,
  BYPASS_REGISTER_SUCCESS_CLEAR,
} from "../types";

// logout
export const logout = () => {
  return {
    type: LOGOUT,
  };
};

// user registration
export const registerRequest = (prop) => {
  return {
    type: REGISTER_REQUEST,
    payload: prop,
  };
};

export const registerSuccess = (prop) => {
  return {
    type: REGISTER_SUCCESS,
    payload: prop,
  };
};

export const registerFailure = (errors) => {
  return {
    type: REGISTER_FAIL,
    payload: { errors },
  };
};

export const registerLoading = (loading) => {
  return {
    type: REGISTER_LOADING,
    payload: loading,
  };
};

export const clearRegisterSuccess = () => {
  return {
    type: REGISTER_SUCCESS_CLEAR,
  };
};

// REDESIGNED
// student registration
export const studentRegisterRequest = (prop) => {
  return {
    type: STUDENT_REGISTER_REQUEST,
    payload: prop,
  };
};

export const studentRegisterSuccess = (prop) => {
  return {
    type: STUDENT_REGISTER_SUCCESS,
    payload: prop,
  };
};

export const studentRegisterFailure = (errors) => {
  return {
    type: STUDENT_REGISTER_FAIL,
    payload: { errors },
  };
};

export const studentRegisterLoading = (loading) => {
  return {
    type: STUDENT_REGISTER_LOADING,
    payload: loading,
  };
};

export const clearStudentRegisterSuccess = () => {
  return {
    type: STUDENT_REGISTER_SUCCESS_CLEAR,
  };
};

// student registration BYPASS
export const studentByPassRegisterRequest = (prop) => {
  return {
    type: BYPASS_REGISTER_REQUEST,
    payload: prop,
  };
};

export const studentByPassRegisterSuccess = (prop) => {
  return {
    type: BYPASS_REGISTER_SUCCESS,
    payload: prop,
  };
};

export const studentByPassRegisterFailure = (errors) => {
  return {
    type: BYPASS_REGISTER_FAIL,
    payload: { errors },
  };
};

export const studentByPassRegisterLoading = (loading) => {
  return {
    type: BYPASS_REGISTER_LOADING,
    payload: loading,
  };
};

export const clearStudentByPassRegisterSuccess = () => {
  return {
    type: BYPASS_REGISTER_SUCCESS_CLEAR,
  };
};

// club registration
export const clubRegisterRequest = (prop) => {
  return {
    type: CLUB_REGISTER_REQUEST,
    payload: prop,
  };
};

export const clubRegisterSuccess = (prop) => {
  return {
    type: CLUB_REGISTER_SUCCESS,
    payload: prop,
  };
};

export const clubRegisterFailure = (errors) => {
  return {
    type: CLUB_REGISTER_FAIL,
    payload: { errors },
  };
};

export const clubRegisterLoading = (loading) => {
  return {
    type: CLUB_REGISTER_LOADING,
    payload: loading,
  };
};

export const clearClubRegisterSuccess = () => {
  return {
    type: STUDENT_REGISTER_SUCCESS_CLEAR,
  };
};

// user login
export const loginRequest = (prop) => {
  return {
    type: LOGIN_REQUEST,
    payload: prop,
  };
};

export const loginSuccess = (prop) => {
  return {
    type: LOGIN_SUCCESS,
    payload: prop,
  };
};

export const loginFailure = (errors) => {
  return {
    type: LOGIN_FAIL,
    payload: { errors },
  };
};

export const loginLoading = (loading) => {
  return {
    type: LOGIN_LOADING,
    payload: loading,
  };
};

export const clearLoginSuccess = () => {
  return {
    type: LOGIN_SUCCESS_CLEAR,
  };
};

// DELETE USER
export const deleteUserRequest = (prop) => {
  return {
      type: DELETE_USER_REQUEST,
      payload: prop
  }
}

export const deleteUserLoading = (loading) => {
  return {
      type: DELETE_USER_LOADING,
      payload: loading
  }
}

export const deleteUserSuccess = (prop) => {
  return {
      type: DELETE_USER_SUCCESS,
      payload: prop
  }
}


export const deleteUserFailure = (errors) => {
  return {
      type: DELETE_USER_FAIL,
      payload: { errors }
  }
}

export const clearDeleteUserSuccess = () => {
  return {
      type: CLEAR_DELETE_USER_SUCCESS
  }
}