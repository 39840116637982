import axios from "./axios";
import { call, put, takeLatest, spawn } from "redux-saga/effects";

import { clientErrorMessage, delay } from "./reusable";

import { GET_USER_CLUB_SUBSCRIPTION_REQUEST, PUT_CLUB_SUBSCRIPTION_PAYMENT_REQUEST } from "../types";

import { 
    getUserClubSubsciptionSuccess,
    getUserClubSubsciptionFailure,
    getUserClubSubsciptionLoading,
    putClubSubscriptionPaymentLoading,
    putClubSubscriptionPaymentSuccess,
    clearPutUserToEventSuccess,
    putClubSubscriptionPaymentFailure
} from "../action";

// ajaxDBCall
const ajaxDBCalls = {

    // get user club subsciption
    getUserClubSubscription: async ({payload}) => {
        const response = await axios({
            method: "GET",
            url: "clubsubscription/user/userclubsubs",
            headers: {
                "Content-Type": "application/json",
                'Authorization':`Bearer ${payload}`
            }
        })
        return response;
    },

    // put club subscription payment
    putClubSubscriptionPayment: async ({formData}) => {
        const response = await axios({
            method: "POST",
            url: "clubsubscription/user/onlinepayment",
            data: formData,
        })
        return response
    }
}

// WORKERS
function* getUserClubSubscription(payload) {
    try {
        yield put(getUserClubSubsciptionLoading(true))

        const response = yield call(ajaxDBCalls.getUserClubSubscription, payload)

        yield put(getUserClubSubsciptionSuccess(response.data));
        yield put(getUserClubSubsciptionLoading(false));
    } catch(err) {
        let errorMessage = ""
        if(err.request) errorMessage = clientErrorMessage;

        if(err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(getUserClubSubsciptionFailure(errorMessage));
        yield put(getUserClubSubsciptionLoading(false))
        yield delay()
        yield put(getUserClubSubsciptionFailure(""))
    }
}

function* putClubSubscriptionPayment({payload}) {
    try {
        yield put(putClubSubscriptionPaymentLoading(true));

        const response = yield call(ajaxDBCalls.putClubSubscriptionPayment, payload)

        yield put(putClubSubscriptionPaymentSuccess(response.data))
        yield put(putClubSubscriptionPaymentLoading(false))
        yield delay()
        yield put(clearPutUserToEventSuccess())
    } catch (err) {
        let errorMessage = "";
        if (err.request) errorMessage = clientErrorMessage;

        if (err.response) {
            console.log("here's what's wrong", err.response.data);

            const { message } = err.response.data;
            errorMessage = message;
        }

        yield put(putClubSubscriptionPaymentFailure(errorMessage))
        yield put(putClubSubscriptionPaymentLoading(false))
        yield delay();
        yield put(putClubSubscriptionPaymentFailure(""))
    }
}

// WATCHERS
function* getUserClubSubscriptionWatcher() {
    yield takeLatest(GET_USER_CLUB_SUBSCRIPTION_REQUEST, getUserClubSubscription)
}
function* putClubSubscriptionPaymentWatcher() {
    yield takeLatest(PUT_CLUB_SUBSCRIPTION_PAYMENT_REQUEST, putClubSubscriptionPayment)
}

// clubsubscription saga
export default function* clubSubscriptionSaga() {
    yield spawn(getUserClubSubscriptionWatcher);
    yield spawn(putClubSubscriptionPaymentWatcher)
}