import React from 'react'
import Tabs,{Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import img from "../../../../assets/dashboard/venture-funding/images/desc-img.png"
import kachi from "../../../../assets/dashboard/venture-funding/images/kachi.png"
import remi from "../../../../assets/dashboard/venture-funding/images/Remi.png"
import dolu from "../../../../assets/dashboard/venture-funding/images/dolu.png"
import TeamCard from './TeamCard';

const MainSection = () => {

    // OBJECT INITIALIZATION
    const firstDesc = "25+ years of experience in the world of Process and Product Development, with a focus on Thin Film Materials %His work resulted in 11 U.S. Patents to date, some of which have issued internationally as well, and has been recognized by AIMCAL with the AIMCAL Technology of the Year Award 2010 + AIMCAL Product of the Year Award (2011) %Dr. Decker has been called upon for production system designs, upgrades, startups, process improvement and optimization while applying Six Sigma Principles, operator training and schooling"

    const secondDesc = "25+ years of experience in the world of Process and Product Development, with a focus on Thin Film Materials %His work resulted in 11 U.S. Patents to date, some of which have issued internationally as well, and has been recognized by AIMCAL with the AIMCAL Technology of the Year Award 2010 + AIMCAL Product of the Year Award (2011) %Dr. Decker has been called upon for production system designs, upgrades, startups, process improvement and optimization while applying Six Sigma Principles, operator training and schooling"

    const thirdDesc = "25+ years of experience in the world of Process and Product Development, with a focus on Thin Film Materials %His work resulted in 11 U.S. Patents to date, some of which have issued internationally as well, and has been recognized by AIMCAL with the AIMCAL Technology of the Year Award 2010 + AIMCAL Product of the Year Award (2011) %Dr. Decker has been called upon for production system designs, upgrades, startups, process improvement and optimization while applying Six Sigma Principles, operator training and schooling"

    return (
        <div className='bg-white flex flex-col rounded-[10px] p-5'>
            <Tabs 
            activeTab="1" 
            className={`mt-3`} 
            ulClassName="!border-[#ccc]" 
            activityClassName="!text-[#8B54F7] bg-green-700" 
            onClick={(event, tab) => console.log(event, tab)} >
                
                <Tab 
                title={"Description"} 
                className="mb-2 mr-7 !px-2">
                    <div className="mt-4">
                        <img src={img} className='w-full h-full object-cover' alt="" />
                    </div>
                </Tab>
            
                <Tab 
                title={"Team Members"} 
                className="mr-3 mb-2 !px-2">
                    <div className="mt-4">
                        
                        {/* Team Members List */}
                        <section className='flex flex-col gap-5'>
                            
                            <TeamCard
                            name={"Bar. Kachi Aghasili"}
                            designation="CEO & Founder"
                            qualities={firstDesc}
                            border={true}
                            image={kachi} />

                            <TeamCard
                            name={"Bar. Kachi Aghasili"}
                            designation="CEO & Founder"
                            qualities={secondDesc}
                            border={true}
                            image={remi} />

                            <TeamCard
                            name={"Bar. Kachi Aghasili"}
                            designation="CEO & Founder"
                            qualities={firstDesc}
                            image={dolu} />
                        </section>
                    </div>
                </Tab>
            </Tabs> 
        </div>
    )
}

export default MainSection