import React from 'react'
import Header2 from '../../../../layouts/Redesign/Header2'
import arc1 from "../../../../assets/webpages/home/images/arc1.png"
import arc2 from "../../../../assets/webpages/home/images/arc2.png"
import shape1 from "../../../../assets/webpages/home/images/shape1.png"
import shape2 from "../../../../assets/webpages/home/images/shape2.png"
import Jumbotron from '../../../../components/Redesign/Webpage/Home/Jumbotron'

const HomeHeader = () => {
    return (
        <div className='flex items-center justify-start bg-white h-[80vh] relative flex-col top-0'>

            {/* header */}
            <Header2 />

            {/* SHAPES */}
            {/* arc1 */}
            <img src={arc1} className="mobile:hidden absolute top-[0] left-[0]" alt="" />

            {/* arc2 */}
            <img src={arc2} className="mobile:hidden absolute bottom-[0] right-[0]" alt="" />

            {/* shape1 */}
            <img src={shape1} className="mobile:w-[20px] absolute top-[30%] left-[20%] mobile:left-[2rem] mobile:top-[7.5rem]" alt="" />

            {/* shape2 */}
            <img src={shape2} className="mobile:w-[20px] absolute bottom-[30%] right-[10%] mobile:right-[7%] mobile:bottom-[55%]" alt="" />

            {/* jumbotron */}
            <Jumbotron />

        </div>
    )
}

export default HomeHeader