import React from "react";
import { ReactComponent as Yellow } from "./../../assets/home/svg/career-yellow.svg";
import { ReactComponent as Blue } from "./../../assets/home/svg/career-blue.svg";
import { ReactComponent as Red } from "./../../assets/home/svg/career-red.svg";
import yellowDude from "./../../assets/home/images/newbie-dude.png";
import yCap from "./../../assets/home/images/ycap.png";
import investors from "./../../assets/home/images/investors.png";
import professionals from "./../../assets/home/images/professionals.png";
import professionalDude from "./../../assets/home/images/professional-dude.png";
import investorLady from "./../../assets/home/images/investor-lady.png";
import t_shape1 from "./../../assets/home/svg/t-shape1.svg";
import t_shape2 from "./../../assets/home/svg/t-shape2.svg";
import t_shape3 from "./../../assets/home/svg/t-shape3.svg";
import { useState } from "react";
import { useHistory } from "react-router-dom";

const CourseTab = () => {
  // history object
  const history = useHistory();

  // tab state
  const [currenctTab, setCurrentTab] = useState(1);

  // dynamic styles
  const style1 = {
    opacity: currenctTab !== 1 ? "0.6" : "1",
    background: "white",
    border: currenctTab === 1 ? "2px solid #FFCB44" : "2px solid white",
  };
  const style2 = {
    opacity: currenctTab !== 2 ? "0.6" : "1",
    background: "white",
    border: currenctTab === 2 ? "2px solid #FFCB44" : "2px solid white",
  };
  const style3 = {
    opacity: currenctTab !== 3 ? "0.6" : "1",
    background: "white",
    border: currenctTab === 3 ? "2px solid #FFCB44" : "2px solid white",
  };

  return (
    <div className="flex flex-col justify-center items-center pt-20 md:px-12 mobile:pt-12 gap-16 mobile:gap-12 relative z-[15]">
      {/* switch bottons */}
      <div className="flex mobile:flex-wrap justify-center items-center gap-7 mobile:gap-4 relative z-[18]">
        {/* button1 */}
        <button
          style={style1}
          className={`flex justify-center items-center rounded-[10px] h-[60px] mobile:w-auto mobile:h-[48px] px-2 gap-4 mobile:gap-2`}
          onClick={() => setCurrentTab(1)}
        >
          <Yellow className="mobile:w-[32px]" />
          <p className="text-[#1D2433] font-semibold text-xl mobile:text-xs leading-[24px] mr-1">
            Newbies
          </p>
        </button>

        {/* button2 */}
        <button
          style={style2}
          className={`flex justify-center items-center rounded-[10px] h-[60px] mobile:w-auto mobile:h-[48px] px-2 gap-4 mobile:gap-2`}
          onClick={() => setCurrentTab(2)}
        >
          <Blue className="mobile:w-[32px]" />
          <p className="text-[#1D2433] font-semibold text-xl mobile:text-xs leading-[24px] mr-1">
            Professionals
          </p>
        </button>

        {/* button3 */}
        <button
          style={style3}
          className={`flex justify-center items-center rounded-[10px] h-[60px] mobile:w-auto mobile:h-[48px] px-2 gap-4 mobile:gap-2`}
          onClick={() => setCurrentTab(3)}
        >
          <Red className="mobile:w-[32px]" />
          <p className="text-[#1D2433] font-semibold text-xl mobile:text-xs leading-[24px] mr-1">
            Investors
          </p>
        </button>
      </div>

      {/* Contents */}

      {/* checking for the first tab */}
      {currenctTab === 1 ? (
        // newbies
        <div className="bg-white rounded-[10px] w-[80%] container mx-auto mobile:w-full flex mobile:flex-col justify-center items-center gap-8 py-12 px-8 pc:px-16 xl:!px-8 lg:!px-8 pc:pl-20 mobile:px-7 mobile:py-8 mobile:gap-5 relative">
          {/* shapes */}
          <img
            src={t_shape1}
            className="absolute top-[2rem] left-[10rem] mobile:left-[45%] mobile:w-[20px]"
            alt=""
          />
          <img
            src={t_shape2}
            className="absolute mobile:top-[19rem] top-[5rem] right-[11rem] mobile:right-[19%] mobile:w-[20px]"
            alt=""
          />
          <img
            src={t_shape3}
            className="absolute mobile:bottom-[2rem] mobile:left-[4%] right-[43%] bottom-[2.8rem] mobile:w-[20px]"
            alt=""
          />

          {/* description container */}
          <div className="flex flex-col justify-center items-start w-[50%] lg:w-[65%] mobile:w-full gap-3">
            {/* icon */}
            <img
              src={yCap}
              className="h-[64px] mobile:w-[36px] mobile:h-[36px]"
              alt=""
            />

            {/* texts */}
            <p className="font-semibold text-2xl mobile:text-base leading-[36px] mobile:leading-3">
              Newbies
            </p>
            <p className="font-normal text-xl mobile:text-sm leading-[30px] text-[#585f5a]">
              We guide you from the very basics of the computer through
              well-structured training and immersion in real-life projects that
              ensure you come out fully prepared with the technical and soft
              skills you need to succeed globally in the tech industry. Our hub
              also offers you the opportunity to plug into an invaluable network
              of tech experts and investors that you desperately require to
              foster your career growth.
            </p>

            {/* button */}
            <button
              onClick={() => history.push("/old/training")}
              className="mt-3 font-semibold mobile:hidden text-[1.2vmax] text-white px-5 h-[44px] bg-[#454953] rounded-[8px] hover:opacity-80"
            >
              View all available courses
            </button>

            {/* mobile button */}
            <button
              onClick={() => history.push("/old/login")}
              className="font-semibold hidden mobile:inline text-base text-white px-5 h-[48px] bg-[#454953] rounded-[8px] w-full hover:opacity-80 mt-1"
            >
              Get Started
            </button>
          </div>

          {/* dude image */}
          <img
            src={yellowDude}
            className="mobile:hidden w-[40%] mobile:w-full object-cover"
            alt=""
          />

          {/* image for mobile view */}
          <div>
            <img
              src={yellowDude}
              className="hidden mobile:inline"
              alt="yellow dude"
            />
          </div>
        </div>
      ) : // checking if it's the second tab
      currenctTab === 2 ? (
        // professional
        <div className="bg-white rounded-[10px] w-[80%] container mx-auto mobile:w-full flex mobile:flex-col justify-center items-center gap-8 py-12 px-8 pc:px-16 xl:!px-8 lg:!px-8 pc:pl-20 mobile:px-7 mobile:py-8 mobile:gap-5 relative">
          {/* shapes */}
          <img
            src={t_shape1}
            className="absolute top-[2rem] left-[10rem] mobile:left-[45%] mobile:w-[20px]"
            alt=""
          />
          <img
            src={t_shape2}
            className="absolute mobile:top-[19rem] top-[5rem] right-[11rem] mobile:right-[19%] mobile:w-[20px]"
            alt=""
          />
          <img
            src={t_shape3}
            className="absolute mobile:bottom-[2rem] mobile:left-[4%] right-[43%] bottom-[2.8rem] mobile:w-[20px]"
            alt=""
          />

          {/* description container */}
          <div className="flex flex-col justify-center items-start w-[50%] lg:w-[65%] mobile:w-full gap-3">
            {/* icon */}
            <img
              src={professionals}
              className="h-[64px] mobile:w-[36px] mobile:h-[36px] object-cover"
              alt=""
            />

            {/* texts */}
            <p className="font-semibold text-2xl mobile:text-base leading-[36px] mobile:leading-3">
              Professionals
            </p>
            <p className="font-normal text-xl mobile:text-sm leading-[30px] text-[#585f5a]">
              We give you a thriving environment alien to what is obtainable in
              the city, that helps you create the solution you want to offer to
              the world. Then we go beyond that to also provide mentorship and a
              formidable ecosystem where dreamers and builders like you can
              connect with other dreamers and builders as well as have access to
              funding that ensures your dreams become realities.
            </p>

            {/* button */}
            <button
              onClick={() => history.push("/old/training")}
              className="mt-3 font-semibold mobile:hidden text-[1.2vmax] text-white px-5 h-[44px] bg-[#454953] rounded-[8px] hover:opacity-80"
            >
              View all available courses
            </button>

            {/* mobile button */}
            <button
              onClick={() => history.push("/old/login")}
              className="font-semibold hidden mobile:inline text-base text-white px-5 h-[48px] bg-[#454953] rounded-[8px] w-full hover:opacity-80 mt-1"
            >
              Get Started
            </button>
          </div>

          {/* dude's image */}
          <img
            src={professionalDude}
            className="mobile:hidden w-[40%] mobile:w-full object-cover"
            alt=""
          />

          {/* image for mobile view */}
          <div>
            <img
              src={professionalDude}
              className="hidden mobile:inline"
              alt="yellow dude"
            />
          </div>
        </div>
      ) : (
        // checking if it's the third tab
        currenctTab === 3 && (
          // investors
          <div className="bg-white rounded-[10px] w-[80%] container mx-auto mobile:w-full flex mobile:flex-col justify-center items-center gap-8 py-12 px-8 pc:px-16 xl:!px-8 lg:!px-8 pc:pl-20 mobile:px-7 mobile:py-8 mobile:gap-5 relative">
            {/* shapes */}
            <img
              src={t_shape1}
              className="absolute top-[2rem] left-[10rem] mobile:left-[45%] mobile:w-[20px]"
              alt=""
            />
            <img
              src={t_shape2}
              className="absolute mobile:top-[19rem] top-[5rem] right-[11rem] mobile:right-[19%] mobile:w-[20px]"
              alt=""
            />
            <img
              src={t_shape3}
              className="absolute mobile:bottom-[2rem] mobile:left-[4%] right-[43%] bottom-[2.8rem] mobile:w-[20px]"
              alt=""
            />

            {/* description container */}
            <div className="flex flex-col justify-center items-start w-[50%] lg:w-[65%] mobile:w-full gap-3">
              {/* icon */}
              <img
                src={investors}
                className="h-[64px] mobile:w-[36px] mobile:h-[36px] object-cover"
                alt=""
              />

              {/* texts */}
              <p className="font-semibold text-2xl mobile:text-base leading-[36px] mobile:leading-3">
                Investors
              </p>
              <p className="font-normal text-xl mobile:text-sm leading-[30px] text-[#585f5a]">
                We grant you access to a pool of worthy ideas and solutions from
                talents in the community that you can invest in to build a great
                investment portfolio. Our ecosystem and deep network of students
                and professionals also ensures that you have qualified talent to
                build globally acceptable solutions.
              </p>

              {/* button */}
              <button
                onClick={() => history.push("/old/training")}
                className="mt-3 font-semibold mobile:hidden text-[1.2vmax] text-white px-5 h-[44px] bg-[#454953] rounded-[8px] hover:opacity-80"
              >
                View all available courses
              </button>

              {/* mobile button */}
              <button
                onClick={() => history.push("/old/login")}
                className="font-semibold hidden mobile:inline text-base text-white px-5 h-[48px] bg-[#454953] rounded-[8px] w-full hover:opacity-80 mt-1"
              >
                Get Started
              </button>
            </div>

            {/* lady's image */}
            <img
              src={investorLady}
              className="object-cover mobile:hidden w-[40%] mobile:w-full"
              alt=""
            />

            {/* image for mobile view */}
            <div>
              <img
                src={investorLady}
                className="hidden mobile:inline"
                alt="yellow dude"
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default CourseTab;
