import {BsCheck2Circle} from "react-icons/bs"
import BtnPrimary from "../../../Global/Redesign/BtnPrimary";

const ChooseCard = ({
    reverse,
    subToptic,
    title,
    desc,
    qualities,
    image,
    toggleModal
}) => {

    return (
        <div className={`flex mobile:flex-col md:!w-[85%] w-[75%] mobile:w-full items-center gap-5 mobile:gap-7 ${reverse?"flex-row-reverse":""}`}>
            
            {/* left div */}
            <div className="w-full h-full flex flex-col gap-5 justify-center items-start text-left">

                {/* texts */}
                <div className="flex flex-col gap-1">
                    <p className="font-semibold text-sm text-[#055774]">
                        {subToptic}
                    </p>
                    <p className="mt-0 text-xl font-semibold text-black">
                        {title}
                    </p>
                </div>

                {/* description */}
                <p className="font-normal text-base text-left text-gray-600">
                    {desc}
                </p>

                {/* qualities */}
                <div className="flex flex-col gap-[14px] mb-4">

                    {
                        qualities.map((data, index)=>(
                            <div 
                            key={index}
                            className="flex items-center gap-2">

                                {/* check */}
                                <span className="w-[32px] mobile:w-[24px] h-[32px] mobile:h-[24px] bg-[#D4F4FF] rounded-[50%] flex items-center justify-center">
                                    <BsCheck2Circle className="text-xl mobile:text-base text-[#055774]" />
                                </span>


                                <p className="font-normal text-base text-[#667085]">
                                    {data}
                                </p>
                            </div>
                        ))
                    }
                </div>

                {/* Button container*/}
                <div className="flex w-full justify-start items-center mobile:justify-center">

                    {/* button */}
                    <BtnPrimary
                    onClick={toggleModal}
                    title="Book a space now" />
                </div>
            </div>

            {/* right */}
            <div className={`w-full h-full flex items-center ${reverse?"justify-start":"justify-center"}`}>

                {/* image */}
                <img src={image} className="mobile:h-[274px] md:!h-[310px] w-[400px] mobile:w-full h-full object-cover rounded-[20px]" alt="" />

            </div>
        </div>
    )
}

export default ChooseCard;