import React from 'react'
import thumbnail from "../../../../assets/home/images/thumbnail.png";
import play from "../../../../assets/home/svg/play-btn.svg";

const Jumbotron = () => {
    return (
        <div className='flex mobile:flex-col items-center gap-12 bg-white rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] p-5'>
            
            <div className='w-full'>

                {/* interface */}
                <div className='flex flex-col justify-center items-center relative'>

                    {/* video thumbnail */}
                    <img src={thumbnail} className="rounded-[10px]" alt="thumbnail" />

                    {/* play button */}
                    <img src={play} alt="play button" className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] cursor-pointer' />

                    {/* duration */}
                    <span className='bg-white z-[1000] px-2 py-[2px] rounded-[4px] font-normal text-[14px] leadings-[20px] absolute bottom-2 left-3'>
                        1:57
                    </span>
                </div>
            </div>

            <div className='w-full'>
                <div className='flex flex-col gap-4'>
                    <div className='flex flex-col gap-1 items-start'>

                        <span className='flex justify-center items-center text-gray-600 h-[24px] rounded-[500px] bg-[#E1E6EF] px-3 font-semibold text-xs'>
                            Environment
                        </span>

                        <p className='text-[#1D2433] font-semibold text-4xl'>
                            Smart Sort Ai
                        </p>
                    </div>

                    <div className='flex flex-col gap-1 items-start mt-2'>
                        <span className='text-gray-600 font-semibold text-xs'>
                            Tags:
                        </span>

                        <span className='font-normal text-xs text-gray-600'>
                            VC backed, Socialtech, Artificial Intelligence
                        </span>
                    </div>

                    <p className='text-[#1D2433] font-normal text-base'>
                        Recycling done smarter - Patented Artificial technology.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Jumbotron