import React from "react";
import { Switch, Route } from "react-router-dom";
import CritiqueForm from "../CritiqueAndLunch";
import ThankyouPage from "./ThanksYouPage";

const CritiqueFormRouter = ({ path }) => {
  return (
    <>
      <Switch>
        <Route 
        exact 
        path={`${path}`} 
        component={CritiqueForm} />

        <Route
        exact
        path={`${path}/success`}
        component={ThankyouPage} />
      </Switch>
    </>
  );
};

export default CritiqueFormRouter;
