import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { useHistory } from 'react-router-dom'
import GreenCheck from '../../../Global/GreenCheck'
import ArrowBtn from './ArrowBtn'

const WhatYouLearn = ({subpackageList, disableRouting}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()
    const subPackages = [
        "You will learn complete front-end web development from scratch",
        "You will learn to build Bootstrap 5 Web Applications",
        "You will learn HTML5 & CSS3 from scratch, & build a full working website",
        "You will conclude this course by building a fully functional web based project based on what you’ve learnt."
    ]


    // HANDLERS
    const handleClick = () => {
        history.push("/dashboard/bootcamps/availble-courses")
    }

    return (
        <div className='flex flex-col gap-4 w-full'>
            
            {/* caption heading */}
            <section className='flex justify-between items-center w-full'>
                <p className='font-medium text-sm text-[#3F444D]'>
                    What you’ll learn
                </p>

                {/* arrow botton for pc view */}
                {
                    !disableRouting &&
                    <ArrowBtn
                    onClick={handleClick}
                    text="veiw other courses"
                    className="mobile:hidden" />
                }
            </section>

            {/* subscription card */}
            <section className='w-full p-5 grid grid-cols-2 mobile:grid-cols-1 gap-5 rounded-[10px] border border-[#E1E6EF] bg-white'>

                {
                    subPackages.map((subPackage, index)=>(
                        <div 
                        key={index}
                        className='flex gap-2 items-center'>
                            <GreenCheck />
                            <p className='mt-0 font-normal text-xs text-gray-800'>
                                {subPackage}
                            </p>
                        </div>
                    ))
                }
            </section>
            
            {/* arrow botton for mobile view */}
            {
                !disableRouting &&
                <ArrowBtn
                onClick={handleClick}
                className="hidden mobile:inline-flex" />
            }
        </div>
    )
}

export default WhatYouLearn