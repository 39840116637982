import { useState, useEffect } from 'react'
import { motion } from "framer-motion";
import StepBtn from './StepBtn';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import PathwayCard from './PathwayCard';
import LoadingCircles from '../LoadingCircle';
import { useDispatch, useSelector } from 'react-redux';
import { getBootcampForUsersRequest } from '../../../../../redux/action';
import EmptyData from '../EmptyData';

const Carousel =({
    pages,
    setPages,
    x,
    setX,
    track,
    setBootcamp,
    disableBtn
}) => {
    
    // STATES
    const [clickedCard, setClickedCard] = useState(null)
    const {
        bootcamps,
        bootcampsLoading
    } = useSelector(state=>{
        const { bootcampsLoading } = state.loadingIndicator
        const { bootcamps } = state.bootcampReducer

        return {
            bootcampsLoading,
            bootcamps
        }
    })


    // OBJECT INITIALIZATION
    const dataset = ["item1","item2","item3","item4","item5",]
    const dispatch = useDispatch()


    // HANDLERS
    // scroll left handler
    const scrollLeft = () => {
        document.querySelector("#slider").scrollBy({
            left: -170,
            behavior: "smooth"
        })
    }
    // scroll right handler
    const scrollRight = () => {
        document.querySelector("#slider").scrollBy({
            left: 170,
            behavior: "smooth"
        })
    }


    // SIDE EFFECT
    useEffect(()=>{
        dispatch(getBootcampForUsersRequest())
    }, [])

    // step button
    useEffect(()=> {
        disableBtn({
            back: false,
            next: clickedCard===null
        })
    }, [clickedCard])
    

    return (
        <motion.div
        initial={{ x: x }}
        key={"carousel"}
        transition={{ duration: 1 }}
        animate={{ x: 0 }}
        exit={{ x: x }}
        className="flex flex-col gap-3 items-center justify-center w-full h-full relative">

            {/* caption */}
            <p className='mt-0 text-gray-800 text-sm font-normal'>
                Select a package and click on next
            </p>

            {/* left button */}
            <button
            disabled={bootcamps?.length===0}
            onClick={scrollLeft}
            className='absolute h-[24px] w-[24px] rounded-[4px] top-[35%] left-[-0.1rem] z-[100] bg-[#055774] text-white flex justify-center items-center disabled:opacity-40'>
                <IoIosArrowBack 
                className='text-lg' />
            </button>

            {/* right button */}
            <button
            disabled={bootcamps?.length===0}
            onClick={scrollRight}
            className='absolute h-[24px] w-[24px] rounded-[4px] top-[35%] right-[-0.1rem] z-[100] bg-[#055774] text-white flex justify-center items-center disabled:opacity-40'>
                <IoIosArrowForward 
                className='text-lg' />
            </button>


            {/* Slider component */}
            <div
            id='slider'
            className='flex h-full w-full overflow-auto disable-scrollbars relative z-[10] snap-x gap-8 px-2'>
                {
                    bootcampsLoading?
                    <LoadingCircles />:
                    bootcamps && bootcamps.length !== 0?
                    <>
                        {
                            bootcamps.map((bootcamp, index)=>(
                                track === "1" ? 
                                    (bootcamp.course_type==="regular" && 
                                        <PathwayCard
                                        pathway={bootcamp.pathway}
                                        name={bootcamp.name}
                                        duration={bootcamp.duration}
                                        oldPrice={bootcamp.old_price}
                                        newPrice={bootcamp.new_price}
                                        onClick={()=>{
                                            setClickedCard(bootcamp._id)
                                            setBootcamp(
                                                bootcamp._id, 
                                                bootcamp.new_price
                                            )
                                        }}
                                        isSelected={clickedCard===bootcamp._id}
                                        key={bootcamp._id} />): 
                                track === "2" && 
                                    (bootcamp.course_type==="pathway" && 
                                        <PathwayCard
                                        pathway={bootcamp.pathway}
                                        name={bootcamp.name}
                                        duration={bootcamp.duration}
                                        oldPrice={bootcamp.old_price}
                                        newPrice={bootcamp.new_price}
                                        onClick={()=>{
                                            setClickedCard(bootcamp._id)
                                            setBootcamp(
                                                bootcamp._id, 
                                                bootcamp.new_price
                                            )
                                        }}
                                        isSelected={clickedCard===bootcamp._id}
                                        key={bootcamp._id} />)
                            ))
                        }
                    </>:
                    <EmptyData />
                }
            </div>

        </motion.div>
    )
}

export default Carousel