import React from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'

const Pathways = ({number, title, courseType, to}) => {

    // history object
    const history = useHistory()

    return (
        <div className='flex mobile:flex-col lg:flex-col mobile:justify-center mobile:gap-4 [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] mobile:items-start lg:items-start lg:gap-4 justify-start gap-12 bg-white rounded-[8px] p-6 items-center mobile:px-4 mobile:py-5 container mx-auto'>

            <span className='rounded-[20px] bg-[#F8F5FF] flex justify-center items-center text-[#3F444D] p-2 px-3 font-normal text-xs'>
                Pathway {number}
            </span>

            <div className='mobile:hidden lg:hidden flex flex-col'>
                <p className='font-semibold text-base text-[#3F444D]'>
                    {title}
                </p>
                <p className='font-normal text-xs text-[#3F444D] opacity-[0.65]'>
                    {courseType}
                </p>
            </div>

            {/* learn more */}
            <button 
            onClick={()=>history.push(`/old/dashboard/courses/${to}`)} 
            className="mobile:hidden lg:hidden border border-[#D0D5DD] hover:no-underline font-semibold text-sm text-[#344054] hover:bg-[#f5f5f5] hover:text-[#344054] rounded-[8px] ml-auto py-2 px-4 [box-shadow:0px_1px_2px_rgba(16,24,40,0.05)]">
                Learn more
            </button>

            {/* mobile section */}
            <div className='hidden lg:flex lg:justify-between lg:items-center lg:w-full mobile:flex mobile:justify-between mobile:items-center mobile:w-full'>
                <div className='flex flex-col mobile:w-[55%]'>
                    <p className='font-semibold text-base text-[#3F444D]'>
                        {title}
                    </p>
                    <p className='font-normal text-xs text-[#3F444D] opacity-[0.65]'>
                        {courseType}
                    </p>
                </div>

                {/* learn more */}
                <button 
                onClick={()=>history.push(`/old/dashboard/courses/${to}`)} 
                className="mobile:w-[36%] border mobile:flex mobile:justify-center mobile:items-center border-[#D0D5DD] hover:no-underline font-semibold text-sm text-[#344054] hover:bg-[#f5f5f5] hover:text-[#344054] rounded-[8px] ml-auto h-[36px] px-4 mobile:px-2 [box-shadow:0px_1px_2px_rgba(16,24,40,0.05)]">
                    Learn more
                </button>
            </div>
        </div>
    )
}

export default Pathways