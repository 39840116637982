import React from "react";
import { Switch, Route } from "react-router-dom";
import DashboardHome from "./Home";
import DashboardSettings from "./Setttings";
import TheClub from "./TheClub";
import Events from "./Events";
import CourseRouter from "./Courses/router";
import ClubRouter from "./TheClub/router";
import EventRouter from "./Events/router";
import SpaceBookingRouter from "./SpaceBooking/router";
import VentureRouter from "./VentureFunding/router";

const Dashboard = ({ match: { path } }) => {
  return (
    <Switch>
      <Route exact path={`${path}/home`} component={DashboardHome} />

      <Route exact path={`${path}/settings`} component={DashboardSettings} />

      <CourseRouter path={`${path}/courses`} />

      <ClubRouter path={`${path}/theclub`} />

      {/* <VentureRouter
      path={`${path}/venture-funding`} /> */}

      <EventRouter path={`${path}/events`} />

      {/* <SpaceBookingRouter path={`${path}/space-booking`} /> */}
    </Switch>
  );
};

export default Dashboard;
