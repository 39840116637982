import React from "react"
import {FiSettings} from "react-icons/fi"
import { useHistory } from "react-router-dom"

const HowItWorks = ({infoList}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()

    return (
        <div className="w-full flex flex-col border border-[#E1E6EF] rounded-[10px] h-fit bg-white">
            
            {/* info card */}
            <div className="flex items-center justify-center bg-[#EDFAFE] w-full p-5 flex-col gap-[11px] rounded-t-[8px]">

                {/* icon */}
                <span className="flex items-center justify-center h-[52px] w-[52px] bg-[#D4F4FF] rounded-[50%]">
                    <FiSettings
                    className="text-2xl text-[#055774]" />
                </span>

                {/* text */}
                <p className="font-semibold text-base text-[#1D2433]">
                    How it works
                </p>

            </div>

            {/* informations */}
            <div className="flex flex-col">
                
                {
                    infoList.map((data)=>(

                        <div 
                        key={data.id}
                        className="flex items-center justify-center bg-white border-[#E1E6EF] w-full border-b p-5">

                            <div 
                            className="w-[65%] gap-4 flex justify-start items-center">
                                <p className="text-[#1D2433] font-normal text-[57px] mt-0">
                                    {data.id}
                                </p>

                                <p className="font-normal text-base text-[#1D2433] mt-0">
                                    {data.message}
                                </p>
                            </div>
                        </div>
                    ))
                }
            </div>

            {/* view all events */}
            <div className="flex flex-col justify-center items-center p-5 rounded-b-[8px] border-t-[0_1px_1px_1px] border-t-[#E1E6EF] bg-white">
                <p
                onClick={()=>history.push("/dashboard/club/availble-packages")}
                className="cursor-pointer hover:opacity-70 font-semibold text-sm text-[#0B8CBA]">
                    Select a pathway to get started now
                </p>
            </div>
        </div>
    )
}

export default HowItWorks