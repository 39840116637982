import React from 'react'
import InvestorsDatabaseBody from '../../../../components/Redesign/Dashboard/TheClub/InvestorsDatabase/Body'
import PageWrapper from '../../../../layouts/Redesign/Dashboard/PageWrapper'

const InvestorsDatabase = () => {
    return (
        <PageWrapper
        enableBackBtn={true}
        title={"Our Investors"}
        subTitle={"People that believe in and support our vision"}>

            {/* body section */}
            <InvestorsDatabaseBody />

        </PageWrapper>
    )
}

export default InvestorsDatabase