import React, { useState } from "react";
import navLogo from "../../../assets/redesign-dashboard/the-club/images/nav-logo.png"
import ActiveLink2 from "../../../components/Redesign/Dashboard/SideNavigation/ActiveLink2";
import { FiBookOpen, FiSettings, FiBriefcase, FiCalendar } from "react-icons/fi";
import { FaUsers, FaChalkboardTeacher } from "react-icons/fa";
import { HiOutlineLogout } from "react-icons/hi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import NavBtn from "../../../components/Global/NavBtn";
import MyModal from "./../../../layouts/MyModal";
import LogoutModalView from "../../../components/Dashboard/SideNav/LogoutModalView";
import MobileNavigationBar from "./MobileNavigationBar";
import LogoutView from "../../../components/Redesign/Dashboard/SideNavigation/LogoutView";

const SideNavigation = () => {

    // STATES
    const [isNavOpen, setNavState] = useState(false);
    const [logoutModalState, setLogoutModalState] = useState(false);


    // DATA INITIALIZATION
    const transformMobile = !isNavOpen
      ? "mobile:translate-x-[-500rem]"
      : "mobile:translate-x-[0px]";
  

    // HANDLERS
    const toggleLogoutModal = () => {
      setLogoutModalState(!logoutModalState);
    };

    return (
        <>

            {/* mobile nav bar */}
            <MobileNavigationBar 
            toggleNav={() => setNavState(!isNavOpen)} />

            {/* sidenav drawer */}
            <div
                style={{
                transition: "transform 1.2s ease-in-out",
                transitionFunction: "cubic-bezier(.22,.68,0,1.71)",
                }}
                className={`bg-[#0B8CBA] fixed h-screen w-[20vw] mobile:z-[1000] mobile:min-h-screen mobile:w-full px-6 mobile:px-7 py-8 flex flex-col gap-10 mobile:gap-[4.1rem] ${transformMobile} top-[0] left-[0]`} >

                {/* logo */}
                <div className="container mx-auto flex justify-between items-center">

                    {/* logo image */}
                    <img src={navLogo} className="h-[39px]" alt="" />

                    {/* close btn mobile */}
                    <NavBtn onClick={() => setNavState(!isNavOpen)}>

                        <FontAwesomeIcon color="#fff" size={"2xl"} icon={faXmark} />
                    </NavBtn>
                </div>

                {/* sidenav items */}
                <ul className="container mx-auto flex flex-col gap-3 mobile:gap-4">

                    <li>
                        <ActiveLink2 to={"/dashboard/bootcamps"}>
                            <FiBookOpen 
                            className="text-2xl" />
                            Bootcamps
                        </ActiveLink2>
                    </li>

                    <li>
                        <ActiveLink2 to={"/dashboard/club"}>
                            <FaUsers 
                            className="text-2xl" />
                            The Club
                        </ActiveLink2>
                    </li>

                    <li>
                        <ActiveLink2 to={"/dashboard/investing"}>
                            <FiBriefcase 
                            className="text-2xl" />
                            Investing
                        </ActiveLink2>
                    </li>

                    <li>
                        <ActiveLink2 to={"/dashboard/space-booking"}>
                            <FaChalkboardTeacher 
                            className="text-2xl" />
                            Space Booking
                        </ActiveLink2>
                    </li>

                    <li>
                        <ActiveLink2 to={"/dashboard/events"}>
                            <FiCalendar 
                            className="text-2xl" />
                            Events
                        </ActiveLink2>
                    </li>

                    <li>
                        <ActiveLink2 to={"/dashboard/settings"}>
                            <FiSettings 
                            className="text-2xl" />
                            Settings
                        </ActiveLink2>
                    </li>

                    <li>
                        <span
                        onClick={toggleLogoutModal}
                        className="text-white font-normal text-sm flex justify-start items-center transition-all hover:text-[#055774] hover:bg-[#f9f5ff75] hover:font-bold rounded-[6px] h-[40px] gap-[8px] active:no-underline px-4 hover:no-underline"
                        to={"/login"}
                        >
                            <HiOutlineLogout
                            className="text-2xl" />
                            Logout
                        </span>
                    </li>
                </ul>
            </div>

            {/* logout modal */}
            <MyModal
            modalIsOpen={logoutModalState}
            toggleModal={toggleLogoutModal}
            disableBtn={true}>

                {/* logout modal */}
                <LogoutView 
                closeModal={toggleLogoutModal} />
            </MyModal>
        </>
    )
}

export default SideNavigation