import React from 'react'
import { AiFillCalendar, AiOutlineClockCircle } from "react-icons/ai"
import { HiOutlineOfficeBuilding, HiUserCircle } from "react-icons/hi"
import BtnSm from '../../Global/BtnSm'
import { useDispatch } from "react-redux";
import { putUserToEventRequest } from "../../../redux/action";

const EventInfo = ({event, user, putUserToEventLoading}) => {

    // dispatch object
    const dispatch = useDispatch()

    const formData = {
        email: user.email
    }

    React.useEffect(()=>{
        console.log(event)
    }, [])

    const MONTHS = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ]

    const DAY = [
        "Sunday",
        "Monday",
        "Tueday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ]

    const getData = {
        date: (prop)=>{
            return new Date(prop).getDate()
        },
        dayNum: (prop)=>{
            return new Date(prop).getDay()
        },
        month: (prop)=>{
            return new Date(prop).getMonth()
        },        
        year: (prop)=>{
            return new Date(prop).getFullYear()
        }
    }

    return (
        <div className='[box-shadow:0_2px_8px_rgba(0,0,0,0.12)] bg-white rounded-[10px] p-6 md:!py-12'>

            <div className='container mx-auto flex flex-col gap-4 md:!gap-7'>
                <div className='flex flex-col gap-5'>
                    
                    {/* Event Info */}
                    <p className='text-[#1d2433] opacity-[0.65] font-semibold text-xl'>
                        Event Info
                    </p>

                    <div className='flex flex-col gap-2 border-b border-[#e1e6ef] pb-9'>
                        <p className='font-normal text-base text-[#1d2433] opacity-[0.65] flex items-start gap-3'>
                            <AiFillCalendar size={23} />
                            <span className='w-[80%]'>
                                {`${getData.date(event?.date)} ${MONTHS[getData.month(event?.date)]}, ${getData.year(event?.date)}`}
                            </span>
                        </p>

                        <p className='font-normal text-base text-[#1d2433] opacity-[0.65] flex items-start gap-3'>
                            <AiOutlineClockCircle size={23} />
                            <span className='w-[80%]'>
                                {event?.time?.concat(" pm")} WAT
                            </span>
                        </p>

                        <p className='font-normal text-base text-[#1d2433] opacity-[0.65] flex items-start gap-3 w-full'>
                            <HiOutlineOfficeBuilding size={23} />
                            <span className='w-[80%]'>
                                {event?.location}
                            </span>
                        </p>
                    </div>
                    
                    {/* Guests */}
                    <div className='flex flex-col gap-4 pt-3'>

                        <p className='text-[#1d2433] opacity-[0.65] font-semibold text-xl'>
                            Guests
                        </p>

                        {
                            event?.speakers?.map((speaker)=>(
                                <div className='font-normal text-base text-[#1d2433] opacity-[0.65] flex items-start gap-3'>
                                    <HiUserCircle size={25} />

                                    <p className='flex flex-col gap-1'>
                                        <span>{speaker.name}</span>
                                        <span className='text-xs'>
                                            {speaker.about}
                                        </span>
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                </div>

                {/* button container */}
                <div className='flex flex-col items-stretch mt-1'>
                    {
                        event?.audience?.find((audience)=>audience.user_id === user._id)?(
                            <BtnSm title="Already Registered" />
                        ) : putUserToEventLoading ? (
                            <BtnSm 
                            loading={putUserToEventLoading} 
                            title={"Register now"} />
                        ) : (
                            <BtnSm 
                            onClick={()=>{
                                dispatch(putUserToEventRequest({
                                    formData,
                                    eventId: event._id
                                }))
                            }}
                            title={"Register now"} />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default EventInfo