import { Skeleton } from '@mui/material'
import React from 'react'
import PackageCard from './PackageCard'

const AvailbleMemberPack = ({clubPackages, loading, isEmpty}) => {
    return (
        <div className='gap-4 flex flex-col'>
            
            {/* caption */}
            <p className='font-medium text-base text-[#1D2433]'>
                Advanced Package Features
            </p>

            {/* cards */}
            <div className='flex flex-col gap-4'>
                {
                    loading?
                    <Skeleton
                    variant="rectangular"
                    sx={{
                    width: "100%",
                    height: "50vh",
                    borderRadius: "10px",
                    }} />:
                    isEmpty?
                    <div className='flex justify-center items-center w-full h-[50vh] text-xl rounded-[10px]'>Nothing Here</div>:
                    clubPackages.map((clubPack)=>(
                        <PackageCard
                        key={clubPack._id}
                        path={clubPack._id}
                        packageName={`${clubPack.name} Package`}
                        packagePrice={clubPack.amount}
                        business_consultancy={clubPack.business_consultancy}
                        discounted_services={clubPack.discounted_services}
                        foreign_tech_consultancy={clubPack.foreign_tech_consultancy}
                        investment_database={clubPack.investment_database}
                        investment_pitch={clubPack.investment_pitch}
                        life_insurance={clubPack.life_insurance}
                        member_swag={clubPack.member_swag}
                        networking_event={clubPack.networking_event}
                        news_letter={clubPack.news_letter}
                        reserved_office_space={clubPack.reserved_office_space} />
                    ))
                }
            </div>
        </div>
    )
}

export default AvailbleMemberPack