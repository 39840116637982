import React from 'react'
import Courses from '../Home/Courses'
import Packages from '../Home/Packages'
import AccountCard from './AccountCard'
import ProfileCard from './ProfileCard'

const ProfileAccount = () => {
    return (
        <div className='py-8 px-5 flex flex-col gap-12'>
            
            {/* profile card */}
            <ProfileCard />

            {/* Account info section */}
            <section className='grid lg:grid-cols-[55%_45%] grid-cols-[60%_40%] mobile:grid-cols-[auto] pl-2 pr-5 mobile:px-0 gap-5'>
                <AccountCard />

                {/* course and package information */}
                <div className='flex flex-col h-fit gap-5 mobile:p-0 mobile:mt-10 p-4 rounded-[10px] bg-[#F8F9FC] mobile:bg-transparent items-stretch'>
                    <Courses shadow={true} width={"100%"} />

                    <Packages shadow={true} width={"100%"} />
                </div>
            </section>
        </div>
    )
}

export default ProfileAccount