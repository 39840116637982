import React from 'react'
import { FiCheckCircle } from "react-icons/fi"
import { VscChromeClose } from "react-icons/vsc"

const DynamicCheckIcon = ({isAvailable}) => {
    return (
        <>
            <span className="w-[32px] h-[32px] rounded-[50%] bg-[#F8F9FC] flex items-center justify-center">
                {
                    isAvailable? 
                    <FiCheckCircle color="#08875D" />
                    : <VscChromeClose color="#E02D3C" />
                }
            </span>
        </>
    )
}

export default DynamicCheckIcon