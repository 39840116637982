import { useState, useEffect } from 'react'
import packageIcon from "./../../../assets/dashboard/home/svg/blue-club.svg"
import { Progress } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'
import { useSelector, useDispatch } from 'react-redux';
import { getUserClubSubsciptionRequest } from '../../../redux/action';
import { CircularProgress } from "@material-ui/core";
import EmptyPackageCard from '../Global/EmptyPackageCard';

const Packages = ({width, shadow}) => {

    // calculating for time spent/exhausted
    const [timeSpent, setTimeSpent] = useState(0);

    // dispatch object
    const dispatch = useDispatch()

    // user token and club subscription states
    const {
        token,
        userClubSubscriptions,
        userClubSubscriptionsLoading
    } = useSelector((state)=>{
        const {token} = state.userData
        const {userClubSubscriptions} = state.clubSubscriptionReducer
        const {userClubSubscriptionsLoading} = state.loadingIndicator
        return {
            token,
            userClubSubscriptions,
            userClubSubscriptionsLoading
        }
    })

    useEffect(()=>{
        dispatch(getUserClubSubsciptionRequest(token))
    }, [])

    useEffect(() => {
        if (userClubSubscriptions) {
          const date1 = new Date(userClubSubscriptions[0]?.start_date);
          const dateNow = new Date();
          let tSpent;
          let months = (dateNow.getFullYear() - date1.getFullYear()) * 12;
          months -= date1.getMonth();
          months += dateNow.getMonth();
          tSpent = months <= 0 ? 0 : months;
          setTimeSpent(tSpent);
        }
      }, [userClubSubscriptions]);

    return (
        <>
            {
                userClubSubscriptionsLoading?
                <div
                    style={{
                        width: width,
                        boxShadow: shadow && "0px 2px 8px rgba(0, 0, 0, 0.12)",
                    }}
                    className="bg-white rounded-[10px] p-6 flex gap-5 flex-col h-[154px] justify-center items-center "
                    >
                    {" "}
                    <CircularProgress size={20} style={{ color: "#7F56D9" }} />{" "}
                </div>:
                <>
                    {
                        userClubSubscriptions.length === 0? 
                        <EmptyPackageCard width={width} shadow={true} /> :
                        <>
                            {
                                userClubSubscriptions.map((clubSubscription)=>(
                                    <div 
                                    key={clubSubscription._id}
                                    style={{
                                        width: width,
                                        boxShadow: shadow && "0px 2px 8px rgba(0, 0, 0, 0.12)"
                                    }}
                                    className='bg-white rounded-[10px] p-6 flex gap-2 flex-col h-fit'>
                                        <p className='font-semibold text-[#1D2433] text-sm opacity-[0.65]'>
                                            Current club package
                                        </p>

                                        <div className='flex justify-start items-center gap-3'>
                                            <img className='w-[44px]' src={packageIcon} alt="" />
                                            <div className='text-[#1D2433] flex flex-col'>
                                                <span className='font-semibold text-base'>
                                                    {clubSubscription.club_id.name}
                                                </span>
                                                <span  className='font-normal text-sm opacity-[0.65]'>
                                                    {clubSubscription.club_id.duration} month(s) membership
                                                </span>
                                            </div>
                                        </div>

                                        <div className='flex items-center -ml-2'>
                                            <div className="w-[60%]">
                                                <Progress.Line percent={Math.floor((timeSpent / (clubSubscription.club_id.duration)) * 100)} showInfo={false} strokeColor="#7F56D9" />
                                            </div>                

                                            <span className='text-xs font-normal text-[#1D2433] opacity-[0.65] w-[40%] mb-1'>
                                                {clubSubscription.club_id.duration - timeSpent} months remaining
                                            </span>
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                    }
                </>
            }
        </>
    )
}

export default Packages