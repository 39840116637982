

const ValueBox = ({
    icon,
    title,
    desc
}) => {

    return (
        <div className="rounded-[10px] bg-white [box-shadow:0px_0px_0px_4px_rgba(255,255,255,0.2)] flex flex-col items-start gap-6 p-5">

            <span className="inline-flex flex-col rounded-[50%] h-[56px] w-[56px] bg-[#D4F4FF] items-center justify-center">
                {icon}
            </span>

            <p className="mt-0 text-2xl font-semibold text-[#3F444D] leading-none">
                {title}
            </p>

            <p className="mt-0 text-gray-600 text-base font-normal">
                {desc}
            </p>
        </div>
    )
}

export default ValueBox;