import React from "react";
import { useHistory } from "react-router-dom";
import MyLink from "../MyLink";
import BtnSm from "../BtnSm";

const DesktopNavList = () => {
  
  // navigation hook
  const history = useHistory();

  return (
    <>
      <div className="w-[75%] mobile:hidden flex">
        <ul className="flex justify-center items-center gap-8 lg:gap-2 mr-auto">
          {/* home link */}
          {/* <li>
            <MyLink to={"/old"}>Home</MyLink>
          </li> */}

          {/* The club link */}
          <li>
            <MyLink to={"/old/club"}>
              <span className=" mr-[6px]">Club </span>
            </MyLink>
          </li>

          {/* Management team link */}
          <li>
            <MyLink to={"/old/management"}>
              <span className=" ml-[6px]">Team</span>
            </MyLink>
          </li>

          {/* Training center link */}
          <li>
            <MyLink to={"/old/training"}>
              Training<span className="ml-[6px]"> center</span>
            </MyLink>
          </li>

          {/* Events link */}
          <li>
            <MyLink to={"/old/events"}>Events</MyLink>
          </li>

          {/* Contact Us */}
          <li>
            <MyLink to={"/old/contactus"}>Contact</MyLink>
          </li>

          {/* Space Booking */}
          {/* <li>
            <MyLink to={"/old/space-booking"}>Spaces</MyLink>
          </li> */}
        </ul>

        {/* get started button */}
        <div className="flex my-auto items-center">
          <BtnSm
          title={"Get started"} 
          onClick={() => history.push("/old/login")} />
        </div>
      </div>
    </>
  );
};

export default DesktopNavList;
