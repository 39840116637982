import React from "react";
import { Switch, Route } from "react-router-dom";
import Courses from "."
import CourseDetails from "./CourseDetails";
import GetStarted from "./GetStarted";

const CourseRouter = ({path}) => {

    return (
        <Switch>
            
            <Route 
            exact 
            path={path}
            component={Courses} />

            <Route 
            exact 
            path={`${path}/:courseId`} 
            component={CourseDetails} />

            <Route 
            exact 
            path={`${path}/:courseId/get-started`} 
            component={GetStarted} />
            
            <Route 
            exact 
            path={"/dashboard/courses/regular-backend"} 
            component={CourseDetails} />
        </ Switch>
    )
}

export default CourseRouter