import React from 'react'

const TrackCard = ({title, desc, clicked, onClick}) => {

    return (
        <div 
        onClick={onClick}
        className={`flex flex-col p-4 rounded-[10px] cursor-pointer items-start justify-center bg-white [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] ${clicked?"border-[1.4px] border-[#0B8CBA]":""}`}>

            {/* title */}
            <p className={`font-semibold text-sm ${clicked?"text-[#055774]":"text-[#1D2433]"}`}>
                {title}
            </p>

            {/* description */}
            <p className='font-normal text-xs text[#3F444D]'>
                {desc}
            </p>
        </div>
    )
}

export default TrackCard