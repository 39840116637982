import React from 'react'

const BreakTextPC = () => {
    return (
        <>
            <br className="hidden pc:block" />
        </>
    )
}

export default BreakTextPC