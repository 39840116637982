import React from 'react'
import { PaystackButton } from 'react-paystack'
import BtnPrimary from '../../../Global/Redesign/BtnPrimary'

const PaymentBtn = ({
    hasSubscription, 
    paymentLoading, 
    paystackProps,
    hasSubscriptionText
}) => {
    return (
        <>
            {
                hasSubscription?
                <BtnPrimary
                title={hasSubscriptionText}
                disabled={true} />:
                paymentLoading?
                <BtnPrimary
                loading={true}
                title={"Make Payment"} />:
                <PaystackButton
                className="h-[44px] mobile:h-[48px] bg-[#0B8CBA] flex justify-center items-center font-semibold text-base xl:text-sm lg:text-xs text-white px-7 rounded-[8px] hover:opacity-80 disabled:opacity-40 disabled:cursor-not-allowed"
                {...paystackProps} />
            }
        </>
    )
}

export default PaymentBtn