import { useState, useEffect } from "react";
import courseIcon from "./../../../assets/dashboard/home/svg/yellow-course.svg";
import { Progress } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { useSelector, useDispatch } from "react-redux";
import { getUsersBootcampRequest } from "../../../redux/action";
import { useHistory } from "react-router-dom";
import EmptyCourseCard from "../Global/EmptyCourseCard";
import { CircularProgress } from "@material-ui/core";

const Courses = ({ width, shadow }) => {
  // calculating for time spent/exhausted
  const [timeSpent, setTimeSpent] = useState(0);

  // dispatch object
  const dispatch = useDispatch();

  // history object
  const history = useHistory();

  const { bootcamp, bootcampLoading, token } = useSelector((state) => {
    const { token } = state.userData;

    const { bootcamp } = state.bootcampReducer;
    const { bootcampLoading } = state.loadingIndicator;

    return {
      bootcamp,
      bootcampLoading,
      token,
    };
  });

  useEffect(() => {
    dispatch(getUsersBootcampRequest(token));
  }, []);

  useEffect(() => {
    if (bootcamp) {
      const date1 = new Date(bootcamp[0]?.start_date);
      const dateNow = new Date();
      let tSpent;
      let months = (dateNow.getFullYear() - date1.getFullYear()) * 12;
      months -= date1.getMonth();
      months += dateNow.getMonth();
      tSpent = months <= 0 ? 0 : months;
      setTimeSpent(tSpent);
    }
  }, [bootcamp]);

  return (
    <>
      {bootcampLoading ? (
        <div
          style={{
            width: width,
            boxShadow: shadow && "0px 2px 8px rgba(0, 0, 0, 0.12)",
          }}
          className="bg-white rounded-[10px] p-6 flex gap-5 flex-col h-[154px] justify-center items-center "
        >
          {" "}
          <CircularProgress size={20} style={{ color: "#7F56D9" }} />{" "}
        </div>
      ) : (
        <>
          {bootcamp.length === 0 ? (
            <EmptyCourseCard width={width} shadow={true} />
          ) : (
            <div 
            style={{
              width: "100%"
            }}
            className="flex gap-6 flex-col">
              {
                bootcamp.map((bootcamp)=>(
                  <div
                    key={bootcamp._id}
                    onClick={() =>
                      history.push(`/old/dashboard/courses/${bootcamp._id}`)
                    }
                    style={{
                      width: width,
                      boxShadow: shadow && "0px 2px 8px rgba(0, 0, 0, 0.12)",
                    }}
                    className="bg-white rounded-[10px] p-6 flex gap-2 flex-col h-fit cursor-pointer"
                  >
                    <p className="font-semibold text-[#1D2433] text-sm opacity-[0.65]">
                      Current bootcamp enrolled in
                    </p>
    
                    <div className="flex justify-start items-center gap-3">
                      <img className="w-[44px]" src={courseIcon} alt="" />
                      <div className="text-[#1D2433] flex flex-col">
                        <span className="font-semibold text-base">
                          {bootcamp.name}
                        </span>
                        <span className="font-normal text-sm opacity-[0.65]">
                          {bootcamp.duration || " -"} Months Course
                        </span>
                      </div>
                    </div>
    
                    <div className="flex items-center -ml-2">
                      <div className="w-[50%]">
                        <Progress.Line
                          percent={Math.floor(
                            (timeSpent / (bootcamp.duration || 0)) * 100
                          )}
                          showInfo={false}
                          strokeColor="#7F56D9"
                        />
                      </div>
    
                      <span className="text-xs font-normal text-[#1D2433] opacity-[0.65] w-[50%] mb-1">
                        {bootcamp.duration - timeSpent || " -"} month(s) remaining
                      </span>
                    </div>
                  </div>
                ))
              }
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Courses;
