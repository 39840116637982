import ClubBody from "../../../../components/Redesign/Webpage/Club/ClubBody";
import ClubHeader from "../../../../components/Redesign/Webpage/Club/ClubHeader";
import Wrapper from "../../../../layouts/Redesign/Wrapper";

const Club = () => {

    return (
        <Wrapper>

            {/* Club Header */}
            <ClubHeader />

            {/* Club Body */}
            <ClubBody />

        </Wrapper>
    )
}

export default Club;