import React from 'react'
import BreakTextPC from '../Global/BrTextPC'
import BtnSm from '../Global/BtnSm'
import { useHistory } from "react-router-dom"

const JoinUs = () => {

    // history object
    const history = useHistory()

    return (
        <div className='flex flex-col items-center justify-center pt-[12rem] mt-24 relative'>
            
            {/* fist container */}
            <div className='w-full flex justify-center absolute top-[-3%] mobile:top-[15%] mobile:px-6 '>
                <div className='flex flex-col rounded-[10px] justify-center items-center bg-[#1D2433] text-white text-center mobile:w-full md:w-[80%] w-[65%] relative py-32 gap-8 px-24 mobile:p-[54px_18px] mobile:h-fit'>
                    <p className='font-semibold mobile:text-2xl text-4xl leading-[54px] mobile:px-6'>
                        Join us today and grow your tech career the right <BreakTextPC />way with the right team
                    </p>
                    <div className='mobile:flex flex-col justify-center hidden items-stretch w-[70%]'>
                        <BtnSm 
                        onClick={()=>history.push("/old/login")}
                        title={"Get started"} />
                    </div>
                </div>
            </div>

            {/* second container */}
            <div className="h-[300px] mobile:h-[30vh] bg-[#F9FAFB] w-full"></div>
        </div>
    )
}

export default JoinUs