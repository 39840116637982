import { useEffect } from "react";
import Event from "./Event";
import eventImage from "./../../assets/events/images/tech-network.png";
import { useSelector, useDispatch } from "react-redux";
import { getEventsForUsersRequest } from "../../redux/action";
import { Skeleton } from "@mui/material";

const Programmes = () => {
  // dispatch object
  const dispatch = useDispatch();

  // event states
  const { events, eventsForUsersLoading } = useSelector((state) => {
    const { events } = state.eventsReducer;
    const { eventsForUsersLoading } = state.loadingIndicator;
    return {
      events,
      eventsForUsersLoading,
    };
  });

  useEffect(() => {
    dispatch(getEventsForUsersRequest());
  }, []);

  const MONTHS = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const DAY = [
    "Sunday",
    "Monday",
    "Tueday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const getData = {
    date: (prop) => {
      return new Date(prop).getDate();
    },
    dayNum: (prop) => {
      return new Date(prop).getDay();
    },
    month: (prop) => {
      return new Date(prop).getMonth();
    },
    year: (prop) => {
      return new Date(prop).getFullYear();
    },
  };

  return (
    <div className="py-32 mobile:py-16 px-8 mobile:px-12 container mx-auto">
      {eventsForUsersLoading ? (
        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            height: "50vh",
            borderRadius: "10px",
          }}
        />
      ) : !(events.length === 0) ? (
        <div className="grid mobile:grid-cols-[auto] md:!grid-cols-2 lg:grid-cols-2 grid-cols-3 items-center justify-center gap-8 container mx-auto mobile:gap-10">
          {events.map((event) => (
            <Event
              key={event._id}
              image={event.imgurl}
              date={`${DAY[getData.dayNum(event.date)]} ${event.time.concat(
                " pm"
              )}`}
              venue={event.location}
              title={event.name}
              desc={event.description.substring(0, 80).concat("...")}
              id={event._id}
            />
          ))}
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <div className="bg-white shadow-xl text-lg p-6 rounded-lg max-w-xl">
            {" "}
            We currently have no up comming event, feel free to always check
            back. we do have something big on the way.{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default Programmes;
