import React from 'react'


const NavBtn = ({onClick, children}) => {
    return (
        <div className='hidden mobile:flex'>
            <button
            onClick={onClick} 
            className='h-[40px] w-[40px] hover:[outline:2px_solid_#e0e0e0] rounded-[8px] flex justify-center items-center'>
                {children}
                {/* #667085 */}
            </button>
        </div>
    )
}

export default NavBtn