import { FileUpload } from "@mui/icons-material";
import { useState, useEffect } from "react";
import MyModal from "../../../layouts/MyModal";
import profileImage from "./../../../assets/dashboard/Settings/images/profile-pic.png";
import { ReactComponent as AddImage } from "./../../../assets/dashboard/Settings/svg/add-image.svg";
import FileUploadView from "./FileUploadView";
import { useSelector } from "react-redux";
import { BiUserCircle } from "react-icons/bi";

const ProfileCard = () => {
  // modal state for saved account info
  const [uploadFile, setUploadFile] = useState(false);

  // toggle saved account info
  const toggleUploadFile = () => {
    setUploadFile(!uploadFile);
  };

  const { user, updateUserSuccess, updateUserError } = useSelector((state) => {
    const {
      success: { updateUser: updateUserSuccess },
      errors: { getUser: getUserError, updateUser: updateUserError },
    } = state.ajaxStatuses;
    const { user } = state.userData;
    return {
      user,
      updateUserSuccess,
      updateUserError,
    };
  });

  useEffect(() => {
    if (updateUserSuccess || updateUserError) {
      setUploadFile(!uploadFile);
    }
  }, [updateUserSuccess, updateUserError]);

  return (
    <div className="flex gap-5">
      {/* image container */}
      <div className="relative">
        {/* profile image */}

        {user.avatar ? (
          <img
            src={user.avatar}
            className="w-[140px] h-[140px] mobile:w-[110px] mobile:h-[110px]  rounded-[50%] object-top object-cover"
            alt=""
          />
        ) : (
          <span className="">
            <BiUserCircle className="mobile:text-[80px] text-[130px]" />
          </span>
        )}

        {/* change image icon/button */}
        <AddImage
          onClick={toggleUploadFile}
          className="cursor-pointer transition-[all_.6s_ease-in-out] hover:scale-105 absolute bottom-[4px] right-[0px]"
        />

        {/* file upload modal */}
        <MyModal
          disableBtn={false}
          modalIsOpen={uploadFile}
          toggleModal={toggleUploadFile}
        >
          <FileUploadView closeModal={toggleUploadFile} />
        </MyModal>
      </div>

      {/* text container */}
      <div className="flex flex-col items-start justify-end">
        <p className="font-semibold leading-3 text-xl text-[#344054]">
          {`${user.firstname || ""} ${user.lastname || "Loading..."}`}
        </p>

        <p className="font-normal text-base text-[#667085]">
          {user.email || "Loading..."}
        </p>
      </div>
    </div>
  );
};

export default ProfileCard;
