import { useLocation } from 'react-router-dom'
import { useEffect, useState } from "react"

const useEventStatus = () => {

    // STATES
    const [hasEnded, setHasEnded] = useState(false)


    // OBJECT INITIALIZATION
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const status = params.get("status")

    
    // SIDE EFFECT
    useEffect(()=>{
        switch(status) {
            case "UPCOMING":
                setHasEnded(false);
                break;
            case "PAST":
                setHasEnded(true);
                break;
            default:
                setHasEnded(false);
        }
    }, [])

    // return event status
    return [hasEnded]
}

export default useEventStatus