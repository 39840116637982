import React from 'react'
import Packages from '../Home/Packages'
import ProfileAvatar from '../Home/ProfileAvatar'
import MembershipOptions from '../Home/MembershipOptions'
import { Link, useHistory } from 'react-router-dom'
import { RiArrowRightSLine } from 'react-icons/ri'
import ViewInvestment from './ViewInvestment'

const ClubBody = () => {

    // OBJECT INITIALIZATION
    const history = useHistory()

    return (
        <section className='w-[80vw] mobile:w-full bg-[#E5E5E5] py-16 mobile:pb-8 mobile:px-0 px-12'>

            {/* header */}
            <header className='container mx-auto mobile:px-4 flex justify-between items-center'>
                <p className='text-4xl mobile:leading-9 mobile:text-2xl font-semibold leading-[54px] text-[#1D2433]'>
                    The Club
                </p>

                {/* profile dp */}
                <div className='mobile:hidden'>
                    <ProfileAvatar />
                </div>
            </header>

            {/* middle section */}
            <section className='flex w-full justify-between mt-12 mobile:mt-8 gap-9'>

                {/* main section */}
                <main className='flex w-full flex-col gap-16'>

                    {/* package detailed info */}
                    <div className='flex flex-col w-full gap-6 mobile:hidden'>
                        <Packages shadow={true} width={"35%"} />

                        {/* view investments */}
                        <div className='w-[35%]'>
                            <ViewInvestment />
                        </div>
                        
                    </div>

                    {/* mobile course package */}
                    <div className='hidden mobile:px-4 mobile:flex w-full flex-col gap-6 container mx-auto'>
                        <Packages shadow={true} width={"100%"} />

                        {/* view investments */}
                        <ViewInvestment />
                    </div>

                    {/* club membership options */}
                    <div className='flex flex-col gap-8'>

                        {/* club membership option section */}
                        <section className='flex flex-col justify-center w-full mt-2 gap-12'>
                            <MembershipOptions />
                        </section>  

                        <Link to={"/old/dashboard/courses"} className='hover:text-[#8B54F7] hover:no-underline font-semibold mobile:self-center mobile:text-sm text-xl text-[#8B54F7]'>
                            Click here to sign up for a class instead
                        </Link>
                    </div>
                </main>
            </section>            
        </section>
    )
}

export default ClubBody