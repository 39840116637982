import React from 'react'
import logo from "../../assets/bootcamp-form/images/form-logo.png"

const Navbar = () => {
    return (
        <div className='[box-shadow:0px_8px_32px_rgba(0,0,0,0.16)] bg-white rounded-[10px] min-h-[84px] w-full flex justify-center items-center mobile:fixed mobile:top-0 mobile:left-0 mobile:h-[72px] mobile:rounded-none z-[800]'>

            {/* logo image */}
            <img src={logo} className='w-[141px]' alt="" />
            
        </div>
    )
}

export default Navbar