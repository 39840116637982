import React from 'react'
import DynamicClubTag from '../../../../Global/DyamicClubTag'

const SubscriptionCard = (
    {
        path, 
        packageName,
        packagePrice,
        business_consultancy,
        discounted_services,
        foreign_tech_consultancy,
        investment_database,
        investment_pitch,
        life_insurance,
        member_swag,
        networking_event, 
        news_letter, 
        reserved_office_space,
        onClick,
        isSelected
    }
) => {

    // OBJECT IINITIALIZATION
    
    return (
        <div 
        onClick={onClick}
        className={`cursor-pointer flex flex-col items-center min-w-[266px] bg-white rounded-[10px] [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] gap-5 snap-center [transition:border-color.4s_linear] p-3 my-2 border ${isSelected?"border-[#0B8CBA]":"border-white"}`}>
            
            {/* package name */}
            <div className='flex flex-col items-center justify-center gap-4 pb-2'>
                <p className='text-[#3F444D] text-sm font-semibold'>
                    {packageName} Package
                </p>
            </div>

            {/* package info */}
            <div className='flex justify-between my-2'>

                {/* Listing */}
                <div className='grid grid-cols-2 text-[#667085] font-semibold text-xs justify-start items-center gap-3 gap-y-4'>
            
                    <DynamicClubTag
                    // status={networking_event}
                    status={networking_event}
                    title={"Networking Event"} />
            
                    <DynamicClubTag
                    // status={member_swag}
                    status={member_swag}
                    title={"Member Swag"} />
            
                    <DynamicClubTag
                    // status={news_letter}
                    status={news_letter}
                    title={"Newsletter"} />
            
                    <DynamicClubTag
                    // status={reserved_office_space}
                    status={reserved_office_space}
                    title={"Reserved Office Space"} />
            
                    <DynamicClubTag
                    // status={discounted_services}
                    status={discounted_services}
                    title={"Discounted Services"} />
            
                    <DynamicClubTag
                    // status={investment_pitch}
                    status={investment_pitch}
                    title={"Investment Pitch"} />
            
                    <DynamicClubTag
                    // status={investment_database}
                    status={investment_database}
                    title={"Investment Database"} />
            
                    <DynamicClubTag
                    // status={business_consultancy}
                    status={business_consultancy}
                    title={"Business Consultancy"} />
            
                    <DynamicClubTag
                    // status={foreign_tech_consultancy}
                    status={foreign_tech_consultancy}
                    title={"Foreign Tech Consultancy"} />
            
                    <DynamicClubTag
                    // status={life_insurance}
                    status={life_insurance}
                    title={"Life Insurance "} />
            
                </div>
                <div>

                </div>
            </div>

            {/* amount */}
            <p className='text-[#6c717a] font-semibold text-xl leading-[24px] text-center'>
                NGN {packagePrice.toLocaleString("en-US")}
                <span className='font-normal text-base'>/yr</span>
            </p>

            {/* select button */}
            <div className='flex flex-col items-stretch w-full'>
                <span
                className={`flex items-center justify-center font-semibold text-base w-full rounded-[8px] border [transition:.4s_linear] [transition-property:background_color] border-[#055774] h-[44px] ${isSelected?"bg-[#0B8CBA] text-white":"bg-white text-[#0B8CBA]"}`}
                role={"button"}>
                    {isSelected?"Selected":"Select"}
                </span>
            </div>
        </div>
    )
}

export default SubscriptionCard