import React from 'react'
import EventBody from '../../../../components/Redesign/Webpage/Event/EventBody'
import EventHeader from '../../../../components/Redesign/Webpage/Event/EventHeader'
import Wrapper from '../../../../layouts/Redesign/Wrapper'

const Event = () => {
    return (
        <Wrapper>
            
            {/* event header */}
            <EventHeader />

            {/* event body */}
            <EventBody />

        </Wrapper>
    )
}

export default Event