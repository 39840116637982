import React from 'react'
import BtnSm from '../../Global/BtnSm'
import { useDispatch } from "react-redux";
import { putUserToEventRequest } from "../../../redux/action";

const AboutEvent = ({event, user, putUserToEventLoading}) => {

    // dispatch object
    const dispatch = useDispatch()

    const formData = {
        email: user.email
    }

    return (
        <div className='[box-shadow:0_2px_8px_rgba(0,0,0,0.12)] bg-white rounded-[10px] p-8 md:!py-12'>

            <div className="container mx-auto flex flex-col gap-4 md:!gap-7">

                <p className='text-[#3f444d] font-semibold text-xl'>
                    About Event
                </p>

                <div className='font-normal text-sm text-[#1d2433] opacity-[0.65] flex flex-col gap-3'>
                    <p>
                        {event?.description}
                    </p>
                </div>

                <div className='mobile:flex flex-col items-stretch mt-1'>
                    {
                        event?.audience?.find((audience)=>audience.user_id === user._id)?(
                            <div className='flex flex-col pc:w-[40%]'>
                                <BtnSm title={"Already Registered"} />
                            </div>
                        ) : putUserToEventLoading ? (
                            <div className='flex flex-col pc:w-[40%]'>
                                <BtnSm 
                                loading={putUserToEventLoading}
                                title={"Register now"} />
                            </div>
                        ) : (
                            <div className='flex flex-col pc:w-[40%]'>
                                <BtnSm 
                                onClick={()=>{
                                    dispatch(putUserToEventRequest({
                                        formData,
                                        eventId: event._id
                                    }))
                                }}
                                title={"Register now"} />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default AboutEvent