import Tabs,{Tab} from 'react-best-tabs';
import 'react-best-tabs/dist/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import "./styles/react-tabs-custom-style.css"
import RegularTrack from './RegularTrack';
import SpecialTrack from './SpecialTrack';
import { useEffect, useState } from 'react';
import MyModal from '../../../layouts/MyModal';
import SpecialTrackInfoView from './SpecialTrackInfoView';
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress } from '@mui/material'
import { getBootcampForUsersRequest } from '../../../redux/action';

const AvailableCourses = () => {

    // dispatch object
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getBootcampForUsersRequest())
    }, [])

    // bootcamp state
    const {
        bootcamps,
        bootcampsLoading
    } = useSelector((state)=>{
        const { bootcamps } = state.bootcampReducer
        const { bootcampsLoading } = state.loadingIndicator
        return {
            bootcamps,
            bootcampsLoading
        }
    })

    const [specialTrackModalState, setSpecialModalState] = useState(false)

    // handle modal toggling
    const toggleSpecialTrackModal = () => {
        setSpecialModalState(!specialTrackModalState)
    }

    // special track tab
    const specialTrack = <span className='flex gap-2 items-center text-base font-normal'>
        Special track

        {/* pc icon */}
        <span className='mobile:hidden'>
            <FontAwesomeIcon 
            onClick={toggleSpecialTrackModal} 
            color='#3F444D' 
            icon={faCircleInfo} 
            size="lg" />
        </span>

        {/* mobile icon */}
        <span className='hidden mobile:inline'>
            <FontAwesomeIcon 
            onClick={toggleSpecialTrackModal} 
            icon={faCircleInfo} 
            size="lg" />
        </span>
    </span>

    // regular track tab
    const regularTrack = <span className='font-normal text-base'>
        Regular track
    </span>

    return (
        <div className={"flex flex-col"}>
            
            {/* caption */}
            <p className='text-[#3F444D] text-xl font-semibold'>
                Available courses
            </p>

            <Tabs 
            activeTab="1" 
            className={`mt-3`} 
            ulClassName="!border-[#ccc]" 
            activityClassName="!text-[#8B54F7] bg-green-700" 
            onClick={(event, tab) => console.log(event, tab)} >
                
                <Tab 
                title={regularTrack} 
                className="mb-2 mr-7 !px-2">
                    <div className="mt-4">
                        <RegularTrack 
                        loadingState={bootcampsLoading}
                        bootcamps={bootcamps} />
                    </div>
                </Tab>
            
                <Tab 
                title={specialTrack} 
                className="mr-3 mb-2 !px-2">
                    <div className="mt-4">
                        <SpecialTrack
                        loadingState={bootcampsLoading}
                        bootcamps={bootcamps} />
                    </div>
                </Tab>
            </Tabs> 

            {/* Special Track Modal */}
            <MyModal
            disableBtn={true}
            modalIsOpen={specialTrackModalState}
            toggleModal={toggleSpecialTrackModal}>
                <SpecialTrackInfoView />
            </MyModal>
        </div>
    )
}

export default AvailableCourses