import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import NoFile from "./NoFile";
import PickedFile from "./PickedFile";

const FileUploadView = ({ closeModal }) => {
  const [files, setFiles] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    noClick: isFilePicked,
    onDrop: (acceptedFiles) => {
      setIsFilePicked(true);
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(
            file,

            {
              preview: URL.createObjectURL(file),
            }
          )
        )
      );
    },
  });

  useEffect(() => {
    files.length > 0 && console.log(files);
    console.log(files.length);
  }, [files]);

  return (
    <section>
      <div
        {...getRootProps({
          className:
            "dropzone cursor-pointer p-6 flex flex-col gap-4 justify-center items-center",
        })}
      >
        <input {...getInputProps()} />

        {files.length <= 0 ? (
          <NoFile />
        ) : (
          <PickedFile file={files[0]} closeModal={closeModal} />
        )}
      </div>
    </section>
  );
};

export default FileUploadView;
