import React from 'react'
import SpaceLabeCard from './SpaceLabeCard'
import Footer2 from "../../../../../layouts/Redesign/Footer2";
import AvailableSpaces from './AvailableSpaces';

const Body = () => {
    return (
        <div className='mobile:w-screen w-full'>

            {/* Available spaces found */}
            <AvailableSpaces />

            {/* space label */}
            <SpaceLabeCard />

            {/* footer */}
            <Footer2 />

        </div>
    )
}

export default Body