import React from "react";
import { Switch, Route } from "react-router-dom";
import Event from "."
import EventDetails from "./EventDetails";

const HomepageEventRouter = ({path}) => {

    return (
        <Switch>

            <Route 
            exact 
            path={path}
            component={Event} />

            <Route 
            path={`${path}/:eventId`} 
            component={EventDetails} />
        </ Switch>
    )
}

export default HomepageEventRouter