import React from 'react'
import { CircularProgress } from '@mui/material';

const CircleLoading = ({bg="bg-white", height="h-[200px]"}) => {
    return (
        <div
        className={`flex items-center justify-center w-full py-3 px-4 ${bg} ${height}`}>

            {" "}

                <CircularProgress
                size={22} 
                style={{ color: "#0B8CBA" }} />
                
            {" "}

        </div>
    )
}

export default CircleLoading