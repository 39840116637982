import React from 'react'
import image from "../../../../assets/webpages/event/images/card-image.png"
import { useHistory, useLocation } from 'react-router-dom'

const EventCard = ({
    eventImage, 
    date, 
    venue, 
    title, 
    desc, 
    path, 
    status
}) => {

    // OBJECT INITIALIZATION
    const history = useHistory()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    params.set("status", status)

    return (
        <div className='flex flex-col [box-shadow:0px_2px_8px_rgba(0,0,0,0.12)] bg-white rounded-[10px] p-3 gap-4 h-full w-full mobile:w-full'>
            
            {/*image */}
            <div className='w-full relative flex flex-col'>
                <img src={eventImage} className="object-cover rounded-[10px] h-[184px] w-full" alt="" />
            </div>

            {/* texts */}
            <div className='flex flex-col items-start justify-center py-4 gap-3 break-words w-full mobile:w-full'>

                <p className='flex flex-col text-xs font-normal text-[#6c707a]'>
                    <span>
                        {date}
                    </span>
                    <span>
                        {venue}
                    </span>
                </p>

                <p className="text-xl xl:text-sm leading-[24px] font-semibold">
                    {title}
                </p>

                <p className="font-normal text-sm text-[#6c707a]">
                    {desc}
                </p>
                
                <p 
                onClick={()=>history.push({
                    pathname:`/events/${path}`,
                    search: params.toString()
                })}
                className="cursor-pointer font-semibold text-base text-[#0B8CBA] xl:text-sm">
                    LEARN MORE
                </p>
            </div>
        </div>
    )
}

export default EventCard